import React from "react";
import { PhotoMode } from "./PhotosView";
import { BadgeType, badgeMapper } from "../badges/Badge";
import { SellingType, Lot, SellingEvent } from "ibase-auctions-domain";
import { SummaryTemplateView } from "./SummaryTemplateView";

export type SummaryViewProps = {
    badges?:BadgeType[];
    item:Lot|SellingEvent;
    photos:string[];
    photoMode?:PhotoMode;
    sellingType?:SellingType;
    info?:any;
    onClick?:(id:string)=>void;
}

const getBadgesForItem = (allowedBadges:BadgeType[], item:any) => {
    let resultBadges:BadgeType[] = [];
    allowedBadges.forEach(x => {
        const badgeProps = badgeMapper.get(x);
        if(badgeProps !== undefined){
            if(badgeProps.check(item)){
                resultBadges.push(x);
            }
        }
    })
    return resultBadges;
} 

export const SummaryView = (props:SummaryViewProps) => {
    const { item, photoMode,sellingType,info,photos, badges=[BadgeType.FEATURED], onClick} = props;
  
    const badgesToShow = badges !== undefined && badges.length > 0 
        ? getBadgesForItem(badges, item)
        : [];
    const resultProps = {
        photoMode,
        photos,
        sellingType,
        info,
        badges:badgesToShow,
        onClick: onClick !== undefined ? ()=>{onClick(item.getId())} : ()=>{}
    };

    return <SummaryTemplateView 
        {...resultProps}
    />
}