import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { WebConfiguration } from "../../configuration/Config.types";
import { application } from "../../Startup";
import {ImageGalleryView} from './ImageGalleryView';
import "react-image-gallery/styles/css/image-gallery.css";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"ImageGaleryViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Carousel component",
    parentId:"components"
};

const items = [
    "/images/single-property-01.jpg",
    "/images/single-property-02.jpg",
    "/images/single-property-03.jpg",
    "/images/single-property-04.jpg",
    "/images/single-property-05.jpg",
].map(x => {return {
    url:`${application.getContainer().resolve<WebConfiguration>("webConfig").baseUrl}${x}`
}});

export class ImageGaleryViewUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:any){
        console.log(result);
    }
    
    render(){
        return <ImageGalleryView photos={items as any} />;
    }
}