import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { UiTestDescription, sleepableConsoleLogger, NotificationType } from "modbin-web";
import { ScreensSessionsRequestPasswordRecoveryView } from "./RequestPasswordRecoveryView";
import { OperationStateResult, OperationState } from "../../operations/States";
import { createLocalizedNotification } from "../../../../../components/notifications/LocalizedNotificationView";

export const description:UiTestDescription = {
    id:"RequestPasswordRecoveryViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Request password recovery test",
    parentId:"components"
};

export class RequestPasswordRecoveryViewUiTest extends Component<TranslationProps,{}>{
        
    render(){

        const  passwordRecoveryRequestState = {
            state: OperationState.ERROR, 
            notification: createLocalizedNotification({
                type:NotificationType.WARNIG,
                headerKey:"common:words.unexpectedErrorTitle",
                text:"screens:public.sessions.activationInvalidNotificationText",
            }),
            name:"NotActivated"  
        };    
        return <ScreensSessionsRequestPasswordRecoveryView
            passwordRecoveryRequestState={passwordRecoveryRequestState}
            onRequest={(email:string)=> sleepableConsoleLogger({email:email})}
            onReactivate={()=>{}}
            t={this.props.t}
        />;
    }
}