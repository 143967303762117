import React, { Fragment } from "react"
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Price, SellingEventDateState } from "ibase-auctions-domain";
import { PriceInformationView } from "../../../../components/price/PriceInformationView";
import { getNumberValueAsStringOrDefault, isNotNullOrEmptyNumber } from "modbin-domain";
import { getFormatedPrice, getNextBid } from "../../../../../components/helpers/Price";

const priceStyle:any = {
    float:"right"
}
const pStyle = {
    margin:"0px"
}

const ReserveValueView = observer((props:TranslationProps & { price:Price} ) => {
    const reserve = getNumberValueAsStringOrDefault(props.price.reserve,undefined,true);
    if(reserve === undefined){
        return <Fragment></Fragment>;
    } 
    return <Fragment>
        <p style={pStyle}>
            <span><strong>{props.t("domain:sales.price.reserve")}:</strong></span>
            <span style={priceStyle}>{getFormatedPrice(reserve, props.price.currency)}</span>
        </p>
    </Fragment>;
})

const BaseValueView = observer((props:TranslationProps & { price:Price} ) => {
    const base = getNumberValueAsStringOrDefault(props.price.base,undefined,true);
    if(base === undefined){
        return <Fragment></Fragment>;
    } 
    return <Fragment>
        <p style={pStyle}>
            <span><strong>{props.t("domain:sales.price.base")}:</strong></span>
            <span style={priceStyle}>{getFormatedPrice(base, props.price.currency)}</span>
        </p>
    </Fragment>;
})


const NextBidView = observer((props:TranslationProps & { price:Price, bids:number} ) => {
    const nextBid = getNextBid(props.price);
    return <Fragment>
        <p style={pStyle}>
            <span><strong>{props.t("domain:sales.price.nextBid")}: </strong></span>
            <span style={priceStyle}>{getFormatedPrice(nextBid.value, nextBid.currency)}</span>
        </p>
        <ReserveValueView {...props}/>
        <BaseValueView {...props}/>
        <p style={pStyle}>
            <span><strong>Número Licitações: </strong></span>
            <span style={priceStyle}>{props.bids}</span>
        </p>
    </Fragment>;
})

const getPriceDefinition = (price:Price, bids:number, dateState:SellingEventDateState,t:any):{label:string,current:number,moreView:any} =>{
    const result:any = {
        label:t("domain:sales.price.reserve"),
        current:undefined,
        bids: 0,
        moreView:undefined
    }
    if(dateState === SellingEventDateState.NOTSTARTED){
        result.current = price.reserve;
        return result;
    }
    const hasBids = isNotNullOrEmptyNumber(price.current!,true);
    if(!hasBids){
        result.label = t("domain:sales.price.noBids");
    }
    if(dateState === SellingEventDateState.ENDED){
        if(!hasBids){
            return result;
        }
        result.label = t("domain:sales.price.current");
        result.current = price.current;
        return result;
    }
    if(hasBids){
        result.label = t("domain:sales.price.current");
        result.current = price.current;
    }

    result.moreView = <NextBidView price={price} bids={bids} t={t}></NextBidView>;
    return result;
}

export const OnlineAuctionPriceView = observer((props:TranslationProps &{price:Price, bids:number, dateState:SellingEventDateState}) => {
    const {label, current, moreView} = getPriceDefinition(props.price,props.bids, props.dateState,props.t);
    return <PriceInformationView
        label={label}
        currency={props.price.currency}
        value={current}
        moreView={moreView}
        t={props.t}
    ></PriceInformationView>
});
