"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sleepableConsoleLogger = void 0;

var Helpers_types_1 = require("./Helpers.types");

exports.sleepableConsoleLogger = function (data, timeout) {
  console.log(data);
  return Helpers_types_1.sleep(timeout).then(function (x) {
    return Promise.resolve();
  });
};