import React, { CSSProperties } from "react"
import { observer } from "mobx-react";
import { SpinnerProps, Spinner} from "./Spinner";

export const NoResults = (props:{text:string})=>{
    return <div className="notification notice">
            <p>{props.text}</p>
        </div>
}

export enum ElementPosition {
    LEFT = "left",
    CENTER = "center",
    RIGHT = "right"
}

export type LoadingViewProps = {
    isLoading:boolean;
    fallbackView?:any;
    showFallback?:boolean;
    spinner?:SpinnerProps;
    children:any;
    position?:ElementPosition;
    vAligned?:boolean;
}

export const LoadingView = observer((props:LoadingViewProps) => {
    const sizes = {
        small:1,
        medium:2,
        large:4
    }
    const {showFallback=false,fallbackView,spinner,position=ElementPosition.CENTER,isLoading,children, vAligned=false} = props;
    
    const size = spinner === undefined || spinner.size === undefined
        ? "large"
        : spinner.size;
    const styles = {
            left: {
            },
            center: {
                left: "50%",
                marginLeft: `-${sizes[size]*16}px`,
                position: vAligned ? "absolute" : "relative",
                marginTop: vAligned ? `-${sizes[size]*16}px` : `${sizes[size]*16}px`,
                marginBottom:`${sizes[size]*16}px`,
                top: vAligned ? "50%" : ""
            },
            right: {
                float:"right",
                marginTop:`${sizes[size]*16}px`,
                marginBottom:`${sizes[size]*16}px`
            }       
        };
    let style = styles[position] as  CSSProperties;   
    const resultSpinner = <div style={style}><Spinner {...spinner}></Spinner></div>;
    return isLoading 
        ? resultSpinner
        : ((showFallback) 
            ? fallbackView
            : children);
});