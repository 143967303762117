import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { createLocalizedNotification } from '../../../../../components/notifications/LocalizedNotificationView';
import { NotificationType } from 'modbin-web';
import { LoginViewStore } from '../LoginViewStore';
import { RequestPasswordRecoveryForm } from '../password-recovery/RequestPasswordRecoveryForm';
import { OperationStateResult, OperationState } from '../../operations/States';
import { LoadingView } from '../../../../../components/loading/LoadingView';

@observer
export class ScreensSessionsReactivationView extends Component<TranslationProps & {
        reactivationState:OperationStateResult,
        onResend:(email:string)=>Promise<void>
    },{}> {

    createBody(){
        const body = this.props.reactivationState.state  !== OperationState.SUCCESS
           ?  <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <RequestPasswordRecoveryForm t={this.props.t} onRequest={(email:string) => {return this.props.onResend(email)}}></RequestPasswordRecoveryForm>
                </div>
            </div>
            : <Fragment />;
        return <Fragment>          
            {this.props.reactivationState.notification!}
            {body}
        </Fragment>;
    }
    
    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const isLoading = this.props.reactivationState.state === "loading";
        return <div>
            <TitleBar title={getTranslationSessions("reactivationTitle")}></TitleBar>
            <LoadingView
                isLoading={isLoading}
            >                    
                <div className="container">
                    {this.createBody()}
                </div>
            </LoadingView>
        </div>
    }
}

@inject("loginViewStore") @observer
export class ScreensSessionsReactivation extends Component<TranslationProps & {loginViewStore:LoginViewStore},{}> {
    
    render() {
        return <ScreensSessionsReactivationView 
            t={this.props.t} 
            reactivationState={this.props.loginViewStore!.reactivationState}
            onResend={(email:string) => this.props.loginViewStore!.onReactivateAccount(email)}
        />;
    }
}