import { observable, computed } from "mobx";
import { SubCategory, ISubCategoryRepository, County, Parish } from "ibase-auctions-domain";
import { LotQuery } from "../../lots/Store";
import { AddressStore } from "./AddressStore";
import { BaseStore, IStore } from "modbin-web";

const defaultQuery = {
    category: undefined,
    subCategory: undefined,
    region:undefined,
    county:undefined,
    parish:undefined,
    maximumValue:undefined,
    minimunValue:undefined,
    term:undefined,
    active:true
}

export class SearchStore extends BaseStore implements IStore {
    
    @observable query:LotQuery = defaultQuery;

    @observable subCategoryItems:SubCategory[] = [];

    @observable showMore = false;

    constructor(private subCategoryRepository:ISubCategoryRepository, private addressStore:AddressStore){
        super();
    }

    initializeFromQuery(query:LotQuery):Promise<void>{
        if(query!== undefined && Object.keys(query).length !== 0 && query.constructor === Object){
            if(query.category !== undefined){
                this.subCategoryItems = this.subCategoryRepository.findAllSubCategoriesFromCategory(query.category!)!;
            }
            return this.initializeAddress(query).then((x)=>{                
                Object.assign(this.query,query);
                this.initializeShowMore();
            });
        }
        return Promise.resolve();

    }

    initializeRegion(region:string, county:string):Promise<void>{
        return this.addressStore.fetchRegions().then((x)=>{
            return this.addressStore.fetchCounties(region).then((x)=> {
                if(county !== undefined){
                    return this.addressStore.fetchParishes(region,county).then((x)=>{return Promise.resolve()});
                }
                return Promise.resolve();
            })
        });
    }

    initializeAddress(query:LotQuery):Promise<void>{
        const {region, county} = query;
        if(region !== undefined){
            return this.initializeRegion(region, county!);
        }
        return Promise.resolve();
    }



    initializeShowMore(){
        this.showMore = this.query.category !== undefined || this.query.minimumValue !== undefined || this.query.maximumValue !== undefined;
    }

    onShowMoreChange(ev:any){
        ev.preventDefault();
        if(this.showMore) {
            this.query.category = undefined;
            this.query.subCategory = undefined;
            this.query.minimumValue = undefined;
            this.query.maximumValue = undefined;
        }
        this.showMore = !this.showMore;
    }

    onCategoryChange(v:string){
        this.onDefaultableFormFieldChange("category",v);
        this.onDefaultableFormFieldChange("subcategory","0");
        this.subCategoryItems = this.subCategoryRepository.findAllSubCategoriesFromCategory(v)!;
    }

    onFormFieldChange(name:string,value:string){
        this.query[name] = value;
    }

    onDefaultableFormFieldChange(name:string, value:string){
        this.query[name] = value === "0" 
            ? undefined
            : value;
    }

    getDefaultableFieldValue(name:string):string{
        return this.query[name] === undefined
            ? "0"
            : this.query[name];
    }

    onRegionChange(regionCode:string){
        if(regionCode === this.query.region){
            return;
        }
        this.onDefaultableFormFieldChange("county","0");
        this.onDefaultableFormFieldChange("parish","0");
        this.onDefaultableFormFieldChange("region",regionCode);
        this.addressStore.onRegionChanged(this.query.region!);
    }

    onCountyChange(countyCode:string){
        if(countyCode === this.query.county){
            return;
        }
        if(countyCode === "0"){
            this.onDefaultableFormFieldChange("parish","0");
        }
        this.query.county = countyCode;
        this.onDefaultableFormFieldChange("county",countyCode);
        this.addressStore.onCountyChanged(this.query.region!,this.query.county);
    }


    @computed
    get Regions(){
        return this.addressStore.getRegions();
    }

    @computed
    get Counties():County[]{
        return this.addressStore.getCountiesFromRegion(this.query.region!);
    }

    @computed
    get Parishes():Parish[]{
        return this.addressStore.getParishesFromCounty(this.query.county!);
    }

    reset(){
        this.showMore = false;
        this.query = defaultQuery;
        return Promise.resolve();
    }
}

