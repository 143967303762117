import React, { Component, Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { OperationStateResult, OperationState } from "../../operations/States";
import { RequestPasswordRecoveryForm } from "./RequestPasswordRecoveryForm";
import { LoadingView } from "../../../../../components/loading/LoadingView";
import { TitleBar } from "../../../../../components/layout/header/TitleBar";
import { inject, observer } from "mobx-react";
import { PasswordRecoveryViewStore } from "./PasswordRecoveryViewStore";
import { MainAppViewStore } from "../../../../../configuration/stores/MainAppViewStore";

export class ScreensSessionsRequestPasswordRecoveryView extends Component<TranslationProps & 
    {
        passwordRecoveryRequestState:OperationStateResult,
        onRequest:(email:string)=>Promise<void>,
        onReactivate:()=>void
    },{}> {
    createBody(){
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`); 
        const body = this.props.passwordRecoveryRequestState.name === "NotActivated"
            ? <div style={{textAlign:"center"}}><button className="button" onClick={()=>{this.props.onReactivate()}}>{getTranslationButtons("resendEmail")}</button></div>
            : ((this.props.passwordRecoveryRequestState.state !== OperationState.SUCCESS)
                ? <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                        <RequestPasswordRecoveryForm t={this.props.t} onRequest={(email:string) => {return this.props.onRequest(email)}}></RequestPasswordRecoveryForm>
                    </div>
                </div>
                : <Fragment />);
        return <Fragment>          
            {this.props.passwordRecoveryRequestState.notification!}
            {body}
        </Fragment>;
    }

    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const isLoading = this.props.passwordRecoveryRequestState.state === "loading";
        return <div>
            <TitleBar title={getTranslationSessions("requestPasswordRecoveryTitle")}></TitleBar>
            <LoadingView
                isLoading={isLoading}
            >                    
                <div className="container">
                    {this.createBody()}
                </div>
            </LoadingView>
        </div>;
    }
}

@inject("passwordRecoveryViewStore","mainAppViewStore") @observer
export class ScreensSessionsRequestPasswordRecovery extends Component<TranslationProps & {passwordRecoveryViewStore:PasswordRecoveryViewStore, mainAppViewStore:MainAppViewStore},{}> {
    
    render() {
        return <ScreensSessionsRequestPasswordRecoveryView 
            t={this.props.t} 
            passwordRecoveryRequestState={this.props.passwordRecoveryViewStore.passwordRecoveryRequestState}
            onRequest={(email:string) => { return this.props.passwordRecoveryViewStore.requestPasswordRecovery(email)}}
            onReactivate={() => { this.props.mainAppViewStore!.navigateTo("public:sessions:reactivation")}}
        ></ScreensSessionsRequestPasswordRecoveryView>
    }
}