"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contactsTranslations = void 0;
exports.contactsTranslations = {
  pt: {
    contact: {
      mobilePhoneNumber: "Telemóvel",
      landPhoneNumber: "Telefone",
      faxNumber: "Fax",
      email: "Email"
    },
    address: {
      street: "Morada",
      zipCode: "Código postal",
      city: "Cidade",
      country: "País",
      region: "Distrito",
      county: "Concelho",
      parish: "Freguesia",
      zipCode1MustBeNumber: "O valor tem que ser um número",
      zipCode1Length: "Exatamente 4 números",
      zipCode2MustBeNumber: "O valor tem que ser um número",
      zipCode2Length: "Exatamente 3 números",
      zipCodeMustBePositiveNumber: "O número tem que ser positivo"
    },
    geographicLocation: {
      latitude: "Latitude",
      longitude: "Longitude"
    }
  },
  en: {
    contact: {
      mobilePhoneNumber: "Mobile",
      landPhoneNumber: "Land",
      faxNumber: "Fax",
      email: "Email"
    },
    address: {
      street: "Street",
      zipCode: "Zip code",
      city: "City",
      country: "Country",
      region: "Region",
      county: "County",
      parish: "Parish",
      zipCode1MustBeNumber: "Provided value must be a number",
      zipCode1Length: "Exactly 4 numbers",
      zipCode2MustBeNumber: "Provided value must be a number",
      zipCode2Length: "Exactly 3 numbers",
      zipCodeMustBePositiveNumber: "Provided number must be positive"
    },
    geographicLocation: {
      latitude: "Latitude",
      longitude: "Longitude"
    }
  }
};