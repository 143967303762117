import React from "react";
import { withTranslation } from "react-i18next";
import { NavigationLink, ReturnableNavigationLink } from "./NavigationLink";
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";

export const InnerLoginWidget = observer((props:TranslationProps) => {
    return  <ReturnableNavigationLink to="public:sessions:login">
        <i className="fa fa-user"></i> {`${props.t("buttons.login")} / ${props.t("buttons.register")}`}
    </ReturnableNavigationLink>;
});

export const LoginWidget = withTranslation(["common"])(InnerLoginWidget as any) as any;
