import React from "react";
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Price } from "ibase-auctions-domain";
import { PriceInformationView } from "./PriceInformationView";

export const DefaultBasePriceView = observer((props:TranslationProps &{price:Price}) => {
    let label = props.t("domain:sales.price.base");
    return <PriceInformationView
        label={label}
        currency={props.price.currency}
        value={props.price.base}
        bids={0}
        t={props.t}
    ></PriceInformationView>
});
