import React from "react"
import { NotificationView } from "../notifications/NotificationView";
import { withTranslation } from "react-i18next";
import { TranslationProps } from "modbin-domain";
import { NotificationType } from "modbin-web";

export const InnerNoResultsView = (props:TranslationProps & {text:string, headerText?:string})=>{
    const resultHeaderText = props.headerText !== undefined 
        ? props.headerText
        : props.t("common:words.noResultsHeader")
    return <NotificationView type={NotificationType.INFO} content={<p>{props.text}</p>} header={resultHeaderText}></NotificationView>;
}

export const NoResultsView = (withTranslation(["common"])(InnerNoResultsView as any) as any) as (props:any) => JSX.Element;
