import React from "react";
import { CarouselView } from "../../../../components/carousel/CarouselView";

const SinglePhotoView = (props:{item:string, onClick?:()=>void}) => {
    const resultProps = {
        className: "carousel-img-wrapper",
        style: {
            width: "100%",
            height: "100%",
            backgroundImage: `url("${props.item}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%"
        },
        onClick: props.onClick !== undefined 
            ? () => { 
                props.onClick!();
            } 
            : () => {}
    } 
    return <div {...resultProps} />;
}

export type PhotoMode = "single" | "multiple";

export const PhotosView = (props:{items:string[], mode?:PhotoMode, onClick?:()=>void}) => {
    const {mode="single", items, onClick=()=>{}} = props; 
    if(mode === "single"){
        return <SinglePhotoView item={items[0]} onClick={()=>{onClick()}}></SinglePhotoView>
    }
    return  <CarouselView 
        infinite={true}
        autoPlay={true}
        itemComponent={SinglePhotoView}
        items={props.items}
        slidesPerStep={1}
        visibleItems={1}
        showDots={false}
        onItemClick={(index)=>{}}
        onSlideChange={(index)=>{}}
        selectedSlide={1}
    />;
}