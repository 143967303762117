import { TranslationProps } from "modbin-domain";
import React from "react";
import { DocumentFile } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { LoadingView } from "../../../../../components/loading/LoadingView";
import { DownloadButton } from "../../../../../components/files/DownloadButton";

export const VirtualVisitView = observer((props:TranslationProps & {visita_virtual:string}) => {
    const body = props.visita_virtual !== undefined && props.visita_virtual !== "null"
        ? <ul className="listing-documents">
             <a className="button fullwidth margin-top-10" href={props.visita_virtual} target="_blank">Ver a visita</a>
        </ul>  
        : props.t("screens:business.lots.details.noVirtualVisit");
    return <div id="booking-widget-anchor" className="boxed-widget booking-widget margin-top-35">
        <h3 style={{textTransform: "uppercase"}}>{props.t("screens:business.lots.details.virtualVisitTitle")}</h3>
        <LoadingView spinner={{size:"medium"}} isLoading={false}>
            {body}
        </LoadingView>
    </div>;
});
