"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Category = void 0;
var Category;

(function (Category) {
  Category["REALESTATE"] = "realestate";
  Category["VEHICLE"] = "vehicle";
  Category["HARDWARE"] = "hardware";
  Category["FORNITURE"] = "forniture";
  Category["MACHINE"] = "machine";
  Category["CLAIM"] = "claim";
  Category["OTHER"] = "other";
})(Category = exports.Category || (exports.Category = {}));