"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromMaritalStatus = exports.convertToMaritalStatus = void 0;

var Client_types_1 = require("../../client/Client.types");

var modbin_domain_1 = require("modbin-domain");

var DoubleConverter_1 = require("../shared/DoubleConverter");

var maritalStatusMappper = {
  "casado": Client_types_1.MaritalStatus.MARRIED,
  "solteiro": Client_types_1.MaritalStatus.SINGLE,
  "divorciado": Client_types_1.MaritalStatus.DIVORCED,
  "viuvo": Client_types_1.MaritalStatus.WIDOW
};
var doubleConverter = DoubleConverter_1.createDoubleConverter(maritalStatusMappper);

exports.convertToMaritalStatus = function (estadoCivil) {
  if (modbin_domain_1.isNullOrEmptyString(estadoCivil) || maritalStatusMappper[estadoCivil] === undefined) {
    return undefined;
  }

  return doubleConverter.toType[estadoCivil];
};

exports.convertFromMaritalStatus = function (maritalStatus) {
  return doubleConverter.fromType[maritalStatus];
};