import React from "react";
import { TranslationProps } from "modbin-domain";
import { LotDetailsView } from "../LotDetailsView";
import { Lot, SellingEventDateState } from "ibase-auctions-domain";
import { OnlineAuctionBidForm } from "./OnlineAuctionBidForm";
import { observer, inject } from "mobx-react";
import { SessionStore } from "../../../../../../public/sessions/SessionStore";
import { LotDetailsViewStore } from "../../ViewStore";
import { OnlineAuctionPriceView } from "./OnlineAuctionPriceView";
import { LotTimeInformationView } from "../../../../components/time/LotTimeInformationView";
import { getNextBid } from "../../../../../components/helpers/Price";

export const OnlineAuctionDetailsView = inject("sessionStore","lotDetailsViewStore")(observer((props:TranslationProps & {
        item:Lot,
        dateState:SellingEventDateState,
        sessionStore?:SessionStore,
        lotDetailsViewStore?:LotDetailsViewStore 
    }) => { 
    const {item:{price,bids},item,dateState,t} = props;
    return <LotDetailsView
        item={item}
        biddingView={{
            loggedIn:  <OnlineAuctionBidForm 
                onBid={(value:number)=>props.lotDetailsViewStore!.onBid(value, props.sessionStore!.user)} 
                t={t} 
                bidderState={props.lotDetailsViewStore!._currentBidderState}
                minimumBid={getNextBid(price)}/>
        }}
        t={props.t}
        priceView={<OnlineAuctionPriceView dateState={dateState} price={price} bids={bids.length} t={t}></OnlineAuctionPriceView>}
        timeView={<LotTimeInformationView t={props.t} item={item} showClock={true} showDate={true}></LotTimeInformationView>}
        dateState={dateState}
        isLoadingPrice={props.lotDetailsViewStore!.isLoadingPrices}
    ></LotDetailsView>
}))
