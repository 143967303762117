"use strict"; // import { getLoggerForModule } from "../../loggers/Debuger";
// var logger = getLoggerForModule(__filename,"StringComparer");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringComparer = void 0;

exports.stringComparer = function (first, second) {
  if (!first) return -1;
  if (!second) return 1; //    logger('first %s, second %s', first, second);

  return first.toLowerCase() < second.toLowerCase() ? -1 : 1;
};