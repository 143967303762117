import React from "react"
import { NotificationType } from "modbin-web";
import { LocalizedNotificationView } from "../notifications/LocalizedNotificationView";

export const NotFoundErrorMessage = ()=>{
    return <LocalizedNotificationView 
        type={NotificationType.WARNIG} 
        headerKey="common:words.notFoundErrorTitle"
        textKey="common:words.notFoundErrorMessage">
    </LocalizedNotificationView>;
}