import { observable } from "mobx";
import { OperationStateResult, unknownState, loadingState, OperationState, invalidPasswordRecoveryParametersState, requestPasswordRecoverySuccessState, passwordRecoverySuccessState } from "../../operations/States";
import { ISessionService } from "ibase-auctions-domain";
import { createLocalizedNotification } from "../../../../../components/notifications/LocalizedNotificationView";
import { isNullOrEmptyString, getStringValueOrDefault, isNotNullOrEmptyString, ILogger } from "modbin-domain";
import { NotificationType, BaseStore, IStore } from "modbin-web";

export class PasswordRecoveryViewStore extends BaseStore implements IStore{
    @observable passwordRecoveryRequestState:OperationStateResult= unknownState;
    @observable passwordRecoveryState:OperationStateResult= unknownState;
    @observable recoveryToken:string|undefined = undefined;

    constructor(
            private sessionService:ISessionService,
            private logger:ILogger
        ){
            super();
    }

    recoverPassword(newPassword:string):Promise<void>{
        this.passwordRecoveryState = loadingState;
        const passwordRecoveryData = { password:newPassword, recoveryToken:this.recoveryToken!};
        return this.sessionService.recoverUserPassword(passwordRecoveryData)
        .then(
            (x:any)=> {
                this.passwordRecoveryState = passwordRecoverySuccessState;    
            },
            (x:any)=> {
                this.logger.error("Error in recoverPassword method in PasswordRecoveryViewStore:",x);
                this.passwordRecoveryState = {
                    state: OperationState.ERROR, 
                    notification: createLocalizedNotification({
                        type:NotificationType.ERROR,
                        headerKey:"common:words.unexpectedErrorTitle",
                        text:x.message
                    })};    
            },
        );
    }

    requestPasswordRecovery(email:string):Promise<void>{
        this.passwordRecoveryRequestState = loadingState;
        return this.sessionService.requestUserPasswordRecovery(email)
        .then(
            (x:any)=> {
                this.passwordRecoveryRequestState = requestPasswordRecoverySuccessState;    
            },
            (x:any)=> {
                if(isNotNullOrEmptyString(x.message as string) && (x.message as string).startsWith("Para fazer recuperação de password, terá de validar o seu email")){
                    this.logger.warning(`Error in requestPasswordRecovery method in PasswordRecoveryViewStore. Not validated email yet (${email}):`,x);
                    this.passwordRecoveryRequestState = {
                        state: OperationState.ERROR, 
                        notification: createLocalizedNotification({
                            type:NotificationType.WARNIG,
                            headerKey:"screens:public.sessions.activationInvalidNotificationTitle",
                            textKey:"screens:public.sessions.activationInvalidNotificationText"
                        }),
                        name:"NotActivated"  
                    };  
                }
                else {
                    this.logger.error(`Error in requestPasswordRecovery method in PasswordRecoveryViewStore (email: ${email}):`,x);
                    this.passwordRecoveryRequestState = {
                        state: OperationState.ERROR, 
                        notification: createLocalizedNotification({
                            type:NotificationType.ERROR,
                            headerKey:"common:words.unexpectedErrorTitle",
                            text:x.message
                        })};
                }    
            },
        );
    }

    setState(params:{recoveryToken:string}){   
        if(params === undefined || isNullOrEmptyString(params.recoveryToken)){
            this.passwordRecoveryState = invalidPasswordRecoveryParametersState;    
            this.recoveryToken = undefined;
            return;
        }
        this.recoveryToken = params.recoveryToken;
    }

    getState(){
        return {"recoveryToken": getStringValueOrDefault(this.recoveryToken,"")};
    }

    reset(){
        this.passwordRecoveryRequestState = unknownState;
        this.passwordRecoveryState = unknownState;
        this.recoveryToken = undefined;
        return Promise.resolve();
    }

    leave(){
        this.reset();
    }
}