"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.singleResponseConverter = void 0;

exports.singleResponseConverter = function (response) {
  var result = response.data && response.error !== undefined && response.error_message !== undefined && response.sugestion !== undefined ? response.data : response;
  return result;
};