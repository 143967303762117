"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsoleLoggerProvider = void 0;

var ConsoleLogger_1 = require("./ConsoleLogger");

var ConsoleLoggerProvider =
/** @class */
function () {
  function ConsoleLoggerProvider() {}

  ConsoleLoggerProvider.prototype.getIdentifier = function () {
    return "Console";
  };

  ConsoleLoggerProvider.prototype.create = function (level, properties) {
    var consoleLogger = new ConsoleLogger_1.ConsoleLogger(level);
    return consoleLogger;
  };

  return ConsoleLoggerProvider;
}();

exports.ConsoleLoggerProvider = ConsoleLoggerProvider;