import React, { Fragment } from "react";
import { AuctionClockView } from "./ClockCountdownView";
import { ClockDate } from "ibase-auctions-domain";
import { DateInformationView } from "./DateInformationView";
import { observer, inject } from "mobx-react";
import { TranslationProps, ITimerService } from "modbin-domain";
import { application } from "../../../../Startup";
import { IBaseClockCountDownView } from "../../../../components/clock/IBaseCountDownClockView";
import { IServerTimeService } from "../../../../configuration/temp/services/ServerTimeService";

export const TimeInformationView = observer((props:TranslationProps&{clockDate:ClockDate,showClock:boolean,showDate:boolean}) => {
    const {clockDate, showClock, showDate, t} = props;
    const timeService = application.getContainer().resolve<IServerTimeService>("timeService");
    const clockView = showClock
        ? <IBaseClockCountDownView size="medium" type="extended" clockDate={clockDate} timeService={timeService} t={t} validatingTimeout={20000}/>
        : <Fragment></Fragment>;
    // console.log("-----CLOCK------")
    // console.log("Clock-start:",clockDate.start)
    // console.log("Clock-end:",clockDate.end)
    // console.log("Clock-now:",timerService.getCurrentDate())
    // console.log("Client-date-now:",new Date(Date.now()))
    const datesView = showDate 
        ? <DateInformationView t={t} startDate={clockDate.start} endDate={clockDate.end}></DateInformationView>
        : <Fragment></Fragment>;
    return <Fragment>
        
            {clockView}
            <div className="boxed-widget booking-widget">
            {datesView}        
            </div>
        </Fragment>;
});

