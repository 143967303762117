"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MachineSubCategory = void 0;
var MachineSubCategory;

(function (MachineSubCategory) {
  MachineSubCategory["FARMING"] = "farming";
  MachineSubCategory["CONSTRUCTION"] = "construction";
  MachineSubCategory["HARDWARE"] = "hardware";
  MachineSubCategory["INDUSTRY"] = "industry";
  MachineSubCategory["OTHER"] = "othermachine";
})(MachineSubCategory = exports.MachineSubCategory || (exports.MachineSubCategory = {}));