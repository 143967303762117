"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeFirstAndLastIfEqualsTo = void 0;

var Strings_1 = require("./Strings");

exports.removeFirstAndLastIfEqualsTo = function (target, match) {
  if (Strings_1.isNullOrEmptyString(target)) {
    throw new Error("Target string cannot be null, undefined or empty.");
  }

  if (Strings_1.isNullOrEmptyString(match)) {
    return target;
  }

  var matchLength = match.length,
      targetLength = target.length;

  if (matchLength > targetLength) {
    return target;
  }

  var startIndex = target.startsWith(match) ? matchLength : 0;
  var endIndex = target.endsWith(match) ? targetLength - matchLength : targetLength;

  if (startIndex === 0 && endIndex === targetLength) {
    return target;
  }

  return target.slice(startIndex, endIndex);
};