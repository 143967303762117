import React, { Fragment } from "react";
import { GoogleMap } from "./GoogleMap";
import { GeographicLocation } from "ibase-auctions-domain";


export type MapMarker = {
    location:GeographicLocation;
}

export type MapProps = {
    type: "single" | "multiple" | "business";
    center: GeographicLocation;
    width:number | string;
    height:number | string;
    zoom:number;
    markers: MapMarker[];
}

export const MapView = (props:MapProps) => {
    if(props.center === undefined){
        return <Fragment></Fragment>;
    }
    const mapsMapper = {
        "business":GoogleMap,
        "single":GoogleMap,
        "multiple":GoogleMap
    }
    const Component = mapsMapper[props.type] as any;
    return <Component {...props}/>;
}