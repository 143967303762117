import React from "react";
import { AuctionClockSize, AuctionClockType, AuctionClockView } from "./ClockCountdownView";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SelectInputView } from "../../../../components/forms/elements/SelectInputView";
import { SellingEventDateState } from "ibase-auctions-domain";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"AuctionClockViewUiTest1",
    groupId:"components",
    description:"lalal",
    title:"Clock testins",
    parentId:"components"
};

const dateStateTest:any = {
    "ended" : {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T12:00:00')
    },
    "days":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:58:50')
    },
    "day":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:59:50')
    },
    "hour":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T09:59:50') 
    },
    "minute":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T10:59:50') 
    },
    "notstarted": {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T09:00:00')
    }
};

const DateStateClockView = (props:{title:string,dateState:string,size:AuctionClockSize,type:AuctionClockType}) => {
    const newDateState = dateStateTest[props.dateState];
    return <div className="col-md-4">
        <h5>{props.title}</h5>
        <AuctionClockView
            size={props.size}
            type={props.type}
            startDate={newDateState.start}
            endDate={newDateState.end}
            nowDate={newDateState.now}
        ></AuctionClockView>
    </div>;
}

@observer
export class AuctionClockViewUiTest extends React.Component<{},{}>{
    @observable size: AuctionClockSize = "small";
    @observable type:AuctionClockType = "extended"

    render(){
        return <div>
                <div className="col-md-12">
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"small",text:"small"},{value:"medium", text:"medium"},{value:"large",text:"large"}]}
                            label="Size"
                            onChange={(v:any)=> {this.size = v}}
                            selected={this.size}
                            key="selector:size"
                        ></SelectInputView>
                    </div>
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"extended",text:"extended"},{value:"simple", text:"simple"}]}
                            label="Size"
                            onChange={(v:any)=> {this.type = v}}
                            selected={this.type}
                            key="selector:type"
                        ></SelectInputView>
                    </div>
                </div>
                <div className="col-md-12">
                    <h4>Dates state</h4>
                    <DateStateClockView title="Not started yet" dateState={SellingEventDateState.NOTSTARTED} size={this.size} type={this.type} />
                    <DateStateClockView title="Days left" dateState={SellingEventDateState.DAYS} size={this.size} type={this.type} />
                    <DateStateClockView title="Less than a day left" dateState={SellingEventDateState.DAY} size={this.size} type={this.type} />
                    <DateStateClockView title="Less than an hour left" dateState={SellingEventDateState.HOUR} size={this.size} type={this.type} />
                    <DateStateClockView title="Less than five minutes left" dateState={SellingEventDateState.MINUTE} size={this.size} type={this.type} />
                    <DateStateClockView title="Ended" dateState={SellingEventDateState.ENDED} size={this.size} type={this.type} />
                </div>
        </div>
    }
};