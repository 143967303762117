import { ErrorMessage } from "modbin-api";
import React, { Fragment } from "react";
import { UserNotification } from "modbin-web";
import {NotificationView} from "./NotificationView";
import { getStringValueOrDefault, getNumberValueAsStringOrDefault } from "modbin-domain";
import { observer } from "mobx-react";

const ErrorDetailsView = observer((props:{error:ErrorMessage}) => {
    if(props.error === undefined){
        return <Fragment/>;
    }
    const {status, type, domainCode, inner} = props.error;
    return <div>
        <div><span><strong>Type: </strong> {getStringValueOrDefault(type,"N/A",{includeEmpty:true})}</span></div>        
        <div><span><strong>Http status: </strong> {status}</span></div>
        <div><span><strong>Domain code: </strong> {getNumberValueAsStringOrDefault(domainCode,"N/A")}</span></div>
        <div><span><strong>Inner: </strong> {getStringValueOrDefault(inner,"N/A",{includeEmpty:true})}</span></div>
    </div>
})

export const DeveloperNotification = observer((props:{notification:UserNotification}) => {

    const {title=undefined, message, error, type} = props.notification;
    const content = <div>
        <div><span><strong>Message: </strong> {getStringValueOrDefault(message,"N/A",{includeEmpty:true})}</span></div>
        <ErrorDetailsView error={error}/>
    </div>;
    return <NotificationView className="toast" type={type!} content={content} header={title!}></NotificationView>;
})

