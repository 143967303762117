"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FornitureSubCategory = void 0;
var FornitureSubCategory;

(function (FornitureSubCategory) {
  FornitureSubCategory["JEWELRY"] = "jewelry";
  FornitureSubCategory["COMMODITY"] = "commodity";
  FornitureSubCategory["OFFICE"] = "office";
  FornitureSubCategory["HOME"] = "home";
  FornitureSubCategory["HEALTH"] = "health";
  FornitureSubCategory["DECOR"] = "decor";
  FornitureSubCategory["OTHER"] = "otherforniture";
})(FornitureSubCategory = exports.FornitureSubCategory || (exports.FornitureSubCategory = {}));