"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromSellingType = exports.convertToSellingType = void 0;

var Client_types_1 = require("../../client/Client.types");

var DoubleConverter_1 = require("../shared/DoubleConverter");

var sellingTypeConverter = {
  "leilao_online": Client_types_1.SellingType.ONLINEAUCTION,
  "leilao_presencial": Client_types_1.SellingType.PRESENTIALAUCTION,
  "carta_fechada": Client_types_1.SellingType.CLOSEDLETTER,
  "negociacao_particular": Client_types_1.SellingType.PRIVATENEGOTIATION
};
var doubleConverter = DoubleConverter_1.createDoubleConverter(sellingTypeConverter);

exports.convertToSellingType = function (externalSellingType) {
  return doubleConverter.toType[externalSellingType];
};

exports.convertFromSellingType = function (sellingType) {
  return doubleConverter.fromType[sellingType];
};