import React, { ReactNode } from "react";
import { TopHeader } from "./TopHeader";
import { DevelopmentBar, getInformationBarProps } from "../../support/DevelopmentBar";

export const Header = (props:{children?:ReactNode|undefined}) => {
    const properties = getInformationBarProps();
    return <header id="header-container">
        <DevelopmentBar properties={properties}/>
        <TopHeader/>
        {props.children}
    </header>;
}