"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.propertyComparerWithDirection = exports.getValuesToCompareByPropertyGetter = exports.getValuesToCompareByPropertyName = exports.propertyComparer = void 0;

var Sortable_types_1 = require("./Sortable.types");

exports.propertyComparer = function (first, second, comparer) {
  return comparer(first, second);
};

exports.getValuesToCompareByPropertyName = function (first, second, propertyName) {
  return {
    first: first[propertyName],
    second: second[propertyName]
  };
};

exports.getValuesToCompareByPropertyGetter = function (first, second, getter) {
  return {
    first: getter(first),
    second: getter(second)
  };
};

exports.propertyComparerWithDirection = function (first, second, comparer, direction) {
  var result = exports.propertyComparer(first, second, comparer);
  if (direction === Sortable_types_1.SortDirection.DESC) result = -result;
  return result;
};