import { observer, inject } from "mobx-react";
import React from "react";
import { SearchStore } from "./SearchStore";

export type AddressDropdonProps = {
    selected:string, 
    items:{ value:string, text:string}[],
    onChange:(id:string)=>void,
    defaultText:string
}

const RegionSelector = observer((props:AddressDropdonProps) => {  
    const placeHolder = [<option key={`region:default`} value="0">{props.defaultText}</option>];
    const options = props.items.map((x:any) => { 
        return <option key={`region:${x.value}`} value={x.value}>{x.text}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>;
});

const CountySelector = observer((props:AddressDropdonProps) => {  
    const placeHolder = [<option key={`county:default`} value="0">{props.defaultText}</option>];
    const options = props.items.map((x:any) => { 
    return <option key={`county:${x.value}`} value={x.value}>{x.text}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>
});

const ParishSelector = observer((props:AddressDropdonProps) => {  
    const placeHolder = [<option key={`parish:default`} value="0">{props.defaultText}</option>];
    const options = props.items.map((x:any) => { 
    return <option key={`parish:${x.value}`} value={x.value}>{x.text}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>
});


export const AddressSelector = inject("searchViewStore")(observer((props:{
        searchViewStore?:SearchStore,
        selectorClass:string;
        regionPlaceHolderText:string,
        countyPlaceHolderText:string,
        parishPlaceHolderText:string,
    }) => {  
    return <div className="row with-forms">
        <div className={props.selectorClass}>
            <RegionSelector 
                selected={props.searchViewStore!.getDefaultableFieldValue("region")} 
                items={props.searchViewStore!.Regions.map(x => {return {value:x.code, text:x.name}})} 
                onChange={(id:string)=> {props.searchViewStore!.onRegionChange(id)}}
                defaultText={props.regionPlaceHolderText}
            ></RegionSelector>
        </div>
        <div className={props.selectorClass}>
            <CountySelector 
                selected={props.searchViewStore!.getDefaultableFieldValue("county")} 
                items={props.searchViewStore!.Counties.map(x => {return {value:x.code, text:x.name}})} 
                onChange={(id:string)=> {props.searchViewStore!.onCountyChange(id)}}
                defaultText={props.countyPlaceHolderText}
            ></CountySelector>
        </div>
        <div className={props.selectorClass}>
            <ParishSelector 
                selected={props.searchViewStore!.getDefaultableFieldValue("parish")} 
                items={props.searchViewStore!.Parishes.map(x => {return {value:x.code, text:x.name}})} 
                onChange={(id:string)=> {props.searchViewStore!.onDefaultableFormFieldChange("parish",id)}}
                defaultText={props.parishPlaceHolderText}
            ></ParishSelector>
        </div>
    </div>
}));
