import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormInput } from "../../../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../../../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { application } from "../../../../../Startup";

const InnerNewPasswordForm = observer((props:TranslationProps & {onPasswordChange:(password:string)=>Promise<void>}) => {
    const getTranslationSessions = (key:string) => props.t(`screens:public.sessions.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const newPasswordSchema = Yup.object().shape({
            password: Yup.string()
            .min(5, props.t("domain:users.user.passwordMustHaveMoreThanChars"))
            .required(createRequiredMessage("domain:users.user.password")),
            passwordConfirm:Yup.string()
            .oneOf([Yup.ref("password")],props.t("domain:users.user.passwordsMustMatch"))
            .required(createRequiredMessage("domain:users.user.password"))
          });
      return (
            <Formik
                initialValues={{password: "", passwordConfirm:""}}
                validationSchema={newPasswordSchema}
                onSubmit={(values, actions) => {
                    return props.onPasswordChange(values.password).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
            >
            {(formProps) => (
                <Form>
                    <div className="form-row form-row-wide">
                        <FormInput type="password" name="password" label={getTranslationSessions("password")} placeHolder={props.t("domain:users.user.password")} formikState={formProps} />
                    </div>
                    <div className="form-row form-row-wide">
                        <FormInput type="password" name="passwordConfirm" label={getTranslationSessions("passwordConfirm")} placeHolder={props.t("domain:users.user.password")} formikState={formProps} />
                    </div>
                    <div className="form-row">
                        <ActionButton type={ButtonType.CONTINUE} onClick={()=>{return formProps.submitForm()}} className="border margin-top-10"/>
                    </div>
                </Form>
                )}
            </Formik>
      );
  });
  
  export const NewPasswordForm = (withTranslation(["common"])(InnerNewPasswordForm as any) as any) as (props:any) => JSX.Element;