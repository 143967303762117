export const buttonsTranslations = {
    pt: {
        search: "Procurar",
        close:"Fechar",
        cancel:"Cancelar",
        continue:"Continuar",
        continueLoading:"A continuar..",
        save:"Guardar",
        saveLoading:"A guardar..",
        bid:"Licitar",
        bidLoading:"A licitar..",
        generateProposal:"Gerar proposta",
        generateProposalLoading:"A gerar..",
        showMore:"Mais opções",
        showLess:"Menos opções",
        details: "Detalhes",
        subscribe:"Subscrever",
        subscribeLoading:"A subscrever..",
        login:"Entrar",
        loginLoading:"A entrar",
        logout:"Sair",
        register:"Registar",
        registerLoading:"A registar..",
        resendEmail:"Reenviar email",
        send:"Enviar",
        sendLoading:"A enviar..",
        informationRequest:"Pedido de informação",
        download:"Descarregar",
        downloadLoading:"A descarregar..",
        confirmation:"Confirmar",
        reload:"Recarregar",
        viewMore:"Ver mais",
        viewLess:"Ver menos"
    },
    en:{
        search: "Search",
        close:"Close",
        cancel:"Cancel",
        continue:"Continue",
        continueLoading:"Continuing..",
        save:"Save",
        saveLoading:"Saving..",
        bid:"Bid",
        bidLoading:"Bidding..",
        generateProposal:"Generate proposal",
        generateProposalLoading:"Generating..",
        showMore:"More options",
        showLess:"Less options",
        details: "Details",
        subscribe:"Subscribe",
        subscribeLoading:"Subscribing..",
        login:"Log in",
        loginLoading:"Logging in..",
        logout:"Log out",
        register:"Register",
        registerLoading:"Registering..",
        resendEmail:"Resend email",
        send:"Send",
        sendLoading:"Sending..",
        informationRequest:"Information request",
        download:"Download",
        downloadLoading:"Downloading..",
        confirmation:"Confirm",
        reload:"Reload",
        viewMore:"See more",
        viewLess:"See less"
    }
}