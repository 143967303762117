"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildContentTypeFormatterMapper = void 0;

var DefaultContentTypeFormatter_1 = require("./DefaultContentTypeFormatter");

exports.buildContentTypeFormatterMapper = function (options) {
  var defaultPassThroughFormatter = DefaultContentTypeFormatter_1.defaultContentTypeFormatter;
  var formatters = new Map();

  if (options !== undefined) {
    if (options.formatters !== undefined && options.formatters.length > 0) {
      formatters = new Map(options.formatters);
    }
  }

  var getFormatterForRequest = function getFormatterForRequest(contentType) {
    var result = defaultPassThroughFormatter.request;
    var handler = formatters.get(contentType);

    if (handler !== undefined && handler.request !== undefined) {
      result = handler.request;
    }

    return result;
  };

  var getFormatterForResponse = function getFormatterForResponse(contentType) {
    var result = defaultPassThroughFormatter.response;
    var handler = formatters.get(contentType);

    if (handler !== undefined && handler.response !== undefined) {
      result = handler.response;
    }

    return result;
  };

  return {
    getFormatterForRequest: getFormatterForRequest,
    getFormatterForResponse: getFormatterForResponse
  };
};