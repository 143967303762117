import React, { Component, Fragment } from 'react';
import { TranslationProps } from "modbin-domain";
import { observer, inject } from 'mobx-react';
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { LoadingView } from '../../../../../components/loading/LoadingView';
import { ProfileBidsViewStore } from './ViewStore';
import { ListView } from './components/ListView';
import { MainAppViewStore } from '../../../../../configuration/stores/MainAppViewStore';

@inject("profileBidsViewStore","mainAppViewStore") @observer   
export class ScreensProfileBids extends Component<TranslationProps&{profileBidsViewStore?:ProfileBidsViewStore, mainAppViewStore?:MainAppViewStore},{}> {
    
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:private.bids.${key}`); 
        return (
            <Fragment>
            <TitleBar title={getTranslation("title")}></TitleBar>
            <div className="container">
                    <div className="row fullwidth-layout">
                        <div className="col-md-12">
                            <div className="listings-container list-layout">
                                <LoadingView 
                                        isLoading={this.props.profileBidsViewStore!.isLoading} 
                                        showFallback={false}
                                    >
                                        <div>
                                            <ListView items={this.props.profileBidsViewStore!.getFavoriteLots()} onClick={(id:string, eventId:string) => {this.props.mainAppViewStore!.navigateTo("business:lots:details",{state:{lotId:id, eventId:eventId}})}}></ListView>
                                        </div>
                                </LoadingView> 
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}