"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultContentTypeFormatter = void 0;
exports.defaultContentTypeFormatter = {
  request: function request(body) {
    return body;
  },
  response: function response(fetchResponse) {
    return Promise.resolve(fetchResponse);
  }
};