"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToGeographicLocation = void 0;

exports.convertToGeographicLocation = function (source) {
  var result = undefined;

  if (source["latitude"] !== undefined && source["latitude"] !== null && source["longitude"] !== undefined && source["longitude"] !== null) {
    result = {
      latitude: source["latitude"].toString(),
      longitude: source["longitude"].toString()
    };
  }

  return result;
};