import React, { Component } from 'react';
import { AuthenticationView } from './components/AuthenticationView';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { LoginData } from 'ibase-auctions-domain';
import { TranslationProps } from "modbin-domain";
import { inject } from 'mobx-react';
import { LoginViewStore } from './screens/LoginViewStore';

@inject("loginViewStore")
export class ScreensSessionsIndex extends Component<TranslationProps & {loginViewStore:LoginViewStore},{}> {

    render() {
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`); 
        return <div>
            <TitleBar title={`${getTranslationButtons("login")} & ${getTranslationButtons("register")}`}></TitleBar>
            <AuthenticationView 
                onLogin={(data:LoginData) => {return this.props.loginViewStore!.onLogin(data)}}
                onRegister={(data:LoginData) => { return this.props.loginViewStore!.onRegister(data)}}
            ></AuthenticationView>                
        </div>;
    }
}