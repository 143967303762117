import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { Lot } from "ibase-auctions-domain";
import { observer } from "mobx-react";
//pagina de detalhes --- lote
export const ProcessDetailsView = observer(
  (props: TranslationProps & { item: Lot }) => {
    return (
      <Fragment>
        <h5>{props.t("screens:business.lots.details.processDetails")}</h5>
        <div>
          <ul
            className="listing-details"
            style={{ padding: "0px", backgroundColor: "white" }}
          >
            {props.item.processInsolvent != "" && props.item.processInsolvent != null ? (
                (/*props.item.processType == 'JUDICIAL' ||*/ props.item.address != null && props.item.address.street == "I") ? (
                <li style={{ display: "block", paddingLeft: "0px" }}>
                  <strong>
                    {props.t("screens:business.lots.details.insolvent")}:{" "}
                  </strong>
                  <span>{props.item.processInsolvent}</span>
                </li>
              ) : (
                <li style={{ display: "block", paddingLeft: "0px" }}>
                  <strong>
                    {props.t("screens:business.lots.details.seller")}:{" "}
                  </strong>
                  <span>{props.item.processInsolvent}</span>
                </li>
              )
            ) : null}
            <li style={{ display: "block", paddingLeft: "0px" }}>
              <strong>
                {props.t("screens:business.lots.details.process")}:{" "}
              </strong>
              <span>{props.item.processReference}</span>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
);
