"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStringValueOrDefault = exports.isNotNullOrEmptyString = exports.isNullOrEmptyString = exports.isString = void 0;

var GetValueOrDefault_1 = require("../functions/GetValueOrDefault");

exports.isString = function (str) {
  var result = typeof str === 'string' || str instanceof String;
  return result;
};

exports.isNullOrEmptyString = function (str, includeEmpty) {
  if (includeEmpty === void 0) {
    includeEmpty = false;
  }

  var result = str === undefined || str === null || !exports.isString(str) || includeEmpty && str.trim() === "";
  return result;
};

exports.isNotNullOrEmptyString = function (str, includeEmpty) {
  return !exports.isNullOrEmptyString(str, includeEmpty);
};

exports.getStringValueOrDefault = function (value, defaultValue, options) {
  var includeEmpty = false;

  var selector = function selector(x) {
    return x.trim();
  };

  if (options !== undefined) {
    if (options.includeEmpty !== undefined) {
      includeEmpty = options.includeEmpty;
    }

    if (options.selector !== undefined) {
      selector = options.selector;
    }
  }

  var validator = function validator(v) {
    return exports.isNotNullOrEmptyString(value, includeEmpty);
  };

  var resultOptions = {
    selector: selector,
    validator: validator
  };
  return GetValueOrDefault_1.getValueOrDefault(value, defaultValue, resultOptions);
};