import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { VisitBookingView } from "./VisitBookingView";
import { UiTestDescription } from "modbin-web";
import { bookingsList } from "../../../../support/tests/data/Bookings";

export const description:UiTestDescription = {
    id:"VisitBookingViewUiTest",
    groupId:"components",
    description:"Shows visit booking component. Used on Lot detais view.",
    title:"Visit bookings component",
    parentId:"components"
};

export class VisitBookingViewUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:any){
        console.log(result);
    }
    
    render(){
        return <div>
            <div>
                <h2>Shows only one booking</h2>
                <VisitBookingView t={this.props.t} bookings={[bookingsList[0]]} isLoading={false}/>
            </div>
            <div>
                <h2>Shows multiple bookings</h2>
                <VisitBookingView t={this.props.t} bookings={bookingsList} isLoading={false}/>
            </div>
        </div>;
    }
}