"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = void 0;
var NotificationType;

(function (NotificationType) {
  NotificationType["WARNIG"] = "warning";
  NotificationType["INFO"] = "info";
  NotificationType["SUCCESS"] = "success";
  NotificationType["ERROR"] = "error";
  NotificationType["LOADING"] = "loading";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));