import { ScreensUsersIndex } from "./users/Index";
import { ScreensNewsletterIndex } from "./newsletter/Index";
import { AuthorizationType, ViewDefinition } from "modbin-web";

const usersIndexView = {
    name:"administration:users",
    component: ScreensUsersIndex,
    title:"Utilizadores registados",
    route:{
        name:"administration:users",
        template:"/restricted/users"
    },
    layout:{
        props: {
            type:"fluid"
        }
    },
    authorization:AuthorizationType.ADMINISTRATOR
};

const newslettersIndexView:ViewDefinition = {
    name:"administration:newsletters",
    component: ScreensNewsletterIndex,
    title:"Subscrições de newsletters",
    //store:appRoot.stores.newsletterViewStore,
    storeName:"newsletterViewStore",
    route:{
        name:"administration:newsletters",
        template:"/restricted/newsletters"
    },
    layout:{
        props: {
            type:"fluid"
        }
    },
    authorization:AuthorizationType.ADMINISTRATOR
};

export const administrationiewsDefinitions:ViewDefinition[] = [
    usersIndexView,
    newslettersIndexView
]