import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { LoginData } from 'ibase-auctions-domain';
import { TranslationProps } from "modbin-domain";
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { LoadingView } from '../../../../../components/loading/LoadingView';
import { LoginForm } from '../../components/LoginForm';
import { OperationStateResult } from '../../operations/States';
import { ActivationViewStore } from './ActivationViewStore';
import { LoginViewStore } from '../LoginViewStore';

export class ScreensSessionsActivationView extends Component<TranslationProps & 
    {
        activationState:OperationStateResult,
        onLogin:(data:LoginData)=>Promise<void>
    },{}> {

    createBody(){
        return <Fragment>          
            {this.props.activationState.notification!}
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <LoginForm t={this.props.t} onLogin={(data:LoginData) => {return this.props.onLogin(data)}}></LoginForm>
                </div>
            </div>
        </Fragment>;
    }

    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const isLoading = this.props.activationState.state === "loading";
        return <LoadingView
            isLoading={isLoading}
        >
            <TitleBar title={getTranslationSessions("activationTitle")}></TitleBar>
            <div className="container">
                {this.createBody()}
            </div>
        </LoadingView>;
    }
}

@inject("activationViewStore","loginViewStore") @observer
export class ScreensSessionsActivation extends Component<TranslationProps & {activationViewStore:ActivationViewStore, loginViewStore:LoginViewStore},{}> {
    
    render() {
        return <ScreensSessionsActivationView 
            t={this.props.t} 
            onLogin={(data:LoginData) => this.props.loginViewStore!.onLogin(data)}
            activationState={this.props.activationViewStore!.activationState}
        ></ScreensSessionsActivationView>
    }
}