"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DomainEvent = void 0;

var DomainEvent =
/** @class */
function () {
  function DomainEvent(options) {
    this.id = "1";
    this.metadata = options.metadata;
    this.type = options.type;
    this.name = options.name;
  }

  return DomainEvent;
}();

exports.DomainEvent = DomainEvent;