"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationTaskRunner = void 0;

var ExecutionTime_1 = require("./ExecutionTime");

var DefaultTaskRunner_1 = require("../../tasks/DefaultTaskRunner");

var ApplicationTaskRunner =
/** @class */
function (_super) {
  __extends(ApplicationTaskRunner, _super);

  function ApplicationTaskRunner(taskProvider, logger) {
    var _this = _super.call(this, logger) || this;

    _this.taskProvider = taskProvider;
    return _this;
  }

  ApplicationTaskRunner.prototype.runExecutionTimes = function (executionTimes) {
    var tasks = this.taskProvider.getTasks({
      time: executionTimes
    });
    return _super.prototype.runAll.call(this, tasks);
  };

  ApplicationTaskRunner.prototype.runBeforeLoad = function () {
    var time = [ExecutionTime_1.ExecutionTime.BEFORE_LOAD];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runLoad = function () {
    var time = [ExecutionTime_1.ExecutionTime.LOAD];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runAfterLoad = function () {
    var time = [ExecutionTime_1.ExecutionTime.AFTER_LOAD];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runBeforeStart = function () {
    var time = [ExecutionTime_1.ExecutionTime.BEFORE_START];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runStart = function () {
    var time = [ExecutionTime_1.ExecutionTime.START];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runAfterStart = function () {
    var time = [ExecutionTime_1.ExecutionTime.AFTER_START];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runBeforeStop = function () {
    var time = [ExecutionTime_1.ExecutionTime.BEFORE_STOP];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runStop = function () {
    var time = [ExecutionTime_1.ExecutionTime.STOP];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runAfterStop = function () {
    var time = [ExecutionTime_1.ExecutionTime.AFTER_STOP];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runBeforeRestart = function () {
    var time = [ExecutionTime_1.ExecutionTime.BEFORE_RESTART];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runRestart = function () {
    var time = [ExecutionTime_1.ExecutionTime.RESTART];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runAfterRestart = function () {
    var time = [ExecutionTime_1.ExecutionTime.AFTER_RESTART];
    return this.runExecutionTimes(time);
  };

  ApplicationTaskRunner.prototype.runRecurring = function () {
    var time = [ExecutionTime_1.ExecutionTime.RECURRING];
    return this.runExecutionTimes(time);
  };

  return ApplicationTaskRunner;
}(DefaultTaskRunner_1.DefaultTaskRunner);

exports.ApplicationTaskRunner = ApplicationTaskRunner;