import { CompanyInfo } from "./info/CompanyInfo"
import { ApiConfiguration } from "./api/ApiConfig.types"
import { ApplicationConfiguration } from "./temp/AplicationConfiguration"

export enum DataSourceType {
    "LOCAL" = "local",
    "API" = "api" 
}

export type WebConfiguration = {
    baseUrl:string,
    dataSource:DataSourceType,
    numberOfDaysToShowPastEvents:number,
    files:{
        privateNegotiationTemplate:string,
        closedLetterTemplate:string
    }
}

export type ServicesConfiguration = {
    images:{
        baseUrl:string,
        noImageUrl:string,
        homeDefaultBannerUrl:string
    },
    visits:{
        baseUrl:string
    }
}

export type ConfigProperties = {
    appConfig:ApplicationConfiguration,
    companyInfo:CompanyInfo,
    webConfig:WebConfiguration,
    apiConfig:ApiConfiguration,
    services: ServicesConfiguration
}
