"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToAccountState = void 0;

var Client_types_1 = require("../../client/Client.types");

exports.convertToAccountState = function (externalUser) {
  var active = externalUser.active,
      blocked = externalUser.blocked;
  var result = blocked ? Client_types_1.UserAccountState.BLOCKED : active ? Client_types_1.UserAccountState.ACTIVATED : Client_types_1.UserAccountState.UNACTIVATED;
  return result;
};