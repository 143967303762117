"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.domainTranslations = void 0;

var Users_translations_1 = require("./users/Users.translations");

var Contacts_translations_1 = require("./contacts/Contacts.translations");

var Category_translations_1 = require("./items/categories/Category.translations");

var Sales_translations_1 = require("./sales/Sales.translations");

var Items_types_1 = require("./items/Items.types");

exports.domainTranslations = {
  pt: {
    contacts: Contacts_translations_1.contactsTranslations.pt,
    users: Users_translations_1.userTranslations.pt,
    sales: Sales_translations_1.salesTranslations.pt,
    categories: {
      main: Category_translations_1.categoryTranslations.pt,
      claim: Items_types_1.claimSubCategoryTranslations.pt,
      forniture: Items_types_1.fornitureSubCategoryTranslations.pt,
      hardware: Items_types_1.hardwareSubCategoryTranslations.pt,
      machine: Items_types_1.machineSubCategoryTranslations.pt,
      other: Items_types_1.otherSubCategoryTranslations.pt,
      realestate: Items_types_1.realEstateSubCategoryTranslations.pt,
      vehicle: Items_types_1.vehicleSubCategoryTranslations.pt
    },
    validation: {
      requiredMessage: "O campo {{field}} é obrigatório"
    }
  },
  en: {
    contacts: Contacts_translations_1.contactsTranslations.en,
    users: Users_translations_1.userTranslations.en,
    sales: Sales_translations_1.salesTranslations.en,
    categories: {
      main: Category_translations_1.categoryTranslations.en,
      claim: Items_types_1.claimSubCategoryTranslations.en,
      forniture: Items_types_1.fornitureSubCategoryTranslations.en,
      hardware: Items_types_1.hardwareSubCategoryTranslations.en,
      machine: Items_types_1.machineSubCategoryTranslations.en,
      other: Items_types_1.otherSubCategoryTranslations.en,
      realestate: Items_types_1.realEstateSubCategoryTranslations.en,
      vehicle: Items_types_1.vehicleSubCategoryTranslations.en
    },
    validation: {
      requiredMessage: "{{field}} field is required"
    }
  }
};