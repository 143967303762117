"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertfromLotQuery = void 0;

var CategoryConverter_1 = require("../categories/CategoryConverter");

var SubCategoryConverter_1 = require("../categories/SubCategoryConverter");

var SellingTypeConverter_1 = require("../../sales/SellingTypeConverter"); // "subCategory":"tipo_bem_id",
// "category":"natureza",
// "term":"q",
// "region":"distrito_cod",
// "county":"concelho_cod",
// "parish":"freguesia_cod",
// "sellingType":"modalidade_venda",
// "minimumValue":"preco_gt",
// "maximumValue":"preco_lt",
// "eventId":"auction_id"


exports.convertfromLotQuery = function (query) {
  var result = {};

  if (query.category !== undefined) {
    result.natureza = CategoryConverter_1.convertFromCategory(query.category);
  }

  if (query.subCategory !== undefined) {
    result.tipo_bem_id = SubCategoryConverter_1.convertFromSubCategoryEnum(query.subCategory);
  }

  if (query.minimumValue !== undefined) {
    result.preco_lt = query.minimumValue;
  }

  if (query.maximumValue !== undefined) {
    result.preco_gt = query.maximumValue;
  }

  if (query.region !== undefined) {
    result.distrito_cod = query.region;
  }

  if (query.county !== undefined) {
    result.concelho_cod = query.county;
  }

  if (query.parish !== undefined) {
    result.freguesia_cod = query.parish;
  }

  if (query.sellingType !== undefined) {
    result.sell_modality = SellingTypeConverter_1.convertFromSellingType(query.sellingType);
  }

  if (query.term !== undefined) {
    result.q = query.term;
  }

  if (query.eventId !== undefined) {
    result.auction_id = query.eventId;
  }

  if (query.active !== undefined) {
    result.flagActive = query.active;
  }

  return result;
};