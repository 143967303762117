"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Condition = void 0;
var Condition;

(function (Condition) {
  Condition["LIKENEW"] = "likenew";
  Condition["VERYGOOD"] = "verygood";
  Condition["GOOD"] = "good";
  Condition["REASONABLE"] = "reasonable";
  Condition["BAD"] = "bad";
  Condition["FORPARTS"] = "forparts";
  Condition["DESIGNATED"] = "designated";
  Condition["OTHER"] = "other";
  Condition["NOTAPPLICABLE"] = "notapplicable";
})(Condition = exports.Condition || (exports.Condition = {}));