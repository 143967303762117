export type ViewVisibility = {
    bidView:boolean;
    priceView:boolean;
    bookingsView:boolean;
    informationRequestView:boolean;
    documentsView:boolean;
}

export const notStartedViewVisibility = {
    bidView:false,
    priceView:true,
    bookingsView:true,
    informationRequestView:true,
    documentsView:true
}

export const startedViewVisibility = {
    bidView:true,
    priceView:true,
    bookingsView:true,
    informationRequestView:true,
    documentsView:true
}

export const endedViewVisibility = {
    bidView:false,
    priceView:true,
    bookingsView:false,
    informationRequestView:false,
    documentsView:false
}

export const validatingViewVisibility = {
    bidView:false,
    priceView:true,
    bookingsView:true,
    informationRequestView:true,
    documentsView:true
}

