export const menuTranslations = {
    pt: {
        home: "Início",
        events: "Leilões",
        lots: "Lotes",
        company: "Empresa",
        professionals: "Profissionais",
        contacts: "Contactos",
        userProfile: "Perfil",
        userBids:"Licitações",
        hi:"Olá, ",
        logout:"Sair",
        admin: {
            index: "Administração",
            users: "Utilizadores",
            newsletters: "Newsletters"
        }
    },
    en:{
        home: "Home",
        events: "Auctions",
        lots: "Lots",
        company: "About",
        professionals: "Professionals",
        contacts: "Contacts",
        userProfile: "Profile",
        userBids:"Bids",
        hi:"Hi, ",
        logout:"Log out",
        admin: {
            index: "Administration",
            users: "Users",
            newsletters: "Newsletters"
        }
    }
}