"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IBaseErrorMessageMapper = void 0;

var modbin_domain_1 = require("modbin-domain");

var extractErrorDetailsFromExternalErrorMessage = function extractErrorDetailsFromExternalErrorMessage(externalErrorMessage) {
  var result = {
    inner: undefined,
    domainCode: undefined,
    message: undefined
  };

  if (externalErrorMessage === undefined) {
    return result;
  }

  var error = externalErrorMessage.error,
      message = externalErrorMessage.error_message,
      data = externalErrorMessage.data;

  if (externalErrorMessage.error !== undefined) {
    result.domainCode = externalErrorMessage.error;
  }

  result.domainCode = error !== undefined ? error : undefined;
  result.inner = data !== undefined ? data : undefined;

  if (modbin_domain_1.isNotNullOrEmptyString(message, true)) {
    result.message = message;
  }

  if (Array.isArray(message)) {
    result.message = message.join(" - ");
  }

  return result;
};

var IBaseErrorMessageMapper =
/** @class */
function () {
  function IBaseErrorMessageMapper() {}

  IBaseErrorMessageMapper.prototype.map = function (sourceErrorMessage) {
    var externalErrorMessage = sourceErrorMessage.body;
    var extractedErrorData = extractErrorDetailsFromExternalErrorMessage(externalErrorMessage);

    var result = __assign({
      type: sourceErrorMessage.type,
      status: sourceErrorMessage.status,
      severity: modbin_domain_1.Severity.ERROR
    }, extractedErrorData);

    return result;
  };

  return IBaseErrorMessageMapper;
}();

exports.IBaseErrorMessageMapper = IBaseErrorMessageMapper;