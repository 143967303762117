import { ScreensHomeIndex } from "./home/Index";
import { ScreensCompanyIndex } from "./company/Index";
import { ScreensContactsIndex } from "./contacts/Index";
import { ScreensRalIndex } from "./ral/Index";
import { ScreensProfessionalsIndex } from "./professionals/Index";
import { ScreensPrivacyPolicyIndex } from "./privacy-policy/Index";
import { ScreensSessionsIndex } from "./sessions/Index";
import { ScreensSessionsRegister } from "./sessions/screens/registration/Register";
import { ScreensSessionsActivation } from "./sessions/screens/activation/Activation";
import { ScreensSessionsRegisterCompleted } from "./sessions/screens/registration/RegisterCompleted";
import { ScreensSessionsNoActivation } from "./sessions/screens/activation/NoActivation";
import { AuthorizationType, ViewDefinition } from "modbin-web";
import { ScreensSessionsRequestPasswordRecovery } from "./sessions/screens/password-recovery/RequestPasswordRecoveryView";
import { ScreensSessionsNewPassword } from "./sessions/screens/password-recovery/NewPassword";
import { LayoutType } from "../../components/layout/Layouts.types";
import { ScreensSessionsReactivation } from "./sessions/screens/activation/Reactivation";

const homeView = {
    name:"public:home",
    component: ScreensHomeIndex,
    title:"Inicio",
    route:{
        name:"public:home",
        template:"/"
    },
    layout:{
        component:LayoutType.MENU,
        props:{type:"fluid"}
    },
    nameTranslationKey:"screens:public.home.title"
};

const companyView = {
    name:"public:company",
    component: ScreensCompanyIndex,
    title:"Empresa",
    route:{
        name:"public:company",
        template:"/company"
    },  
    layout:{
       // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home",
    nameTranslationKey:"screens:public.company.title"
}

const contactsView = {
    name:"public:contacts",
    component: ScreensContactsIndex,
    title:"Contactos",
    route:{
        name:"public:contacts",
        template:"/contact"
    },  
    layout:{
       // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home",
    nameTranslationKey:"screens:public.contacts.title"
}

const ralView = {
    name:"public:ral",
    component: ScreensRalIndex,
    title:"Ral",
    route:{
        name:"public:ral",
        template:"/ral"
    },  
    layout:{
       // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home",
    nameTranslationKey:"screens:public.ral.title"
}

const professionalsView = {
    name:"public:professionals",
    component: ScreensProfessionalsIndex,
    title:"Empresa",
    route:{
        name:"public:professionals",
        template:"/professionals"
    },  
    layout:{
       // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home",
    nameTranslationKey:"screens:public.professionals.title"
}

const privacyPolicyView = {
    name:"public:privacy",
    component: ScreensPrivacyPolicyIndex,
    title:"Política de privacidade",
    route:{
        name:"public:privacy",
        template:"/privacy-policy"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home",
    nameTranslationKey:"screens:public.privacy.title"
}

const loginView = {
    name:"public:sessions:login",
    component: ScreensSessionsIndex,
    title:"Login",
    //store:appRoot.stores.loginViewStore,
    storeName:"loginViewStore",
    route:{
        name:"public:sessions:login",
        template:"/login",
        parametersToStateMapper:{
            "url":"url"
        },
        stateToParametersMapper:{
            "url":"url"
        }
    },  
    layout:{
        component:LayoutType.BASE,
        props:{type:"fluid"}
    },
    authorization:AuthorizationType.NOTAUTHENTICATED
}

const registerView = {
    name:"public:sessions:register",
    component: ScreensSessionsRegister,
    title:"Register",
    route:{
        name:"public:sessions:register",
        template:"/register"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    },
    authorization:AuthorizationType.NOTAUTHENTICATED
}

const registerCompletedView:ViewDefinition = {
    name:"public:sessions:register-completed",
    component: ScreensSessionsRegisterCompleted,
    title:"Register completed",
    route:{
        name:"public:sessions:register-completed",
        template:"/register-completed"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    },
    authorization:AuthorizationType.NOTAUTHENTICATED
}

const activationView = {
    name:"public:sessions:activation",
    component: ScreensSessionsActivation,
    title:"Account activation",
    //store: appRoot.stores.activationViewStore,
    storeName: "activationViewStore",
    route:{
        name:"public:sessions:activation",
        template:"/activate-account",
        parametersToStateMapper:{"validationToken":"validationToken","email":"email"},
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    }
}

const passwordRecoveryConfirmationView = {
    name:"public:sessions:password-recovery-confirmation",
    component: ScreensSessionsNewPassword,
    title:"Password recovery - change password",
    //store: appRoot.stores.passwordRecoveryViewStore,
    storeName: "passwordRecoveryViewStore",
    route:{
        name:"public:sessions:password-recovery-confirmation",
        template:"/password-recovery-confirm/:recoveryToken",
        parametersToStateMapper:{"0":"recoveryToken"}
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    }
}

const passwordRecoveryRequestView = {
    name:"public:sessions:password-recovery-request",
    component: ScreensSessionsRequestPasswordRecovery,
    title:"Password recovery - request",
    storeName: "passwordRecoveryViewStore",
    route:{
        name:"public:sessions:password-recovery-request",
        template:"/password-recovery-request"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    }
}


const activationInvalidView = {
    name:"public:sessions:noactivation",
    component: ScreensSessionsNoActivation,
    title:"Invalid account activation",
    route:{
        name:"public:sessions:noactivation",
        template:"/activate-account-needed"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    }
}

const reactivationView = {
    name:"public:sessions:reactivation",
    component: ScreensSessionsReactivation,
    title:"Account reactivation email sender",
    storeName: "loginViewStore",
    route:{
        name:"public:sessions:reactivation",
        template:"/reactivate-account"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fluid"}
    }
}

export const publicViewsDefinitions:ViewDefinition[] = [
    homeView,
    companyView,
    contactsView,
    ralView,
    professionalsView,
    privacyPolicyView,
    loginView,
    registerView,
    registerCompletedView,
    activationView,
    activationInvalidView,
    reactivationView,
    passwordRecoveryRequestView,
    passwordRecoveryConfirmationView
]