import React, { Component } from "react";
import { LoginData } from "ibase-auctions-domain";
import { TranslationProps} from 'modbin-domain';
import { ScreensSessionsRegisterCompletedView } from "./RegisterCompleted";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"ScreensSessionsRegisterCompletedViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Register page",
    parentId:"components"
};

export class ScreensSessionsRegisterCompletedViewUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:LoginData){
        console.log(result);
    }
    
    render(){
        return <ScreensSessionsRegisterCompletedView
            registrationState="completed"
            t={this.props.t}
        />;
    }
}