import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import React from "react";
import { DownloadButton } from "../../../../../components/files/DownloadButton";
import { genericDownloadFile } from "../../../../../components/files/Download";

export const DocumentDownloadView = observer((props:TranslationProps & {url:string, name:string, fileName:string}) => {
    const {name, url, fileName} = props;
    return <div style={{textAlign:"center"}}>
        <ul className="listing-documents">
            <li key="document:download">
                <DownloadButton 
                    downloadFile={genericDownloadFile}
                    url={url} 
                    name={name} 
                    fileName={fileName}>
                </DownloadButton>
            </li>
        </ul>
    </div>;
});
