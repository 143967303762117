"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExecutionMode = void 0;
var ExecutionMode;

(function (ExecutionMode) {
  ExecutionMode["AWAITABLE"] = "awaitable";
  ExecutionMode["NOT_AWAITABLE"] = "not_awaitable";
})(ExecutionMode = exports.ExecutionMode || (exports.ExecutionMode = {}));