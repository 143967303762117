"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseFetchHttpRequestClient = void 0;

var HttpHeaders_1 = require("../../http/HttpHeaders");

var ContentTypeFormatterMapper_1 = require("./ContentTypeFormatterMapper");

var modbin_domain_1 = require("modbin-domain");

var convertHeaders = function convertHeaders(fetchResponse) {
  var result = [];
  fetchResponse.headers.forEach(function (val, key) {
    result.push({
      name: key,
      value: val
    });
  });
  var headers = new HttpHeaders_1.HttpHeaders(result);
  return headers;
};

var convertFromFetchResponse = function convertFromFetchResponse(fetchResponse, contentTypeFormatterMapper) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, contentType, formatter, response, _a;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          headers = convertHeaders(fetchResponse);
          contentType = headers.getContentType();
          formatter = contentTypeFormatterMapper.getFormatterForResponse(contentType);
          _a = {
            statusCode: fetchResponse.status,
            headers: headers
          };
          return [4
          /*yield*/
          , formatter(fetchResponse)];

        case 1:
          response = (_a.body = _b.sent(), _a.url = fetchResponse.url, _a.isError = !fetchResponse.ok, _a);
          return [2
          /*return*/
          , response];
      }
    });
  });
};

var convertToFetchRequest = function convertToFetchRequest(request, contentTypeFormatterMapper) {
  return __awaiter(void 0, void 0, void 0, function () {
    var contentType, formatter, requestInit, _a;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          contentType = request.headers.getContentType();
          formatter = contentTypeFormatterMapper.getFormatterForRequest(contentType);
          _a = {
            method: request.method,
            headers: request.headers.convertToObject()
          };
          return [4
          /*yield*/
          , formatter(request.body)];

        case 1:
          requestInit = (_a.body = _b.sent(), _a);
          return [2
          /*return*/
          , requestInit];
      }
    });
  });
};

var isErrorResponse = function isErrorResponse(resp) {
  return !resp.ok;
};

var parseJson = function parseJson(body) {
  return JSON.parse(body);
};

var isJson = function isJson(resp) {
  var contentType = resp.headers.get("Content-type");
  return modbin_domain_1.isNotNullOrEmptyString(contentType, true) && contentType.includes("application/json");
};

var parseErrorResponse = function parseErrorResponse(response) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, _a, e_1;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          body = undefined;
          if (!isJson(response)) return [3
          /*break*/
          , 6];
          _b.label = 1;

        case 1:
          _b.trys.push([1, 3,, 5]);

          _a = parseJson;
          return [4
          /*yield*/
          , response.text()];

        case 2:
          body = _a.apply(void 0, [_b.sent()]);
          return [3
          /*break*/
          , 5];

        case 3:
          e_1 = _b.sent();
          return [4
          /*yield*/
          , response.text()];

        case 4:
          body = _b.sent();
          return [3
          /*break*/
          , 5];

        case 5:
          return [3
          /*break*/
          , 8];

        case 6:
          return [4
          /*yield*/
          , response.text()];

        case 7:
          body = _b.sent();
          _b.label = 8;

        case 8:
          return [2
          /*return*/
          , response.status >= 500 ? Promise.resolve({
            type: 'ServerError',
            status: response.status,
            body: body
          }) : Promise.resolve({
            type: 'ApplicationError',
            status: response.status,
            body: body
          })];
      }
    });
  });
};

var parseSuccessResponse = function parseSuccessResponse(fetchResponse, contentTypeFormatterMapper) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2
      /*return*/
      , convertFromFetchResponse(fetchResponse, contentTypeFormatterMapper)];
    });
  });
};

var parseResponse = function parseResponse(response, contentTypeFormatterMapper) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2
      /*return*/
      , new Promise(function (resolve, reject) {
        return __awaiter(void 0, void 0, void 0, function () {
          var result, errorMessage;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                if (!!isErrorResponse(response)) return [3
                /*break*/
                , 2];
                return [4
                /*yield*/
                , parseSuccessResponse(response, contentTypeFormatterMapper)];

              case 1:
                result = _a.sent();
                return [2
                /*return*/
                , resolve(result)];

              case 2:
                return [4
                /*yield*/
                , parseErrorResponse(response)];

              case 3:
                errorMessage = _a.sent();
                return [2
                /*return*/
                , reject(errorMessage)];
            }
          });
        });
      })];
    });
  });
};

var BaseFetchHttpRequestClient =
/** @class */
function () {
  function BaseFetchHttpRequestClient(options) {
    this.contentTypeFormatterMapper = ContentTypeFormatterMapper_1.buildContentTypeFormatterMapper(options);
  }

  BaseFetchHttpRequestClient.prototype.makeRequest = function (request) {
    return __awaiter(this, void 0, void 0, function () {
      var fetchRequest;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , convertToFetchRequest(request, this.contentTypeFormatterMapper)];

          case 1:
            fetchRequest = _a.sent();
            return [2
            /*return*/
            , this.fetch(request.url, fetchRequest).then(function (fetchResponse) {
              //return convertFromFetchResponse(fetchResponse,this.contentTypeFormatterMapper);
              return parseResponse(fetchResponse, _this.contentTypeFormatterMapper);
            }) //todo: test if a catch here is able to detect when there is no network
            //.catch((reason) => {console.log(reason)});
            ];
        }
      });
    });
  };

  return BaseFetchHttpRequestClient;
}();

exports.BaseFetchHttpRequestClient = BaseFetchHttpRequestClient;