import { ApplicationFactoryOptions, IIocContainer, IBusinessEventService } from "modbin-domain";
import { IViewDefinitionProvider, DefaultWebApplicationFactory, IWebApplicationFactory, DefaultViewNavigator,DefaultWebApplicationViewModule,IWebApplicationViewModule, WebApplicationViewModuleOptions, WebApplicationFactoryOptions } from "modbin-web";
import { IUserAuthenticationService} from "ibase-auctions-domain";
export class IBaseWebApplicationFactory extends DefaultWebApplicationFactory implements IWebApplicationFactory {
    
    constructor(options:WebApplicationFactoryOptions){
        super(options);
    }

    private createDefaultViewNavigator(container:IIocContainer,viewProvider:IViewDefinitionProvider){
        const businessEventService = container.resolve<IBusinessEventService>("businessEventService");
        const userAuthenticationService = container.resolve<IUserAuthenticationService>("userAuthenticationService");
        const authenticationVerifier = () => { return userAuthenticationService.isAuthenticated();};
        const roleVerifier = (role:string) => {return true};
        return new DefaultViewNavigator({
            viewProvider:viewProvider,
            businessEventService:businessEventService,
            authenticationVerifier:authenticationVerifier,
            roleVerifier:roleVerifier
        });
    }

    createViewModule(options:WebApplicationViewModuleOptions & {container:IIocContainer}):IWebApplicationViewModule{
        const {container, navigator, provider, views} = options;
        const finalNavigator = navigator !== undefined 
            ? navigator 
            : this.createDefaultViewNavigator(container, provider);
        const finalOptions = {
            container,
            navigator:finalNavigator,
            provider,
            views
        };
        const module = new DefaultWebApplicationViewModule(finalOptions);
        return module;
    }
}