import React, { Component } from 'react';
import { TranslationProps } from 'modbin-domain';
import { ScreensNewsletterIndexView } from './IndexView';
import { observer, inject } from "mobx-react";
import { NewsletterViewStore } from './ViewStore';

@inject("newsletterViewStore") @observer
export class ScreensNewsletterIndex extends Component<TranslationProps&{newsletterViewStore?:NewsletterViewStore},{}> {
    
    render() {
        return (
            <ScreensNewsletterIndexView 
                t={this.props.t}
                newsletterSubscriptions={this.props.newsletterViewStore!.getSubscriptions()}
                isLoading={this.props.newsletterViewStore!.isLoading}
                newslettersFileUrl={this.props.newsletterViewStore!.getFileUrl()}
            />
        );
    }
}