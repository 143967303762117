"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Lot = void 0; // "id": 85,
//   "date_created": 1496313490661,
//   "date_updated": 1496313546336,
//   "referencia": null,
//   "number": 2,
//   "publicado": false,
//   "titulo": null,
//   "descricao": "",
//   "valor_minimo": 50000.00,
//   "valor_base": 50000.00,
//   "vendido": false,
//   "valor_venda": null,
//   "valor_abertura": null,
//   "user_id": 64,
//   "escritorio_id": 1,
//   "processo_id": null,
//   "foto": null,
//   "listBem": null,
//   "destacado": false,
//   "lista_fotos": null,
//   "auction_id": null,
//   "modalidade_venda": null,
//   "date_start": null,
//   "date_end": null,
//   "natureza": null,
//   "tipo_bem_id": null,
//   "area_total": null,
//   "area_construcao": null,
//   "numero_quartos": null,
//   "wcs": null,
//   "lugares_garagem": null,
//   "distrito_cod": null,
//   "distrito": null,
//   "concelho_cod": null,
//   "concelho": null,
//   "freguesia_cod": null,
//   "morada": null,
//   "morada_numero_predio": null,
//   "morada_andar": null,
//   "morada_fraccao": null,
//   "morada_localidade": null,
//   "codigo_postal_4": null,
//   "codigo_postal_3": null,
//   "codigo_postal_designacao": null,
//   "pais": null,
//   "latitude": null,
//   "longitude": null,
//   "number_bids": null,
//   "currentBid": null,
//   "nextBid": null,
//   "list_next_bids": null,
//   "server_time": null,
//   "list_bids": null,

var modbin_domain_1 = require("modbin-domain");

;

var Lot =
/** @class */
function (_super) {
  __extends(Lot, _super);

  function Lot(options) {
    var _this = _super.call(this, options) || this;

    _this.initialize();

    return _this;
  }

  Lot.prototype.initialize = function () {
    this.documents = [];
    this.documentsLoaded = false;
    this.bookings = [];
    this.bokingsLoaded = false;
    this.updateData = null;
    this.updateDataLoaded = false;
    this.bids = [];
  };

  return Lot;
}(modbin_domain_1.Entity);

exports.Lot = Lot;