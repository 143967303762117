import React, { Component, Fragment } from 'react';
import { TranslationProps } from "modbin-domain";
import { User } from 'ibase-auctions-domain';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { inject, observer } from 'mobx-react';
import { SessionViewStore } from '../../public/sessions/ViewStore';
import { ProfileForm } from './components/ProfileForm';
import { ButtonType } from '../../../components/buttons/ActionButton';
import { LoadingView } from '../../../components/loading/LoadingView';
import { SessionStore } from '../../public/sessions/SessionStore';
// import { application } from '../../../Startup';
import {ProfileViewStore} from "./ViewStore";

const convertToUserEditForm = (user:User) => {
    const result:any = {};
    result.id = user.getId();
    result.createdDate = user.getCreatedDate();
    result.modifiedDate = user.getModifiedDate();
    result.firstName = user.firstName;
    result.lastName = user.lastName;
    if(user.contact !== undefined) {
        result.contact = {
            mobilePhoneNumber : user.contact.mobilePhoneNumber !== undefined ? user.contact.mobilePhoneNumber : "",
            landPhoneNumber : user.contact.landPhoneNumber !== undefined ? user.contact.landPhoneNumber : "",
            faxNumber : user.contact.faxNumber !== undefined ? user.contact.faxNumber : "",
            email : user.contact.email !== undefined ? user.contact.email :""
        }
    }
    else {
        result.contact = {
            mobilePhoneNumber : "",
            landPhoneNumber : "",
            faxNumber :  "",
            email : "" 
        }
    }
    if(user.address !== undefined) {
        result.address = {
            street : user.address.street !== undefined ? user.address.street : "",
            city : user.address.city !== undefined ? user.address.city : "",
            zipCode1 : user.address.zipCode1 !== undefined ? user.address.zipCode1 : "",
            zipCode2 : user.address.zipCode2 !== undefined ? user.address.zipCode2 : ""
        }
    }
    else {
        result.address = {
            street:"",
            city:"",
            zipCode1:"",
            zipCode2:""
        }
    }
    result.title = user.title;
    result.maritalStatus = user.maritalStatus;
    if(user.identificationCard !== undefined) {
        result.identificationCard = {
            number : user.identificationCard.number !== undefined ? user.identificationCard.number : "",
            issuedAt : user.identificationCard.issuedAt !== undefined ? user.identificationCard.issuedAt : "",
            expiration : user.identificationCard.expiration !== undefined ? user.identificationCard.expiration : new Date(),
            vatNumber : user.identificationCard.vatNumber !== undefined ? user.identificationCard.vatNumber : ""
        }
    }
    else {
        result.identificationCard = {
            number: "",
            issuedAt: "",
            expiration: new Date(),
            vatNumber: ""
        }
    }
    result.birthDate = user.birthDate;
    result.bornAt = user.bornAt;
    result.provider = user.providder;
    result.officeId = user.officeId;
    if(user.company === undefined || !user.company.isRepresentative) {
        result.company = {
            isRepresentative:false,
            name:"",
            vatNumber:""
        }
    }
    else {
        result.company = {
            isRepresentative:true,
            vatNumber : user.company.vatNumber !== undefined ? user.company.vatNumber : "",
            name : user.company.name !== undefined ? user.company.name : ""
        }
    }
    return result;
}



@inject("sessionViewStore", "sessionStore", "profileViewStore") @observer   
export class ScreensProfileIndex extends Component<TranslationProps & {sessionViewStore?:SessionViewStore, sessionStore?:SessionStore,profileViewStore?:ProfileViewStore},{}> {

    render() {
        let user = this.props.sessionStore!.user!;
        user = user !== undefined ? convertToUserEditForm(user) : undefined;
        const getTranslation = (key:string) => this.props.t(`screens:private.profile.${key}`); 
        return (
            <Fragment>
            <TitleBar title={getTranslation("title")}></TitleBar>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "justify"}}>
                            <LoadingView 
                                    isLoading={!this.props.sessionViewStore!.isLoaded && this.props.profileViewStore!.isLoading} 
                                    showFallback={false}
                                >
                                <ProfileForm user={user} t={this.props.t}  buttonType={ButtonType.SAVE} onSave={(user:any)=> {return this.props.sessionViewStore!.onUserUpdate(user)}}></ProfileForm>
                            </LoadingView>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
