import React, { Component } from 'react';
import { TranslationProps} from 'modbin-domain';

export class GenericComponentsScreenIndex extends Component<TranslationProps,{}> {

    render(){
        return (<div>
                <h1>Ui tests generic components index</h1>
            </div>
        );
    }
}