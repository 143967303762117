import React from "react"
import { NavigationItem } from "../../../components/layout/navigation/menus/NavigationItem"
import { NavigationBackLink } from "../../../components/layout/navigation/menus/NavigationLink"

export const SupportMenu = () => {
    return <div style={{backgroundColor:"#337ab7"}}>
        <div className="container margin-top-10">
            <div className="left-side">            
                <ul className="top-bar-menu">
                    <NavigationItem text="Home" to="public:home"></NavigationItem>
                    <NavigationItem text="Support index" to="support:index"></NavigationItem>
                    <NavigationItem text="Components index" to="support:tests:index"></NavigationItem>
                </ul>
            </div>
            <div className="right-side">
                <ul className="top-bar-menu">
                    <li style={{float:"right"}}><NavigationBackLink>Back</NavigationBackLink></li>
                </ul>
            </div>
        </div>
    </div>
}