import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { LoadingView } from '../../../../../components/loading/LoadingView';
import { OperationStateResult, OperationState } from '../../operations/States';
import { PasswordRecoveryViewStore } from './PasswordRecoveryViewStore';
import { NewPasswordForm } from './NewPasswordForm';
import { LoginForm } from '../../components/LoginForm';
import { LoginData } from 'ibase-auctions-domain';
import { LoginViewStore } from '../LoginViewStore';

export class ScreensSessionsNewPasswordView extends Component<TranslationProps & 
    {
        passwordRecoveryState:OperationStateResult,
        onPasswordChange:(password:string)=>Promise<void>,
        onLogin:(data:LoginData)=>Promise<void>
    },{}> {

    createBody(){
        const body = this.props.passwordRecoveryState.state !== OperationState.SUCCESS
        ?  <NewPasswordForm t={this.props.t} onPasswordChange={(password:string) => {return this.props.onPasswordChange(password)}}></NewPasswordForm>
        : <LoginForm t={this.props.t} onLogin={(data:LoginData) => {return this.props.onLogin(data)}}></LoginForm>;
        return <Fragment>          
            {this.props.passwordRecoveryState.notification!}
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    {body}
                </div>
            </div>
        </Fragment>;
    }

    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const isLoading = this.props.passwordRecoveryState.state === "loading";
        return <LoadingView
            isLoading={isLoading}
        >
            <TitleBar title={getTranslationSessions("passwordRecoveryTitle")}></TitleBar>
            <div className="container">
                {this.createBody()}
            </div>
        </LoadingView>;
    }
}

@inject("passwordRecoveryViewStore","loginViewStore") @observer
export class ScreensSessionsNewPassword extends Component<TranslationProps & {passwordRecoveryViewStore:PasswordRecoveryViewStore, loginViewStore:LoginViewStore},{}> {
    
    render() {
        return <ScreensSessionsNewPasswordView 
            t={this.props.t} 
            passwordRecoveryState={this.props.passwordRecoveryViewStore.passwordRecoveryState}
            onPasswordChange={(password:string) => { return this.props.passwordRecoveryViewStore.recoverPassword(password)}}
            onLogin={(data:LoginData) => this.props.loginViewStore.onLogin(data)}
        ></ScreensSessionsNewPasswordView>
    }
}