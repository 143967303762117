import { observable, computed } from "mobx";
import { User, AuthenticationData, ISessionService, IUserProperties } from "ibase-auctions-domain";
import { IRepository, buildUrlPathFromPathTemplate, IBusinessEventService, ILogger } from "modbin-domain";
import { IRestApiClient } from "modbin-api";
import { configProperties } from "../../../configuration/ConfigProperties";
import { application } from "../../../Startup";
import { LoginEvent } from "./business-events/LoginEvent";
import { UserUpdateEvent } from "./business-events/UserUpdateEvent";
import { IContextProvider } from "../../../configuration/temp/web/application/ContextProvider";
import { BaseStore, IStore } from "modbin-web";

export class SessionStore  extends BaseStore implements IStore{
    @observable user:User|undefined = undefined; 
    @observable authData:AuthenticationData | undefined = undefined;
    @observable isAdmin:boolean= false;

    constructor(
            private sessionService:ISessionService, 
            private userProfileRepository:IRepository<User>,
            private apiClient: IRestApiClient,
            private businessEventService:IBusinessEventService,
            private contextProvider:IContextProvider,
            private logger:ILogger
        ){
            super();
            this.authData = sessionService.getAuthenticationData();    
            //this.initialize();
    }

    async initialize():Promise<void>{
        if(this.isAuthenticated && this.user === undefined){
            await this.userProfileRepository.findById(this.authData!.userId).then(x => {
               this.user = x; 
            },
            (e:any) => {
                this.logger.error(`Error in initialize method (fetch userId: ${this.authData!.userId}) on SessionStore: `,e);
                throw e;
            });
        }
        if(this.isAuthenticated){ 
            this.loadRoles();
            this.contextProvider.setContext({
                user:{
                    id:this.user!.getId(), 
                    isAuthenticated:true, 
                    roles:[]
                }
            })
        } 
        return Promise.resolve();
    }

    loadRoles(){
        const userId = this.userId;
        const url = buildUrlPathFromPathTemplate(configProperties.apiConfig.endpoints.administration.roles,{userId:userId}) + `?userId=${userId}`;
        this.apiClient.get<string[]>(url).then(x => {
            if(x.includes("Administrator")){
                this.isAdmin = true;
                this.contextProvider.setContext({
                                user:{
                                    id:userId, 
                                    isAuthenticated:true, 
                                    roles:["administrator"]
                                }
                })
            }
        }).catch(()=>{
            this.isAdmin = false;
        })
    }

    @computed 
    get isAuthenticated(){
        return this.authData !== undefined && this.authData.token !== undefined && this.authData.userId !== undefined && this.authData.token !== null && this.authData.userId !== null;
    }

    @computed 
    get userId(){
        return this.isAuthenticated ? this.authData!.userId : undefined;
    }

    loginUser(user:User){        
        this.user = user;
        this.authData = this.sessionService.getAuthenticationData(); 
        this.loadRoles();
        this.contextProvider.setContext({
            user:{
                id:this.user!.getId(), 
                isAuthenticated:true, 
                roles:[]
            }
        })
        this.logger.info(`User logged in (Email: ${user.contact.email})`);
        this.businessEventService.sendTrackingEvent(new LoginEvent());
    }

    reset(){
        this.logoutUser();
        return Promise.resolve();
    }

    internalReset(){
        this.authData = undefined;
        this.user = undefined;
        this.isAdmin = false;
        return Promise.resolve();
    }

    logoutUser(){
        this.sessionService.logoutUser();
        this.internalReset();
        this.contextProvider.setContext({
            user:{
                id:undefined, 
                isAuthenticated:false, 
                roles:[]
            }
        })
        this.businessEventService.sendTrackingEvent(new LoginEvent());
    }

    updateUser(user:IUserProperties):Promise<void>{
        const toSendUser:any = user;
        toSendUser.modifiedDate = this.user!.getModifiedDate();
        return this.sessionService.updateUser(new User(toSendUser as any)).then(x => {
            const newUser = new User(x as any) as any; 
            this.businessEventService.sendTrackingEvent(new UserUpdateEvent(this.user!,newUser!));
            this.user = newUser;
        },
        (e:any) => {
            this.logger.error(`Error in updateUser method on SessionStore (Email: ${user.contact.email}): `,e);
            throw e;
        });
    }
}