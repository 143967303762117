import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { Currency, IBidService } from "ibase-auctions-domain";
import { ClosedLetterBidForm } from "./ClosedLetterBidForm";
import { downloadFile } from "../../../../../../../components/files/Download";
import { UiTestDescription } from "modbin-web";
import { application } from "../../../../../../../Startup";

export const description:UiTestDescription = {
    id:"ClosedLetterFormViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"ClosedLetterFormView component",
    parentId:"components"
};

export class ClosedLetterBidFormUiTest extends Component<TranslationProps,{}>{
    
    onBid(value:any){
        const bidService = application.getContainer().resolve<IBidService>("bidService");
        const createBidCmd = {
            value:value,
            userId:"98",
            eventId:"80",
            lotId:"2867",
            officeId:"69",
            provider:"C.Paraiso"
        }
        return bidService.makeProposalGeneratorUrl(createBidCmd).then((x:string) => {
       //         window.open(x,"_blank");
                return downloadFile(x,"Proposta.pdf","application/pdf");
            });
    }
    
    render(){
        return <div>
            <div>
                
            </div>
            <ClosedLetterBidForm t={this.props.t} minimumBid={{value:1000.00,currency:Currency.EURO}} onBid={(value:any)=> {return this.onBid(value)}}/>;
        </div>
    }
}