import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { BidderState } from "../../ViewStore";
import { application } from "../../../../../../../Startup";

const BidderStateMessage = observer((props:{icon:string, text:string, color: string})=> {
    return <div className="margin-top-10">
        <i className={props.icon} 
                        style={{
                                color:props.color, 
                                fontSize:"22px",
                                zIndex:110,
                                verticalAlign:"middle"
                        }} />
                    <strong style={{marginLeft:"5px", fontSize:"16px", color:props.color, verticalAlign:"middle"}}>{props.text}</strong>
    </div>
})

const createBidderStateMapper = (translator:(key:string,params?:any)=>string) => {

    const bidderStateMapper = new Map<BidderState,{icon:string, color:string, text:string}>([
        [BidderState.NOBIDS,{
            icon:"fa fa-info",
            color:"#3b5998",
            text:translator("bidNoBidsYetText")
        }],
        [BidderState.HIGHESTBIDDER,{
            icon:"fa fa-check",
            color:"green",
            text:translator("bidHighestBidderText")
        }],
        [BidderState.OUTBID,{
            icon:"fa fa-close",
            color:"red",
            text:translator("bidOutbidText")
        }]
    ]);
    return bidderStateMapper;
}

const InnerBidderStateView = observer((props:TranslationProps & {bidderState:BidderState}) => {
    let body = <Fragment></Fragment>;
    if(props.bidderState === undefined){
        return body;
    }
    const getTranslation = (key:string,params?:any) => props.t(`screens:business.lots.details.${key}`,params); 
    const mapper = createBidderStateMapper(getTranslation);
    return <BidderStateMessage {...mapper.get(props.bidderState)!}></BidderStateMessage>
  });
  
  export const BidderStateView = (withTranslation(["common"])(InnerBidderStateView as any) as any) as (props:any) => JSX.Element;