"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var MaritalStatus_1 = require("./MaritalStatus");

var UserAccountState_1 = require("./UserAccountState");

var maritalStatusTranslations = modbin_domain_1.createTranslationObjectForEnum([[MaritalStatus_1.MaritalStatus.DIVORCED, {
  pt: "Divorciado(a)",
  en: "Divorced"
}], [MaritalStatus_1.MaritalStatus.MARRIED, {
  pt: "Casado(a)",
  en: "Married"
}], [MaritalStatus_1.MaritalStatus.SINGLE, {
  pt: "Solteiro(a)",
  en: "Single"
}], [MaritalStatus_1.MaritalStatus.WIDOW, {
  pt: "Víuvo(a)",
  en: "Widow"
}]]);
var accountStateTranslations = modbin_domain_1.createTranslationObjectForEnum([[UserAccountState_1.UserAccountState.ACTIVATED, {
  pt: "Ativado",
  en: "Activated"
}], [UserAccountState_1.UserAccountState.BLOCKED, {
  pt: "Bloqueado",
  en: "Blocked"
}], [UserAccountState_1.UserAccountState.UNACTIVATED, {
  pt: "Por ativar",
  en: "Unactivated"
}]]);
exports.userTranslations = {
  pt: {
    user: {
      firstName: "Nome",
      lastName: "Sobrenome",
      title: "Título",
      maritalStatus: "Estado civil",
      birthDate: "Data de nascimento",
      bornAt: "Naturalidade",
      accountState: "Estado da conta",
      email: "Email",
      emailInvalid: "O email inserido não é válido",
      password: "Password",
      passwordsMustMatch: "As passwords inseridas não são iguais",
      passwordMustHaveMoreThanChars: "A password tem que ter no mínimo 5 caracteres"
    },
    maritalStatus: maritalStatusTranslations.pt,
    accountState: accountStateTranslations.pt,
    identificationCard: {
      vatNumber: "NIF",
      number: "BI/CC",
      issuedAt: "Local de emissão",
      expiration: "Validade"
    },
    company: {
      name: "Nome da empresa",
      representative: "Representante de empresa",
      vatNumber: "NIF"
    }
  },
  en: {
    user: {
      firstName: "First name",
      lastName: "Last name",
      vatNumber: "Vat number",
      title: "Title",
      maritalStatus: "Marital status",
      birthDate: "Birthdate",
      bornAt: "Place of birth",
      accountState: "Account state",
      email: "Email",
      emailInvalid: "Provided email is not valid",
      password: "Password",
      passwordsMustMatch: "Provided passwords don´t match",
      passwordMustHaveMoreThanChars: "Password must be more than 5 characters long"
    },
    maritalStatus: maritalStatusTranslations.en,
    accountState: accountStateTranslations.en,
    identificationCard: {
      vatNumber: "Vat number",
      number: "Id card number",
      issuedAt: "Issued at",
      expiration: "Expiration"
    },
    company: {
      name: "Company name",
      representative: "Company representative",
      vatNumber: "Vat number"
    }
  }
};