"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.claimSubCategoryTranslations = void 0;

var ClaimSubCategory_1 = require("./ClaimSubCategory");

var modbin_domain_1 = require("modbin-domain");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[ClaimSubCategory_1.ClaimSubCategory.UNLISTEDSTOCKS, {
  pt: "Ações não cotadas",
  en: "Unlisted stocks"
}], [ClaimSubCategory_1.ClaimSubCategory.INHERITANCE, {
  pt: "Herança",
  en: "Inheritance"
}], [ClaimSubCategory_1.ClaimSubCategory.BRANDPATENTDOMAIN, {
  pt: "Marca/Patente/Domínio Internet",
  en: "Brand/Patent/Domain"
}], [ClaimSubCategory_1.ClaimSubCategory.QUOTAS, {
  pt: "Quotas",
  en: "Quota"
}], [ClaimSubCategory_1.ClaimSubCategory.TRANSFERS, {
  pt: "Trespasse/Direito Arrendamento",
  en: "Transferable"
}], [ClaimSubCategory_1.ClaimSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}] //31
]);
exports.claimSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de direitos",
    value: translationObject.pt
  },
  en: {
    name: "Claim subcategory",
    value: translationObject.en
  }
};