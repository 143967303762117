
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { Lot } from 'ibase-auctions-domain';
import { DetailsView } from './DetailsView';
import { LoadingView } from '../../../../../components/loading/LoadingView';

@observer
export class ScreensBusinessLotsDetails extends Component<TranslationProps & {selected:Lot, isLoading:boolean},{}> {
    render() {
        const {isLoading, selected, t} = this.props;
        return (
            <Fragment>                
                <LoadingView isLoading={isLoading}>
                    <DetailsView t={t} item={selected!}></DetailsView>
                </LoadingView>                 
            </Fragment>
        );
    }
}