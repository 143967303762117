"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readEntryStats = exports.getPath = exports.existsEntry = void 0;

var modbin_domain_1 = require("modbin-domain");

var util = require('util');

var fs = require('fs');

var pathNodeModule = require("path");

exports.existsEntry = function (path) {
  return fs.existsSync(path);
};

exports.getPath = function (segments) {
  var path = pathNodeModule.normalize(segments.join("\\"));
  return path;
};

exports.readEntryStats = function (path) {
  var statAsync = util.promisify(fs.stat);
  return statAsync(path).then(function (x) {
    return {
      size: x.size,
      birthtime: x.birthtime,
      mTime: x.mTime
    };
  }, function (err) {
    if (err.code === 'ENOENT') {
      var error = new modbin_domain_1.FileNotFoundError(err);
      throw error;
    }

    throw err;
  });
};