"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fornitureSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var FornitureSubCategory_1 = require("./FornitureSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[FornitureSubCategory_1.FornitureSubCategory.JEWELRY, {
  pt: "Joia/Relógio",
  en: "Jewel/watch"
}], [FornitureSubCategory_1.FornitureSubCategory.COMMODITY, {
  pt: "Mercadoria",
  en: "Commodity"
}], [FornitureSubCategory_1.FornitureSubCategory.OFFICE, {
  pt: "Escritório",
  en: "Office"
}], [FornitureSubCategory_1.FornitureSubCategory.HOME, {
  pt: "Casa",
  en: "Housing"
}], [FornitureSubCategory_1.FornitureSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}], [FornitureSubCategory_1.FornitureSubCategory.HEALTH, {
  pt: "Saúde",
  en: "Healhcare"
}], [FornitureSubCategory_1.FornitureSubCategory.DECOR, {
  pt: "Decoração",
  en: "Decor"
}]]);
exports.fornitureSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de mobiliário",
    value: translationObject.pt
  },
  en: {
    name: "Forniture subcategory",
    value: translationObject.en
  }
};