import { observable, computed } from "mobx";
import { LotStore } from "../../../../business/lots/Store";
import { Lot } from "ibase-auctions-domain";
import { SessionViewStore } from "../../../../public/sessions/ViewStore";
import { SessionStore } from "../../../../public/sessions/SessionStore";
import { BaseStore, IStore } from "modbin-web";

export class ProfileBidsViewStore extends BaseStore implements IStore{
    @observable _isLoading = false; 
    @observable favorites:Lot[] = [];
    

    constructor(private lotStore:LotStore, private sessionViewStore:SessionViewStore, private sessionStore:SessionStore){
          super();
    }

    getFavoriteLots(){
        return this.favorites;
    }

    @computed 
    get isLoading(){
        return this._isLoading && !this.sessionViewStore.isLoaded;
    }

    setState(){
        this._isLoading = true;
        const userId = this.sessionStore.authData!.userId!;
        this.lotStore.fetchUserFavoriteLots(userId).then(x => {
            this._isLoading = false;
            this.favorites = x;
        });
    }

    reset(){
        this._isLoading = false;
        this.favorites = [];
        return Promise.resolve();
    }
}