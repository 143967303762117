import { Formik, Form } from "formik";
import * as Yup from "yup";
import React from "react";
import { TranslationProps } from "modbin-domain";
import { LoginData } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../../components/buttons/ActionButton";
import { application } from "../../../../Startup";


const RegisterFormView = observer((props:TranslationProps & {onRegister:(data:LoginData)=>Promise<void>}) => {
    const getTranslationSessions = (key:string) => props.t(`screens:public.sessions.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;

    const loginSchema = Yup.object().shape({
            email: Yup.string()
            .email(props.t("domain:users.user.emailInvalid"))
            .required(createRequiredMessage("domain:users.user.email")),
            password: Yup.string()
            .min(5, props.t("domain:users.user.passwordMustHaveMoreThanChars"))
            .required(createRequiredMessage("domain:users.user.password")),
            passwordConfirm:Yup.string()
            .oneOf([Yup.ref("password")],props.t("domain:users.user.passwordsMustMatch"))
            .required(createRequiredMessage("domain:users.user.password"))
          });
      return (
            <Formik
                initialValues={{ email: "", password: "", passwordConfirm:""}}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                    return props.onRegister({email:values.email,password:values.password}).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
            >
            {(formProps) => (
                <Form>
                    <div className="form-row form-row-wide">
                        <FormInput type="email" name="email" label={getTranslationSessions("email")} placeHolder={props.t("domain:users.user.email")} formikState={formProps} />
                    </div>
                    <div className="form-row form-row-wide">
                        <FormInput type="password" name="password" label={getTranslationSessions("password")} placeHolder={props.t("domain:users.user.password")} formikState={formProps} />
                    </div>
                    <div className="form-row form-row-wide">
                        <FormInput type="password" name="passwordConfirm" label={getTranslationSessions("passwordConfirm")} placeHolder={props.t("domain:users.user.password")} formikState={formProps} />
                    </div>
                    <div className="form-row">
                        <ActionButton type={ButtonType.CONTINUE} onClick={()=>{return formProps.submitForm()}} className="border margin-top-10"/>
                    </div>
                </Form>
                )}
            </Formik>
      );
  });
  
  export const RegisterForm = (withTranslation(["common"])(RegisterFormView as any) as any) as (props:any) => JSX.Element;
