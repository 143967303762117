"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SellingEventService = void 0;

var modbin_domain_1 = require("modbin-domain");

var SellingDates_1 = require("./SellingDates");

var Shared_types_1 = require("../../shared/Shared.types");

var moment = require("moment");

var getSoldState = function getSoldState(item) {
  var dateState = SellingDates_1.getEventDateStateFromSellingDates(item.event.dates);

  if (dateState !== Shared_types_1.SellingEventDateState.ENDED) {
    return Shared_types_1.SellingEventSoldState.NOTSOLD;
  }

  return Shared_types_1.SellingEventSoldState.SOLD;
};

var SellingEventService =
/** @class */
function (_super) {
  __extends(SellingEventService, _super);

  function SellingEventService(repository, endingTimeout) {
    var _this = _super.call(this, repository) || this;

    _this.endingTimeout = endingTimeout;
    return _this;
  }

  SellingEventService.prototype.getSellingEventStates = function (item) {
    return {
      sold: getSoldState(item),
      date: SellingDates_1.getEventDateStateFromSellingDates(item.event.dates, this.endingTimeout)
    };
  };

  return SellingEventService;
}(modbin_domain_1.BaseDomainService);

exports.SellingEventService = SellingEventService;