"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bidTranslations = void 0;
exports.bidTranslations = {
  pt: {
    bid: "Licitação",
    bidMustBeNumber: "O valor tem que ser um número",
    bidMustBeGreaterThenNextBid: "O valor tem que ser maior que {{minimumBidValue}}{{currency}}",
    bidRequired: "Tem que introduzir um número"
  },
  en: {
    bid: "Licitação",
    bidMustBeNumber: "Provided value must be a number",
    bidMustBeGreaterThenNextBid: "Provided value must be greater than {{minimumBidValue}}{{currency}}",
    bidRequired: "You must provide a value"
  }
};