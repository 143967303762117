import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { PhotoMode, PhotosView } from "./PhotosView";
import { SelectInputView } from "../../../../components/forms/elements/SelectInputView";
import { WebConfiguration } from "../../../../configuration/Config.types";
import { UiTestDescription } from "modbin-web";
import { application } from "../../../../Startup";

export const description:UiTestDescription = {
    id:"PhotoViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Spinner and loading view component",
    parentId:"components"
};

const items = [
    "/images/single-property-01.jpg",
    "/images/single-property-02.jpg",
    // "/images/single-property-03.jpg",
    // "/images/single-property-04.jpg",
    // "/images/single-property-05.jpg",
    // "/images/single-property-01.jpg",
    // "/images/single-property-02.jpg",
    // "/images/single-property-03.jpg",
    // "/images/single-property-04.jpg",
    // "/images/single-property-05.jpg",
].map(x => {return `${application.getContainer().resolve<WebConfiguration>("webConfig").baseUrl}${x}`})

@observer
export class PhotoViewUiTest extends Component<TranslationProps,{}>{
    
    @observable mode:PhotoMode = "single";

    render(){
        return <div className="container-fluid">
            <div className="row">
                <h2>Photo views</h2>
                <div className="col-md-6">
                    
                    <SelectInputView
                        isLoading={false}
                        items={[{value:"single",text:"single"},{value:"multiple",text:"multiple"}]}
                        label="Mode"
                        onChange={(v:any)=> {this.mode = v}}
                        key="selector:type"
                        selected={this.mode as any}
                    ></SelectInputView>
                </div>
            </div>
            <div className="row">
                <div style={{width:"300px", height:"200px"}}>
                    <PhotosView mode={this.mode} items={items}/>
                </div>
            </div>
        </div>
    }
}