import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { LoginData } from "ibase-auctions-domain";
import { LoginForm} from "./LoginForm";
import { sleepableConsoleLogger, UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"LoginFormUiTest",
    groupId:"components",
    description:"lalal",
    title:"Login component",
    parentId:"components"
};

export class LoginFormUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:LoginData){
        console.log(result);
    }
    
    render(){
        return <LoginForm onLogin={(data:any)=> {return sleepableConsoleLogger(data)}}/>;
    }
}