"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UuuidEntityKeyGenerator = void 0;

var uuid_1 = require("uuid");

var UuuidEntityKeyGenerator =
/** @class */
function () {
  function UuuidEntityKeyGenerator() {}

  UuuidEntityKeyGenerator.prototype.generateKey = function (options) {
    var result = {
      id: uuid_1.v4(),
      prefix: "",
      sufix: ""
    };

    if (!options) {
      return result.id;
    }

    result.prefix = options.prefix !== undefined ? options.prefix : result.prefix;
    result.sufix = options.sufix !== undefined ? options.sufix : result.sufix;
    return "" + result.prefix + result.id + result.sufix;
  };

  return UuuidEntityKeyGenerator;
}();

exports.UuuidEntityKeyGenerator = UuuidEntityKeyGenerator;