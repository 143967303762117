import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { MainAppViewStore } from '../../../configuration/stores/MainAppViewStore';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { SellingEventListItemView } from './components/SellingEventListItemView';
import { LoadingView} from "../../../components/loading/LoadingView";
import { NoResultsView} from "../../../components/loading/NoResultsView";
import { SellingEventVM } from './Store';

const ListView = observer((props: TranslationProps& {items:(SellingEventVM)[], onClick:(id:string)=>void}) => {
    return <div className="container">
        <div className="row fullwidth-layout">
            <div className="col-md-12">
                <div className="listings-container list-layout">
                    {props.items.map(x => <SellingEventListItemView t={props.t} key={x.getId()} item={x} onClick={props.onClick}></SellingEventListItemView>)}
                </div>
            </div>
        </div>
    </div>;
});

@inject("mainAppViewStore") @observer
export class ScreensBusinessEventsIndex extends Component<TranslationProps&{mainAppViewStore?:MainAppViewStore, events:SellingEventVM[], isLoading:boolean, showActive:boolean, onFilterToggle:()=>void},{}> {

    render() {        
        const title = this.props.showActive 
            ? this.props.t("screens:business.events.index.activeTitle")
            : this.props.t("screens:business.events.index.endedTitle");
        const noEventsMsg = this.props.showActive 
            ? this.props.t("screens:business.events.index.noActiveEvents")
            : this.props.t("screens:business.events.index.noEndedEvents");
        return (
            <div>
                <TitleBar title={title}></TitleBar>

                <div className="container">
	                <div className="row fullwidth-layout">
                        <div className="col-md-12">
                            <div className="btn-group btn-toggle" 
                                style={{float: "right",
                                        marginBottom: "40px",
                                        marginTop: "-20px"}}
                                > 
                                <label className="switch">
                                    <input type="checkbox" checked={this.props.showActive} onChange={()=>{this.props.onFilterToggle()}}/>
                                    <span className="slider round"></span>
                                </label>
                                <span style={{
                                        fontSize: "15px",
                                        verticalAlign: "text-top",
                                        marginLeft: "10px"}}>{this.props.t("screens:business.events.index.activeFilter")}</span>
                                {/* <button className={`btn btn-default ${activeFilterClass}`} onClick={()=>{this.props.onFilterToggle()}}>Ativos</button>
                                <button className={`btn btn-primary ${endedFilterClass}`} onClick={()=>{this.props.onFilterToggle()}}>Terminados</button> */}
                            </div>    
                            <div className="clearfix" />  
                            <div className="listings-container list-layout">
                            <LoadingView
                                    isLoading={this.props.isLoading} 
                                    showFallback={this.props.events.length === 0} 
                                    fallbackView={<NoResultsView text={noEventsMsg}></NoResultsView>}
                                >
                                <ListView t={this.props.t} items={this.props.events} onClick={(id:string) => {this.props.mainAppViewStore!.navigateTo("business:events:details",{state:{sellingEventId:id}})}}></ListView>
                            </LoadingView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}