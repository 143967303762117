import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import { appTranslations } from './translations/App.translations';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      debug: true,
      lng: "pt", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      fallbackLng: "pt",
      ns: ['common',"screens","domain"],
      defaultNS:"",
      resources: appTranslations,
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export default i18n;
// i18n
// .use(initReactI18next)
// .init({
//   debug: true,
//
//   lng: 'pt',
//   fallbackLng: "pt",
//   ns: ['common',"screens","domain"],
//   defaultNS:"",
//   resources: appTranslations,
//   react: {
//     wait: false,
//     bindI18n: 'languageChanged loaded',
//     bindStore: 'added removed',
//     nsMode: 'default'
//   }
// });
// export default i18n;
