"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressService = void 0;

var AddressService =
/** @class */
function () {
  function AddressService(regionRepository, countyRepository, parishRepository) {
    this.regionRepository = regionRepository;
    this.countyRepository = countyRepository;
    this.parishRepository = parishRepository;
  }

  AddressService.prototype.getRegions = function () {
    return this.regionRepository.findAll();
  };

  AddressService.prototype.getCounties = function (regionCode) {
    return this.countyRepository.findAllByRepresentation({
      regionCode: regionCode
    });
  };

  AddressService.prototype.getParishes = function (regionCode, countyCode) {
    return this.parishRepository.findAllByRepresentation({
      regionCode: regionCode,
      countyCode: countyCode
    });
  };

  return AddressService;
}();

exports.AddressService = AddressService;