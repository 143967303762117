import { IEvent, DomainEvent } from "modbin-domain"

export class RegisterUserFirstStepEvent extends DomainEvent implements IEvent {
    constructor(email:string){
        super({type:"Session", name:"RegisterUserFirstStep", metadata:{email:email}});
    }
}

export class RegisterUserCompletedEvent extends DomainEvent implements IEvent {
    constructor(email:string){
        super({type:"Session", name:"RegisterUserCompletedStep", metadata:{email:email}});
    }
}