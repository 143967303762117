"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromSubCategoryEnum = exports.convertToSubCategoryEnum = exports.convertFromSubCategory = exports.convertToSubCategory = void 0;

var Client_types_1 = require("../../../client/Client.types");

var CategoryConverter_1 = require("./CategoryConverter");

var DoubleConverter_1 = require("../../shared/DoubleConverter");

var Shared_types_1 = require("../../shared/Shared.types");

var subCategoryConverter = {
  "1": Client_types_1.RealEstateSubCategory.APARTMENT,
  "2": Client_types_1.RealEstateSubCategory.WAREHOUSE,
  "3": Client_types_1.RealEstateSubCategory.OFFICE,
  "4": Client_types_1.RealEstateSubCategory.STORAGE,
  "5": Client_types_1.RealEstateSubCategory.STORE,
  "6": Client_types_1.RealEstateSubCategory.HOME,
  "7": Client_types_1.RealEstateSubCategory.BUILDING,
  "8": Client_types_1.RealEstateSubCategory.FARM,
  "9": Client_types_1.RealEstateSubCategory.RUSTIC,
  "10": Client_types_1.RealEstateSubCategory.CONSTRUCTIONLAND,
  "11": Client_types_1.RealEstateSubCategory.OTHER,
  "12": Client_types_1.HardwareSubCategory.CONSTRUCTION,
  "13": Client_types_1.HardwareSubCategory.OFFICE,
  "14": Client_types_1.HardwareSubCategory.INDUSTRY,
  "15": Client_types_1.HardwareSubCategory.OTHER,
  "16": Client_types_1.FornitureSubCategory.JEWELRY,
  "17": Client_types_1.FornitureSubCategory.COMMODITY,
  "18": Client_types_1.FornitureSubCategory.OFFICE,
  "19": Client_types_1.FornitureSubCategory.HOME,
  "20": Client_types_1.FornitureSubCategory.OTHER,
  "21": Client_types_1.MachineSubCategory.FARMING,
  "22": Client_types_1.MachineSubCategory.CONSTRUCTION,
  "23": Client_types_1.MachineSubCategory.HARDWARE,
  "24": Client_types_1.MachineSubCategory.INDUSTRY,
  "25": Client_types_1.MachineSubCategory.OTHER,
  "39": Client_types_1.FornitureSubCategory.HEALTH,
  "40": Client_types_1.FornitureSubCategory.DECOR,
  "34": Client_types_1.HardwareSubCategory.HOSPITALITY,
  "35": Client_types_1.HardwareSubCategory.SPORTS,
  "36": Client_types_1.HardwareSubCategory.COMPUTING,
  "37": Client_types_1.HardwareSubCategory.HEALTH,
  "32": Client_types_1.RealEstateSubCategory.LAND,
  "33": Client_types_1.RealEstateSubCategory.PREMISE,
  "26": Client_types_1.ClaimSubCategory.UNLISTEDSTOCKS,
  "27": Client_types_1.ClaimSubCategory.INHERITANCE,
  "28": Client_types_1.ClaimSubCategory.BRANDPATENTDOMAIN,
  "29": Client_types_1.ClaimSubCategory.QUOTAS,
  "30": Client_types_1.ClaimSubCategory.TRANSFERS,
  "31": Client_types_1.ClaimSubCategory.OTHER,
  "41": Client_types_1.OtherSubCategory.FOOTWEAR,
  "42": Client_types_1.OtherSubCategory.GOLDJEWELRY,
  "43": Client_types_1.OtherSubCategory.CLOTHING,
  "44": Client_types_1.OtherSubCategory.STOCK,
  "45": Client_types_1.OtherSubCategory.PETS,
  "46": Client_types_1.OtherSubCategory.MISCELLANEOUS,
  "47": Client_types_1.OtherSubCategory.ART,
  "48": Client_types_1.OtherSubCategory.RAWMATERIAL,
  "49": Client_types_1.OtherSubCategory.UNDEFINED,
  "50": Client_types_1.OtherSubCategory.ANTIQUES,
  "51": Client_types_1.OtherSubCategory.TECHNOLOGY,
  "52": Client_types_1.OtherSubCategory.MUMISMATICSANDPHILATELY,
  "53": Client_types_1.OtherSubCategory.WATCHESANDJEWELRY,
  "54": Client_types_1.OtherSubCategory.LAMPS,
  "55": Client_types_1.OtherSubCategory.DISKSANDBOOKS,
  "56": Client_types_1.OtherSubCategory.COMPUTERSANDGAMES,
  "57": Client_types_1.OtherSubCategory.WINESANDDRINKS,
  "101": Client_types_1.VehicleSubCategory.PASSENGER,
  "102": Client_types_1.VehicleSubCategory.DUTY,
  "103": Client_types_1.VehicleSubCategory.HEAVYDUTY,
  "104": Client_types_1.VehicleSubCategory.FARMIMGTRACTOR,
  "105": Client_types_1.VehicleSubCategory.MOTORCYCLE,
  "106": Client_types_1.VehicleSubCategory.BOAT,
  "107": Client_types_1.VehicleSubCategory.AIRCRAFT,
  "108": Client_types_1.VehicleSubCategory.CARAVAN,
  "109": Client_types_1.VehicleSubCategory.OTHER
};
var doubleConverter = DoubleConverter_1.createDoubleConverter(subCategoryConverter);

exports.convertToSubCategory = function (externalSubCategory) {
  externalSubCategory.id = externalSubCategory["tipo_bem_id"];
  var entityData = Shared_types_1.convertToEntity(externalSubCategory);

  var result = __assign(__assign({}, entityData), {
    description: subCategoryConverter["" + entityData.id],
    parentId: CategoryConverter_1.convertToCategory(externalSubCategory.natureza)
  });

  return result;
};

exports.convertFromSubCategory = function (subCategory) {
  return doubleConverter.fromType[subCategory.getId()];
};

exports.convertToSubCategoryEnum = function (tipo_bem_id) {
  return doubleConverter.toType[tipo_bem_id];
};

exports.convertFromSubCategoryEnum = function (subCategory) {
  return doubleConverter.fromType[subCategory];
};