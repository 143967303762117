"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileService = void 0;

var modbin_domain_1 = require("modbin-domain");

var FileService =
/** @class */
function () {
  function FileService(options) {
    this.basePath = options.basePath;
    this.downloadUrlTemplate = options.downloadUrlTemplate;
    this.assetDownloadUrlTemplate = options.assetDownloadUrlTemplate;
    this.noImageAvailable = options.noImageAvailable;
  }

  FileService.prototype.getImageUrl = function (relativePath) {
    //console.log("FileService:getImageUrl:relativePAth", relativePath);
    var result = modbin_domain_1.isNullOrEmptyString(relativePath) ? this.noImageAvailable : "" + this.basePath + relativePath; //console.log("FileService:getImageUrl:result", result);

    return result;
  };

  FileService.prototype.getFileDownloadUrl = function (parameters) {
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.downloadUrlTemplate, parameters);
  };

  FileService.prototype.getAssetFileDownloadUrl = function (parameters) {
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.assetDownloadUrlTemplate, parameters);
  };

  return FileService;
}();

exports.FileService = FileService;