import { observable, action } from "mobx";
import { Region, County, Parish, IAddressService } from "ibase-auctions-domain";
import { BaseStore, IStore } from "modbin-web";
import { ILogger } from "modbin-domain";

const emptyDefaultId = undefined;

const isEmptyDefault = (code:string):boolean => { return code === emptyDefaultId }; 

export class AddressStore extends BaseStore implements IStore {
    @observable regions = new Map<string,Region>();

    @observable counties = new Map<string,County>();

    constructor(private adressService:IAddressService, private logger:ILogger){
        super();
        this.fetchRegions();
    }

    areRegionLoaded(){
        return this.regions.size > 0;
    }

    areRegionCountiesLoaded(regionCode:string){
        const d = this.regions.get(regionCode);
        if(d !== undefined && d.counties !== undefined && d.counties.length !== 0){
            return true;
        }
        return false;
    }

    areCountyParishesLoaded(countyCode:string){
        const c = this.counties.get(countyCode);
        if(c !== undefined && c.parishes !== undefined && c.parishes.length !== 0){
            return true;
        }
        return false;
    }

    fetchRegions():Promise<Region[]>{
        return this.adressService.getRegions().then(x => {           
            this.loadRegionsFromServer(x);
            return x;
        },
        (e:any) => {
            this.logger.error(`Error in fetchRegions method on AddressStore: `,e);
            throw e;
        });
    }

    fetchCounties(regionCode:string):Promise<County[]>{
        return this.adressService.getCounties(regionCode).then(x => {            
            this.loadRegionCountiesFromServer(regionCode,x);
            return x;
        },
        (e:any) => {
            this.logger.error(`Error in fetchCounties method on AddressStore: `,e);
            throw e;
        });
    }

    fetchParishes(regionCode:string,countyCode:string):Promise<Parish[]>{
        return this.adressService.getParishes(regionCode,countyCode).then(x => {            
            this.loadCountyParishesFromServer(countyCode,x);
            return x;
        },
        (e:any) => {
            this.logger.error(`Error in fetchParishes method on AddressStore: `,e);
            throw e;
        });
    }

    @action
    loadRegionsFromServer(regions:Region[]){
        regions.forEach(r => {
            r.counties = observable([]);
            this.regions.set(r.code,r);
        });
    }

    @action 
    loadRegionCountiesFromServer(regionCode:string,counties:County[]){
        counties.forEach(c => {
            c.parishes = observable([]);
            this.counties.set(c.code,c)
            const region = this.regions.get(regionCode)!;
            region.counties!.push(c);// = region.counties!.concat(x);
        });
    }

    @action 
    loadCountyParishesFromServer(countyCode:string,parishes:Parish[]){
        parishes.forEach(p => {
            const county = this.counties.get(countyCode)!;
            county.parishes!.push(p);
        });
    }

    getRegions(){
        return Array.from(this.regions.values());
    }

    getCountiesFromRegion(regionCode:string){
        return isEmptyDefault(regionCode)
            ? []
            : this.regions.get(regionCode)!.counties!;
    }

    getParishesFromCounty(countyCode:string){
        return isEmptyDefault(countyCode)
            ? []
            : this.counties.get(countyCode)!.parishes!;
    }

    onRegionChanged(regionCode:string){
        if(!isEmptyDefault(regionCode) && !this.areRegionCountiesLoaded(regionCode)){
            this.fetchCounties(regionCode);
        }
    }

    onCountyChanged(regionCode:string,countyCode:string){
       if(!isEmptyDefault(regionCode) && !isEmptyDefault(countyCode) && !this.areCountyParishesLoaded(countyCode)){
            this.fetchParishes(regionCode,countyCode);
        }
    }
} 