"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromUserAddress = exports.convertToUserAddress = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.convertToUserAddress = function (source) {
  var street = modbin_domain_1.getStringValueOrDefault(source["morada"], undefined);
  var zipCode1 = modbin_domain_1.getStringValueOrDefault(source["codigo_postal_4"], undefined);
  var zipCode2 = modbin_domain_1.getStringValueOrDefault(source["codigo_postal_3"], undefined);
  var city = modbin_domain_1.getStringValueOrDefault(source["localidade"], undefined);
  var country = modbin_domain_1.getStringValueOrDefault(source["pais"], undefined);
  return {
    street: street,
    zipCode1: zipCode1,
    zipCode2: zipCode2,
    city: city,
    country: country
  };
};

exports.convertFromUserAddress = function (address, target) {
  if (address !== undefined) {
    target.morada = address.street, target.codigo_postal_4 = address.zipCode1, target.codigo_postal_3 = address.zipCode2, target.localidade = address.city; //     target.country = address.country
  }

  return target;
};