import React, { Fragment } from "react";
import { SellingType } from "ibase-auctions-domain";
import { withTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sellingModeIconMapper } from "./SellingModeIconMapper";
import { TranslationProps } from "modbin-domain";

export const InnerSellingModeIndicator = (props:TranslationProps & {mode?:SellingType}) => {
    const {mode} = props;
    if(mode === undefined){
        return <Fragment></Fragment>
    }
    return <OverlayTrigger overlay={<Tooltip id={`tooltip:${mode}`}>{props.t(`domain:sales.events.type.value.${mode}`)}</Tooltip>}>
        <span className={sellingModeIconMapper.get(mode)} 
        style={{
                color:"#2ceef0", 
                fontSize:"35px",
                right: "30px",
                width: "23px",
                height: "26px",
                bottom: "28px",
                position:"absolute",
                zIndex:110
        }} />
    </OverlayTrigger>;
}

export const SellingModeIndicator = (withTranslation(["domain"])(InnerSellingModeIndicator as any) as any) as (props:any) => JSX.Element;