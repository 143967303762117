"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CsvSingleFileRepository = exports.csvConvertersBuilder = void 0;

var UuuidEntityKeyGenerator_1 = require("./UuuidEntityKeyGenerator");

var pathModule = require("path");

exports.csvConvertersBuilder = function (options) {
  var _a = options.separatorElement,
      separatorElement = _a === void 0 ? "," : _a;

  var entityToCvsConverter = function entityToCvsConverter(entiy) {
    var result = options.from(entiy).join(separatorElement) + "\n";
    return result;
  };

  var csvToEntityConverter = function csvToEntityConverter(str) {
    var parts = str.split(separatorElement);
    return options.to(parts);
  };

  return {
    from: entityToCvsConverter,
    to: csvToEntityConverter
  };
};

function activator(Type, props) {
  return new Type(props);
}

var CsvSingleFileRepository =
/** @class */
function () {
  function CsvSingleFileRepository(options) {
    if (options === undefined) {
      return;
    }

    var idGenerator = options.idGenerator;

    if (idGenerator === undefined) {
      this.idGenerator = new UuuidEntityKeyGenerator_1.UuuidEntityKeyGenerator();
    }

    this.fileSystemService = options.fileSystemService;
    this.filePath = pathModule.normalize(options.filePath);
    var converters = exports.csvConvertersBuilder(options.converter);
    this.fromEntityToCsvConverter = converters.from;
    this.toEntityFromCsvConverter = converters.to;
    this.typeConstructor = options.typeConstructor;
  }

  CsvSingleFileRepository.prototype.generateKey = function (options) {
    return this.idGenerator.generateKey(options);
  };

  CsvSingleFileRepository.prototype.extendWithEntityBaseInfo = function (entity) {
    var currentDate = new Date(new Date().toISOString());
    var id = this.idGenerator.generateKey();
    return __assign(__assign({}, entity), {
      id: id,
      createdDate: currentDate,
      modifiedDate: currentDate
    });
  };

  CsvSingleFileRepository.prototype.create = function (entity, options) {
    return __awaiter(this, void 0, void 0, function () {
      var entityProps, entityToStore, csvEntity;
      return __generator(this, function (_a) {
        entityProps = entity.getId === undefined || entity.getId() === undefined ? this.extendWithEntityBaseInfo(entity) : entity;
        entityToStore = activator(this.typeConstructor, entityProps);
        csvEntity = this.fromEntityToCsvConverter(entityToStore); //console.log("CsvSingleFileRepository:create -> calculated csvEntity: ",csvEntity);

        return [2
        /*return*/
        , this.fileSystemService.files.append(this.filePath, csvEntity).then(function (x) {
          return entity;
        })];
      });
    });
  };

  CsvSingleFileRepository.prototype.findById = function (id, options) {
    throw new Error("Method not implemented.");
  };

  CsvSingleFileRepository.prototype.update = function (entity) {
    throw new Error("Method not implemented.");
  };

  CsvSingleFileRepository.prototype.del = function (id) {
    throw new Error("Method not implemented.");
  };

  CsvSingleFileRepository.prototype.findAll = function (predicate) {
    return __awaiter(this, void 0, void 0, function () {
      var content, lines, result;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.fileSystemService.files.readContent(this.filePath, "utf8")];

          case 1:
            content = _a.sent();
            console.log("C", content);
            lines = content.split(/\r?\n/);
            result = lines.slice(0, -1).map(function (x) {
              return _this.toEntityFromCsvConverter(x);
            });
            return [2
            /*return*/
            , Promise.resolve(result)];
        }
      });
    });
  };

  CsvSingleFileRepository.prototype.findAllByRepresentation = function (query, options) {
    throw new Error("Method not implemented.");
  };

  CsvSingleFileRepository.prototype.findAllPageableByRepresentation = function (query, options) {
    throw new Error("Method not implemented.");
  };

  CsvSingleFileRepository.prototype.find = function (query) {
    throw new Error("Method not implemented.");
  };

  return CsvSingleFileRepository;
}();

exports.CsvSingleFileRepository = CsvSingleFileRepository;