import React from "react";
import { observer, inject } from "mobx-react";
import { LoginWidget } from "./LoginWidget";
import { AccountMenu } from "./AccountMenu";
import { SessionStore } from "../../../../screens/public/sessions/SessionStore";

export const LoginAccountWidget = inject("sessionStore")(observer((props:{sessionStore?:SessionStore}) => {
    const children = !props.sessionStore!.isAuthenticated
        ? <LoginWidget/>
        : <AccountMenu/>;
    return children;
}));