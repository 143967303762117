import React, {Component} from "react";
import {isNotNullOrEmptyNumber, TranslationProps} from "modbin-domain";
import {Currency, Money} from "ibase-auctions-domain";
import {Form, Formik, FormikProps} from "formik";
import {FormInput} from "../../../../../components/forms/elements/FormInput";
import {withTranslation} from "react-i18next";
import * as Yup from "yup";
import {ActionButton, ButtonType} from "../../../../../components/buttons/ActionButton";
import {currencyMapper} from "../../../components/helpers/CurrencyMappers";
import {Button, Modal} from "react-bootstrap";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {getFormatedPrice} from "../../../components/helpers/Price";

export type MinimumBid = {
    value:Money,
    currency?:Currency,
    default?:Money
}


const ConfirmationView = observer((props:TranslationProps & {visible:boolean, value:number, onCancel:(ev:any)=>void, onConfirmation:()=>Promise<void>}
) => {
    const confirmationModalView = 
        <Modal show={props.visible} onHide={(ev:any)=> {props.onCancel(ev)}}>
            <Modal.Header>
                <Modal.Title>{props.t("screens:business.lots.details.bidConfirmationTitle")}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                {/*<div>*/}
                {/*    <span>{props.t("screens:business.lots.details.bidConfirmationText")}</span>*/}
                {/*</div>*/}
                <div style={{textAlign:"center"}}>
                    <span>{props.t("screens:business.lots.details.bidConfirmationValueText")}</span> <span style={{fontWeight:"bold"}}>{getFormatedPrice(props.value,Currency.EURO)} </span>
                </div>                
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={(ev)=>{ props.onCancel(ev)}}>{props.t(`common:buttons.cancel`)}</Button>
                <Button bsStyle="primary" onClick={(ev) => { return props.onConfirmation()}}>{props.t(`common:buttons.confirmation`)}</Button>
            </Modal.Footer>
        </Modal>;
    return confirmationModalView;
})

// const BidFormView = observer((props:TranslationProps & {
//         minimumBid:MinimumBid, 
//         buttonType:ButtonType, onBid:(value:number)=>Promise<void>}
//     ) => {

    
//     const getTranslation = (key:string,params?:any) => props.t(`domain:sales.bid.${key}`,params); 
    
//     const bidSchema = Yup.object().shape({
//             bid: Yup.number()
//                 .typeError(getTranslation("bidMustBeNumber"))
//                 .required(getTranslation("bidRequired"))
//                 .min(props.minimumBid!.value,getTranslation("bidMustBeGreaterThenNextBid",{minimumBidValue:props.minimumBid.value, currency:currencyMapper.get(props.minimumBid.currency!)!.symbol}))
//           });
//     const defaultValue = props.minimumBid.default !== undefined
//           ? props.minimumBid.default!
//           : props.minimumBid.value;
//     return (
//         <div>
//             <Formik
//                 initialValues={{bid:defaultValue}}
//                 enableReinitialize={true} 
//                 validationSchema={bidSchema}
//                 onSubmit={(values, actions) => {
//                     return props.onBid(values.bid).then((x)=>{
//                         actions.setSubmitting(false);
//                     });
//                 }}
//               >
//                 {(formProps) => (
//                     <Form>
//                         <div className="select-input">
//                             <i className="data-unit" style={{top:"25px"}}>{currencyMapper.get(props.minimumBid.currency!)!.symbol}</i>
//                             <FormInput type="number" name="bid" formikState={formProps} />
//                         </div>
//                         <div className="form-row">
//                             <ActionButton type={props.buttonType} onClick={()=>{return formProps.submitForm()}} className="fullwidth"/>
//                         </div>
//                   </Form>
//                 )}
//               </Formik>

//         </div>
//       );
//   });

  @observer
  export class BidFormView extends Component<
    TranslationProps & {
    minimumBid:MinimumBid, 
    buttonType:ButtonType, 
    onBid:(value:number)=>Promise<void>
  },{}> {
        @observable confirmationModalVisible:boolean = false;
        @observable currentValue:number = 0.0;
        formProps:any = undefined;

        onCancelSubmition(ev:any){
            this.confirmationModalVisible = false;
        }

        onBid(formProps:FormikProps<{
            bid: number;
        }>){
            formProps.validateField("bid");
            if(formProps.isValid && formProps.getFieldProps("bid").value !== undefined ){
                this.confirmationModalVisible = true;
                this.currentValue = formProps.getFieldProps("bid").value;
            }
            return Promise.resolve()
        }

        onConfirmation(){
            this.confirmationModalVisible = false;
            return this.formProps.submitForm();
        }

        render(){
            const { minimumBid, onBid, buttonType} = this.props;
            const getTranslation = (key:string,params?:any) => this.props.t(`domain:sales.bid.${key}`,params); 
            const bidSchema = Yup.object().shape({
                    bid: Yup.number()
                        .typeError(getTranslation("bidMustBeNumber"))
                        .required(getTranslation("bidRequired"))
                        .min(minimumBid!.value,getTranslation("bidMustBeGreaterThenNextBid",{minimumBidValue:minimumBid.value, currency:currencyMapper.get(minimumBid.currency!)!.symbol}))
                });
            const defaultValue = isNotNullOrEmptyNumber(minimumBid.default!)
                ? minimumBid.default!
                : minimumBid.value;
            //const defaultValue = undefined as any;
            return <div>
                <Formik
                    initialValues={{bid:defaultValue}}
                    enableReinitialize={true} 
                    validationSchema={bidSchema}
                    onSubmit={(values, actions) => {
                        return onBid(values.bid).then((x)=>{
                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(formProps) => {
                        this.formProps = formProps;
                        return (<div>
                            <Form>
                                <div className="select-input">
                                    <i className="data-unit" style={{top:"25px"}}>{currencyMapper.get(minimumBid.currency!)!.symbol}</i>
                                    <FormInput type="number" name="bid" formikState={formProps} />
                                </div>
                                <div className="form-row">
                                    <ActionButton type={buttonType} onClick={()=>{return this.onBid(formProps);}} className="fullwidth"/>
                                </div>
                            </Form>
                        </div>
                    )}}
                  
                </Formik>
                
                <ConfirmationView 
                                t={this.props.t}
                                onCancel={(ev:any) => this.onCancelSubmition(ev)}
                                onConfirmation={()=>{return this.onConfirmation()}}
                                value={this.currentValue}
                                visible={this.confirmationModalVisible}
                            ></ConfirmationView>
            </div>;
        }
  }
  
  export const BidForm = (withTranslation(["common"])(BidFormView as any) as any) as (props:any) => JSX.Element;
