"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EstadoCivil = void 0;
var EstadoCivil;

(function (EstadoCivil) {
  EstadoCivil["CASADO"] = "casado";
  EstadoCivil["SOLTEIRO"] = "solteiro";
  EstadoCivil["DIVORCIADO"] = "divorciado";
  EstadoCivil["VIUVO"] = "viuvo";
})(EstadoCivil = exports.EstadoCivil || (exports.EstadoCivil = {}));