"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceUrlTemplateBuilder = void 0;

var modbin_domain_1 = require("modbin-domain");

var ResourceUrlTemplateBuilder =
/** @class */
function () {
  function ResourceUrlTemplateBuilder(template, resourceName) {
    var _this = this;

    this.getParametersFromObjectNeedForUrlTemplate = function (entity, template) {
      var templateParametersNames = template.split("/").filter(function (x) {
        return x.startsWith("{") && x.endsWith("}");
      }).map(function (x) {
        return x.slice(1, -1);
      });

      var idParameterName = _this.getIdParameterName();

      var result = {};
      templateParametersNames.forEach(function (name) {
        if (entity[name] !== undefined) {
          result[name] = entity[name];
        } else {
          if (name === idParameterName) {
            if (entity.id !== undefined) {
              result[idParameterName] = entity.id;
            }

            if (entity.getId !== undefined && entity.getId() !== undefined) {
              result[idParameterName] = entity.id;
            }
          }
        }
      });
      return result;
    };

    this.resourceName = resourceName;
    this.setTemplatesForMethods(template);
  }

  ResourceUrlTemplateBuilder.prototype.setTemplatesForMethods = function (templates) {
    var collection = templates.collection,
        _a = templates.single,
        single = _a === void 0 ? collection + "/{" + this.getIdParameterName() + "}" : _a,
        get = templates.get,
        post = templates.post,
        put = templates.put,
        del = templates.delete;
    this.templates = {
      collection: collection,
      get: get !== undefined ? get : single,
      post: post !== undefined ? post : collection,
      put: put !== undefined ? put : single,
      delete: del !== undefined ? del : single
    };
  };

  ResourceUrlTemplateBuilder.prototype.getIdParameterName = function () {
    return this.resourceName + "Id";
  };

  ResourceUrlTemplateBuilder.prototype.buildCollectionTemplate = function (query) {
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.templates.collection, query);
  };

  ResourceUrlTemplateBuilder.prototype.buildGetTemplate = function (query) {
    var templateParameters = {};

    if (modbin_domain_1.isNotNullOrEmptyString(query)) {
      templateParameters[this.getIdParameterName()] = query;
    } else {
      templateParameters = this.getParametersFromObjectNeedForUrlTemplate(query, this.templates.get);
    }

    return modbin_domain_1.buildUrlPathFromPathTemplate(this.templates.get, templateParameters);
  };

  ResourceUrlTemplateBuilder.prototype.buildPostTemplate = function (source) {
    var templateParameters = this.getParametersFromObjectNeedForUrlTemplate(source, this.templates.post);
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.templates.post, templateParameters);
  };

  ResourceUrlTemplateBuilder.prototype.buildPutTemplate = function (source) {
    var templateParameters = this.getParametersFromObjectNeedForUrlTemplate(source, this.templates.put);
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.templates.put, templateParameters);
  };

  ResourceUrlTemplateBuilder.prototype.buildDeleteTemplate = function (id) {
    var templateParameters = {};
    templateParameters[this.getIdParameterName()] = id;
    return modbin_domain_1.buildUrlPathFromPathTemplate(this.templates.delete, templateParameters);
  };

  ResourceUrlTemplateBuilder.prototype.buildTemplate = function (defaultTemplate, options) {
    if (options === undefined) {
      return defaultTemplate;
    }

    var _a = options.template,
        template = _a === void 0 ? defaultTemplate : _a,
        params = options.params,
        query = options.query;
    var url = modbin_domain_1.buildUrlPathFromPathTemplate(template, __assign(__assign({}, params), query));
    return url;
  };

  return ResourceUrlTemplateBuilder;
}();

exports.ResourceUrlTemplateBuilder = ResourceUrlTemplateBuilder;