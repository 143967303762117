"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromCompanyRepresentative = exports.convertToCompanyRepresentative = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.convertToCompanyRepresentative = function (externalCompany) {
  if (!externalCompany.is_company) {
    return {
      isRepresentative: false
    };
  }

  if (externalCompany.is_company && (modbin_domain_1.isNullOrEmptyString(externalCompany.company_name, true) || modbin_domain_1.isNullOrEmptyString(externalCompany.company_vat_number, true))) {
    return {
      isRepresentative: false
    };
  }

  return {
    isRepresentative: externalCompany.is_company,
    name: externalCompany.company_name,
    vatNumber: externalCompany.company_vat_number
  };
};

exports.convertFromCompanyRepresentative = function (companyRepresentative, target) {
  target.is_company = companyRepresentative.isRepresentative;
  target.company_vat_number = companyRepresentative.vatNumber;
  target.company_name = companyRepresentative.name;
  return target;
};