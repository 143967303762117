import { Contact, Address, GeographicLocation } from "ibase-auctions-domain";

export type CompanyInfo = {
    headquarters: { 
        contacts:Contact,
        address:Address,
        location:GeographicLocation
    },
    warehouse_office: { 
        contacts:Contact,
        address:Address,
        location:GeographicLocation
    }
};

export const companyInfo: CompanyInfo = {
    headquarters: {
        contacts: {
            email: "info@cparaiso.pt",
            faxNumber: "(+351) 21 815 53 16",
            landPhoneNumber: "",
            mobilePhoneNumber: "(+351) 21 812 23 84"
        },
        address: {
            street:"Rua Andrade, 2 R/C Dto",
            city:"",
            zipCode1:"1170-015 Lisboa",
            country:""
        },
        location:{
            latitude:"38.7198502",
            longitude:"-9.1331314"
        }
    },
    warehouse_office: {
        contacts: {
            email: "info@cparaiso.pt",
            faxNumber: "",
            landPhoneNumber: "(+351) 21 812 39 84",
            mobilePhoneNumber: ""
        },
        address: {
            street:"Rua Casal dos Netos, Lt. 15",
            city:"",
            zipCode1:"1495-687 Cruz Quebrada",
            country:""
        },
        location:{
            latitude:"",
            longitude:""
        }
    }
} 