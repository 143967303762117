import React, { Component } from 'react';
import { TranslationProps } from "modbin-domain";
import { TitleBar } from '../../../components/layout/header/TitleBar';

export class ScreensProfessionalsIndex extends Component<TranslationProps,{}> {
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:public.professionals.${key}`); 
        return <div>
            <TitleBar title={getTranslation("title")}></TitleBar>
            <div className="container">
                <div className="row">
                    <div className="numbered color">
                        <ol>
                            <li>Pesquisa e localização de bens móveis e imóveis</li>
                            <li>Promoção e acompanhamento de registos em Conservatórias</li>
                            <li>Reportagem fotográfica dos bens</li>
                            <li>Posse de imóveis</li>
                            <li>Promoção e acompanhamento de CPCV´s e Escrituras Públicas</li>
                            <li>Lançamento/criação das vendas nas seguintes modalidades : leilão eletrónico, carta fechada, leilão presencial e ultima fase negociação particular</li>
                            <li>levantamentos topográficos</li>
                            <li>Avaliações dos imóveis e móveis</li>
                            <li>Remoção e parqueamentos das viaturas</li>
                            <li>Escritura públicas</li>
                            <li>Solicitação das licenças e plantas dos imóveis nas respetivas camara municipais</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>;
    }
}