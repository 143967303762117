import React from "react";
import { observer } from "mobx-react";
import ReactLoading from 'react-loading';

export enum SpinnerType {
    BALLS = "balls",
    BARS = "bars",
    BUBBLES = "bubbles",
    CUBES = "cubes",
    CYLON = "cylon",
    SPIN = "spin",
    SPINNIGBUBBLES = "spinningBubbles",
    SPOKES = "spokes"
}

export type SpinnerProps = {
    type?:SpinnerType,
    size?:"small"|"medium"|"large",
    color?:string,
    className?:string
}

export const Spinner = observer((props:SpinnerProps) => {
    const sizes = {
        small:1,
        medium:2,
        large:4
    }
    const {type=SpinnerType.SPIN, size="large", color="#1c95a7", className=""} = props 
    const resultSize = {
        height:sizes[size]*16,
        width:sizes[size]*16
    }
    return <ReactLoading type={type as any} color={color} {...resultSize} className={className}/>
});