"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromCategory = exports.convertToCategory = void 0;

var Client_types_1 = require("../../../client/Client.types");

var DoubleConverter_1 = require("../../shared/DoubleConverter");

var categoryConverter = {
  "VEICULO": Client_types_1.Category.VEHICLE,
  "IMOVEL": Client_types_1.Category.REALESTATE,
  "MOBILIARIO": Client_types_1.Category.FORNITURE,
  "MAQUINA": Client_types_1.Category.MACHINE,
  "EQUIPAMENTO": Client_types_1.Category.HARDWARE,
  "DIREITO": Client_types_1.Category.CLAIM,
  "OUTRO": Client_types_1.Category.OTHER
};
var doubleConverter = DoubleConverter_1.createDoubleConverter(categoryConverter);

exports.convertToCategory = function (natureza) {
  return doubleConverter.toType[natureza];
};

exports.convertFromCategory = function (category) {
  return doubleConverter.fromType[category];
};