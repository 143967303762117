"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultQueryableArray = void 0;

var Utils_types_1 = require("../../utils/Utils.types");

var DefaultQueryableArray =
/** @class */
function () {
  function DefaultQueryableArray(options) {
    this.queryableSteps = [];
    this.totalItems = 0;

    if (options) {
      if (options.specifiable) {
        this.filter(options.specifiable);
      } else {
        this.filter(function (x) {
          return true === true;
        });
      } //this.clone(Cloner.withJson);


      if (options.sortable) {
        this.sortBy(options.sortable);
      }

      if (options.pageable) {
        this.paginate(options.pageable);
      }

      if (options.projectable) {
        this.project(options.projectable);
      }
    }
  }

  DefaultQueryableArray.prototype.setSteps = function (queryableSteps) {
    this.queryableSteps = queryableSteps;
  };

  DefaultQueryableArray.prototype.clone = function (cloner) {
    var _this = this;

    var cloneFunction = function cloneFunction(items) {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2
          /*return*/
          , items.map(function (x) {
            return cloner(x);
          })];
        });
      });
    };

    this.queryableSteps.push(cloneFunction);
    return this;
  };

  DefaultQueryableArray.prototype.getTotal = function () {
    return this.totalItems;
  };

  DefaultQueryableArray.prototype.sortBy = function (comparer) {
    var _this = this;

    var sortByFunction = function sortByFunction(items) {
      return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2
          /*return*/
          , items.sort(comparer)];
        });
      });
    };

    this.queryableSteps.push(sortByFunction);
    return this;
  };

  DefaultQueryableArray.prototype.filter = function (predicates) {
    var _this = this;

    var filterFunction = function filterFunction(items) {
      return __awaiter(_this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
          result = items.filter(predicates);
          this.totalItems = result.length;
          return [2
          /*return*/
          , result];
        });
      });
    };

    this.queryableSteps.push(filterFunction);
    return this;
  };

  DefaultQueryableArray.prototype.project = function (projector) {
    var _this = this;

    var projectFunction = function projectFunction(items) {
      return __awaiter(_this, void 0, void 0, function () {
        var result;

        var _this = this;

        return __generator(this, function (_a) {
          result = Promise.all(items.map(function (x) {
            return __awaiter(_this, void 0, void 0, function () {
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    return [4
                    /*yield*/
                    , projector(x)];

                  case 1:
                    return [2
                    /*return*/
                    , _a.sent()];
                }
              });
            });
          }));
          return [2
          /*return*/
          , result];
        });
      });
    };

    this.queryableSteps.push(projectFunction);
    var newQueryable = new DefaultQueryableArray();
    newQueryable.setSteps(this.queryableSteps);
    return newQueryable;
  };

  DefaultQueryableArray.prototype.paginate = function (parameters) {
    var _this = this;

    var paginateFunction = function paginateFunction(items) {
      return __awaiter(_this, void 0, void 0, function () {
        var page, size, init, end;
        return __generator(this, function (_a) {
          page = parameters.page, size = parameters.size;
          init = (page - 1) * size;
          end = page * size;
          return [2
          /*return*/
          , items.slice(init, end)];
        });
      });
    };

    this.queryableSteps.push(paginateFunction);
    return this;
  };

  DefaultQueryableArray.prototype.execute = function (items) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , Utils_types_1.composePromise.apply(void 0, this.queryableSteps.reverse())(items)];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  return DefaultQueryableArray;
}();

exports.DefaultQueryableArray = DefaultQueryableArray;