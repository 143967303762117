import { Component } from "react";
import { TranslationProps } from "modbin-domain";
import React from "react";
import { NotFoundErrorMessage } from "../../../components/errors/NotFoundErrorMessage";

export class ScreensErrorsNotFound extends Component<TranslationProps,{}> {
    render() {
        
        return (
            <div className="margin-top-100">
                <NotFoundErrorMessage></NotFoundErrorMessage>
            </div>
        );
    }
}

