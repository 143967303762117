import { observable } from "mobx";
import { OperationStateResult, unknownState, loadingState, activationSuccessState, OperationState, invalidActivationParametersState } from "../../operations/States";
import { ActivationData, ISessionService } from "ibase-auctions-domain";
import { createLocalizedNotification } from "../../../../../components/notifications/LocalizedNotificationView";
import { ILogger, isNullOrEmptyString } from "modbin-domain";
import { NotificationType, BaseStore, IStore } from "modbin-web";

export class ActivationViewStore extends BaseStore implements IStore {
    @observable activationState:OperationStateResult= unknownState;

    constructor(
            private sessionService:ISessionService,
            private logger:ILogger
        ){
            super();
    }

    activateAccount(activationData:ActivationData){
        this.activationState = loadingState;
        this.sessionService.activateAccount(activationData)
        .then(
            (x:any)=> {
                this.activationState = activationSuccessState;    
            },
            (x:any)=> {
                this.logger.error("Error in activateAccount method in ActivationViewStore:",x);
                this.activationState = {
                    state: OperationState.ERROR, 
                    notification: createLocalizedNotification({
                        type:NotificationType.ERROR,
                        headerKey:"common:words.unexpectedErrorTitle",
                        text:x.message
                    })};    
            },
        );
    }

    setState(params:{validationToken:string,email:string}){   
        if(params === undefined || isNullOrEmptyString(params.validationToken) || isNullOrEmptyString(params.email)){
            this.activationState = invalidActivationParametersState;    
        }
        else { 
            this.activateAccount({validationToken:params.validationToken,email:params.email});
        }
    }

    reset(){
        this.activationState = unknownState;
        return Promise.resolve();
    }
}