"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogLevel = void 0;
var LogLevel;

(function (LogLevel) {
  LogLevel[LogLevel["OFF"] = 0] = "OFF";
  LogLevel[LogLevel["FATAL"] = 100] = "FATAL";
  LogLevel[LogLevel["ERROR"] = 200] = "ERROR";
  LogLevel[LogLevel["WARN"] = 300] = "WARN";
  LogLevel[LogLevel["INFO"] = 400] = "INFO";
  LogLevel[LogLevel["DEBUG"] = 500] = "DEBUG";
  LogLevel[LogLevel["TRACE"] = 600] = "TRACE";
  LogLevel[LogLevel["ALL"] = Number.MAX_VALUE] = "ALL";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));