import React from "react";
import { Field, ErrorMessage, getIn } from "formik";

export const TermsCheckbox = (props:{name:string,formikState:any, label:any})=>{
    const {touched,errors} = props.formikState;
    const { name, formikState, label, ...rest} = props;
    return <div className={`form-group ${
        getIn(touched,name) && getIn(errors,name) ? "has-error" : ""
        }`}>
        <Field
            type="checkbox"
            name={name}
            className={`form-control ${
                getIn(touched,name) && getIn(errors,name) ? "is-invalid" : ""
            }`}
            {...rest}
            style={{width:"20px", height:"20px"}}
        />
        {label}
        <ErrorMessage
            component="div"
            name={props.name}
            className="help-block invalid-feedback"
        />
    </div>;
}