import { BaseStore, IViewNavigator, IViewDefinitionProvider, ViewDefinition, IViewInitialState } from "modbin-web";
import { IMainAppViewStore } from "./IMainAppViewStore";import { observable, computed } from "mobx";import { Language } from "modbin-domain";import { LayoutStore } from "../../../../screens/public/home/LayoutStore";import i18n from "../../../../i18n";import { BannerType } from "ibase-auctions-domain";

export class BaseMainAppViewStore extends BaseStore implements IMainAppViewStore{
    @observable language:Language = Language.PT;
    @observable isLoaded:boolean = false;
    @observable popupVisible:boolean = true;

    constructor(private viewNavigator:IViewNavigator, private viewProvider:IViewDefinitionProvider){
        super();
    }
    getPathForView(viewId: string): { viewId: string; nameTranslationKey: string; parentId?: string | undefined; }[] {
        return this.viewNavigator.getPathForView(viewId);
    }
    get currentPath(){
        return this.viewNavigator.currentPath;
    }
   
    getViewDefinition(viewName: string): ViewDefinition | undefined {
        return this.viewProvider.getViewDefinition(viewName);
    }
    
    getViewDefinitions(): ViewDefinition[] {
        return this.viewProvider.getViewDefinitions();
    }

    registerViews(views:ViewDefinition[]): void {
        return this.viewProvider.registerViews(views);
    }

    get currentView(){
        return this.viewNavigator.currentView;
    }

    navigateTo(viewName: string, options?: { event?: any; state?: IViewInitialState | undefined; } | undefined): void {
        if(this.currentView !== undefined && this.currentView!.store !== undefined && this.currentView!.store.leave !== undefined){
            this.currentView.store.leave();
        }
        window.scrollTo(0, 0);
        this.viewNavigator!.navigateTo(viewName,options);
        return;
    }

    navigateBack(): void {
        return this.viewNavigator!.navigateBack();
    }

    navigateToFromUrl(url: string): void {
        return this.viewNavigator!.navigateToFromUrl(url);
    }

    createViewRouteUrl(viewId:string, options:{ state?:any, escape:boolean}):string{
        return this.viewNavigator.createViewRouteUrl(viewId,options);
    }

    changeLanguage(language:Language){
        this.language = language;
        i18n.changeLanguage(language)
    }

    setLoaded(){
        this.isLoaded = true;
    }

    configure(options?:any){

    }

    reset(){
        this.isLoaded = false;
        this.popupVisible = true;
        return Promise.resolve();
    }
}

