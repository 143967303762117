"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usersProfile = void 0;

var UserConverter_1 = require("./UserConverter");

var proponenteToUser = {
  sourceType: "proponente",
  targetType: "user",
  converter: UserConverter_1.convertToUser
};
var userToProponente = {
  sourceType: "user",
  targetType: "proponente",
  converter: UserConverter_1.convertFromUser
};
exports.usersProfile = {
  name: "Users",
  definitions: [proponenteToUser, userToProponente]
};