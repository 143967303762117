import React, { Component, Fragment } from 'react';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { TranslationProps } from '../../../translations/Translations.types';

export class ScreensCompanyIndex extends Component<TranslationProps,{}> {
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:public.company.${key}`); 
        return (<Fragment>
            <TitleBar title={getTranslation("title")}></TitleBar>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="headline margin-bottom-25">{getTranslation("s1t1")}</h3>

                        <p>{getTranslation("s1p1")}</p>
                        <p>{getTranslation("s1p2")}</p>
                        <p>{getTranslation("s1p3")}</p>

                        <h3 className="margin-top-40 margin-bottom-25">{getTranslation("s2t1")}</h3>

                        <p>{getTranslation("s2p1")}</p>
                        <p>{getTranslation("s2p2")}</p>
                        <p>{getTranslation("s2p3")}</p>
                    </div>
                </div>
            </div>
        </Fragment>);
    }
}