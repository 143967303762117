"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Environment = void 0;
var Environment;

(function (Environment) {
  Environment["LOCAL"] = "local";
  Environment["TESTING"] = "testing";
  Environment["DEVELOPMENT"] = "development";
  Environment["STAGING"] = "staging";
  Environment["PRODUCTION"] = "production";
})(Environment = exports.Environment || (exports.Environment = {}));