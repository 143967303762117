import React, { Fragment } from "react"
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Money, Currency } from "ibase-auctions-domain";
import { BidForm } from "../../../../components/bid/BidForm";
import { ButtonType } from "../../../../../../../components/buttons/ActionButton";
import { BidderStateView } from "./BidderStateView";
import { BidderState } from "../../ViewStore";


export const OnlineAuctionBidForm = observer((props:TranslationProps & {bidderState?:BidderState, minimumBid?:{value:Money,currency?:Currency}, onBid:(value:number)=>Promise<void>}) => {
    const {bidderState, ...rest} = props;

    return <div>
        <BidForm buttonType={ButtonType.BID} {...rest}/>
        <BidderStateView bidderState={bidderState}></BidderStateView>
    </div>
});
