import {observable, computed, get} from "mobx";
import { SellingEvent,SellingEventQuery } from "ibase-auctions-domain";
import { SellingEventStore, activeQuery } from "./Store";
import { clockEndDateSorter } from "../components/details-common/Helpers";
import { BaseStore, IStore } from "modbin-web";
import { boolean, string, number } from "yup";
import { constructor } from "react";
const moment = require("moment");

const createEndedQuery = (currentDate:Date, numberOfDaysToShowPastEvents:number):SellingEventQuery => {
    let toDate = moment(currentDate).clone();
    toDate.subtract(1, "days");
    //console.log("TODATE",toDate.toDate().getTime())
    let fromDate = toDate.clone();
    fromDate.subtract(numberOfDaysToShowPastEvents, "days");
    //console.log("TODATE",toDate.toDate().getTime())
    //console.log("FROMDATE",fromDate.toDate().getTime())
    //console.log("DAYS",numberOfDaysToShowPastEvents)
    return {
        dateEndGreaterThan:fromDate.toDate().getTime(),
        dateEndLesserThan:toDate.toDate().getTime(),
        published:true,
        includeInsolvent:true
    };
}

export class SellingEventIndexViewStore extends BaseStore implements IStore { 
    
    @observable isLoading:boolean = false;
    @observable showActive = true; 
    @observable visibleEvents:string[] = []; 

    constructor(private sellingEventStore:SellingEventStore, private numberOfDaysToShowPastEvents:number) {
        super();
    }
    
    getEvents(){
        return this.sellingEventStore.getSellingEventsFromIdList(this.visibleEvents).sort(clockEndDateSorter);
    }

    getEventById(id:string):SellingEvent | undefined{
        return this.sellingEventStore.getEventById(id);
    }

    @computed
    get props(){
        return {
            events:this.getEvents(),
            isLoading:this.isLoading,
            showActive:this.showActive,
            onFilterToggle:()=>{this.togleFilterChange()}
        }
    }

    togleFilterChange(){
        this.showActive = !this.showActive;
        this.fetchData();
    }

    fetchData():Promise<SellingEvent[]>{
        this.isLoading = true;
        const query = this.showActive ? activeQuery : createEndedQuery(new Date(Date.now()),this.numberOfDaysToShowPastEvents);
        return this.sellingEventStore.fetchSellingEvents(query).then(x => {
            this.visibleEvents = x.map(x => {return x.getId()})
            this.isLoading = false;
            return x;
        },(e)=> {
            this.isLoading = false;
            throw e;
        });
    }

    setState(){
        this.fetchData();
    }

}