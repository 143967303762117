import React, { Component } from 'react';
//import { Nav, NavItem } from 'react-bootstrap';
import { TranslationProps} from 'modbin-domain';

// const GroupMenu = (props:{groupName:string, groupId:string, elements:UiTestSpec[], onSelect:(id:string)=>void}) => {
//     const groupElements = props.elements.filter(e => e.groupId = props.groupId)
//     return <div>
//         <h3>{props.groupName}</h3>
//         <Nav bsStyle="pills" >
//             <NavItem eventKey={1} href="/home">
//             NavItem 1 content
//             </NavItem>
//             <NavItem eventKey={2} title="Item">
//             NavItem 2 content
//             </NavItem>
//             <NavItem eventKey={3} disabled>
//             NavItem 3 content
//             </NavItem>
//         </Nav>
//     </div>
// }

export class UiTestScreenIndex extends Component<TranslationProps,{}> {

    render(){
        return (<div>
                <h1>Ui tests index</h1>
            </div>
        );
    }
}