import { MaritalStatus, User, UserAccountState } from "ibase-auctions-domain";

export const users:User[] = [
    new User({ 
        id:"1",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        firstName:"User1",
        lastName:"Test1",
        accountState:UserAccountState.ACTIVATED,
        contact: {
            email:"user1@gmail.com"
        },
        address:{
            street:"street1",
            zipCode1:"zipCode1",
            city:"city1",
            country:"Portugal"    
        },
        title:"Sr.1",
        maritalStatus: MaritalStatus.SINGLE,
        identificationCard: {
            number:"100000001",
            issuedAt: "Lisboa",
            expiration: new Date(Date.now()),
            vatNumber:"123123123"
        },
        birthDate: new Date(Date.now()),
        bornAt:"Portugal",
        //TODO: what is this?
        provider: "What is this?"
    }),
    new User({
        id:"2",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        firstName:"User2",
        lastName:"Test2",
        accountState:UserAccountState.UNACTIVATED,
        contact: {
            email:"user2@gmail.com"
        },
        address:{
            street:"street2",
            zipCode1:"zipCode2",
            city:"city2",
            country:"Portugal"    
        },
        title:"Sr.2",
        maritalStatus: MaritalStatus.SINGLE,
        identificationCard: {
            number:"100000002",
            issuedAt: "Lisboa",
            expiration: new Date(Date.now()),
            vatNumber:"100000002",
        },
        birthDate: new Date(Date.now()),
        bornAt:"Portugal",
        //TODO: what is this?
        provider: "What is this?"
    }),
    new User({
        id:"3",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        firstName:"User3",
        lastName:"Test3",
        accountState:UserAccountState.BLOCKED,
        contact: {
            email:"user3@gmail.com"
        },
        address:{
            street:"street3",
            zipCode1:"zipCode3",
            city:"city3",
            country:"Portugal"    
        },
        title:"Sr.3",
        maritalStatus: MaritalStatus.SINGLE,
        identificationCard: {
            number:"100000003",
            issuedAt: "Lisboa",
            expiration: new Date(Date.now()),
            vatNumber:"100000003",
        },
        birthDate: new Date(Date.now()),
        bornAt:"Portugal",
        //TODO: what is this?
        provider: "What is this?"
    }),
    new User({
        id:"4",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        firstName:"User4",
        lastName:"Test4",
        accountState:UserAccountState.UNACTIVATED,
        contact: {
            email:"user3@gmail.com"
        },
        address:{
            street:"street4",
            zipCode1:"zipCode4",
            city:"city4",
            country:"Portugal"    
        },        
        title:"Sr.4",
        maritalStatus: MaritalStatus.SINGLE,
        identificationCard: {
            number:"100000004",
            issuedAt: "Lisboa",
            expiration: new Date(Date.now()),
            vatNumber:"100000004",
        },
        birthDate: new Date(Date.now()),
        bornAt:"Portugal",
        //TODO: what is this?
        provider: "What is this?"
    })
];