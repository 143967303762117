import React from 'react';
import { ReactToastifyUserNotificationService } from "./ReactToastifyUserNotificationService"
import { UserNotification } from "modbin-web"
import { DeveloperNotification } from './DeveloperNotification';

export class DeveloperReactToastifyUserNotificationService extends ReactToastifyUserNotificationService {

    createNotification(notification:UserNotification){
        notification.title = "Developer message only"
        return <DeveloperNotification notification={notification}></DeveloperNotification>
    }
}