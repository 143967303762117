"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truncate = void 0;

var Strings_1 = require("./Strings");

exports.truncate = function (str, options) {
  if (options === void 0) {
    options = {};
  }

  if (Strings_1.isNullOrEmptyString(str)) {
    return str;
  }

  var _a = options.length,
      length = _a === void 0 ? 50 : _a,
      _b = options.ending,
      ending = _b === void 0 ? "..." : _b,
      _c = options.fill,
      fill = _c === void 0 ? false : _c;

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    if (fill) {
      return "" + str + " ".repeat(length - str.length);
    }

    return str;
  }
};