"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseUserNotificationService = void 0;

var modbin_domain_1 = require("modbin-domain");

var NotificationType_1 = require("./NotificationType");

var BaseUserNotificationService =
/** @class */
function () {
  function BaseUserNotificationService() {}

  BaseUserNotificationService.prototype.internalShowNotification = function (notification) {
    notification.component = this.createNotification(notification);
    this.showNotification(notification);
  };

  BaseUserNotificationService.prototype.internalCreateNotification = function (type, notification) {
    var resultNotification = modbin_domain_1.isString(notification) ? {
      message: notification,
      type: type
    } : __assign(__assign({}, notification), {
      type: type
    });
    return resultNotification;
  };

  BaseUserNotificationService.prototype.error = function (notification) {
    var resultNotification = this.internalCreateNotification(NotificationType_1.NotificationType.ERROR, notification);
    this.internalShowNotification(resultNotification);
  };

  BaseUserNotificationService.prototype.warning = function (notification) {
    var resultNotification = this.internalCreateNotification(NotificationType_1.NotificationType.WARNIG, notification);
    this.internalShowNotification(resultNotification);
  };

  BaseUserNotificationService.prototype.info = function (notification) {
    var resultNotification = this.internalCreateNotification(NotificationType_1.NotificationType.INFO, notification);
    this.internalShowNotification(resultNotification);
  };

  BaseUserNotificationService.prototype.success = function (notification) {
    var resultNotification = this.internalCreateNotification(NotificationType_1.NotificationType.SUCCESS, notification);
    this.internalShowNotification(resultNotification);
  };

  BaseUserNotificationService.prototype.notify = function (level, notification) {
    this[level](notification);
  };

  return BaseUserNotificationService;
}();

exports.BaseUserNotificationService = BaseUserNotificationService;