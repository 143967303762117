import React, { Fragment } from "react";
import { TranslationProps, ITimerService } from "modbin-domain";
import {Lot, ClockDate, DocumentFile, SellingType } from "ibase-auctions-domain";
import { ShortTextAddressView } from "../../components/location/ShortTextAddressView";
import { observer } from "mobx-react";
import { DownloadButton } from "../../../../components/files/DownloadButton";
import {
  LoadingView,
  ElementPosition,
} from "../../../../components/loading/LoadingView";
import { SellingEventSummaryView } from "./SellingEventSummaryView";
import { SellingEventVM } from "../Store";
import { AuctionClockView } from "../../components/time/ClockCountdownView";
import { application } from "../../../../Startup";
import { NavigationLink } from "../../../../components/layout/navigation/menus/NavigationLink";


export const SellingEventListItemView = observer(
  (
    props: TranslationProps & {
      item: SellingEventVM;
      onClick: (id: string) => void;
    }
  ) => {
    const onClick = (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      props.onClick((props.item as any).getId());
    };
    const clockDate: ClockDate = props.item.clockDate;
    const timerService = application
      .getContainer()
      .resolve<ITimerService>("timeService");
    const clockView =
      props.item.type !== SellingType.PRIVATENEGOTIATION ? (
        <AuctionClockView
          type="simple"
          size="small"
          startDate={clockDate.start}
          endDate={clockDate.end}
          nowDate={timerService.getCurrentDate()}
        ></AuctionClockView>
      ) : (
        <Fragment />
      );
    return (
      <div
        className="listing-item"
        onClick={(ev: any) => {
          onClick(ev);
        }}
      >
        {/* <SummaryView item={props.item}
        size="small"
        showPictures={true}
        showSellingMode={true}
        badges={[BadgeType.FEATURED]}
         /> */}
        <SellingEventSummaryView item={props.item} />

        <div className="listing-content">
          <div className="listing-title">
            <h4>{props.item.title}</h4>
            <ShortTextAddressView
              address={props.item.address}
            ></ShortTextAddressView>
            <NavigationLink
              className="details button border"
              to="business:events:details"
              state={{ sellingEventId: (props.item as any).getId() }}
            >
              {props.t("common:buttons.details")}
            </NavigationLink>
            {/* <a href="# " className="details button border" onClick={(ev:any)=> {onClick(ev)}}>{props.t("common:buttons.details")}</a> */}
          </div>

          <div
            style={{
              padding: "18px 35px 18px 35px",
              backgroundColor: "rgb(247, 247, 247)",
              color: "rgb(134, 134, 134)",
              lineHeight: "27px",
              overflow: "hidden",
            }}
          >
            {props.item.description}
          </div>
          <ul
            className="listing-details"
            style={{ padding: "0px", backgroundColor: "white" }}
          >
            {props.item.processInsolvent != "" && props.item.processInsolvent != null ? (
                (/*props.item.processType == 'JUDICIAL' ||*/ props.item.location != null && props.item.location.latitude == 0) ? (

                <li style={{ display: "block" }}>
                  <strong>
                    {props.t("domain:sales.events.event.insolvent")}:{" "}
                  </strong>
                  <span>{props.item.processInsolvent}</span>
                </li>
              ) : (
                <li style={{ display: "block" }}>
                  <strong>
                    {props.t("domain:sales.events.event.seller")}:{" "}
                  </strong>
                  <span>{props.item.processInsolvent}</span>
                </li>

              )
            ) : null}
            <li style={{ display: "block" }}>
              <strong>
                {props.t("domain:sales.events.event.processId")}:{" "}
              </strong>
              <span>{props.item.processReference}</span>
            </li>
          </ul>
          <div className="listing-footer">
            {/* <ul className="listing-documents">
                    <LoadingView isLoading={!props.item.documentsLoaded} position={ElementPosition.LEFT} spinner={{size:"small"}}>                        
                        {
                            props.item.documents.slice(0,2).map((x:DocumentFile) => {
                                //return <li key={`document:${(x as any).getId()}`}><a href={(x as any).url} download={x.name}><i className="fa  fa-file-text-o"></i>{x.name}</a></li>;
                                return <li key={`document:${(x as any).getId()}`}><DownloadButton url={(x as any).url} name={(x as any).typeDescription} fileName={x.fileName}></DownloadButton></li>;
                            })
                        }                        
                    </LoadingView>
                    
                </ul> */}
            <span>{clockView}</span>
          </div>
        </div>
      </div>
    );
  }
);
