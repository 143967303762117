"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertfromSellingEventQuery = void 0; // "published":"publicado",
// "featured":"destaque",
// "includeToday":"flag_include_closed_today",
// "includeInsolvent":"info_insolvent"
//date_end_lt : new Date(Date.now()),
//date_end_gt : new Date(Date.now()) - 7,

exports.convertfromSellingEventQuery = function (query) {
  var result = {};

  if (query.published !== undefined) {
    result.publicado = query.published;
  }

  if (query.featured !== undefined) {
    result.destaque = query.featured;
  }

  if (query.includeToday !== undefined) {
    result.flag_include_closed_today = query.includeToday;
  }

  if (query.includeInsolvent !== undefined) {
    result.info_insolvent = query.includeInsolvent;
  }

  if (query.active !== undefined) {
    result.flag_event_active = query.active;
  }

  if (query.dateEndGreaterThan !== undefined) {
    result.date_end_gt = query.dateEndGreaterThan;
  }

  if (query.dateEndLesserThan !== undefined) {
    result.date_end_lt = query.dateEndLesserThan;
  }

  return result;
};