"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultApplicationFactory = void 0;

var Logging_types_1 = require("../logging/Logging.types");

var IApplicationLoggingModule_1 = require("./IApplicationLoggingModule");

var Ioc_types_1 = require("../ioc/Ioc.types");

var Application_1 = require("./Application");

var DefaultApplicationTaskProvider_1 = require("./tasks/DefaultApplicationTaskProvider");

var DefaultApplicationTaskRunner_1 = require("./tasks/DefaultApplicationTaskRunner");

var IApplicationTaskModule_1 = require("./tasks/IApplicationTaskModule");

var Mapper_types_1 = require("../mapper/Mapper.types");

var createDefaultTaskOptions = function createDefaultTaskOptions() {
  var provider = new DefaultApplicationTaskProvider_1.DefaultApplicationTaskProvider();

  var runner = function runner(logger) {
    return new DefaultApplicationTaskRunner_1.ApplicationTaskRunner(provider, logger);
  };

  return {
    provider: provider,
    runner: runner
  };
};

var defaultOptions = {
  logging: {
    factory: new Logging_types_1.DefaultLoggerFactory([new Logging_types_1.ConsoleLoggerProvider()]),
    providers: []
  },
  tasks: createDefaultTaskOptions(),
  targetConstructor: Application_1.Application
};

var DefaultApplicationFactory =
/** @class */
function () {
  function DefaultApplicationFactory(options) {
    var finalOptions = Object.assign(defaultOptions, options);
    this.options = finalOptions;
    this.initialize(this.options);
  }

  DefaultApplicationFactory.prototype.initialize = function (options) {
    this.loggerFactory = this.initializeLoggerFactory(options);
    this.taskProvider = options.tasks.provider;
    this.taskRunner = options.tasks.runner;
  };

  DefaultApplicationFactory.prototype.createContainer = function (logger) {
    return new Ioc_types_1.DefaultIocContainer(logger);
  };

  DefaultApplicationFactory.prototype.initializeLoggerFactory = function (options) {
    var _a = options.logging,
        factory = _a.factory,
        providers = _a.providers;
    providers.forEach(function (x) {
      factory.addProvider(x);
    });
    return factory;
  };

  DefaultApplicationFactory.prototype.createLogger = function (factory, options) {
    return factory.create(options);
  };

  DefaultApplicationFactory.prototype.createLoggingModule = function (options) {
    var module = new IApplicationLoggingModule_1.ApplicationLoggingModule(options);
    return module;
  };

  DefaultApplicationFactory.prototype.createTaskModule = function (options) {
    var taskProvider = options.taskProvider,
        taskRunner = options.taskRunner,
        tasks = options.tasks;

    if (tasks !== undefined) {
      taskProvider.addTaskDefinitions(tasks.definitions);
      taskProvider.addTaskExecutionDefinitions(tasks.executionDefinitions);
    }

    var module = new IApplicationTaskModule_1.ApplicationTaskModule(taskProvider, taskRunner);
    return module;
  };

  DefaultApplicationFactory.prototype.registerModules = function (container, modules) {
    //container.register("logger", logger);
    if (modules !== undefined && modules.length > 0) {
      modules.forEach(function (x) {
        x.configure({
          kernel: container
        });
        container.registerModule(x);
      });
    }
  };

  DefaultApplicationFactory.prototype.createOptions = function (options) {
    var logging = options.logging,
        tasks = options.tasks,
        modules = options.modules,
        settings = options.settings,
        name = options.name,
        rest = __rest(options, ["logging", "tasks", "modules", "settings", "name"]);

    var loggerDescription = this.createLogger(this.loggerFactory, logging);
    var container = this.createContainer(loggerDescription.logger);
    var loggingModule = this.createLoggingModule({
      container: container,
      factory: this.loggerFactory,
      loggerDescription: loggerDescription
    });
    var taskModule = this.createTaskModule({
      tasks: tasks,
      taskProvider: this.taskProvider,
      taskRunner: this.taskRunner(loggerDescription.logger)
    });
    this.registerModules(container, modules);

    var applicationOptions = __assign({
      container: container,
      logging: loggingModule,
      tasks: taskModule,
      modules: modules,
      name: name,
      settings: settings
    }, rest);

    return applicationOptions;
  };

  DefaultApplicationFactory.prototype.createApplication = function (options) {
    var applicationOptions = this.createOptions(options);
    var application = Mapper_types_1.activator(this.options.targetConstructor, applicationOptions);
    application.tasks.getProvider().initialize(application);
    return application;
  };

  return DefaultApplicationFactory;
}();

exports.DefaultApplicationFactory = DefaultApplicationFactory;