import { ScreensProfileIndex } from "./profile/Index";
import { ScreensProfileBids } from "./profile/screens/bids/Index";
import { AuthorizationType, ViewDefinition } from "modbin-web";

const profileViewIndex = {
    name:"private:profile",
    component: ScreensProfileIndex,
    title:"Perfil de utilizador",
    storeName:"profileViewStore",
    route:{
        name:"private:profile",
        template:"/users/profile"
    },
    layout:{
        props: {
            type:"fluid"
        }
    },
    authorization:AuthorizationType.REQUIRED
};

const profileViewBids = {
    name:"private:profile:bids",
    component: ScreensProfileBids,
    title:"Licitações",
    //store: appRoot.stores.profileBidsViewStore,
    storeName:"profileBidsViewStore",
    route:{
        name:"private:profile:bids",
        template:"/users/bids"
    },
    layout:{
        props: {
            type:"fluid"
        }
    },
    authorization:AuthorizationType.REQUIRED
};

export const privateViewsDefinitions:ViewDefinition[] = [
    profileViewIndex,
    profileViewBids
]