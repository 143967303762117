import { ISubCategoryRepository, SubCategory, Category } from "ibase-auctions-domain";
import { InMemoryRepository, IResourceMapper } from "modbin-domain-extensions";

export class InMemorySubCategoryRepository extends InMemoryRepository<SubCategory> implements ISubCategoryRepository {
    private categories = new Map<string,SubCategory[]>();
    private resourceMapper:IResourceMapper;
    private initialElements?:any[] = [];
    
    constructor(options: {elements?:any[], resourceMapper:IResourceMapper}){
            super({initialElements:[]});
            this.resourceMapper = options.resourceMapper;
            this.initialElements = options.elements;
    }

    private async mapElements(initialElements:any[]):Promise<any[]>{
        const x = (await this.resourceMapper.mapCollection(initialElements,{source:"tipo_bem",target:"subcategory",targetConstructor:SubCategory}));
        x.forEach((e:any) => { super.create(e);})
        return Promise.resolve(x);
    }

    async loadCategories():Promise<void>{
        const elements = await this.mapElements(this.initialElements!);
        Object.keys(Category).forEach(x => {
            this.categories.set((Category as any)[x as any] as any,[]);
        })
        elements.forEach(x => {
            if(x.parentId !== undefined){
                if(this.categories.get(x.parentId) !== undefined){
                    this.categories.set(x.parentId,this.categories.get(x.parentId)!.concat(x));
                }
            }
        });
        return Promise.resolve();
    }

    findAllSubCategoriesFromCategory(category:string): SubCategory[]|undefined {
        const cat = this.categories.get(category);
        return cat !== undefined ? this.categories.get(category) : [];
    }
}