"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpContentType = void 0;
var HttpContentType;

(function (HttpContentType) {
  HttpContentType["JSON"] = "application/json";
  HttpContentType["MULTIPARTFORMDATA"] = "multipart/form-data";
  HttpContentType["FORMURLENCODED"] = "x-www-form-urlencoded";
  HttpContentType["DEFAULT"] = "dafault";
})(HttpContentType = exports.HttpContentType || (exports.HttpContentType = {}));