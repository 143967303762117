"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultQueryBuilder = void 0;

var Queryable_types_1 = require("../queryable/Queryable.types");

var parseOrSetDefault = function parseOrSetDefault(defaultValues, queryParameters) {
  var result = queryParameters.has(defaultValues.name) ? queryParameters.get(defaultValues.name) : defaultValues.value;
  return result;
};

var DefaultQueryBuilder =
/** @class */
function () {
  function DefaultQueryBuilder(options) {
    var _this = this;

    this.options = options;

    this.buildQuery = function (queryParameters) {
      var query = {
        specifiable: undefined,
        sortable: undefined,
        pageable: undefined,
        projectable: undefined
      };

      if (!_this.options) {
        return query;
      }

      var _a = _this.options,
          specifiable = _a.specifiable,
          pageable = _a.pageable,
          sortable = _a.sortable,
          projectable = _a.projectable;
      query.specifiable = specifiable !== undefined ? _this.buildSpecifiable(queryParameters, specifiable) : undefined;
      query.pageable = pageable !== undefined ? _this.buildPageable(queryParameters, pageable) : undefined;
      query.sortable = sortable !== undefined ? _this.buildSortable(queryParameters, sortable) : undefined;
      query.projectable = projectable !== undefined ? _this.buildProjectable(queryParameters, projectable) : undefined;
      return query;
    };

    this.build = function (queryParameters) {
      var query = _this.buildQuery(queryParameters);

      return query;
    };
  }

  DefaultQueryBuilder.prototype.getOptions = function () {
    return this.options;
  };

  DefaultQueryBuilder.prototype.buildSpecifiable = function (queryParameters, options) {
    var mapper = options.mapper;
    var predicates = Queryable_types_1.specifiableMapper(queryParameters, mapper);

    var predicate = function predicate(x) {
      return predicates.map(function (f) {
        return f(x);
      }).every(function (r) {
        return r == true;
      });
    };

    return predicate;
  };

  DefaultQueryBuilder.prototype.buildPageable = function (queryParameters, options) {
    var page = options.page,
        size = options.size;
    return {
      page: parseOrSetDefault(page, queryParameters),
      size: parseOrSetDefault(size, queryParameters)
    };
  };

  DefaultQueryBuilder.prototype.buildSortable = function (queryParameters, options) {
    var by = options.by,
        direction = options.direction;
    var sortableParameters = {
      by: parseOrSetDefault(by, queryParameters),
      direction: parseOrSetDefault(direction, queryParameters)
    };
    var comparer = options.mapper.get(sortableParameters.by)(sortableParameters.direction);
    return comparer;
  };

  ;

  DefaultQueryBuilder.prototype.buildProjectable = function (queryParameters, options) {
    var selector = options.selector;
    var projectableParameters = {
      selector: parseOrSetDefault(selector, queryParameters)
    };
    var selectorF = options.mapper.get(projectableParameters.selector);
    selectorF = selectorF === undefined ? options.mapper.get(options.selector.value) : selectorF;
    return selectorF;
  };

  ;
  return DefaultQueryBuilder;
}();

exports.DefaultQueryBuilder = DefaultQueryBuilder;