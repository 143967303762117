"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DirectorRouter = void 0;

var modbin_web_1 = require("modbin-web");

var director_1 = require("director/build/director");

var mobx_1 = require("mobx");

var queryString = require('query-string');

var DirectorRouter =
/** @class */
function (_super) {
  __extends(DirectorRouter, _super);

  function DirectorRouter(viewProvider, viewNavigator) {
    var _this = _super.call(this, viewNavigator) || this;

    _this.viewProvider = viewProvider;
    return _this;
  }

  DirectorRouter.prototype.initialize = function () {
    this.updateStateOnUrlChange();
    this.updateUrlOnStateChange();
  };

  DirectorRouter.prototype.updateStateOnUrlChange = function () {
    var _this = this;

    var routes = {};
    this.viewProvider.getViewDefinitions().forEach(function (x) {
      var route = x.route;
      routes[route.template] = {
        on: function on() {
          var params = [];

          for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
          }

          var options = {
            urlParameters: params,
            queryString: window.location.search,
            routeDefinition: route
          };
          var stateParam = modbin_web_1.convertParametersToState(options);

          _this.viewNavigator.navigateTo(x.name, {
            state: stateParam
          });
        },
        before: function before() {
          var params = [];

          for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
          }

          console.log.apply(console, params); //this.routeStore.navigateTo(x.name,{...params});
        },
        after: function after() {
          if (x.store !== undefined && x.store.leave !== undefined) {
            x.store.leave();
          }
        }
      };
    });
    var router = new director_1.Router(routes).configure({
      //  const router = new Router.http.Router().configure({
      html5history: true,
      on: function on(m, p, r) {// console.log("M",m);
        // console.log("P",p);
        // console.log("R",r);
      },
      notfound: function notfound() {
        _this.viewNavigator.navigateTo("common:errors:notfound");
      }
    }); // router.on("/lots/:lotId/?((\w|.)*)", function(lotId:string,query:string,lala:string){
    //     console.log(queryString.parse(window.location.search));
    //     alert(`${lotId}, ${query}, ${lala}`);
    // })

    router.init();
  };

  DirectorRouter.prototype.updateUrlOnStateChange = function () {
    var _this = this; // update url on state changes


    mobx_1.autorun(function () {
      _super.prototype.updateUrlOnStateChange.call(_this);
    });
  };

  return DirectorRouter;
}(modbin_web_1.BaseBrowserRouter);

exports.DirectorRouter = DirectorRouter;