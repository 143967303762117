import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { InformationRequest } from "ibase-auctions-domain";
import { Formik, Form } from "formik";
import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import { observable } from "mobx";
import { ActionButton, ButtonType } from "../../buttons/ActionButton";
import { FormInput } from "../elements/FormInput";
import { application } from "../../../Startup";

export type ContactFormTrigger = {
    Component:any;
    otherProps?:any;
}

export const ContactFormFieldsView = observer((props:
    TranslationProps & 
    {
        initialValues?:InformationRequest,
        onSubmit:(request:InformationRequest)=>Promise<void>,
        trigger:ContactFormTrigger
    }) => {
    const getTranslation = (key:string) => props.t(`common:contactform.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loginSchema = Yup.object().shape({
            email: Yup.string()
            .email(props.t("domain:users.user.emailInvalid"))
            .required(createRequiredMessage("domain:users.user.email")),
            name: Yup.string()
            .required(createRequiredMessage(getTranslation("name"))),
            subject:Yup.string()
            .required(createRequiredMessage(getTranslation("subject"))),
            message:Yup.string()
            .required(createRequiredMessage(getTranslation("message")))
          });
    const initialValues = props.initialValues !== undefined
          ? props.initialValues 
          : { name:"", email: "", subject: "", message:""};
      return (
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                    return props.onSubmit(values as InformationRequest).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
            >
            {(formProps) => {
                const {Component,otherProps} = props.trigger;
                return (
                <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInput type="text" name="name" label={getTranslation("name")} placeHolder={getTranslation("name")} formikState={formProps} />
                        </div>
                        <div className="col-md-6">
                            <FormInput type="text" name="email" label={getTranslation("email")} placeHolder={getTranslation("email")} formikState={formProps} />
                        </div>
                    </div>
                    <div>
                        <FormInput type="text" name="subject" label={getTranslation("subject")} placeHolder={getTranslation("subject")} formikState={formProps} />
                    </div>
                    <div>
                        <FormInput as="textarea" type="text" name="message" label={getTranslation("message")} placeHolder={getTranslation("message")} formikState={formProps} />
                    </div>
                    <div className="form-row">
                        <Component type={ButtonType.SEND} onClick={()=>{return formProps.submitForm()}} {...otherProps}/>
                    </div>
                </Form>
                )}}
            </Formik>
      );
  });
  
@observer
export class ContactForm extends Component<TranslationProps & {onSubmit:(request:InformationRequest)=>Promise<void>},{}> {

    @observable sent:boolean = false;

    onSubmit(request:InformationRequest):Promise<void>{
        return this.props.onSubmit(new InformationRequest(request)).then(x =>{
               this.sent = true;
            return Promise.resolve();
        }).catch(x =>{this.sent = true});
    }

    createContactFormBody(){
        const getTranslationContacts = (key:string) => this.props.t(`screens:public.contacts.${key}`); 
        return 	<Fragment>
            <h4 className="headline margin-bottom-35">{getTranslationContacts("contactUs")}</h4>
            <div id="contact-message"></div> 
            <div>
                <ContactFormFieldsView t={this.props.t} trigger={{Component:ActionButton,otherProps:{style:{float:"right"}}}} onSubmit={(req)=>{return this.onSubmit(req)}}></ContactFormFieldsView>
                {/* <ActionButton type={ButtonType.SEND} onClick={()=>{return this.handleSubmitForm()}} /> */}
                {/* // <input type="submit" className="submit button" id="submit" value={getTranslation("send")} style={{float:"right"}} onClick={(ev)=>{this.handleSubmitForm(ev)}}/> */}
            </div> 
        </Fragment>;
    }

    createSentMessage(){
        return <div className="notification notice">
            <p><strong>Obrigado pelo seu contacto!</strong></p>
            <p><strong>A nossa equipa irá responder com a maior brevidade possível.</strong></p>
        </div>;
    }

    render(){
        return this.sent ? this.createSentMessage() : this.createContactFormBody();
    }
}
