import React, { Fragment } from "react";
import { PhotosView, PhotoMode } from "./PhotosView";
import { BadgeType, Badge } from "../badges/Badge";
import { withTranslation } from "react-i18next";
import { SellingModeIndicator } from "../selling-mode/SellingModeIndicator";

export type SummaryTemplateViewProps = {
    photoMode?:PhotoMode;
    photos:string[];
    badges?:BadgeType[];
    info?:any;
    sellingType?:any;
    onClick?:()=>void;
}

export const InnerSummaryTemplateView = (props:SummaryTemplateViewProps) => {
    const {onClick=()=>{}} = props;
    const photos = <PhotosView mode={props.photoMode} items={props.photos}></PhotosView>
    
    const badges = props.badges !== undefined && props.badges.length > 0
        ? <div className="listing-badges">{props.badges.map(x => {return <Badge key={`badge:type:${x}`} type={x}></Badge>})}</div>
        : <Fragment></Fragment>;
    
    const info = props.info !== undefined
        ? props.info
        : <Fragment></Fragment>;

    const sellingModeIndicator = props.sellingType !== undefined
        ? <SellingModeIndicator mode={props.sellingType} />
        : <Fragment></Fragment>;

    const resultProps = {
        className:"listing-img-container",
        style:{flex:"1.9 1", minHeight:"250px"},
        onClick:()=>{onClick()}
    }
    return <div {...resultProps}> 
        {badges}
        <div className="listing-img-content">
            {info}
            {sellingModeIndicator}
        </div>
        {photos}
    </div>;
}

export const SummaryTemplateView = (withTranslation(["domain"])(InnerSummaryTemplateView as any) as any) as (props:any) => JSX.Element;