import React from "react";
import { inject, observer } from "mobx-react";
import { MainAppViewStore } from "../../configuration/stores/MainAppViewStore";
import pt from "../../resources/images/pt.svg";
import gb from "../../resources/images/gb.svg";
import { Language } from "../../translations/Translations.types";

@inject("mainAppViewStore") @observer
export class LanguageSelectorView extends React.Component<React.HTMLAttributes<HTMLElement>& { mainAppViewStore?:MainAppViewStore},{}> {

    onChange(ev:any, language:Language){
        ev.preventDefault();
        this.props.mainAppViewStore!.changeLanguage(language);
    }

    getActiveClass(lang:Language){
        return lang === this.props.mainAppViewStore!.language
            ? "active"
            : "";
    }

    render(){
        return <ul className="lang-selector">
            <li><img className={this.getActiveClass(Language.PT)} src={pt} onClick={(ev) => this.onChange(ev,Language.PT)} alt="Portuguese"></img></li>
            <li><img className={this.getActiveClass(Language.EN)} src={gb} onClick={(ev) => this.onChange(ev,Language.EN)} alt="English"></img></li>
        </ul>;
    }
}