import React, { Fragment } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { observer } from "mobx-react";
import { getStringValueOrDefault } from "modbin-domain";

export type SelectInputProps = {
    placeHolder?:{value:string,text:string};
    items:{value:string,text:string}[];
    label?:string;
    isLoading:boolean;
    selected:string;
    onChange:(value:string)=>void;
    key:string;
    className?:string;
}

const OptionItemView = (props:{key:string,value:string,text:string}) => {
    return <option key={`${props.key}:${props.value}`} value={props.value}>{props.text}</option>
}

const createOptions = (key:string,items:{value:string,text:string}[],placeHolder?:{value:string,text:string}) => {
    let result = [];
    if(placeHolder !== undefined){
        result.push(<OptionItemView key={key} value={placeHolder.value} text={placeHolder.text}></OptionItemView>)
    }
    return result.concat(items.map(x => {return <OptionItemView key={key} value={x.value} text={x.text}></OptionItemView>}));
}
    

export const SelectInputView = observer((props:SelectInputProps) => {
    const body = props.isLoading 
        ? <option>loading..</option>
        : createOptions(props.key,props.items,props.placeHolder!);
    const label = props.label !== undefined && props.label !== ""
        ? <ControlLabel>{props.label}</ControlLabel>
        : <Fragment></Fragment>;
    const classÑame = getStringValueOrDefault(props.className,"");
    return <FormGroup key={props.key}>
        {label}
        <FormControl className={classÑame} componentClass="select" placeholder="select" value={props.selected} onChange={(e:any) => props.onChange(e.target.value)}>
            {body}
        </FormControl>
    </FormGroup>;
})