"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var VehicleSubCategory_1 = require("./VehicleSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[VehicleSubCategory_1.VehicleSubCategory.PASSENGER, {
  pt: "Ligeiro de passageiros",
  en: "Passengers"
}], [VehicleSubCategory_1.VehicleSubCategory.DUTY, {
  pt: "Ligeiro comercial",
  en: "Light duty"
}], [VehicleSubCategory_1.VehicleSubCategory.HEAVYDUTY, {
  pt: "Pesado",
  en: "Heavy duty"
}], [VehicleSubCategory_1.VehicleSubCategory.FARMIMGTRACTOR, {
  pt: "Trator agrícola",
  en: "Farming tractor"
}], [VehicleSubCategory_1.VehicleSubCategory.MOTORCYCLE, {
  pt: "Moto",
  en: "Motorcycle"
}], [VehicleSubCategory_1.VehicleSubCategory.BOAT, {
  pt: "Barco",
  en: "Boat"
}], [VehicleSubCategory_1.VehicleSubCategory.AIRCRAFT, {
  pt: "Aeronave",
  en: "Aircraft"
}], [VehicleSubCategory_1.VehicleSubCategory.CARAVAN, {
  pt: "Autocaravana",
  en: "CAravan"
}], [VehicleSubCategory_1.VehicleSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}]]);
exports.vehicleSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de veículos",
    value: translationObject.pt
  },
  en: {
    name: "Vehicle subcategory",
    value: translationObject.en
  }
};