"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.realEstateSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var RealEstateSubCategory_1 = require("./RealEstateSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[RealEstateSubCategory_1.RealEstateSubCategory.APARTMENT, {
  pt: "Apartamento",
  en: "Apartment"
}], [RealEstateSubCategory_1.RealEstateSubCategory.WAREHOUSE, {
  pt: "Armazém/Indústria",
  en: "Warehouse/Industry"
}], [RealEstateSubCategory_1.RealEstateSubCategory.OFFICE, {
  pt: "Escritório",
  en: "Office"
}], [RealEstateSubCategory_1.RealEstateSubCategory.STORAGE, {
  pt: "Garagem/Arrumos",
  en: "Garage/Storage"
}], [RealEstateSubCategory_1.RealEstateSubCategory.STORE, {
  pt: "Loja",
  en: "Store"
}], [RealEstateSubCategory_1.RealEstateSubCategory.HOME, {
  pt: "Moradia",
  en: "House"
}], [RealEstateSubCategory_1.RealEstateSubCategory.BUILDING, {
  pt: "Prédio",
  en: "Building"
}], [RealEstateSubCategory_1.RealEstateSubCategory.FARM, {
  pt: "Quinta/Misto",
  en: "Farm/Mixed"
}], [RealEstateSubCategory_1.RealEstateSubCategory.RUSTIC, {
  pt: "Rústico",
  en: "Rustic"
}], [RealEstateSubCategory_1.RealEstateSubCategory.CONSTRUCTIONLAND, {
  pt: "Terreno construção",
  en: "Construction land"
}], [RealEstateSubCategory_1.RealEstateSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}], [RealEstateSubCategory_1.RealEstateSubCategory.LAND, {
  pt: "Terreno",
  en: "Land"
}], [RealEstateSubCategory_1.RealEstateSubCategory.PREMISE, {
  pt: "Edifício",
  en: "Premise"
}]]);
exports.realEstateSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de imóveis",
    value: translationObject.pt
  },
  en: {
    name: "Real estate subcategory",
    value: translationObject.en
  }
};