"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalidadeVenda = void 0;
var ModalidadeVenda;

(function (ModalidadeVenda) {
  ModalidadeVenda["LEILAO_ONLINE"] = "leilao_online";
  ModalidadeVenda["LEILAO_PRESENCIAL"] = "leilao_presencial";
  ModalidadeVenda["CARTA_FECHADA"] = "carta_fechada";
  ModalidadeVenda["NEGOCIACAO_PARTICULAR"] = "negociacao_particular";
})(ModalidadeVenda = exports.ModalidadeVenda || (exports.ModalidadeVenda = {}));