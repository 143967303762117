import { Booking } from "ibase-auctions-domain";

export const bookingsList = [
    new Booking({
        id:"55",
        createdDate: new Date(),
        modifiedDate:new Date(),
        assetId:"0",
        processId:"0",
        appointmentOnly:true,
        lotId:"2866",
        startDate:new Date(1574208000000),
        endDate:new Date(1572912000000),
        startHour:"12",
        endHour:"18",
        address: {
            street: "Rua da Covilhã",
            zipCode1: "4800",
            zipCode2: "179",
            city: "Guimarães"
        },
        location:undefined,
        person:{
            name:"",
            email:"maria@gmail.com",
            mobilePhoneNumber:"919191919"
        }
    }),
    new Booking({
        id:"56",
        createdDate: new Date(),
        modifiedDate:new Date(),
        assetId:"0",
        processId:"0",
        appointmentOnly:true,
        lotId:"2866",
        startDate:new Date(1574208000000),
        endDate:new Date(1572912000000),
        startHour:"15",
        endHour:"20",
        address: {
            street: "Rua da Padre António",
            zipCode1: "4800",
            zipCode2: "179",
            city: "Guimarães"
        },
        location:undefined,
        person:{
            name:"Bruno Silva",
            email:"outro@gmail.com",
            mobilePhoneNumber:"919191966"
        }
    })
]