"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaritalStatus = void 0;
var MaritalStatus;

(function (MaritalStatus) {
  MaritalStatus["MARRIED"] = "maried";
  MaritalStatus["SINGLE"] = "single";
  MaritalStatus["DIVORCED"] = "divorced";
  MaritalStatus["WIDOW"] = "widow";
})(MaritalStatus = exports.MaritalStatus || (exports.MaritalStatus = {}));