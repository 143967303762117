"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceMapperFactory = void 0;

var ibase_auctions_domain_1 = require("ibase-auctions-domain");

var modbin_domain_extensions_1 = require("modbin-domain-extensions");

var ResourceMapperFactory =
/** @class */
function () {
  function ResourceMapperFactory() {}

  ResourceMapperFactory.create = function (fileService) {
    var definitions = [ibase_auctions_domain_1.createImageUrlMapper(fileService), ibase_auctions_domain_1.createFileUrlMapper(fileService), ibase_auctions_domain_1.createAssetFileUrlMapper(fileService)];
    var profiles = [ibase_auctions_domain_1.categoriesProfile, ibase_auctions_domain_1.contactsProfile, ibase_auctions_domain_1.bookingsProfile, ibase_auctions_domain_1.filesProfile, ibase_auctions_domain_1.lotsProfile, ibase_auctions_domain_1.usersProfile, ibase_auctions_domain_1.eventsProfile, ibase_auctions_domain_1.layoutsProfile];
    var resourceMapper = new modbin_domain_extensions_1.ResourceMapper({
      definitions: definitions,
      profiles: profiles
    });
    return resourceMapper;
  };

  return ResourceMapperFactory;
}();

exports.ResourceMapperFactory = ResourceMapperFactory;