"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseHttpClient = void 0;

var HttpMethod_1 = require("../http/HttpMethod");

var HttpHeaders_1 = require("../http/HttpHeaders");

var BaseHttpClient =
/** @class */
function () {
  function BaseHttpClient(httpRequestClient) {
    this.httpRequestClient = httpRequestClient;
  }

  BaseHttpClient.prototype.createRequest = function (method, url, headers, body) {
    var httpHeadersObjArray = Object.keys(headers).map(function (x) {
      return {
        name: x,
        value: headers[x]
      };
    });
    var httpHeaders = new HttpHeaders_1.HttpHeaders(httpHeadersObjArray);
    var httpRequest = {
      method: method,
      url: url,
      headers: httpHeaders,
      body: body
    };
    return httpRequest;
  };

  BaseHttpClient.prototype.makeRequest = function (method, url, options, body) {
    var resultOptions = {
      headers: {}
    };

    if (options !== undefined && options.headers !== undefined) {
      resultOptions = options;
    }

    var request = this.createRequest(method, url, resultOptions.headers, body);
    return this.httpRequestClient.makeRequest(request);
  };

  BaseHttpClient.prototype.get = function (url, options) {
    return this.makeRequest(HttpMethod_1.HttpMethod.GET, url, options);
  };

  BaseHttpClient.prototype.post = function (url, body, options) {
    return this.makeRequest(HttpMethod_1.HttpMethod.POST, url, options, body);
  };

  BaseHttpClient.prototype.put = function (url, body, options) {
    return this.makeRequest(HttpMethod_1.HttpMethod.PUT, url, options, body);
  };

  BaseHttpClient.prototype.delete = function (url, options) {
    return this.makeRequest(HttpMethod_1.HttpMethod.DELETE, url, options);
  };

  return BaseHttpClient;
}();

exports.BaseHttpClient = BaseHttpClient;