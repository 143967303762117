"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerTypeServer = void 0;
var BannerTypeServer;

(function (BannerTypeServer) {
  BannerTypeServer["BANNER_TOP"] = "BANNER_TOP";
  BannerTypeServer["BANNER_ADVERTISING_LEFT"] = "BANNER_ADVERTISING_LEFT";
  BannerTypeServer["WELCOME_POPUP"] = "WELCOME_POPUP";
})(BannerTypeServer = exports.BannerTypeServer || (exports.BannerTypeServer = {}));