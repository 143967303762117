import { useFormikContext, useField } from "formik";
import React, { Fragment } from "react";
import DatePicker, { registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

export const FormDatePicker = ({ label, ...props }:any) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const labelElement = label !== undefined ? <label htmlFor={props.name}>{label}</label> : <Fragment></Fragment>;
    return <div className="form-group">
            {labelElement}
            <DatePicker
                locale="pt"
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                onChange={val => {
                setFieldValue(field.name, val);
                }}
            />
        </div>; 
  };