import React from 'react';
import { TranslationProps } from "modbin-domain";
import { Lot } from "ibase-auctions-domain";
import { observer } from 'mobx-react';
import moment from "moment";
import { sellingModeIconMapper } from '../../../../../components/selling-mode/SellingModeIconMapper';
import { formatAddress } from '../../../../../components/location/ShortTextAddressView';

export const InfoView = observer((props:TranslationProps & {lot:Lot}) => {
    const datesStyle:any = {
        marginLeft:"10px"
    };
    const pStyle = {
        margin:"5px",
        fontSize:"15px"
    }
    const iStyle = {
        margin:"0px",
        fontSize:"20px"
    }
    return <div className="boxed-widget booking-widget">  
            <div className="margin-bottom-10"> 
                <i className={sellingModeIconMapper.get(props.lot.event.type)} 
                    style={{
                            color:"#2ceef0", 
                            fontSize:"30px",
                            zIndex:110,
                            verticalAlign:"middle"
                    }} />
                <strong style={{marginLeft:"10px", fontSize: "20px"}}>{props.t(`domain:sales.events.type.value.${props.lot.event.type}`)}</strong>
            </div>
            <div>
                <p style={pStyle}>
                    <i className="fa fa-calendar-check-o " style={iStyle}></i>
                    <span style={datesStyle}>{moment(props.lot.event.dates.endDate).format("DD/MM/YYYY")}</span>
                </p>
                <p style={pStyle}>
                    <i className="fa fa-calendar-check-o " style={iStyle}></i>
                    <span style={datesStyle}>{moment(props.lot.event.dates.endDate).format("HH:mm")}</span>
                </p>
                <p style={pStyle}>
                    <i className="fa fa-map-marker" style={iStyle}></i>
                    <span style={{marginLeft:"10px"}}>{formatAddress(props.lot.address)}</span>
                </p>
            </div>
    </div>
});