"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SellingEventDateState = exports.SellingEventSoldState = void 0;
var SellingEventSoldState;

(function (SellingEventSoldState) {
  SellingEventSoldState["SOLD"] = "sold";
  SellingEventSoldState["NOTSOLD"] = "notsold";
})(SellingEventSoldState = exports.SellingEventSoldState || (exports.SellingEventSoldState = {}));

var SellingEventDateState;

(function (SellingEventDateState) {
  SellingEventDateState["NOTSTARTED"] = "notstarted";
  SellingEventDateState["DAYS"] = "days";
  SellingEventDateState["DAY"] = "day";
  SellingEventDateState["HOUR"] = "hour";
  SellingEventDateState["MINUTE"] = "minute";
  SellingEventDateState["VALIDATING"] = "validating";
  SellingEventDateState["ENDED"] = "ended";
  SellingEventDateState["NOTAPPLICABLE"] = "notapplicable";
})(SellingEventDateState = exports.SellingEventDateState || (exports.SellingEventDateState = {}));