"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HardwareSubCategory = void 0;
var HardwareSubCategory;

(function (HardwareSubCategory) {
  HardwareSubCategory["CONSTRUCTION"] = "construction";
  HardwareSubCategory["OFFICE"] = "office";
  HardwareSubCategory["INDUSTRY"] = "industry";
  HardwareSubCategory["HOSPITALITY"] = "hospitality";
  HardwareSubCategory["SPORTS"] = "sports";
  HardwareSubCategory["COMPUTING"] = "computing";
  HardwareSubCategory["HEALTH"] = "health";
  HardwareSubCategory["OTHER"] = "otherhardware";
})(HardwareSubCategory = exports.HardwareSubCategory || (exports.HardwareSubCategory = {}));