"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClaimSubCategory = void 0;
var ClaimSubCategory;

(function (ClaimSubCategory) {
  ClaimSubCategory["UNLISTEDSTOCKS"] = "unlistedstocks";
  ClaimSubCategory["INHERITANCE"] = "inheritance";
  ClaimSubCategory["BRANDPATENTDOMAIN"] = "brandpatentdomain";
  ClaimSubCategory["QUOTAS"] = "quotas";
  ClaimSubCategory["TRANSFERS"] = "transfers";
  ClaimSubCategory["OTHER"] = "otherclaim";
})(ClaimSubCategory = exports.ClaimSubCategory || (exports.ClaimSubCategory = {}));