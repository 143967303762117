import { IEvent } from "modbin-domain";
import { LotQuery } from "ibase-auctions-domain";

export class LotSearchEvent implements IEvent {
    constructor(searchQuery:LotQuery){
        this.id = "";
        this.name = "LotSearchEvent";
        this.type = "SearchEvent";
        this.metadata = searchQuery;
    }
    id: string;    
    clientId?: string | undefined;
    actorId?: string | undefined;
    source?: string | undefined;
    metadata?: any;
    type?: string | undefined;
    name?: string | undefined;
} 