import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { LoginData } from "ibase-auctions-domain";
import { ScreensSessionsActivationView } from "./Activation";
import { activationSuccessState } from "../../operations/States";
import { UiTestDescription, sleepableConsoleLogger } from "modbin-web";

export const description:UiTestDescription = {
    id:"ActivationViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Activation page",
    parentId:"components"
};

export class ActivationViewUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:LoginData){
        console.log(result);
    }
    
    render(){
        return <ScreensSessionsActivationView 
            onLogin={(data:LoginData)=>{return sleepableConsoleLogger(data)}}
            activationState={activationSuccessState}
            t={this.props.t}
        />;
    }
}