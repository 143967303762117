import React, { Fragment } from "react";
import { Tab, Nav, NavItem } from "react-bootstrap";
import { LoginData } from "ibase-auctions-domain";
import { withTranslation } from "react-i18next";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";

//type AuthenticationViewProps = { onLogin: (loginData: LoginData) => Promise<void>, onRegister: (LoginData: LoginData) => Promise<void>; };
//type AuthenticationViewPropsWithTranslations = AuthenticationViewProps & TranslationProps;

export const InnerAuthenticationView = (props:any) => {
    const getTranslationButtons = (key:string) => props.t(`common:buttons.${key}`);
    return <div className="container">
	    <div className="row">
	        <div className="col-md-4 col-md-offset-4">
	            <div className="my-account style-1 margin-top-5 margin-bottom-40">
                    <Tab.Container defaultActiveKey="login" id="loginTab">
                        <Fragment>
                            <Nav className="tabs-nav">
                                <NavItem eventKey="login">{getTranslationButtons("login")}</NavItem>
                                <NavItem eventKey="register">{getTranslationButtons("register")}</NavItem>
                            </Nav>
                            <Tab.Content className="tabs-container">
                                <Tab.Pane eventKey="login">
                                    <LoginForm onLogin={(data:LoginData) => {return props.onLogin(data)}}></LoginForm>
                                </Tab.Pane>
                                <Tab.Pane eventKey="register" >
                                    <RegisterForm onRegister={(data:LoginData) => {return props.onRegister(data)}}></RegisterForm>
                                </Tab.Pane>
                            </Tab.Content>
                        </Fragment>
                    </Tab.Container>
                </div>
            </div>
        </div>
    </div>;
}

export const AuthenticationView = (withTranslation(["common"])(InnerAuthenticationView) as any) as (props:any) => JSX.Element;