"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromUser = exports.convertToUser = void 0;

var Shared_types_1 = require("../shared/Shared.types");

var modbin_domain_1 = require("modbin-domain");

var MaritalStatusConverter_1 = require("./MaritalStatusConverter");

var IdentificationCardConverter_1 = require("./IdentificationCardConverter");

var UserAddressConverter_1 = require("./UserAddressConverter");

var AccountStateConverter_1 = require("./AccountStateConverter");

var ContactConverter_1 = require("../contacts/ContactConverter");

var CompanyRepresentativeConverter_1 = require("./CompanyRepresentativeConverter");

exports.convertToUser = function (externalUser) {
  var extractedExternalUser = Shared_types_1.singleResponseConverter(externalUser);
  var entityData = Shared_types_1.convertToEntity(extractedExternalUser);

  var result = __assign(__assign({}, entityData), {
    officeId: modbin_domain_1.getNumberValueAsStringOrDefault(extractedExternalUser.escritorio_id, undefined),
    firstName: extractedExternalUser.nome,
    lastName: extractedExternalUser.last_name,
    maritalStatus: MaritalStatusConverter_1.convertToMaritalStatus(extractedExternalUser.estado_civil),
    birthDate: Shared_types_1.convertToDate(extractedExternalUser.data_nascimento),
    bornAt: extractedExternalUser.naturalidade,
    provider: extractedExternalUser.provider,
    identificationCard: IdentificationCardConverter_1.convertToIdentificationCard(extractedExternalUser),
    contact: ContactConverter_1.convertToContact(extractedExternalUser),
    address: UserAddressConverter_1.convertToUserAddress(extractedExternalUser),
    accountState: AccountStateConverter_1.convertToAccountState(extractedExternalUser),
    title: extractedExternalUser.titulo,
    company: CompanyRepresentativeConverter_1.convertToCompanyRepresentative(extractedExternalUser)
  });

  return result;
};

exports.convertFromUser = function (internalUser) {
  var entityData = Shared_types_1.convertFromEntity(internalUser);

  var result = __assign(__assign({}, entityData), {
    escritorio_id: internalUser.officeId,
    nome: internalUser.firstName,
    last_name: internalUser.lastName,
    estado_civil: MaritalStatusConverter_1.convertFromMaritalStatus(internalUser.maritalStatus),
    password: internalUser.password,
    data_nascimento: internalUser.birthDate !== undefined && internalUser.birthDate !== null ? internalUser.birthDate.getTime() : undefined,
    naturalidade: internalUser.bornAt,
    //accountState: convertToAccountState(extractedExternalUser),
    titulo: internalUser.title,
    tipo: "7",
    provider: "Website C.Paraiso"
  });

  result = ContactConverter_1.convertFromContact(internalUser.contact, result);
  result = IdentificationCardConverter_1.convertFromIdentificationCard(internalUser.identificationCard, result);
  result = UserAddressConverter_1.convertFromUserAddress(internalUser.address, result);
  result = CompanyRepresentativeConverter_1.convertFromCompanyRepresentative(internalUser.company, result);
  return result;
};