import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { RegisterForm } from "./RegisterForm";
import { sleepableConsoleLogger, UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"RegisterFormUiTest",
    groupId:"components",
    description:"lalal",
    title:"Register component",
    parentId:"components"
};

export class RegisterFormUiTest extends Component<TranslationProps,{}>{
        
    render(){
        return <RegisterForm onRegister={(data:any)=> {return sleepableConsoleLogger(data)}}/>;
    }
}