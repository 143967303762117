"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToBooking = void 0;

var EntityConverter_1 = require("../shared/EntityConverter");

var DateConverter_1 = require("../shared/DateConverter");

var PersonConverter_1 = require("./PersonConverter");

var IBaseSingleResponseConverter_1 = require("../shared/IBaseSingleResponseConverter");

var GeographicLocationConverter_1 = require("../contacts/GeographicLocationConverter");

var AddressConverter_1 = require("../contacts/AddressConverter");

exports.convertToBooking = function (externalBooking) {
  var extractedExternalBooking = IBaseSingleResponseConverter_1.singleResponseConverter(externalBooking);
  var entityData = EntityConverter_1.convertToEntity(extractedExternalBooking);

  var result = __assign(__assign({}, entityData), {
    assetId: extractedExternalBooking.bem_id.toString(),
    processId: extractedExternalBooking.processo_id.toString(),
    appointmentOnly: extractedExternalBooking.por_marcacao,
    lotId: extractedExternalBooking.lote_id.toString(),
    startDate: DateConverter_1.convertToDate(extractedExternalBooking.data_inicio),
    endDate: DateConverter_1.convertToDate(extractedExternalBooking.data_fim),
    startHour: extractedExternalBooking.hora_inicio,
    endHour: extractedExternalBooking.hora_fim,
    address: AddressConverter_1.convertToAddress(extractedExternalBooking),
    location: GeographicLocationConverter_1.convertToGeographicLocation(extractedExternalBooking),
    person: PersonConverter_1.convertToPerson(extractedExternalBooking)
  });

  return result;
};