"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExecutionTime = void 0;
var ExecutionTime;

(function (ExecutionTime) {
  ExecutionTime["BEFORE_LOAD"] = "before_load";
  ExecutionTime["LOAD"] = "load";
  ExecutionTime["AFTER_LOAD"] = "after_load";
  ExecutionTime["BEFORE_START"] = "before_start";
  ExecutionTime["START"] = "start";
  ExecutionTime["AFTER_START"] = "after_start";
  ExecutionTime["BEFORE_STOP"] = "before_stop";
  ExecutionTime["STOP"] = "stop";
  ExecutionTime["AFTER_STOP"] = "after_stop";
  ExecutionTime["BEFORE_RESTART"] = "before_restart";
  ExecutionTime["RESTART"] = "restart";
  ExecutionTime["AFTER_RESTART"] = "after_restart";
  ExecutionTime["RECURRING"] = "recurring";
  ExecutionTime["UNKNOWN"] = "unknown";
})(ExecutionTime = exports.ExecutionTime || (exports.ExecutionTime = {}));