import { application } from "../../Startup";
import download from 'downloadjs'
import { IRestApiClient, BaseHttpClient, BrowserFetchHttpClient, FetchHttpClientOptions, HttpContentType, jsonContentTypeFormatter } from "modbin-api";

export const downloadFile = (url:string, fileName:string, mimeType?:string):Promise<void> => {
    const apiClient = application.getContainer().resolve<IRestApiClient>("apiClient");
    return apiClient.get(url)
            .then((response:any) => (response as any).blob())
            .then((blob:any) => {
                download(blob, fileName, mimeType);
                return Promise.resolve();
            });
}


export const genericDownloadFile = (url:string, fileName:string, mimeType?:string):Promise<void> => {
    const fetchHttpClientOptions:FetchHttpClientOptions = {
        formatters:[[HttpContentType.JSON,jsonContentTypeFormatter]]
    } as any;
    
    const httpClient = new BaseHttpClient(new BrowserFetchHttpClient(fetchHttpClientOptions));
    return httpClient.get(url)
            .then((response:any) => (response as any).body.blob())
            .then((blob:any) => {
                download(blob, fileName, mimeType);
                return Promise.resolve();
            });
}

