"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IntercomEventService = void 0;

var BaseBusinessEventService_1 = require("./BaseBusinessEventService");

var IntercomEventService =
/** @class */
function (_super) {
  __extends(IntercomEventService, _super);

  function IntercomEventService(appId, options) {
    var _this = _super.call(this) || this;

    _this.appId = appId;
    _this.options = options;
    return _this;
  }

  IntercomEventService.prototype.configure = function (options) {
    _super.prototype.configure.call(this, options);

    window.Intercom("boot", {
      app_id: this.appId,
      userId: this.actorId
    });
  };

  IntercomEventService.prototype.sendTrackingEvent = function (event) {
    if (event.metadata) {
      window.Intercom("trackEvent", event.name, event.metadata);
    } else {
      window.Intercom("trackEvent", event.name);
    }
  };

  return IntercomEventService;
}(BaseBusinessEventService_1.BaseBusinessEventService);

exports.IntercomEventService = IntercomEventService;