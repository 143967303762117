"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultViewNavigator = void 0;

var PageNavigationEvent_1 = require("../events/PageNavigationEvent");

var path_to_regexp_1 = require("path-to-regexp");

var Authentication_types_1 = require("../authentication/Authentication.types");

var Routes_types_1 = require("../routes/Routes.types");

var DefaultViewNavigator =
/** @class */
function () {
  function DefaultViewNavigator(options) {
    var _this = this;

    this._pathsInitialized = false;
    this._paths = new Map();

    this.getViewDefinitionIfMatched = function (url) {
      var viewDefinitions = Array.from(_this._viewProvider.getViewDefinitions());
      var result = undefined;

      for (var i = 0; i < viewDefinitions.length; i++) {
        var currView = viewDefinitions[i];

        var matchResult = _this.isRouteAndPathTemplateMatch(url, currView.route.template);

        if (matchResult.isMatch) {
          result = {
            view: currView,
            state: matchResult.params
          };
          break;
        }
      }

      return result;
    };

    this.isRouteAndPathTemplateMatch = function (route, template) {
      var result = {
        isMatch: false,
        params: undefined
      };
      var matcher = path_to_regexp_1.match(template);
      var matched = matcher(route);

      if (matched) {
        result.isMatch = true;
        var params = matched.params;
        result.params = params !== undefined ? params : undefined;
      }

      return result;
    };

    this._viewProvider = options.viewProvider;
    this._businessEventService = options.businessEventService;
    this._authenticationVerifier = options.authenticationVerifier;
    this._roleVerifier = options.roleVerifier;
  } //@computed
  // currentView({
  //     return this._currentView!;
  // }


  DefaultViewNavigator.prototype.setCurrentView = function (viewDefinition, stateParameters) {
    var name = viewDefinition.name,
        title = viewDefinition.title,
        component = viewDefinition.component,
        route = viewDefinition.route,
        layout = viewDefinition.layout,
        store = viewDefinition.store,
        authorization = viewDefinition.authorization;

    if (store !== undefined && store.setState !== undefined) {
      store.setState(stateParameters);
    } // const actions = (viewDefinition.store !== undefined && viewDefinition.store.getActions !== undefined) 
    //     ? viewDefinition.store.getActions()!
    //    : undefined
    // const urlParameters = (viewDefinition.store !== undefined && viewDefinition.store.getUrlParameters !== undefined) 
    //     ? viewDefinition.store!.getUrlParameters()
    //     : undefined;


    this.currentView = {
      title: title,
      name: name,
      //viewState:state,
      //actions:actions,
      store: store,
      component: component,
      route: route,
      layout: layout,
      authorization: authorization
    };
    this.currentPath = this.getPathForView(this.currentView.name);
  };

  DefaultViewNavigator.prototype.validateAndSelectView = function (viewId, state) {
    var viewDefinition = this._viewProvider.getViewDefinition(viewId);

    var result = {
      definition: viewDefinition,
      state: state
    };
    var loginViewId = "public:sessions:login";
    var authenticatedToaNonAuthViewId = "private:profile";
    var authType = viewDefinition.authorization !== undefined ? viewDefinition.authorization : Authentication_types_1.AuthorizationType.NOTREQUIRED;

    if (authType === Authentication_types_1.AuthorizationType.NOTREQUIRED) {
      return result;
    }

    if (authType === Authentication_types_1.AuthorizationType.REQUIRED) {
      var isAuthenticated = this._authenticationVerifier();

      if (isAuthenticated) {
        return result;
      } else {
        var url = Routes_types_1.convertStateToUrlPath(viewDefinition.route, state);
        result.definition = this._viewProvider.getViewDefinition(loginViewId);
        result.state = {
          url: url
        };
        return result;
      }
    }

    if (authType === Authentication_types_1.AuthorizationType.ADMINISTRATOR) {
      var isAuthenticated = this._authenticationVerifier();

      if (!isAuthenticated) {
        var url = Routes_types_1.convertStateToUrlPath(viewDefinition.route, state);
        result.definition = this._viewProvider.getViewDefinition(loginViewId);
        result.state = {
          url: url
        };
        return result;
      } else {
        var isAdmin = this._roleVerifier("admin");

        if (!isAdmin) {
          result.definition = this._viewProvider.getViewDefinition("common:errors:unauthorized");
          result.state = undefined;
          return result;
        } else {
          return result;
        }
      }
    }

    if (authType === Authentication_types_1.AuthorizationType.NOTAUTHENTICATED) {
      var isAuthenticated = this._authenticationVerifier();

      if (!isAuthenticated) {
        return result;
      } else {
        result.definition = this._viewProvider.getViewDefinition(authenticatedToaNonAuthViewId);
        result.state = undefined;
        return result;
      }
    }

    return result;
  };

  DefaultViewNavigator.prototype.navigateTo = function (viewId, options) {
    var state = undefined;

    if (options !== undefined) {
      if (options.event !== undefined) {
        options.event.preventDefault();
      }

      if (options.state !== undefined) {
        state = options.state;
      }
    }

    var viewDefinitionResult = this.validateAndSelectView(viewId, state);
    var viewName = this.currentView !== undefined ? this.currentView.name : "OUT";

    this._businessEventService.sendTrackingEvent(new PageNavigationEvent_1.PageNavigationEvent(viewName, viewId));

    this.setCurrentView(viewDefinitionResult.definition, viewDefinitionResult.state);
  };

  DefaultViewNavigator.prototype.extractPath = function (url) {
    var decodedUrl = decodeURIComponent(url);
    var questionMarkIndex = decodedUrl.indexOf('?');

    if (questionMarkIndex === -1) {
      return decodedUrl;
    }

    var path = decodedUrl.substr(0, decodedUrl.indexOf('?'));
    return path;
  };

  DefaultViewNavigator.prototype.initializePaths = function () {
    var _this = this;

    var views = this._viewProvider.getViewDefinitions();

    views.forEach(function (x) {
      _this._paths.set(x.name, {
        viewId: x.name,
        parentId: x.parent,
        nameTranslationKey: x.nameTranslationKey
      });
    });
    this._pathsInitialized = true;
  };

  DefaultViewNavigator.prototype.internalGetPathForView = function (viewId) {
    var view = this._paths.get(viewId);

    if (view === undefined || view.parentId === undefined) {
      return [];
    }

    var paths = [];
    var parentView = view;

    while (parentView.parentId !== undefined) {
      paths.push(parentView);
      parentView = this._paths.get(parentView.parentId);
    }

    paths.push(parentView);
    return paths;
  };

  DefaultViewNavigator.prototype.getPathForView = function (viewId) {
    if (!this._pathsInitialized) {
      this.initializePaths();
    }

    return this.internalGetPathForView(viewId).reverse();
  };

  DefaultViewNavigator.prototype.navigateToFromUrl = function (url) {
    var path = this.extractPath(url);
    var viewDefinition = this.getViewDefinitionIfMatched(path);

    this._businessEventService.sendTrackingEvent(new PageNavigationEvent_1.PageNavigationEvent(url, viewDefinition.view.name));

    this.navigateTo(viewDefinition.view.name, {
      state: viewDefinition.state
    });
  };

  DefaultViewNavigator.prototype.navigateBack = function () {
    window.history.back();
  };

  DefaultViewNavigator.prototype.createViewRouteUrl = function (viewId, options) {
    var state = undefined,
        escape = false;

    if (options !== undefined) {
      state = options.state;

      if (options.escape !== undefined) {
        escape = options.escape;
      }
    }

    var viewDefinition = this._viewProvider.getViewDefinition(viewId);

    var url = Routes_types_1.convertStateToUrlPath(viewDefinition.route, state);
    var finalUrl = escape ? encodeURIComponent(url) : url;
    return finalUrl;
  };

  return DefaultViewNavigator;
}();

exports.DefaultViewNavigator = DefaultViewNavigator;