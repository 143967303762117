import endpoints from "./endpoints.json";
import { ProxyType } from "../../screens/support/administration/SettingsForm";

export type ApiConfiguration = {
    baseUrl:string,
    officeId:string,
    useProxy:boolean,
    proxyType:ProxyType,
    authorizationToken:string,
    authorizationWebToken:string,
    endpoints: {
        clients: {
            offices: string,
            newOffices:string
        },
        contacts: {
            regions: string,
            counties: string,
            parishes: string,
            informationRequest:string
        },
        assets: {
            lots: {
                collection: string,
                single:string,
                updates:string,
                bookings:string,
                documents:string,
                documentsDownload:string,
                userFavorites:string
            }
        },
        sales: {
            events:{
                collection: string,
                single: string,
                documents: string,
                documentsDownload : string,
                photos: string,
                bid: string,
                proposalGenerator:string,
            }
        },
        users: {
            profiles:{
                collection:string,
                single:string   
            },
            sessions:{
                login:string,
                activate:string,
                reactivate:string,
                passwordRecovery:string,
                passwordRecoveryRequest:string,
            }
        },
        administration:{
            roles:string,
            newsletters:{
                subscriptions: string,
                collection: string,
                download: string
            },
            layouts:string
        },
        settings:{
            serverTime:string,
            banners:string
        } 
    }
}

export const loadApiConfiguration = (apiConfiguration:ApiConfiguration):ApiConfiguration => {
    const apiConfig = apiConfiguration;
    apiConfig.endpoints = endpoints;
    if(!apiConfiguration.useProxy || apiConfiguration.proxyType === ProxyType.PASSTHROUGH){
        const officePath = apiConfig.endpoints.clients.offices.replace("{officeId}",apiConfig.officeId.toString());
        const newOfficePath = apiConfig.endpoints.clients.newOffices.replace("{officeId}",apiConfig.officeId.toString());
        //console.log(officePath);
        apiConfig.endpoints.assets = {
            lots:{
                collection:`${officePath}${apiConfig.endpoints.assets.lots.collection}`,
                single:`${officePath}${apiConfig.endpoints.assets.lots.single}`,
                updates:`${officePath}${apiConfig.endpoints.assets.lots.updates}`,
                bookings:`${officePath}${apiConfig.endpoints.assets.lots.bookings}`,
                documents:`${newOfficePath}${apiConfig.endpoints.assets.lots.documents}`,
                documentsDownload:`${officePath}${apiConfig.endpoints.assets.lots.documentsDownload}`,
                userFavorites:`${officePath}${apiConfig.endpoints.assets.lots.userFavorites}`,
            },
        };
        apiConfig.endpoints.contacts.informationRequest=`${officePath}${apiConfig.endpoints.contacts.informationRequest}`;
        apiConfig.endpoints.sales = {
            events:{
                collection:`${officePath}${apiConfig.endpoints.sales.events.collection}`,
                single:`${officePath}${apiConfig.endpoints.sales.events.single}`,
                documents: `${officePath}${apiConfig.endpoints.sales.events.documents}`,
                documentsDownload : `${officePath}${apiConfig.endpoints.sales.events.documentsDownload}`,
                photos: `${officePath}${apiConfig.endpoints.sales.events.photos}`,
                bid: `${officePath}${apiConfig.endpoints.sales.events.bid}`,
                proposalGenerator: `${newOfficePath}${apiConfig.endpoints.sales.events.proposalGenerator}`
            }            
        };
        apiConfig.endpoints.users = {        
            profiles :{
                collection:`${officePath}${apiConfig.endpoints.users.profiles.collection}`,
                single:`${officePath}${apiConfig.endpoints.users.profiles.single}`,
            },
            sessions: {
                login:`${officePath}${apiConfig.endpoints.users.sessions.login}`,
                activate:`${apiConfig.endpoints.users.sessions.activate}`,     
                reactivate:`${officePath}${apiConfig.endpoints.users.sessions.reactivate}`,      
                passwordRecovery:`${apiConfig.endpoints.users.sessions.passwordRecovery}`,
                passwordRecoveryRequest:`${apiConfig.endpoints.users.sessions.passwordRecoveryRequest}`
            }
        }
    }
    return apiConfig;
}
