import { BaseMainAppViewStore } from "./BaseMainAppViewStore";
import { computed } from "mobx";
import { BannerType } from "ibase-auctions-domain";
import { IViewNavigator, IViewDefinitionProvider, ViewDefinition } from "modbin-web";
import { LayoutStore } from "../../../../screens/public/home/LayoutStore";
import { buildUrlPathFromPathTemplate } from "modbin-domain";

export class MainAppViewStore extends BaseMainAppViewStore {
    private layoutStore:LayoutStore|undefined = undefined;

    constructor(viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider){
        super(viewNavigator,viewProvider);
    }

    configure(options?:any){
        this.layoutStore = (options as any).layoutStore;
    }

    isPopupableView(currentViewDefinition:ViewDefinition):boolean{
        const viewName = currentViewDefinition.name;
        return !viewName.startsWith("support") && !viewName.startsWith("common"); 
    }

    getViewUrlPath(viewName:string, parameters?:any): string|undefined{
        const viewDefinition = this.getViewDefinition(viewName);
        if(viewDefinition === undefined){
            return undefined;
        }
        if(parameters !== undefined){
            return buildUrlPathFromPathTemplate(viewDefinition.route.template, parameters,{begin:":"});
        }
        else {
            return viewDefinition.route.template;
        }
    }
    
    @computed
    get showPopup (){
        const popupBannersNumber = this.layoutStore!.layout !== undefined
            ? this.layoutStore!.layout.banners.filter(x => x.type === BannerType.POPUP).length
            : 0;
        return this.isLoaded && popupBannersNumber > 0 && this.popupVisible && this.isPopupableView(this.currentView!);
    }

    closePopup(){
        this.popupVisible = false;
    }
}
