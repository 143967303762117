"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.salesTranslations = void 0;

var SellingEvent_translations_1 = require("./events/SellingEvent.translations");

var Price_translations_1 = require("../items/Price.translations");

var Bid_translations_1 = require("./bids/Bid.translations");

exports.salesTranslations = {
  pt: {
    events: SellingEvent_translations_1.sellingEventTranslations.pt,
    price: Price_translations_1.priceTranslations.pt,
    bid: Bid_translations_1.bidTranslations.pt
  },
  en: {
    events: SellingEvent_translations_1.sellingEventTranslations.en,
    price: Price_translations_1.priceTranslations.en,
    bid: Bid_translations_1.bidTranslations.pt
  }
};