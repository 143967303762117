export const sessionsTranslations = {
    pt: {
        email:"Endereço de email:",
        password:"Password:",
        passwordConfirm:"Confirmar a password:",
        passwordLost:"Esqueceu-se da password?",
        registerTitle:"Completar o registo",
        registerCompletedTitle:"Registo concluído!",
        registerSuccessfulTitle:"Registo concluído com sucesso!",
        registerSuccessfulText:"A sua conta foi registada com sucesso. Foi enviado um email de ativação para o seu endereço de email. Por favor, clique no link presente no email para proceder à ativação da sua conta. Obrigado e até já!",
        activationTitle:"Ativação de conta",
        activationSuccessfulTitle:"Ativação de conta concluída com sucesso!",
        activationSuccessfulText:"A sua conta foi ativada com sucesso. Por favor, faça login para começar a licitar.",
        activationInvalidValidationParameters:"Os parâmetros fornecidos para validação da conta não são válidos. Se já tem uma conta ativa, use o formulário abaixo para fazer login.",
        activationInvalidTitle:"Conta por ativar",
        activationInvalidNotificationTitle:"A sua conta ainda não foi ativada",
        activationInvalidNotificationText:"Um email de ativação de conta foi enviado para a sua caixa de email. Por favor verifique a sua caixa de correio. Necessita de ativar a sua conta para prosseguir. Se pretender receber um novo email de ativação, por favor, use o botão em baixo.",
        loginSuccessfulTitle:"Login efetuado com successo. Bem vindo!",
        reactivationTitle:"Pedido de reenvio de email de ativação de conta",
        reactivationSuccessfulTitle:"Pedido de reenvio de email de ativação enviado com sucesso!",
        reactivationSuccessfulText:"Um novo email de ativação de conta foi enviado para a sua caixa de email. Por favor verifique a sua caixa de correio. ",
        requestPasswordRecoveryTitle:"Pedido de recuperação de password",
        requestPasswordRecoverySuccessfulTitle:"Pedido de recuperação de password enviado com sucesso!",
        requestPasswordRecoverySuccessfulText:"O seu pedido de recuperação de password foi enviado com sucesso. Um email de recuperação de passsword foi enviado para o seu endereço de email. Por favor, clique no link presente no email para proceder à recuperação da sua password. Obrigado e até já!",
        passwordRecoveryTitle:"Recuperação de password",
        passwordRecoveryInvalidRecoveryParameters:"Os parâmetros fornecidos para recuperação da password não são válidos.",
        passwordRecoverySuccessfulTitle:"Recuperação de password foi concluída com sucesso!",
        passwordRecoverySuccessfulText:"Já pode usar a sua nova password. Por favor, faça login para começar a licitar."
    },
    en:{
        email:"Email address:",
        password:"Password:",
        passwordConfirm:"Confirm password:",
        passwordLost:"Lost your password?",
        registerTitle:"Finish the registration",
        registerCompletedTitle:"Registration completed!",
        registerSuccessfulTitle:"Registration was successful!",
        registerSuccessfulText:"Your account was successfully registered. An activation email was sent to your email address. Please, click on the link present on the email body in order to activate your account. Thank you and see you soon!",
        activationTitle:"Account activation",
        activationSuccessfulTitle:"Account activation was successful!",
        activationSuccessfulText:"Your account was successfully activated. Please login to start bidding.",
        activationInvalidValidationParameters:"Provided parameters for account activation are invalid. If you already have an active account, use the bellow form in order to login.",
        activationInvalidTitle:"Account not activated yet",
        activationInvalidNotificationTitle:"Your account was not activated yet",
        activationInvalidNotificationText:"An email was sent to your email inbox in order to activate your account. Please check your email. You need to activate your account in order to proceed. You can request a new activation email using the button below.",
        loginSuccessfulTitle:"Login was successful. Welcome!",
        reactivationTitle:"New activation email request",
        reactivationSuccessfulTitle:"New activation email was successfully sent!",
        reactivationSuccessfulText:"A new email was sent to your email inbox in order to activate your account. Please check your email.",
        requestPasswordRecoveryTitle:"Password recovery request",
        requestPasswordRecoverySuccessfulTitle:"Password recovery request was successfully sent!",
        requestPasswordRecoverySuccessfulText:"Your password recovery request was successfully sent. A password recovery email was sent to your email address. Please, click on the link present on the email body in order to recover your password. Thank you and see you soon!",
        passwordRecoveryTitle:"Password recovery",
        passwordRecoveryInvalidRecoveryParameters:"Provided parameters for password recovery are invalid.",
        passwordRecoverySuccessfulTitle:"Password recovery was successful!",
        passwordRecoverySuccessfulText:"You should use your new password. Please login to start bidding."
    }
}