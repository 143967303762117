"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sellingEventTranslations = void 0;

var SellingType_translations_1 = require("./SellingType.translations");

var EventState_translations_1 = require("./EventState.translations");

exports.sellingEventTranslations = {
  pt: {
    event: {
      title: "Título",
      description: "Descrição",
      dates: {
        startDate: "Data de início",
        endDate: "Data de fim"
      },
      thumbnail: "Thumbnail",
      photos: "Fotos",
      processId: "Número de processo",
      processReference: "Referência do processo",
      insolvent: "Insolvente",
      seller: "Vendedor",
      location: "Localização"
    },
    type: {
      name: "Modalidade de venda",
      value: SellingType_translations_1.sellingTypeTranslations.pt
    },
    state: {
      name: "Estado da conta",
      value: EventState_translations_1.eventStatesTranslations.pt
    }
  },
  en: {
    event: {
      title: "Title",
      description: "Description",
      dates: {
        startDate: "Start date",
        endDate: "End date"
      },
      thumbnail: "Thumbnail",
      photos: "Photos",
      processId: "Process identifier",
      processReference: "Process reference",
      insolvent: "Insolvent",
      seller: "Seller",
      location: "Location"
    },
    type: {
      name: "Selling mode",
      value: SellingType_translations_1.sellingTypeTranslations.en
    },
    state: {
      name: "Account state",
      value: EventState_translations_1.eventStatesTranslations.en
    }
  }
};