

import { ILogger, IRepository } from "modbin-domain"
import { observable } from "mobx";
import { configProperties } from "../../../configuration/ConfigProperties";
import { Banner } from "ibase-auctions-domain";
import { BaseStore, IStore } from "modbin-web";

export enum BannerType {
    HOME = "home",
    PROMO = "promo"
}

export type LayoutSettings = {
    defaultHomeBanner:string;
    banners:Banner[];
}

export class LayoutStore extends BaseStore implements IStore {

    @observable
    layout:LayoutSettings|undefined = undefined;

    @observable isLoaded:boolean = false;
    
    constructor(private bannerRepository:IRepository<Banner>, private defaultHomeBannerUrl:string, private logger:ILogger){
        super();
    }

    initialize():Promise<void>{
        return this.fetchLayout() as any;
    }

    getLayoutUrl(){
        const url = configProperties.apiConfig.endpoints.administration.layouts;
        return url;
    }

    fetchLayout():Promise<LayoutSettings>{
        return this.bannerRepository.findAllPageableByRepresentation({active:true,order_by:"banner_order ASC"}).then((x)=> {
            this.layout = { defaultHomeBanner:this.defaultHomeBannerUrl, banners:x.items};
            this.isLoaded = true;
            return this.layout!;
        },
        (e:any) => {
            this.logger.error(`Error in fetchLayout method on LayoutStore: `,e);
            throw e;
        });
    }

    reset(){
        this.layout = undefined;
        this.isLoaded = false;
        return Promise.resolve();
    }
}