"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.projectable = void 0; // export type Comparer<T> = (first:T, second:T) => number; 
// export type SortableBuilder<T> = (propertyName:string,type:ComparerType) => (direction:SortDirection) => (first:T,second:T) => number;
// export type Sortable<T> = (direction:SortDirection) => Comparer<T>; 
// type ComparerMapper = Map<string,Comparer<any>>;
// export type QuerySortableMapper<T> = Map<QueryParameter,Sortable<T>>;

exports.projectable = function (projection) {
  return function (element) {
    return projection(element);
  };
};