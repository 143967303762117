"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidMethod = void 0;

exports.isValidMethod = function (method, endpoint) {
  return endpoint.methods.filter(function (m) {
    return m.toLowerCase() === method.toLowerCase();
  }).length > 0;
};