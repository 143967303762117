import React, {ErrorInfo} from "react";
import { isNotNullOrEmptyString } from "modbin-domain";
import { NotificationView } from "../../../components/notifications/NotificationView";
import { NotificationType } from "modbin-web";
import { BaseLayout } from "../../../components/layout/BaseLayout";

export type ErrorUI = {
    title: string;
    type: 'success' | 'info' | 'warning' | 'error';
    message:string;
    js?: Error;
    react?:ErrorInfo;
} 

const StackDetailView = (props:{title:string, stack:string}) => {     
    const {title,stack} = props;
    const stackView = <div>
                        <div>
                            <h2>{title}</h2>
                        </div>
                        <div>
                            { stack!.split("\n").map((k,i) => <div key={i}>{k}</div>)}
                        </div>
                    </div>; 
    return (isNotNullOrEmptyString(stack!)) ? <div></div> : stackView;
}

const JsErrorDetailView = (props:{error:Error}) => {     
    let view = <div></div>;
    if(props.error !== undefined) {
        const {name,message,stack} = props.error;
        view = <div>
                        <h2>Javascript error:</h2>
                        <div>Error: {name}</div>
                        <div>Message:</div>
                        <div>{message}</div>
                        <StackDetailView stack={stack!} title="Javascript stack:" />
                </div>;
    }
    
    return view;
}

const ReactErrorDetailView = (props:{error:ErrorInfo}) => {
    return (props.error !== undefined && props.error.componentStack !== undefined) 
        ? <StackDetailView stack={props.error.componentStack!} title="React stack:" />
        : <div></div>;
}

export const ErrorItemView = (props:{error:ErrorUI}) => {
    const view = <div>
                <NotificationView type={NotificationType.ERROR} header={props.error.title}  content={props.error.message}/>;
                <JsErrorDetailView error={props.error.js!}></JsErrorDetailView>
                <ReactErrorDetailView error={props.error.react!}></ReactErrorDetailView>
            </div>;
    return view;
}

export const ErrorDetailView = (props:{error:ErrorUI}) => {
    const view = <BaseLayout> 
                    <ErrorItemView error={props.error}></ErrorItemView>
            </BaseLayout>;
    return view;
}