import { TranslationProps } from "modbin-domain";
import { Booking, Person, Address } from "ibase-auctions-domain";
import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { LoadingView } from "../../../../../components/loading/LoadingView";
import { isNotNullOrEmptyString, isNullOrEmptyString } from "modbin-domain";
import moment from "moment";
import { formatAddress } from "../../../components/location/ShortTextAddressView";
import { observable } from "mobx";
// import { bookingsList } from "../../../../support/tests/data/Bookings";

const isValidPerson = (p:Person) => {
    return p !== undefined && (isNotNullOrEmptyString(p.name) || (isNotNullOrEmptyString(p.email!) || isNotNullOrEmptyString(p.mobilePhoneNumber!)))
}


const BookingLineView = observer((props:{label:string, value:string}) => {
    const style = { marginBottom: "0px"};
    return <p style={style}><span><strong>{props.label}: </strong></span><span>{props.value}</span></p>
})

const HourLineView = observer((props:{label:string, booking:Booking}) => {
    const { label, booking:{startHour,endHour}} = props;
    if(isNullOrEmptyString(startHour) && isNullOrEmptyString(endHour)){
        return <Fragment></Fragment>;
    }
    const style = { marginBottom: "0px"};
    const hours = [];
    if(isNotNullOrEmptyString(startHour)){
        hours.push(startHour);
    }
    if(isNotNullOrEmptyString(endHour)){
        hours.push(endHour);
    }
    const value = hours.join(" - ");
    return <p style={style}><span><strong>{label}: </strong></span><span>{value}</span></p>
})

const DateLineView = observer((props:{label:string, booking:Booking}) => {
    const { label, booking:{startDate,endDate}} = props;
    if(startDate === undefined && endDate === undefined){
        return <Fragment></Fragment>;
    }
    const style = { marginBottom: "0px"};
    const hours = [];
    if(startDate !== undefined){
        hours.push(moment(startDate).format("DD/MM/YYYY"));
    }
    if(endDate !== undefined){
        hours.push(moment(endDate).format("DD/MM/YYYY"));
    }
    const value = hours.join(" - ");
    return <p style={style}><span><strong>{label}: </strong></span><span>{value}</span></p>
})

const AddressLineView = observer((props:{label:string, address?:Address}) => {
        const {label,address} = props;
        if(address === undefined || (isNullOrEmptyString(address.street,true) && isNullOrEmptyString(address.city,true) && isNullOrEmptyString(address.zipCode1,true) && isNullOrEmptyString(address.zipCode2!,true))){
            return  <Fragment/>;
        }
        const style = { marginBottom: "0px"};
        return <p style={style}><span><strong>{label}: </strong></span><span>{formatAddress(address)}</span></p>;
})

const SingleBookingView = observer((props:TranslationProps & {booking:Booking}) => {
    // const persons = props.bookings.filter(b => isValidPerson(b.person!));
    // const person = persons.length > 0 
    //     ? persons[0].person!
    //     : props.bookings[0].person!;
    const firstBookingLine =  props.booking;
    const person = firstBookingLine.person!;
    if(!isValidPerson(person)){
        return <div>{props.t("screens:business.lots.details.noBookings")}</div>;
    }
    const nameView = <BookingLineView  label={props.t("screens:business.lots.details.bookingsPerson")} value={person.name}/>
    const emailView = isNotNullOrEmptyString(person.email!)
        ? <BookingLineView  label={props.t("domain:contacts.contact.email")} value={person.email!}/>
        : <Fragment></Fragment>
    const mobilePhoneNumberView = isNotNullOrEmptyString(person.mobilePhoneNumber!)
        ? <BookingLineView  label={props.t("domain:contacts.contact.mobilePhoneNumber")} value={person.mobilePhoneNumber!}/>
        : <Fragment></Fragment>
    const datesView = <DateLineView label={props.t("screens:business.lots.details.bookingsDates")} booking={firstBookingLine}></DateLineView>
    const hoursView = <HourLineView label={props.t("screens:business.lots.details.bookingsHours")} booking={firstBookingLine}></HourLineView>
    const addressView = <AddressLineView label={props.t("screens:business.lots.details.bookingsAddress")} address={firstBookingLine.address} />
    return <div className="row with-forms  margin-top-0">
        {nameView}
        {emailView}
        {mobilePhoneNumberView}
        {datesView}
        {hoursView}
        {addressView}
    </div>
});

@observer
export class MultipleBookingView extends React.Component<TranslationProps&{bookings:Booking[]},{}>{
    @observable showMore:boolean = false;

    toggleShowMore(ev:any){
        ev.preventDefault();
        this.showMore = !this.showMore;
    }

    render(){
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`);
        const showMoreText = this.showMore ? getTranslationButtons("viewLess") : getTranslationButtons("viewMore");
        // const showMoreStyle = this.showMore ? {display:"block"} : {};
        const displayViewMoreText = this.props.bookings.length > 1;
        const viewMoreTextComponent = displayViewMoreText 
            ? <div style={{textAlign:"center"}}><a href="# " className="more-search-options-trigger" onClick={(ev) => this.toggleShowMore(ev)}>{showMoreText}</a></div>
            : <Fragment></Fragment>;
        const viewMoreListComponent = displayViewMoreText &&  this.showMore
            ? this.props.bookings.slice(1).map(x => <SingleBookingView t={this.props.t} booking={x}></SingleBookingView>)
            : <Fragment></Fragment>;
        return <ul>
            <SingleBookingView t={this.props.t} booking={this.props.bookings[0]}></SingleBookingView>
            {viewMoreTextComponent}
            {viewMoreListComponent}
        </ul>
    };
}

export const VisitBookingView = observer((props:TranslationProps & {bookings:Booking[], isLoading:boolean}) => {
    const body = props.bookings !== undefined && props.bookings.length > 0
    ? <MultipleBookingView t={props.t} bookings={props.bookings}></MultipleBookingView>
    : props.t("screens:business.lots.details.noBookings");
    return <div id="booking-widget-anchor" className="boxed-widget booking-widget margin-top-35">
            <h3 style={{textTransform: "uppercase"}}>{props.t("screens:business.lots.details.bookingsTitle")}</h3>
        <LoadingView spinner={{size:"medium"}} isLoading={props.isLoading}>
            {body}
        </LoadingView>        
    </div>;
})
