import { BaseBusinessEventService, IBusinessEventService, IEvent } from "modbin-domain";
import ReactGA from 'react-ga';

export class GoogleAnalyticsEventService extends BaseBusinessEventService implements IBusinessEventService {
    trackerId:string;

    constructor(trackerId:string){
        super();
        this.trackerId = trackerId;
        ReactGA.initialize(this.trackerId);
    }

    configure(options:any) {
        
    }

    sendTrackingEvent(event:IEvent) {
        if (event.type === "PageNavigation") {
            ReactGA.pageview(window.location.pathname + window.location.search,undefined, (event.metadata as any).to);
        } else {
            ReactGA.event({
                category: event.type!,
                action: event.name!,
                label: event.name!,
              });
        }
    }
}