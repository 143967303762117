import {observable, action} from "mobx";
import { SellingEvent, DocumentFile, Lot, ClockDate, getClockDatesFromSellingDates, SellingEventQuery } from "ibase-auctions-domain";
import { IRepository, PageableResult, ITimerService, ILogger } from "modbin-domain";
import { LotStore } from "../lots/Store";
import { BaseStore, IStore } from "modbin-web";
const moment = require("moment");

export const activeQuery:SellingEventQuery = {
    published:true,
    includeInsolvent:true,
    includeToday:true
}

const queryFeatured:SellingEventQuery = { 
    featured:true, 
    published:true,
    active:true,
    includeInsolvent:true
}

export type SellingEventVM = SellingEvent & {
    clockDate: ClockDate;
}

export class SellingEventStore extends BaseStore implements IStore {

    @observable
    sellingEvents:Map<string,SellingEventVM> = new Map<string,SellingEventVM>();
    
    constructor(private lotStore: LotStore, 
        private sellingEventRepository:IRepository<SellingEvent>, 
        private sellingEventFileRepository:IRepository<DocumentFile>, 
        private timerService:ITimerService,
        private logger:ILogger){
    //    this.fetchSellingEvents({});
        super();
        lotStore.setLotEventFetcher((id) => this.fetchSellingEventIfNotLoaded(id));
    }

    initialize():Promise<void>{
        return this.fetchSellingEvents(activeQuery) as any;
    }

    private updateSingleSellingEvent(event:SellingEvent){
        let storedEvent = this.sellingEvents.get(event.getId())!;
        if(storedEvent === undefined){
            event.documents = observable([]);
            event.documentsLoaded = false;
            event.lots = observable([]);
            event.lotsLoaded = false;
            storedEvent = event as any; 
        }
        else {
            storedEvent.description = event.description;
            
        }
        let nowDate = new Date(Date.now());
        try {
            nowDate = this.timerService.getCurrentDate();
        }
        catch(e){
        //    this.logger.error("Error in updateSingleSellingEvent method on SellingEventStore. Fetching timeService currentDate: ",e);
        }
        storedEvent.clockDate = getClockDatesFromSellingDates(event.dates, nowDate);
        this.sellingEvents.set(event.getId(),storedEvent);
    }


    getEventById(id:string):SellingEvent|undefined{
        return this.sellingEvents.get(id);
    }

    fetchSellingEventIfNotLoaded(id:string):Promise<SellingEvent> {
        const event = this.getEventById(id);
        if(event === undefined){
            return this.fetchSellingEventById(id);
        }
        return Promise.resolve(event);
    }

    fetchSellingEventAndComponentsById(id:string):Promise<SellingEvent>{
        return this.fetchSellingEventById(id).then(x => {
            this.fetchEventLots(id);
            //this.fetchEventDocuments(id);
            return x;
        },
        (e) => {
            this.logger.error("Error in fetchSellingEventAndComponentsById method on SellingEventStore: ",e);
            throw e;
        });
    }


    fetchSellingEventById(id:string): Promise<SellingEvent> {
        //const url = application.getContainer().resolve<ApiConfiguration>("apiConfig").endpoints.sales.events.single;
        return this.sellingEventRepository.findById(id).then((x:any) => {
            this.updateSingleSellingEvent(x);
            return x;
        },
        (e) => {
            this.logger.error(`Error in fetchSellingEventById method on SellingEventStore (EventId: ${id}): `,e);
            throw e;
        });
    }

    fetchEventLots(id:string):Promise<Lot[]>{
        const event = this.getEventById(id);
        return this.lotStore.fetchEventLots(id,event).then((x:any) => {
            this.loadLotsFromServer(id,x.items);
            return x.items
        },
        (e) => {
            this.logger.error(`Error in fetchEventLots method on SellingEventStore (EventId: ${id}): `,e);
            throw e;
        });
    }

    @action
    loadLotsFromServer(eventId:string, lots:Lot[]){
        const event = this.getEventById(eventId)!;
        if(event !== undefined && !event.lotsLoaded){
            lots.forEach(b => {
                event.lots.push(b as any);
            })
            event.lotsLoaded = true;
        }
    }


    fetchSellingEvents(query:SellingEventQuery): Promise<SellingEvent[]> {
        return this.sellingEventRepository.findAllPageableByRepresentation(query).then((x:any) => {
            x.items.forEach((e:SellingEvent) => {
                this.updateSingleSellingEvent(e);
            //    this.fetchEventDocuments(e.getId());
            });                
            return x.items;
        },
        (e) => {
            this.logger.error(`Error in fetchSellingEvents method on SellingEventStore`,e);
            throw e;
        });
    }

    fetchEventDocuments(eventId:string):Promise<DocumentFile[]>{
        return this.sellingEventFileRepository.findAllPageableByRepresentation({ auctionId: eventId, publicado:true}).then((x:PageableResult<DocumentFile>) => {
            this.loadDocumentsFromServer(eventId,x.items);
            const event = this.getEventById(eventId)!;
            event.documentsLoaded = true;
            return x.items;
        },
        (e) => {
            this.logger.error(`Error in fetchEventDocuments method on SellingEventStore (EventId: ${eventId}): `,e);
            const event = this.getEventById(eventId)!;
            event.documentsLoaded = true;
            throw e;
        });
    }

    @action
    loadDocumentsFromServer(eventId:string, files:DocumentFile[]){
        const event = this.getEventById(eventId)!;
        if(event !== undefined) {
            if(!event.documentsLoaded){
                files.forEach(b => {
                    event.documents.push(b as any);
                })
            }
            event.documentsLoaded = true;
        }
    }

    fetchFeatured():Promise<SellingEvent[]> {
        
        return this.sellingEventRepository.findAllPageableByRepresentation(queryFeatured).then((x:PageableResult<SellingEvent>) => {
            x.items.forEach((e:SellingEvent) => {
                this.updateSingleSellingEvent(e);
               // this.fetchEventDocuments(e.getId());
            });                
            return x.items;
        },
        (e) => {
            this.logger.error(`Error in fetchFeatured method on SellingEventStore`,e);
            throw e;
        });
    }

    getFeaturedEvents():SellingEvent[] {
        return Array.from(this.sellingEvents.values())
            .filter(x => (
                x.featured === true 
                && ( moment(x.dates.endDate).isAfter(this.timerService.getCurrentDate())
                || moment(x.dates.extendedDate).isAfter(this.timerService.getCurrentDate()))
            ));
    }

    getSellingEvents(){
        return Array.from(this.sellingEvents.values());
    }

    getSellingEventsFromIdList(ids:string[]){
        const result = ids.map(x => {
            const event = this.getEventById(x);
            if(event!==undefined){
                return event;
            }
            return;
        })
        return result;
    }

    reset(){
        this.sellingEvents = new Map<string,SellingEventVM>();
        return Promise.resolve();
    }
}