import React from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import { getNumberValueOrDefault } from "modbin-domain";

@observer
export class TextSwitcherView extends React.Component<{texts:string[],timeout?:number},{}> {

    @observable selectedTextIndex:number = 0;
    @observable 
    _currentInterval:NodeJS.Timeout | undefined = undefined;
    @observable className = "";

    changeIndex(){
        const currentIndex = this.selectedTextIndex;
        this.selectedTextIndex = (currentIndex + 1 === this.props.texts.length)
            ? 0
            : this.selectedTextIndex + 1;
    }

    toggleFadeClass(){
        if(this.className === ""){
            this.className = "fade"
        }
        else {
            this.className = "";
        }
    }

    componentWillMount(){
        this.runTimer();
    }

    componentWillUnmount(){
        if(this._currentInterval !== undefined){
            clearInterval(this._currentInterval!);
        }
    }

    @computed
    get timeout(){
        return getNumberValueOrDefault(this.props.timeout,5000);   
    }

    @computed
    get selectedText(){
        return this.props.texts[this.selectedTextIndex];   
    }

    runTimer(){
        this._currentInterval = setInterval(() => {
            this.toggleFadeClass();
            this.changeIndex();
            setTimeout(() =>{
                this.toggleFadeClass()
            }, 1000);
        }, this.timeout);
    }

    render(){
        return <h2 className={this.className}>{this.selectedText}</h2>
    }
}