export const subCategories = // 20191120220800
// http://www.ibase.com.pt:8082/ibase/rest/tipo_bem

[
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 1,
    "tipo": "imo-apartamento",
    "imagem": "veiculo_not_found.png",
    "descricao": "Apartamento",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 2,
    "tipo": "imo-armazem-industr",
    "imagem": "veiculo_not_found.png",
    "descricao": "Armazém / Indústria",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 3,
    "tipo": "imo-escritorio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Escritório",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 4,
    "tipo": "imo-garagem-arrumos",
    "imagem": "veiculo_not_found.png",
    "descricao": "Garagem / Arrumos",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 5,
    "tipo": "imo-loja",
    "imagem": "veiculo_not_found.png",
    "descricao": "Loja",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 6,
    "tipo": "imo-moradia",
    "imagem": "veiculo_not_found.png",
    "descricao": "Moradia",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 7,
    "tipo": "imo-predio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Prédio",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 8,
    "tipo": "imo-quinta-misto",
    "imagem": "veiculo_not_found.png",
    "descricao": "Quinta / Misto",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 9,
    "tipo": "imo-rustico",
    "imagem": "veiculo_not_found.png",
    "descricao": "Rústico",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 10,
    "tipo": "imo-terreno_constr",
    "imagem": "veiculo_not_found.png",
    "descricao": "Terreno Construção",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 12,
    "tipo": "equi-constr_civil",
    "imagem": "veiculo_not_found.png",
    "descricao": "Construção Cívil",
    "date_created": 1468508314105,
    "date_updated": 1468508314105,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 13,
    "tipo": "equi-escritorio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Escritório",
    "date_created": 1468508314105,
    "date_updated": 1468508314105,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 14,
    "tipo": "equi-industriais",
    "imagem": "veiculo_not_found.png",
    "descricao": "Indústriais",
    "date_created": 1468508314105,
    "date_updated": 1468508314105,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 16,
    "tipo": "mob-joia-relogio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Joía / Relógio",
    "date_created": 1468508320644,
    "date_updated": 1468508320644,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 17,
    "tipo": "mob-mercadoria",
    "imagem": "veiculo_not_found.png",
    "descricao": "Mercadoria",
    "date_created": 1468508320644,
    "date_updated": 1468508320644,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 18,
    "tipo": "mob-movel_escrit",
    "imagem": "veiculo_not_found.png",
    "descricao": "Móvel de Escritório",
    "date_created": 1468508320644,
    "date_updated": 1468508320644,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 19,
    "tipo": "mob-movel_habitacao",
    "imagem": "veiculo_not_found.png",
    "descricao": "Móvel de Habitação",
    "date_created": 1468508320644,
    "date_updated": 1468508320644,
    "number_lots": null
  },
  {
    "natureza": "MAQUINA",
    "tipo_bem_id": 21,
    "tipo": "maq-agricolas",
    "imagem": "veiculo_not_found.png",
    "descricao": "Agrícolas",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "MAQUINA",
    "tipo_bem_id": 22,
    "tipo": "maq-construcao",
    "imagem": "veiculo_not_found.png",
    "descricao": "Construção",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "MAQUINA",
    "tipo_bem_id": 23,
    "tipo": "maq-ferramentas",
    "imagem": "veiculo_not_found.png",
    "descricao": "Ferramentas",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "MAQUINA",
    "tipo_bem_id": 24,
    "tipo": "maq-industriais",
    "imagem": "veiculo_not_found.png",
    "descricao": "Indústriais",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 26,
    "tipo": "dir-acoes_n_cotadas",
    "imagem": "veiculo_not_found.png",
    "descricao": "Ações não cotadas",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 27,
    "tipo": "dir-heranca",
    "imagem": "veiculo_not_found.png",
    "descricao": "Herança",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 28,
    "tipo": "dir-marc-pat-dom_int",
    "imagem": "veiculo_not_found.png",
    "descricao": "Marca / Patente / Domínio Internet",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 29,
    "tipo": "dir-quotas",
    "imagem": "veiculo_not_found.png",
    "descricao": "Quotas",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 30,
    "tipo": "dir-tresp-dto_arrend",
    "imagem": "veiculo_not_found.png",
    "descricao": "Trespasse / Direito Arrendamento",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 101,
    "tipo": "vei-ligeiro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Veículo Ligeiro",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 102,
    "tipo": "vei-comercial",
    "imagem": "veiculo_not_found.png",
    "descricao": "Veículo Comercial",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 103,
    "tipo": "vei-pesado",
    "imagem": "veiculo_not_found.png",
    "descricao": "Veículo Pesado",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 104,
    "tipo": "vei-trator_agricola",
    "imagem": "veiculo_not_found.png",
    "descricao": "Trator Agrícola",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 105,
    "tipo": "vei-moto",
    "imagem": "veiculo_not_found.png",
    "descricao": "Moto",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 106,
    "tipo": "vei-barco",
    "imagem": "veiculo_not_found.png",
    "descricao": "Barco",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 107,
    "tipo": "vei-aeronave",
    "imagem": "veiculo_not_found.png",
    "descricao": "Aeronave",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 108,
    "tipo": "vei-autocaravana",
    "imagem": "veiculo_not_found.png",
    "descricao": "Autocaravana",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "MAQUINA",
    "tipo_bem_id": 25,
    "tipo": "maq-outro_com_motor",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outra Máquina",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "VEICULO",
    "tipo_bem_id": 109,
    "tipo": "vei-outro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outro Veículo",
    "date_created": 1468508330662,
    "date_updated": 1468508330662,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 20,
    "tipo": "mob-outro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outro Mobiliário",
    "date_created": 1468508320644,
    "date_updated": 1468508320644,
    "number_lots": null
  },
  {
    "natureza": "DIREITO",
    "tipo_bem_id": 31,
    "tipo": "dir-outro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outro Direito",
    "date_created": 1468508326317,
    "date_updated": 1468508326317,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 11,
    "tipo": "imo-outro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outro Imobiliário",
    "date_created": 1468508301815,
    "date_updated": 1468508301815,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 15,
    "tipo": "equi-outro",
    "imagem": "veiculo_not_found.png",
    "descricao": "Outro Equipamento",
    "date_created": 1468508314105,
    "date_updated": 1468508314105,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 32,
    "tipo": "imov-terreno",
    "imagem": "veiculo_not_found.png",
    "descricao": "Terreno",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "IMOVEL",
    "tipo_bem_id": 33,
    "tipo": "imov-edificio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Edifício",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 34,
    "tipo": "equi-hotelaria",
    "imagem": "veiculo_not_found.png",
    "descricao": "Hotelaria",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 35,
    "tipo": "equi-desporto",
    "imagem": "veiculo_not_found.png",
    "descricao": "Desporto",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 36,
    "tipo": "equi-mat-informatic",
    "imagem": "veiculo_not_found.png",
    "descricao": "Material Informático",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 37,
    "tipo": "equi-saude",
    "imagem": "veiculo_not_found.png",
    "descricao": "Saúde",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "EQUIPAMENTO",
    "tipo_bem_id": 38,
    "tipo": "equi-laboratorio",
    "imagem": "veiculo_not_found.png",
    "descricao": "Laboratório",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 39,
    "tipo": "mob-saude",
    "imagem": "veiculo_not_found.png",
    "descricao": "Saúde",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "MOBILIARIO",
    "tipo_bem_id": 40,
    "tipo": "mob-decoracao",
    "imagem": "veiculo_not_found.png",
    "descricao": "Decoração",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 41,
    "tipo": "out-calcado",
    "imagem": "veiculo_not_found.png",
    "descricao": "Calçado",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 42,
    "tipo": "out-art-ourivesaria",
    "imagem": "veiculo_not_found.png",
    "descricao": "Artigos de Ourivesaria",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 43,
    "tipo": "out-vestuario",
    "imagem": "veiculo_not_found.png",
    "descricao": "Vestuário",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 44,
    "tipo": "out-stock",
    "imagem": "veiculo_not_found.png",
    "descricao": "Stock",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 45,
    "tipo": "out-animais",
    "imagem": "veiculo_not_found.png",
    "descricao": "Animais",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 46,
    "tipo": "out-diversos",
    "imagem": "veiculo_not_found.png",
    "descricao": "Diversos",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 47,
    "tipo": "out-arte",
    "imagem": "veiculo_not_found.png",
    "descricao": "Arte",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 48,
    "tipo": "out-materia-prima",
    "imagem": "veiculo_not_found.png",
    "descricao": "Matéria-Prima",
    "date_created": 1555327363604,
    "date_updated": 1555327363604,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 49,
    "tipo": "out-indefinido",
    "imagem": "veiculo_not_found.png",
    "descricao": "Indefinido",
    "date_created": 1555329787541,
    "date_updated": 1555329787541,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 50,
    "tipo": "out-antig",
    "imagem": "veiculo_not_found.png",
    "descricao": "Antiguidades",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 51,
    "tipo": "out-tecnologia",
    "imagem": "veiculo_not_found.png",
    "descricao": "Tecnologia",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 52,
    "tipo": "out-num-fila",
    "imagem": "veiculo_not_found.png",
    "descricao": "Numismática e Filatelia",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 53,
    "tipo": "out-rel-joias",
    "imagem": "veiculo_not_found.png",
    "descricao": "Relógios e Jóias",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 54,
    "tipo": "out-candeeiros",
    "imagem": "veiculo_not_found.png",
    "descricao": "Candeeiros",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 56,
    "tipo": "out-comput-jogos",
    "imagem": "veiculo_not_found.png",
    "descricao": "Computadores e Jogos",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 57,
    "tipo": "out-vinhos-bebidas",
    "imagem": "veiculo_not_found.png",
    "descricao": "Vinhos e Bebidas",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  },
  {
    "natureza": "OUTRO",
    "tipo_bem_id": 55,
    "tipo": "out-discos-livros",
    "imagem": "veiculo_not_found.png",
    "descricao": "Discos e Livros",
    "date_created": 1572520795183,
    "date_updated": 1572520795183,
    "number_lots": null
  }
];