import { BaseStore, IStore } from "modbin-web";
import { observable } from "mobx";
import { SessionStore } from "../../public/sessions/SessionStore";
import { IRepository } from "modbin-domain";
import { User } from "ibase-auctions-domain";

export class ProfileViewStore extends BaseStore implements IStore{
    
    @observable isLoading = true;

    constructor(private sessionStore:SessionStore,private userProfileRepository:IRepository<User>,) {
        super();
    }
    

    setState(){
        this.isLoading = true;
        this.userProfileRepository.findById(this.sessionStore.authData!.userId).then(x => {
            this.sessionStore.user = x; 
            this.isLoading = false;
         })
    }
}