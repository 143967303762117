import React, { Component, Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { Currency, Price, SellingType, SellingEventDateState } from "ibase-auctions-domain";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { SellingTypeSelector } from "../../../components/search/SellingTypeSelector";
import { SelectInputView } from "../../../../../components/forms/elements/SelectInputView";
import { PriceForm } from "./PriceForm";
import { OnlineAuctionPriceView } from "../../screens/details/types/online-auction/OnlineAuctionPriceView";
import { DefaultBasePriceView } from "./DefaultBasePriceView";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"PriceInformationViewUiTest",
    groupId:"components",
    description:"",
    title:"Price information component",
    parentId:"components"
};


const EditForm = (props:TranslationProps&{
    price:Price, 
    onSave:(v:any)=>Promise<void>,
    sellingType:SellingType, 
    onSellingTypeChange:(m:any)=>void, 
    dateState:SellingEventDateState,
    onDateStateChange:(d:any)=>void, 
})=>{
    const {t,price,onSave} = props;
    const subStyle = { borderBottom: "1px solid #eaeaea"};
    return <div>
        <div className="col-md-12 my-profile">
            <h4 style={subStyle}>Seeling type and date state</h4>
            <div className="col-md-6">
                <label>Modalidade de venda</label>
                <SellingTypeSelector
                    selected={props.sellingType}
                    onChange={(v:string) => {props.onSellingTypeChange(v)}}
                ></SellingTypeSelector>    
            </div>
            <div className="col-md-6">                  
                <SelectInputView
                    className="chosen-select-no-single"
                    isLoading={false}
                    items={[{value:"ended",text:"Ended"},{value:"days",text:"Active"},{value:"notstarted",text:"Not started"}]}
                    label="Date state"
                    onChange={(v:any)=> {props.onDateStateChange(v)}}
                    key="selector:type"
                    selected={props.dateState}
                ></SelectInputView>
            </div>
        </div>
        <div className="col-md-12 my-profile">
            <h4 style={subStyle}>Price</h4>
            <PriceForm t={t} price={price} onSave={(value:any)=> {return onSave(value)}} />  
        </div>
        <div className="clearfix"></div>
    </div>;
};

@observer
export class PriceInformationViewViewUiTest extends Component<TranslationProps,{}>{
    
    @observable price = {
        base:1000,
        reserve:2000,
        starting:900,
        current:0,
        nextBid:1000,
        currency:Currency.EURO

    } as Price;

    @observable sellingType = SellingType.ONLINEAUCTION;
    @observable dateState = SellingEventDateState.DAYS;

    onSellingTypeChange(t:any){
        this.sellingType = t;
    }

    onDateStateChange(t:any){
        this.dateState = t;
    }

    onSave(price:Price){
        this.price = price;
        return Promise.resolve();
    }

    render(){
        let testElement = <Fragment></Fragment>;
        switch(this.sellingType){
            case SellingType.ONLINEAUCTION:
                testElement = <OnlineAuctionPriceView dateState={this.dateState} price={this.price} bids={0} t={this.props.t}></OnlineAuctionPriceView>;
                break;
            case SellingType.CLOSEDLETTER:
            case SellingType.PRIVATENEGOTIATION:
            case SellingType.PRESENTIALAUCTION:
                testElement = <DefaultBasePriceView price={this.price} t={this.props.t}></DefaultBasePriceView>;
                break;
        }
        return <div>
            <div>
            <EditForm 
                price={this.price} 
                t={this.props.t} 
                onSave={(v)=>{return this.onSave(v)}}
                sellingType={this.sellingType}
                onSellingTypeChange={(t:any)=>{this.onSellingTypeChange(t);}}
                dateState={this.dateState}
                onDateStateChange={(t:any)=>{this.onDateStateChange(t);}}
            />
            </div>
            <h3>Visualization</h3>
            {testElement}
        </div>
    }
}
