import { ConsoleLogger } from "modbin-domain";
import { now } from "moment";

const moment = require("moment");

const logCalculateRefreshTimeout = (startDate:Date, endDate:Date, nowDate:Date, validatingTimeout:number) => {
    console.log("-----Calculate refresh timeout------");
    console.log("StartDate",startDate);
    console.log("EndDate",endDate);
    console.log("NowDate",nowDate);
    console.log("ValidatingTimeout",validatingTimeout);
    console.log("DifferenceEndAndNowAbs",Math.abs(moment(endDate).diff(nowDate,"milliseconds")))
    console.log("DifferenceEndAndNow",moment(nowDate).diff(endDate,"milliseconds"))
    console.log("ComparisonTimeoutAndDifferenceEndAndNowAbs",Math.abs(moment(endDate).diff(nowDate,"milliseconds"))>=validatingTimeout);
    console.log("DifferenceInSecondsToCalculateTimeout", moment(nowDate).diff(endDate,"milliseconds")>=validatingTimeout)
}

export const calculateRefreshTimeout = (startDate:Date, endDate:Date, nowDate:Date, validatingTimeout?:number) => {
    const resultValidatingTimeout = validatingTimeout !== undefined ? validatingTimeout : 10000;
    //logCalculateRefreshTimeout(startDate,endDate,nowDate,resultValidatingTimeout);
    if(moment(nowDate).diff(endDate,"milliseconds")>=resultValidatingTimeout){
        return undefined;
    }
    if(moment(startDate).isAfter(nowDate)){
        return undefined;
    }
    const endTime = moment(endDate);
    const diffInSeconds = endTime.diff(moment(nowDate),"second");
    //console.log("Diff in seconds:", diffInSeconds);
    if(diffInSeconds >= 3600){
        return 5*60000;
    }
    if(diffInSeconds >= 5*60){
        return 60000;
    }
    if(diffInSeconds >= 60){
        return 30000;
    }
    return 5000;
}