"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseStore = void 0;

var BaseStore =
/** @class */
function () {
  function BaseStore() {}

  BaseStore.prototype.initialize = function () {
    return Promise.resolve();
  };

  BaseStore.prototype.reset = function () {
    return Promise.resolve();
  };

  return BaseStore;
}();

exports.BaseStore = BaseStore;