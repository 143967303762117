"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultWebApplicationFactory = void 0;

var modbin_domain_1 = require("modbin-domain");

var Views_types_1 = require("../views/Views.types");

var WebApplication_1 = require("./WebApplication");

var DefaultWebContextProvider_1 = require("./DefaultWebContextProvider");

var DefaultWebApplicationStoreModule_1 = require("./DefaultWebApplicationStoreModule");

var defaultOptions = {
  targetConstructor: WebApplication_1.WebApplication,
  contextProvider: new DefaultWebContextProvider_1.DefaultContextProvider(),
  viewProvider: new Views_types_1.DefaultViewDefinitionProvider()
};

var DefaultWebApplicationFactory =
/** @class */
function (_super) {
  __extends(DefaultWebApplicationFactory, _super);

  function DefaultWebApplicationFactory(options) {
    var _this = this;

    var finalOptions = Object.assign(defaultOptions, options);
    _this = _super.call(this, finalOptions) || this;
    _this.viewProvider = _this.options.viewProvider;
    _this.storeFactory = _this.options.storeFactory;
    _this.contextProvider = _this.options.contextProvider;
    return _this;
  }

  DefaultWebApplicationFactory.prototype.createStoreModule = function (container, viewNavigator, viewProvider) {
    var stores = this.storeFactory.create(container, viewNavigator, viewProvider);
    var module = new DefaultWebApplicationStoreModule_1.DefaultWebApplicationStoreModule(stores);
    return module;
  };

  DefaultWebApplicationFactory.prototype.createOptions = function (options) {
    var views = options.views,
        translations = options.translations,
        rest = __rest(options, ["views", "translations"]);

    var applicationOptions = _super.prototype.createOptions.call(this, rest);

    var viewModule = this.createViewModule({
      container: applicationOptions.container,
      navigator: undefined,
      provider: this.viewProvider,
      views: views
    });
    var storeModule = this.createStoreModule(applicationOptions.container, viewModule.getNavigator(), viewModule.getProvider());
    viewModule.getProvider().registerViews(views, storeModule);

    var webApplicationOptions = __assign(__assign({}, applicationOptions), {
      views: viewModule,
      stores: storeModule,
      contextProvider: this.contextProvider,
      translations: translations
    });

    return webApplicationOptions;
  };

  return DefaultWebApplicationFactory;
}(modbin_domain_1.DefaultApplicationFactory);

exports.DefaultWebApplicationFactory = DefaultWebApplicationFactory;