import React, { Fragment } from "react";
import { BadgeType } from "../../components/badges/Badge";
import { Lot, SellingType } from "ibase-auctions-domain";
import { SummaryView } from "../../components/photos/SummaryView";
import { EndDateView } from "../../components/time/EndDateView";

export const LotSummaryView = (props:{item:Lot}) => {
    const { item, item:{photos,thumbnail,event:{type, dates}}} = props;    
    const info = type !== SellingType.PRIVATENEGOTIATION 
        ? <EndDateView endDate={dates.endDate} extendedDate={dates.extendedDate}/>
        : <Fragment/>
    const resultPhotos = photos !== undefined && photos.length > 0
        ? photos.map(x => {return x.url})
        : [thumbnail]; 
    return <SummaryView 
        item={item}
        photos={resultPhotos}
        sellingType={type}
        badges={[BadgeType.FEATURED]}
        info={info}
    />
}