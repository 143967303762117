"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OtherSubCategory = void 0;
var OtherSubCategory;

(function (OtherSubCategory) {
  OtherSubCategory["FOOTWEAR"] = "footwear";
  OtherSubCategory["GOLDJEWELRY"] = "goldjewelry";
  OtherSubCategory["CLOTHING"] = "clothing";
  OtherSubCategory["STOCK"] = "stock";
  OtherSubCategory["PETS"] = "pets";
  OtherSubCategory["MISCELLANEOUS"] = "miscellaneous";
  OtherSubCategory["ART"] = "art";
  OtherSubCategory["RAWMATERIAL"] = "rawmaterial";
  OtherSubCategory["UNDEFINED"] = "undefined";
  OtherSubCategory["ANTIQUES"] = "antiques";
  OtherSubCategory["TECHNOLOGY"] = "technology";
  OtherSubCategory["MUMISMATICSANDPHILATELY"] = "mumismaticsandPhilately";
  OtherSubCategory["WATCHESANDJEWELRY"] = "watchesandjewelty";
  OtherSubCategory["LAMPS"] = "lamps";
  OtherSubCategory["DISKSANDBOOKS"] = "disksandbooks";
  OtherSubCategory["COMPUTERSANDGAMES"] = "computersandgames";
  OtherSubCategory["WINESANDDRINKS"] = "winesanddrinks"; //57
})(OtherSubCategory = exports.OtherSubCategory || (exports.OtherSubCategory = {}));