import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { MainAppViewStore } from '../../../../configuration/stores/MainAppViewStore';
import { Lot, SellingEvent, ClockDate, getClockDatesFromSellingDates, SellingType } from 'ibase-auctions-domain';
import { LotListItemView } from '../../lots/components/LotListItemView';
import { LoadingView } from '../../../../components/loading/LoadingView';
import { NoResultsView } from '../../../../components/loading/NoResultsView';
import { NavigationBackLink } from '../../../../components/layout/navigation/menus/NavigationLink';
import { ShortTextAddressView } from '../../components/location/ShortTextAddressView';
import { TimeInformationView } from '../../components/time/TimeInformationView';

const ListView = observer((props:{ items:(Lot)[], onClick:(id:string, eventId:string)=>void}) => {
    return <div className="container">
        <div className="row fullwidth-layout">
            <div className="col-md-12">
                <div className="listings-container list-layout">
                    {props.items.map(x => <LotListItemView key={x.getId()} item={x} onClick={props.onClick}></LotListItemView>)}
                </div>
            </div>
        </div>
    </div>;
});

const EventDetailsSection = observer((props:TranslationProps&{selected:SellingEvent}) => {
    const clockDate:ClockDate = getClockDatesFromSellingDates(props.selected.dates);
    const timeView = props.selected.type !== SellingType.PRIVATENEGOTIATION
        ? <TimeInformationView t={props.t} clockDate={clockDate} showClock={true} showDate={true}></TimeInformationView>
        : <Fragment />
    return <Fragment>
            <div id="titlebar" className="property-titlebar margin-bottom-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <NavigationBackLink className="back-to-listings"></NavigationBackLink>
                            <div className="property-title">
                                <h2>{props.selected.title}</h2>
                                <ShortTextAddressView address={props.selected.address}></ShortTextAddressView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">


                    <div className="col-lg-8 col-md-7 sp-content">
                        
                    <h3 className="desc-headline">{props.t("domain:sales.events.event.description")}</h3>
                    <div>
                        <p>
                            {props.selected.description} 
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-5 sp-sidebar">
                        <div className="sidebar sticky right">
                            <div className="widget margin-bottom-30">                           
                                {timeView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Fragment>;
});

@inject("mainAppViewStore") @observer
export class ScreensBusinessEventsDetails extends Component<TranslationProps&{selected:SellingEvent, isLoading:boolean, mainAppViewStore?:MainAppViewStore},{}> {

    render() {
        if(this.props.selected === undefined){
            return <Fragment></Fragment>;
        }
        const isLoading = this.props.isLoading;
        const isLoadingLots = this.props.selected === undefined || isLoading === true || this.props.selected.lotsLoaded === false;
        const items = this.props.selected!.lots;
        return (
            <div>
                {/* <TitleBar title="Lotes de leilão"></TitleBar> */}
                <EventDetailsSection t={this.props.t} selected={this.props.selected}/>
                <div className="container">
	                <div className="row fullwidth-layout">
                        <div className="col-md-12">
                        <h3 className="desc-headline margin-bottom-30">{this.props.t("screens:business.events.details.lotsTitle")}</h3>
                        </div>
                        <div className="col-md-12">
                            <div className="listings-container list-layout">
                                <LoadingView 
                                    isLoading={isLoadingLots}
                                    showFallback={items.length === 0}
                                    fallbackView={<NoResultsView text={this.props.t("screens:business.events.details.noLots")}></NoResultsView>}
                                > 
                                    <ListView items={this.props.selected!.lots} onClick={(id:string, eventId:string) => {this.props.mainAppViewStore!.navigateTo("business:lots:details",{state:{lotId:id, eventId:eventId}})}}></ListView>
                                </LoadingView> 
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}