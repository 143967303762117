"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RealEstateSubCategory = void 0;
var RealEstateSubCategory;

(function (RealEstateSubCategory) {
  RealEstateSubCategory["APARTMENT"] = "apartment";
  RealEstateSubCategory["WAREHOUSE"] = "warehouse";
  RealEstateSubCategory["OFFICE"] = "office";
  RealEstateSubCategory["STORAGE"] = "storage";
  RealEstateSubCategory["STORE"] = "store";
  RealEstateSubCategory["HOME"] = "home";
  RealEstateSubCategory["BUILDING"] = "building";
  RealEstateSubCategory["FARM"] = "farm";
  RealEstateSubCategory["RUSTIC"] = "rustic";
  RealEstateSubCategory["CONSTRUCTIONLAND"] = "constructionland";
  RealEstateSubCategory["LAND"] = "land";
  RealEstateSubCategory["PREMISE"] = "premise";
  RealEstateSubCategory["OTHER"] = "otherrealstate";
})(RealEstateSubCategory = exports.RealEstateSubCategory || (exports.RealEstateSubCategory = {}));