"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToPrice = void 0;

var modbin_domain_1 = require("modbin-domain");

var Client_types_1 = require("../../client/Client.types");

exports.convertToPrice = function (source) {
  var result = {
    market: source.valor_base,
    base: source.valor_base,
    reserve: source.valor_minimo,
    starting: source.valor_abertura,
    current: source.currentBid,
    nextBid: source.nextBid,
    buyItNow: null,
    vat: modbin_domain_1.getNumberValueAsStringOrDefault(source.iva_id, undefined),
    sold: source.valor_venda,
    currency: Client_types_1.Currency.EURO
  };
  return result;
};