"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hardwareSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var HardwareSubCategory_1 = require("./HardwareSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[HardwareSubCategory_1.HardwareSubCategory.CONSTRUCTION, {
  pt: "Construção civil",
  en: "Construction"
}], [HardwareSubCategory_1.HardwareSubCategory.OFFICE, {
  pt: "Escritório",
  en: "Office"
}], [HardwareSubCategory_1.HardwareSubCategory.INDUSTRY, {
  pt: "Industria",
  en: "Industry"
}], [HardwareSubCategory_1.HardwareSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}], [HardwareSubCategory_1.HardwareSubCategory.HOSPITALITY, {
  pt: "Hotelaria",
  en: "Hospitality"
}], [HardwareSubCategory_1.HardwareSubCategory.SPORTS, {
  pt: "Desporto",
  en: "Sports"
}], [HardwareSubCategory_1.HardwareSubCategory.COMPUTING, {
  pt: "Material informático",
  en: "Computing"
}], [HardwareSubCategory_1.HardwareSubCategory.HEALTH, {
  pt: "Saúde",
  en: "Healthcare"
}]]);
exports.hardwareSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de equipamentos",
    value: translationObject.pt
  },
  en: {
    name: "Hardware subcategory",
    value: translationObject.en
  }
};