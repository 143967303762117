"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorizationType = void 0;
var AuthorizationType;

(function (AuthorizationType) {
  AuthorizationType["REQUIRED"] = "required";
  AuthorizationType["NOTREQUIRED"] = "notrequired";
  AuthorizationType["NOTAUTHENTICATED"] = "notauthenticated";
  AuthorizationType["ADMINISTRATOR"] = "administrator";
})(AuthorizationType = exports.AuthorizationType || (exports.AuthorizationType = {}));