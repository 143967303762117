import React, { Fragment } from 'react';
import { Lot } from 'ibase-auctions-domain';
import { observer } from 'mobx-react';
import moment from "moment";
import { LotListItemView } from '../../../../../business/lots/components/LotListItemView';

export const ListItemView = observer((props:{item:Lot,onClick:(id:string)=>void}) => {
    const {item} = props
    return <tr onClick={(ev)=>{props.onClick(item.getId())}}>
        <td>{item.getId()}</td>
        <td>{item.title}</td>
        <td>{moment(item.event.dates.endDate).format("DD/MM/YYYY HH:mm")}</td>
    </tr>; 
});

export const ListView = observer((props:{ items:(Lot)[],onClick:(id:string,eventId:string)=>void}) => {
    return <Fragment>{props.items.map(x => <LotListItemView key={x.getId()} item={x} onClick={props.onClick}></LotListItemView>)}</Fragment>;
})
