"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseBusinessEventService = void 0;

var BaseBusinessEventService =
/** @class */
function () {
  function BaseBusinessEventService() {}

  BaseBusinessEventService.prototype.configure = function (options) {
    var clientId = options.clientId,
        userId = options.userId,
        source = options.source;
    this.actorId = userId;
    this.clientId = clientId;
    this.source = source;
  };

  BaseBusinessEventService.prototype.sendTrackingEvent = function (event) {
    event.actorId = this.actorId;
    event.clientId = this.clientId;
    event.source = this.source;
  };

  return BaseBusinessEventService;
}();

exports.BaseBusinessEventService = BaseBusinessEventService;