"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.specifiableMapper = void 0;

exports.specifiableMapper = function (queryParameters, mapper) {
  var queryKeys = Array.from(queryParameters.keys());
  var predicate = queryKeys.filter(function (x) {
    return mapper.get(x) !== undefined;
  }).map(function (x) {
    return mapper.get(x)(queryParameters.get(x));
  });
  return predicate;
};