"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestApiRepository = void 0;

var Resources_types_1 = require("../../resources/Resources.types");

var buildQuery = function buildQuery(resourceMapper, sourceType, targetType, query) {
  return __awaiter(void 0, void 0, void 0, function () {
    var createMappedQuery, mappedQuery, _a;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          createMappedQuery = function createMappedQuery() {
            return __awaiter(void 0, void 0, void 0, function () {
              var mappedQuery;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    return [4
                    /*yield*/
                    , resourceMapper.map(query, {
                      source: sourceType + "Query",
                      target: targetType + "Query"
                    })];

                  case 1:
                    mappedQuery = _a.sent();
                    Object.keys(mappedQuery).forEach(function (x) {
                      if (mappedQuery[x] === undefined) {
                        delete mappedQuery[x];
                      }
                    });
                    return [2
                    /*return*/
                    , mappedQuery];
                }
              });
            });
          };

          if (!resourceMapper.hasMap(sourceType + "Query", targetType + "Query")) return [3
          /*break*/
          , 2];
          return [4
          /*yield*/
          , createMappedQuery()];

        case 1:
          _a = _b.sent();
          return [3
          /*break*/
          , 3];

        case 2:
          _a = query;
          _b.label = 3;

        case 3:
          mappedQuery = _a;
          return [2
          /*return*/
          , mappedQuery];
      }
    });
  });
};

var RestApiRepository =
/** @class */
function () {
  function RestApiRepository(options) {
    this._templateGetter = undefined;
    this.idGenerator = undefined;
    this._mapper = options.mappings.mapper;
    this._mapperOptions = options.mappings.options;
    this._templates = options.templateGetter !== undefined ? options.templateGetter() : options.template;
    this._templateGetter = options.templateGetter;
    this._templateBuilder = new Resources_types_1.ResourceUrlTemplateBuilder(this._templates, options.mappings.options.source);
    this._httpClient = options.httpClient;
  }

  RestApiRepository.prototype.checkTemplates = function () {
    if (this._templateGetter !== undefined) {
      var newTemplates = this._templateGetter();

      if (JSON.stringify(this._templates) !== JSON.stringify(newTemplates)) {
        this._templates = newTemplates;
        this._templateBuilder = new Resources_types_1.ResourceUrlTemplateBuilder(this._templates, this._mapperOptions.source);
      }
    }
  };

  RestApiRepository.prototype.create = function (entity, options) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, source, target, targetConstructor, toPost, url;

      var _this = this;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this._mapperOptions, source = _a.source, target = _a.target, targetConstructor = _a.targetConstructor;
            return [4
            /*yield*/
            , this._mapper.map(entity, {
              source: target,
              target: source
            })];

          case 1:
            toPost = _b.sent();
            this.checkTemplates();

            if (options !== undefined) {
              url = this._templateBuilder.buildTemplate(this._templateBuilder.templates.post, options);
            } else {
              url = this._templateBuilder.buildPostTemplate(toPost);
            } //console.log(`ApiRepository type: ${this._mapperOptions.source}. Calling create. `, toPost);


            return [2
            /*return*/
            , this._httpClient.post(url, toPost).then(function (x) {
              return _this._mapper.map(x, _this._mapperOptions);
            })];
        }
      });
    });
  };

  RestApiRepository.prototype.update = function (entity) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, source, target, targetConstructor, toPut, url;

      var _this = this;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this._mapperOptions, source = _a.source, target = _a.target, targetConstructor = _a.targetConstructor;
            return [4
            /*yield*/
            , this._mapper.map(entity, {
              source: target,
              target: source
            })];

          case 1:
            toPut = _b.sent();
            this.checkTemplates();
            url = this._templateBuilder.buildPutTemplate(toPut);
            return [2
            /*return*/
            , this._httpClient.put(url, toPut).then(function (x) {
              return _this._mapper.map(x, _this._mapperOptions);
            })];
        }
      });
    });
  };

  RestApiRepository.prototype.del = function (id) {
    this.checkTemplates();

    var url = this._templateBuilder.buildDeleteTemplate(id);

    return this._httpClient.delete(url);
  };

  RestApiRepository.prototype.findById = function (query) {
    var _this = this;

    this.checkTemplates();

    var url = this._templateBuilder.buildGetTemplate(query);

    return this._httpClient.get(url).then(function (x) {
      return _this._mapper.map(x, _this._mapperOptions);
    });
  };

  RestApiRepository.prototype.findAll = function (predicate) {
    var _this = this;

    this.checkTemplates();

    if (predicate === undefined) {
      var url = this._templateBuilder.buildCollectionTemplate();

      return this._httpClient.get(url).then(function (x) {
        return _this._mapper.mapCollection(x, _this._mapperOptions);
      });
    } else {
      throw new Error("Not implemented!");
    }
  };

  RestApiRepository.prototype.find = function (query) {
    this.checkTemplates();
    return this._httpClient.get("").then();
  };

  RestApiRepository.prototype.findAllByRepresentation = function (query) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, source, target, mappedQuery, url;

      var _this = this;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this._mapperOptions, source = _a.source, target = _a.target;
            return [4
            /*yield*/
            , buildQuery(this._mapper, target, source, query)];

          case 1:
            mappedQuery = _b.sent();
            this.checkTemplates();
            url = this._templateBuilder.buildCollectionTemplate(mappedQuery);
            return [2
            /*return*/
            , this._httpClient.get(url).then(function (x) {
              return _this._mapper.mapCollection(x, _this._mapperOptions);
            })];
        }
      });
    });
  };

  RestApiRepository.prototype.findAllPageableByRepresentation = function (query, options) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, source, target, mappedQuery, _b, url;

      var _this = this;

      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            _a = this._mapperOptions, source = _a.source, target = _a.target;
            if (!(query !== undefined)) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , buildQuery(this._mapper, target, source, query)];

          case 1:
            _b = _c.sent();
            return [3
            /*break*/
            , 3];

          case 2:
            _b = undefined;
            _c.label = 3;

          case 3:
            mappedQuery = _b; // console.log("RestApiRepository: query",query);
            // console.log("RestApiRepository: mappedQuery",mappedQuery);
            // console.log("RestApiRepository: collectionTempalte",this._templateBuilder.templates.collection);
            // console.log("RestApiRepository: options",options);

            this.checkTemplates();
            url = this._templateBuilder.buildTemplate(this._templateBuilder.templates.collection, __assign(__assign({}, options), {
              query: mappedQuery
            }));
            return [2
            /*return*/
            , this._httpClient.get(url).then(function (x) {
              return _this._mapper.mapPageableCollection(x, _this._mapperOptions);
            })];
        }
      });
    });
  };

  RestApiRepository.prototype.generateKey = function (options) {
    throw new Error("Method not implemented.");
  };

  return RestApiRepository;
}();

exports.RestApiRepository = RestApiRepository;