import React from "react";
import { TranslationProps } from "modbin-domain";
import { inject, observer } from "mobx-react";
import { NewsletterViewStore } from "../../../screens/administration/newsletter/ViewStore";
import { NavigationLink } from "../../layout/navigation/menus/NavigationLink";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FormInput } from "../elements/FormInput";
import { ActionButton, ButtonType } from "../../buttons/ActionButton";
import { TermsCheckbox } from "./TermsCheckbox";
import { observable } from "mobx";
import { application } from "../../../Startup";

export const NewsletterSubscriptionFormView = observer((props:TranslationProps & {onSubscribe:(ev:any)=>Promise<void>}) => {
    const getTranslationFooter = (key:string) => props.t(`common:footer.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loginSchema = Yup.object().shape({
            email: Yup.string()
              .email(props.t("domain:users.user.emailInvalid"))
              .required(createRequiredMessage(getTranslationFooter("emailField"))),
            name: Yup.string()
              .required(createRequiredMessage(getTranslationFooter("nameField"))),
            terms: Yup.boolean().oneOf([true],getTranslationFooter("readAndAcceptedRequiredMessage"))
          });
      return (
        <Formik
                initialValues={{ email: "", name: "", terms:false }}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                    return props.onSubscribe({name:values.name, email:values.email} as any).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
              >
                {(formProps) => (
                  <Form>
                      <div className="form-row form-row-wide">
                        <FormInput type="text" name="name" label={getTranslationFooter("nameField")} placeHolder={getTranslationFooter("nameField")} formikState={formProps} />
                     </div>
                      <div className="form-row form-row-wide">
                        <FormInput type="email" name="email" label={getTranslationFooter("emailField")} placeHolder={getTranslationFooter("emailField")} formikState={formProps} />
                     </div>
                     <div>
                        <TermsCheckbox
                            name="terms"
                            formikState={formProps}
                            label={
                                <label
                                    style={{fontSize:"13px", display:"inline", margin: "0px", marginLeft:"10px",verticalAlign: "text-bottom"}}
                                >
                                    {getTranslationFooter("readAndAccepted")}
                                    <NavigationLink to="public:privacy">{getTranslationFooter("privacyLinkText")}</NavigationLink>
                                </label>
                            }
                        ></TermsCheckbox>
                    </div>
                     <div className="form-row">
                        <ActionButton type={ButtonType.SUBSCRIBE} onClick={()=>{return formProps.submitForm()}}  style={{float:"right",marginTop: "20px"}} />
                    </div>
                  </Form>
                )}
              </Formik>
      );
  });

const SucessfullSubscriptionView = (props:TranslationProps) => {
    return <div
        style={{
            textAlign:"center"
        }}
    >
        <h4
            style={{
                color:"white",
            }}>
            {props.t(`common:footer.successfulSubscriptionMessage`)}
        </h4>
    </div>
}

@inject("newsletterViewStore") @observer
export class NewsletterSubscriptionForm extends React.Component<TranslationProps & {newsletterViewStore?:NewsletterViewStore},{}> {
    @observable subscribed:boolean = false;

    hideFormAfterSubscription(){
        this.subscribed = true;
    }

    render(){
        const sucessfullSubscriptionView = <SucessfullSubscriptionView  t={this.props.t}/>;
        const formView = <NewsletterSubscriptionFormView
            t={this.props.t}
            onSubscribe={(s) => { return this.props.newsletterViewStore!.subscribe(s).then((x)=>{
                this.hideFormAfterSubscription();
            });}}
        />;
        return (!this.subscribed) ? formView : sucessfullSubscriptionView;
    }
}

