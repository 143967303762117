import React, { Component, Fragment } from 'react';
import { ApplicationConfiguration } from '../../../configuration/temp/AplicationConfiguration';
import { CompanyInfo } from '../../../configuration/info/CompanyInfo';
import { ServicesConfiguration } from '../../../configuration/Config.types';
import { observer } from 'mobx-react';
import { application } from '../../../Startup';


const PropertiesSection = (props:{title:string, description:string, properties:any})=> {
    return <div className="margin-top-50">
        <div>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
        </div>
        <div>
            <pre>{JSON.stringify(props.properties, null, 4)}</pre>
        </div>
    </div>
}

@observer
export class ScreensSupportProperties extends Component<{},{}> {

    render(){
        const appProperties = application.getContainer().resolve<ApplicationConfiguration>("appConfig");
        const webProperties = application.getContainer().resolve<ApplicationConfiguration>("webConfig");
        const companyInfoProperties = application.getContainer().resolve<CompanyInfo>("companyInfoConfig");
        const servicesProperties = application.getContainer().resolve<ServicesConfiguration>("servicesConfig");
        const apiProperties = application.getContainer().resolve<ApplicationConfiguration>("apiConfig");
        return (<Fragment>
            <div className="container">
                <h1>Configuration properties page</h1>
            </div>
            <div className="container">
                <PropertiesSection
                    title="Application configuration properties"
                    description="Shows the current properties for wide application configuration."
                    properties={appProperties}    
                ></PropertiesSection>

                <PropertiesSection
                    title="Website configuration properties"
                    description="Shows the current properties for configuring current web site instance."
                    properties={webProperties}    
                ></PropertiesSection>

                <PropertiesSection
                    title="Client configuration properties"
                    description="Shows the client data being used on the website."
                    properties={companyInfoProperties}    
                ></PropertiesSection>

                <PropertiesSection
                    title="Services configuration properties"
                    description="Shows the external servies being used."
                    properties={servicesProperties}    
                ></PropertiesSection>

                <PropertiesSection
                    title="Api configuration properties"
                    description="Shows the current properties for api configuration."
                    properties={apiProperties}    
                ></PropertiesSection>
            </div>
        </Fragment>
        );
    }
}