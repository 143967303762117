import { TranslationDefinition } from "../../../translations/Translations.types";

export const companyTranslations:TranslationDefinition = {
    pt: {
        title:"Sobre nós",
        s1t1:"Quem somos",
        s1p1:"Com 50 anos de existência, somos uma leiloeira altamente especializada em áreas como vendas judiciais, propostas de carta fechada, negociações particulares, arrolamentos e alienação de património e ainda realização de leilões particulares ou judiciais de bens móveis ou imóveis.",
        s1p2:"Contamos com uma equipa de colaboradores formada por profissionais experientes com o conhecimento adequado para lhe sugerir ou aconselhar as melhores soluções. Qualificados, também, para fazer as melhores avaliações de acordo com a realidade e exigência dos nossos mercados.",
        s1p3:"Com vasta experiência na liquidação de, insolvências efectivação de penhoras, apreensão de bens, avaliações e liquidação de patrimónios de falências fazemos do perfeccionismo e do rigor com que abraçamos o nosso cliente o nosso desafio diário.",
        s2t1:"Missão e valores",
        s2p1:"Acima de tudo, acreditamos nos objectivos de negócio dos nossos clientes.",
        s2p2:"Na Paraíso procuramos realizar bons negócios com a máxima eficiência, proporcionando assim a maior tranquilidade e satisfação possíveis aqueles que nos procuram. Tentamos responder da forma mais estruturada e eficaz às necessidades dos nossos parceiros e clientes assentes sempre uma lógica de parceria cimentada nos laços de confiança criados. Por esta razão, prestamos os nossos serviços com base no princípio da confiança, assumindo como compromisso primordial da nossa actividade a criação de valor para o cliente, actuando no Mercado leiloeiro com o máximo rigor e credibilidade.",
        s2p3:"Contacte-nos e comprove o nosso valor.",
    },
    en:{
        title:"About us",
        s1t1:"Who we are",
        s1p1:"With wide experience in the liquidation of insolvencies, realization of attachments, seizure of assets, evalutions and liquidation of bankruptcy estate, it´s our perfectionism, our accuracy and the exquisite way in wich we accompany our client that defines the quality of our work and, therefore, determine the traits we consider vital for our daily challenges.",
        s1p2:"We rely on a team of employees trained by extremely experienced professionals with relevant expertise on suggesting and advising the best solutions. They are also qualified to make the best evaluations according to the reality and demand of our markets.",
        s1p3:"With 50 years of experience in the business, we are a auctioneer highly specialized in areas such as judicial sales, sealed letter proposals, private negociations, inventories and disposal of assets, also responsible for private or judicial auctions of movable or immovable properties.",
        s2t1:"Our mission and values",
        s2p1:"We believe, above all, in the business goals of our clients.",
        s2p2:"At Paraíso, we seek to achieve good businesses with maximum efficiency, providing complete tranquility and contentment to those whom we offer our services. We try to respond to the needs of our partners and clients on the most structured and efficient way, promoting our work on a trustful partnership. For this reason, we provide our services relying on mutual trust, assuming the creation of value as our main commitment towards our clients, taking action on the auctioneer market with maximum credibility and accuracy.",
        s2p3:"Contact us and prove our value.",
    }
}