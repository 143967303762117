"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseEndpoints = void 0;

var Endpoint_1 = require("./Endpoint");

var getIfIsEndpoint = function getIfIsEndpoint(source) {
  return Endpoint_1.isEndpoint(source) ? source : undefined;
};

exports.parseEndpoints = function (source) {
  if (source === null || source === undefined) {
    return [];
  }

  var endpoint = getIfIsEndpoint(source);

  if (endpoint !== undefined) {
    return [endpoint];
  }

  if (Array.isArray(source)) {
    return source.filter(function (x) {
      return Endpoint_1.isEndpoint(x);
    });
  }

  if (typeof source === "object") {
    var result_1 = [];
    Object.getOwnPropertyNames(source).forEach(function (x) {
      var endpoints = exports.parseEndpoints(source[x]);
      result_1 = result_1.concat(endpoints);
    });
    return result_1;
  }

  return [];
};