"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultContextProvider = void 0;

var DefaultContextProvider =
/** @class */
function () {
  function DefaultContextProvider() {
    this.context = undefined;
    this.subscribers = [];
  }

  DefaultContextProvider.prototype.registerListener = function (listener) {
    this.subscribers.push(listener);
  };

  DefaultContextProvider.prototype.setContext = function (context) {
    this.context = context;
    this.subscribers.forEach(function (x) {
      x(context);
    });
  };

  DefaultContextProvider.prototype.getContext = function () {
    return this.context;
  };

  DefaultContextProvider.prototype.hasContext = function (verifier) {
    if (this.context === undefined) {
      return false;
    }

    if (verifier !== undefined) {
      return verifier(this.context);
    }

    return true;
  };

  return DefaultContextProvider;
}();

exports.DefaultContextProvider = DefaultContextProvider;