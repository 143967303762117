import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SellingEventDateState } from "ibase-auctions-domain";
import { UiTestDescription } from "modbin-web";
import { CountDownClockSize, CountDownClockType, CountDownClockView } from "./CountDownClockView";
import { SelectInputView } from "../forms/elements/SelectInputView";
import { DefaultTimeService } from "../../configuration/temp/DefaultTimeService";
import { IBaseClockCountDownView } from "./IBaseCountDownClockView";
import { TranslationProps } from "modbin-domain";

export const description:UiTestDescription = {
    id:"IBaseCountDownClockViewUiTest",
    groupId:"components",
    description:"IBase specific clock that shows differents states with text",
    title:"IBase specific",
    parentId:"components"
};

const dateStateTest:any = {
    "ended" : {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T12:00:00')
    },
    "days":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:58:50')
    },
    "day":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:59:50')
    },
    "hour":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T09:59:50') 
    },
    "minute":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T10:59:50') 
    },
    "notstarted": {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T09:00:00')
    }
};

const DateStateClockView = (props:TranslationProps & {title:string,dateState:string,size:CountDownClockSize,type:CountDownClockType}) => {
    const newDateState = dateStateTest[props.dateState];
    const timeService = new DefaultTimeService(newDateState.now.getTime());
    return <div className="col-md-4">
        <h5>{props.title}</h5>
        <IBaseClockCountDownView
            size={props.size}
            type={props.type}
            clockDate={newDateState}
            timeService={timeService}
            validatingTimeout={20000}
            t={props.t}
        ></IBaseClockCountDownView>
    </div>;
}

@observer
export class AuctionClockViewUiTest extends React.Component<TranslationProps,{}>{
    @observable size: CountDownClockSize = "small";
    @observable type:CountDownClockType = "extended"

    render(){
        return <div>
                <div className="col-md-12">
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"small",text:"small"},{value:"medium", text:"medium"},{value:"large",text:"large"}]}
                            label="Size"
                            onChange={(v:any)=> {this.size = v}}
                            selected={this.size}
                            key="selector:size"
                        ></SelectInputView>
                    </div>
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"extended",text:"extended"},{value:"simple", text:"simple"}]}
                            label="Size"
                            onChange={(v:any)=> {this.type = v}}
                            selected={this.type}
                            key="selector:type"
                        ></SelectInputView>
                    </div>
                </div>
                <div className="col-md-12">
                    <h4>Dates state</h4>
                    <DateStateClockView t={this.props.t} title="Not started yet" dateState={SellingEventDateState.NOTSTARTED} size={this.size} type={this.type} />
                    <DateStateClockView t={this.props.t} title="Days left" dateState={SellingEventDateState.DAYS} size={this.size} type={this.type} />
                    <DateStateClockView t={this.props.t} title="Less than a day left" dateState={SellingEventDateState.DAY} size={this.size} type={this.type} />
                    <DateStateClockView t={this.props.t} title="Less than an hour left" dateState={SellingEventDateState.HOUR} size={this.size} type={this.type} />
                    <DateStateClockView t={this.props.t} title="Less than five minutes left" dateState={SellingEventDateState.MINUTE} size={this.size} type={this.type} />
                    <DateStateClockView t={this.props.t} title="Ended" dateState={SellingEventDateState.ENDED} size={this.size} type={this.type} />
                </div>
        </div>
    }
};