"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileSystemService = void 0;

var DirectoryOperations_1 = require("./DirectoryOperations");

var FileOperations_1 = require("./FileOperations");

var FileSystemService =
/** @class */
function () {
  function FileSystemService() {}

  Object.defineProperty(FileSystemService.prototype, "directories", {
    get: function get() {
      return DirectoryOperations_1.directoryOperations;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(FileSystemService.prototype, "files", {
    get: function get() {
      return FileOperations_1.fileOperations;
    },
    enumerable: false,
    configurable: true
  });
  return FileSystemService;
}();

exports.FileSystemService = FileSystemService;