import { IIocContainer, IIocModule, BaseModule, IServiceDescriptor } from "modbin-domain";
import { WebConfiguration, DataSourceType } from "../Config.types";

export class IBaseModule extends BaseModule implements IIocModule {

    constructor(name:string){
        // console.log('IBaseModule: ' + name);
        super(name);
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        return [];
    }


    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        return [];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        return [];
    }

    createBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        console.log('createBindings');
        const dataSource = kernel.resolve<WebConfiguration>("webConfig").dataSource;
        if(dataSource === DataSourceType.LOCAL){
            this.dependencies = this.dependencies.concat(this.createBindingsForLocal(kernel,options));
        }
        if(dataSource === DataSourceType.API){
            console.log("1");
            this.dependencies = this.dependencies.concat(this.createBindingsForApi(kernel,options));
        }
        this.dependencies = this.dependencies.concat(this.createCommonBindings(kernel,options));
        return this.dependencies;
    }
}
