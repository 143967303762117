"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IntegerEntityKeyGenerator = void 0;

var IntegerEntityKeyGenerator =
/** @class */
function () {
  function IntegerEntityKeyGenerator() {
    this.current = 0;
  }

  IntegerEntityKeyGenerator.prototype.generateKey = function () {
    this.current += 1;
    return this.current.toString();
  };

  return IntegerEntityKeyGenerator;
}();

exports.IntegerEntityKeyGenerator = IntegerEntityKeyGenerator;