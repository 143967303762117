import { decorate, observable, computed } from "mobx";
import { Lot, SellingEvent, User } from "ibase-auctions-domain";
import { DefaultViewNavigator } from "modbin-web";

decorate(Lot,{
    eventId:observable,
    officeId:observable,
    userId:observable,

    
    title:observable,
    description:observable,
    number:observable,
    reference:observable,
    thumbnail:observable,
    photos:observable,
    processReference:observable,
    processId:observable,
    processType:observable,
    processInsolvent:observable,

    featured:observable,
    published: observable,
    sold:observable,
    
    event:observable,
    price:observable,
    address:observable,
    location:observable,
    category:observable,
    subCategory:observable,
    
    documents:observable,
    documentsLoaded:observable,
    bookings:observable,
    bokingsLoaded:observable,
    updateData:observable
});

decorate(SellingEvent,{
    type:observable,
    title:observable,
    description:observable,
    featured:observable,
    published:observable,
    thumbnail:observable,
    photos:observable,
    address:observable,
    location:observable,
    dates:observable,
    officeId:observable,
    processReference:observable,
    processId:observable,
    processType:observable,
    processInsolvent:observable,
    
    documents:observable,
    documentsLoaded:observable,
    lots:observable,
    lotsLoaded:observable
})

decorate(User,{
    firstName:observable,
    lastName:observable,
    contact:observable,
    address:observable,
    title:observable,
    maritalStatus: observable,
    identificationCard: observable,
    birthDate:observable,
    bornAt:observable,
    //TODO: what is this?
    providder:observable,
    accountState:observable,
    provider: observable,
    officeId:observable,
    password:observable,
    token:observable
})

decorate(DefaultViewNavigator,{
    currentView:observable,
    currentPath:observable
})
