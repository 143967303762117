"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRequiredErrorMessage = exports.createTranslationObjectForEnum = exports.translationKeyGetter = exports.Language = void 0;
var Language;

(function (Language) {
  Language["PT"] = "pt";
  Language["EN"] = "en";
})(Language = exports.Language || (exports.Language = {}));

exports.translationKeyGetter = function (base) {
  return function (key) {
    return base + "." + key;
  };
};

exports.createTranslationObjectForEnum = function (values) {
  var obj = {
    pt: {},
    en: {}
  };
  values.forEach(function (el) {
    var propName = el[0];
    obj.pt[propName] = el[1].pt;
    obj.en[propName] = el[1].en;
  });
  return obj;
};

exports.createRequiredErrorMessage = function (t, property) {
  return t("domain:validation.requiredMessage", {
    field: t(property)
  });
};