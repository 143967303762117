"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultViewDefinitionProvider = void 0;

var modbin_domain_1 = require("modbin-domain");

var DefaultViewDefinitionProvider =
/** @class */
function () {
  function DefaultViewDefinitionProvider(views) {
    if (views === void 0) {
      views = [];
    }

    this._views = new Map();
    this.registerViews(views);
  }

  DefaultViewDefinitionProvider.prototype.getViewDefinition = function (name) {
    var view = this._views.get(name);

    if (view === undefined) {
      throw new Error("View with name " + name + " is not defined!");
    }

    return this._views.get(name);
  };

  DefaultViewDefinitionProvider.prototype.getViewDefinitions = function () {
    return Array.from(this._views.values());
  };

  DefaultViewDefinitionProvider.prototype.registerViews = function (views, storeProvider) {
    var _this = this;

    if (views === undefined || views.length === 0) {
      return;
    }

    var finalViews = views.map(function (x) {
      if (storeProvider !== undefined && modbin_domain_1.isNotNullOrEmptyString(x.storeName) && storeProvider.getStore(x.storeName) !== undefined) {
        x.store = storeProvider.getStore(x.storeName);
      }

      return x;
    });
    finalViews.forEach(function (x) {
      if (_this._views.get(x.name) !== undefined) {
        throw new Error("View with name " + x.name + " is already defined!");
      }

      _this._views.set(x.name, x);
    });
  };

  return DefaultViewDefinitionProvider;
}();

exports.DefaultViewDefinitionProvider = DefaultViewDefinitionProvider;