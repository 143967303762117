"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryRepository = void 0;

var modbin_domain_1 = require("modbin-domain");

var IntegerEntityKeyGenerator_1 = require("./IntegerEntityKeyGenerator");

var InMemoryRepository =
/** @class */
function () {
  function InMemoryRepository(options) {
    this.elements = new Map();
    this.idGenerator = undefined;

    if (options === undefined) {
      return;
    }

    var initialElements = options.initialElements,
        idGenerator = options.idGenerator;

    if (idGenerator === undefined) {
      this.idGenerator = new IntegerEntityKeyGenerator_1.IntegerEntityKeyGenerator();
    }

    if (!modbin_domain_1.arrayIsNullOrEmpty(initialElements)) {
      this.addElements(initialElements);
    }
  }

  InMemoryRepository.prototype.addElements = function (elements) {
    var _this = this;

    elements.forEach(function (e) {
      _this.create(e);
    });
  };

  InMemoryRepository.prototype.addElement = function (element) {
    if (this.elements.get(element.getId()) === undefined) {
      this.elements.set(element.getId(), element);
      return;
    }

    throw new Error("Element with same key already exists");
  };

  InMemoryRepository.prototype.getAllElements = function () {
    return Array.from(this.elements.values());
  };

  InMemoryRepository.prototype.createEntityBaseInfo = function () {
    var currentDate = new Date(new Date().toISOString());
    var id = this.idGenerator.generateKey();
    return {
      id: id,
      createdDate: currentDate,
      modifiedDate: currentDate
    };
  };

  InMemoryRepository.prototype.generateKey = function (options) {
    return this.idGenerator.generateKey(options);
  };

  InMemoryRepository.prototype.create = function (entity) {
    var options = entity.getId() === undefined ? this.createEntityBaseInfo() : undefined;
    var entityToStore = entity.clone(options);
    this.addElement(entityToStore);
    return Promise.resolve(entityToStore);
  };

  InMemoryRepository.prototype.update = function (entity) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        this.elements.set(entity.getId(), entity);
        return [2
        /*return*/
        , Promise.resolve(entity)];
      });
    });
  };

  InMemoryRepository.prototype.del = function (id) {
    if (this.elements.get(id) === undefined) {
      throw new Error("Element with the provided key was not found.");
    }

    this.elements.delete(id);
    return Promise.resolve();
  };

  InMemoryRepository.prototype.findById = function (id) {
    return Promise.resolve(this.elements.get(id));
  };

  InMemoryRepository.prototype.findAll = function (predicate) {
    if (predicate === undefined) {
      return Promise.resolve(this.getAllElements());
    }

    return Promise.resolve(this.getAllElements().filter(function (e) {
      return predicate(e);
    }));
  };

  InMemoryRepository.prototype.find = function (query) {
    throw new Error("Method not implemented.");
  };

  InMemoryRepository.prototype.findAllByRepresentation = function (query) {
    if (query === undefined) {
      return Promise.resolve(this.getAllElements());
    }

    return Promise.resolve(this.getAllElements());
  };

  InMemoryRepository.prototype.findAllPageableByRepresentation = function (query) {
    return Promise.resolve({
      page: 1,
      size: 100,
      total: 1000,
      items: this.getAllElements()
    });
  };

  return InMemoryRepository;
}();

exports.InMemoryRepository = InMemoryRepository;