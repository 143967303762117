"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationLoggingModule = void 0;

var ApplicationLoggingModule =
/** @class */
function () {
  function ApplicationLoggingModule(options) {
    this.loggerDescription = options.loggerDescription;
    this.container = options.container;
    this.factory = options.factory;
    this.container.register("loggerFactory", this.factory);
  }

  ApplicationLoggingModule.prototype.createLogger = function (options) {
    var logger = this.factory.create(options);
    return logger;
  };

  ApplicationLoggingModule.prototype.getLogger = function () {
    return this.container.resolve("logger");
  };

  ApplicationLoggingModule.prototype.setLogging = function (options) {
    var loggingDescription = this.createLogger(options);
    this.container.replace("logger", loggingDescription.logger);
  }; //todo: Check why is this needed. This is set at contrcutor but never changes again..


  ApplicationLoggingModule.prototype.getLogging = function () {
    return this.loggerDescription;
  };

  ApplicationLoggingModule.prototype.getFactory = function () {
    return this.factory;
  };

  return ApplicationLoggingModule;
}();

exports.ApplicationLoggingModule = ApplicationLoggingModule;