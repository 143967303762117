import React from "react";
import { NewsletterFooter } from "./NewsletterFooter";
import { CopyrightFooter } from "./CopyrightFooter";

export const Footer = () => {
    return  <div className="custom-footer">
        <div className="container">  
            <NewsletterFooter></NewsletterFooter>
        </div>
        <CopyrightFooter></CopyrightFooter>
    </div>;
}