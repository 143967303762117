import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { AdministrationMenu } from "./AdministrationMenu";
import { SessionStore } from "../../../../screens/public/sessions/SessionStore";

export const AdministrationWidget = inject("sessionStore")(observer((props:{sessionStore?:SessionStore}) => {
    const children = props.sessionStore!.isAdmin
        ? <AdministrationMenu/>
        : <Fragment />;
    return children;
}));