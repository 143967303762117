"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eventStatesTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var EventState_1 = require("./EventState");

exports.eventStatesTranslations = modbin_domain_1.createTranslationObjectForEnum([[EventState_1.EventState.ACTIVE, {
  pt: "Ativo",
  en: "Active"
}], [EventState_1.EventState.ENDED, {
  pt: "Terminou",
  en: "Ended"
}], [EventState_1.EventState.ENDINGSOON, {
  pt: "Termina brevemente",
  en: "Ending soon"
}], [EventState_1.EventState.FEATURED, {
  pt: "Destaque",
  en: "Featured"
}], [EventState_1.EventState.HOT, {
  pt: "Concorrido",
  en: "Hot"
}], [EventState_1.EventState.LISTED, {
  pt: "Listado",
  en: "Listed"
}], [EventState_1.EventState.NEW, {
  pt: "Novo",
  en: "New"
}], [EventState_1.EventState.SHORTLY, {
  pt: "Mesmo a terminar",
  en: "Ending"
}], [EventState_1.EventState.SOLD, {
  pt: "Vendido",
  en: "Sold"
}], [EventState_1.EventState.SOON, {
  pt: "Brevemente",
  en: "Soon"
}]]);