"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromContact = exports.convertToContact = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.convertToContact = function (source) {
  var mobilePhoneNumber = modbin_domain_1.getStringValueOrDefault(source["telemovel"], undefined);
  var landPhoneNumber = modbin_domain_1.getStringValueOrDefault(source["telefone"], undefined);
  var faxNumber = modbin_domain_1.getStringValueOrDefault(source["fax"], undefined);
  var email = modbin_domain_1.getStringValueOrDefault(source["email"], undefined);
  return {
    mobilePhoneNumber: mobilePhoneNumber,
    landPhoneNumber: landPhoneNumber,
    faxNumber: faxNumber,
    email: email
  };
};

exports.convertFromContact = function (contact, target) {
  if (contact !== undefined) {
    target.email = contact.email;
    target.telemovel = contact.mobilePhoneNumber;
    target.telefone = contact.landPhoneNumber;
    target.fax = contact.faxNumber;
  }

  return target;
};