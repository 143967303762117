import { UiTestLayout } from "../screens/components/UiTestLayout";
import { UiTestPanel } from "./components/UiTestPanel";
import { UiTestScreenIndex } from "./Index";
import { uiTestService } from "../helpers/WebpackLoaderUiTestService";
import { ViewDefinition } from "modbin-web";
import { LayoutType } from "../../../../components/layout/Layouts.types";
import { GenericComponentsScreenIndex } from "./GenericComponentsIndex";
import { IBaseComponentsScreenIndex } from "./IBaseComponentsIndex";

const testRoutes = uiTestService.getAll().map(t => { return { 
        name:`support:tests:${t.parentId}:${t.id}`,
        component: UiTestPanel,
        title:"Perfil de utilizador",
        route:{
            name:`support:tests:${t.id}`,
            template:`/restricted/support/tests/${t.parentId.replace(/:/g,"/")}/${t.id}`
        },
        store: {
            props: {...t}
        },
        layout:{
            component:LayoutType.TESTING,
            props: {
                type:"fluid"
            }
        },
        parent:`support:tests:index`,
        nameTranslationKey: t.id
    }
});

const ibaseIndexTestRoute = { 
    name:`support:tests:components:ibase:index`,
    component: IBaseComponentsScreenIndex,
    title:"Perfil de utilizador",
    route:{
        name:`support:tests:components:ibase:index`,
        template:`/restricted/support/tests/components/ibase`
    },
    layout:{
        component:LayoutType.TESTING,
        props: {
            type:"fluid"
        }
    },
    parent:"support:tests:index",
    nameTranslationKey:"iBase custom components"
};

const genericComponentsIndexTestRoute = { 
    name:`support:tests:components:generic:index`,
    component: GenericComponentsScreenIndex,
    title:"Perfil de utilizador",
    route:{
        name:`support:tests:components:generic:index`,
        template:`/restricted/support/tests/components/generic`
    },
    layout:{
        component:LayoutType.TESTING,
        props: {
            type:"fluid"
        }
    },
    parent:"support:tests:index",
    nameTranslationKey:"Generic custom components"
};

const indexTestRoute = { 
    name:`support:tests:index`,
    component: UiTestScreenIndex,
    title:"Perfil de utilizador",
    route:{
        name:`support:tests:index`,
        template:`/restricted/support/tests`
    },
    layout:{
        component:LayoutType.TESTING,
        props: {
            type:"fluid"
        }
    },
    parent:"support:index",
    nameTranslationKey:"Support index"
};

export const testViewsDefinitions:ViewDefinition[] = [ibaseIndexTestRoute, indexTestRoute,genericComponentsIndexTestRoute, ...testRoutes];