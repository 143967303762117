"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileInfo = void 0;

var FileSystem_types_1 = require("../model/FileSystem.types");

var Entry_1 = require("./Entry");

var FileInfo =
/** @class */
function (_super) {
  __extends(FileInfo, _super);

  function FileInfo(path, stats, content) {
    var _this = _super.call(this, path, FileSystem_types_1.EntryType.File, stats) || this;

    _this._content = undefined;
    _this._content = content ? content : undefined;
    return _this;
  }

  FileInfo.prototype.getContent = function () {
    return this._content;
  };

  FileInfo.prototype.isContentLoaded = function () {
    throw new Error("Method not implemented.");
  };

  return FileInfo;
}(Entry_1.Entry);

exports.FileInfo = FileInfo;