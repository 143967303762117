"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageNavigationEvent = void 0;

var modbin_domain_1 = require("modbin-domain");

var PageNavigationEvent =
/** @class */
function (_super) {
  __extends(PageNavigationEvent, _super);

  function PageNavigationEvent(from, to, options) {
    var _this = this;

    var metadata = {
      from: from,
      to: to,
      source: undefined,
      event: undefined
    };

    if (options !== undefined) {
      if (options.source !== undefined) {
        metadata.source = options.source;
      }

      if (options.event !== undefined) {
        metadata.event = options.event;
      }
    }

    _this = _super.call(this, {
      type: "PageNavigation",
      name: "PageNavigation",
      metadata: metadata
    }) || this;
    return _this;
  }

  return PageNavigationEvent;
}(modbin_domain_1.DomainEvent);

exports.PageNavigationEvent = PageNavigationEvent;