import React from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { observer } from 'mobx-react';
import { observable } from 'mobx';

export type ImageSource = {
    url:string;
    title?:string;
    metadata?:any;
}

// export const ImagePopup = observer((
//     props:{
//         images:ImageSource[],
//         photoIndex?:number, 
//         visible:boolean,
//         onClose:() => void
//         //onDelete:() => void
//     }) => {
//         const { visible, photoIndex=0 } = props;
//         const images = props.images.map(x => x.url);
//         return (
//           <div>         
    
//             {visible && (
//               <Lightbox
//                 mainSrc={images[photoIndex]}
//                 onCloseRequest={() => props.onClose()}
//                 imageTitle={props.images[photoIndex].title}
//                 nextSrc={images[(photoIndex + 1) % images.length]}
//                 onMovePrevRequest={() => {
//                         props.onIndexChange((photoIndex + images.length - 1) % images.length)
//                 }}
//                 toolbarButtons={[]}
//                 enableZoom={false}
//                 onMoveNextRequest={() => {
//                     //props.onIndexChange((photoIndex + 1) % images.length)
//                 }}
//               />
//             )}
//           </div>
//         );
// });

@observer
export class ImagePopup extends  React.Component<{
    images:ImageSource[],
    visible:boolean,
    onClose:() => void
    //onDelete:() => void
},{}>{
    
    @observable photoIndex = 0;
   
    constructor(props:any){
        super(props);
    }
    
    render(){
        const { visible, images=[] } = this.props;
        const imagesUrls = this.props.images.map(x => x.url);
        const nextSrc = images.length === 0 || images.length === 1 
            ? undefined
            : imagesUrls[(this.photoIndex + 1) % images.length];
        const prevSrc = images.length === 0 || images.length === 1 
            ? undefined
            : imagesUrls[(this.photoIndex + images.length - 1) % images.length];
        return  <div>         
        {visible && (
          <Lightbox
            mainSrc={imagesUrls[this.photoIndex]}
            onCloseRequest={() => this.props.onClose()}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            onMovePrevRequest={() => {
                    this.photoIndex = (this.photoIndex + images.length - 1) % images.length;
            }}
            toolbarButtons={[]}
            enableZoom={false}
            onMoveNextRequest={() => {
                this.photoIndex = ((this.photoIndex + 1) % images.length);
            }}
          />
        )}
      </div>
    }
}