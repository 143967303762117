"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultTimerService = void 0;

var DefaultTimerService =
/** @class */
function () {
  function DefaultTimerService() {}

  DefaultTimerService.prototype.getCurrentDateInUtc = function () {
    return new Date(new Date().toISOString());
  };

  DefaultTimerService.prototype.getCurrentDate = function () {
    return new Date(Date.now());
  };

  return DefaultTimerService;
}();

exports.DefaultTimerService = DefaultTimerService;