import React from 'react';
import { TranslationProps } from "modbin-domain";
import { SellingType } from "ibase-auctions-domain";
import { observer } from 'mobx-react';
import { NotLoggedInView } from './NotLoggedInView';
import { sellingModeIconMapper } from '../../../components/selling-mode/SellingModeIconMapper';

export const BidView = observer((props:TranslationProps & 
    {
        isAuthenticated:boolean,
        biddingView: {
            loggedIn: React.ReactElement
            notLoggedIn?:React.ReactElement
        },
        sellingType:SellingType
    }) => {
    let body = props.biddingView.notLoggedIn !== undefined 
        ? props.biddingView.notLoggedIn!
        : <NotLoggedInView t={props.t}></NotLoggedInView>;
    if(props.isAuthenticated){
        body = props.biddingView.loggedIn;
    }


    return <div className="boxed-widget booking-widget margin-top-35">  
                <div className="margin-bottom-10"> 
                    <i className={sellingModeIconMapper.get(props.sellingType)} 
                        style={{
                                color:"#2ceef0", 
                                fontSize:"35px",
                                zIndex:110,
                                verticalAlign:"middle"
                        }} />
                    <strong style={{marginLeft:"10px", fontSize:"20px"}}>{props.t(`domain:sales.events.type.value.${props.sellingType}`)}</strong>
                </div>
        {body}

    </div>
});
