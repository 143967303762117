"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryTranslations = void 0;

var Category_1 = require("./Category");

var modbin_domain_1 = require("modbin-domain");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[Category_1.Category.CLAIM, {
  pt: "Direito",
  en: "Claim"
}], [Category_1.Category.FORNITURE, {
  pt: "Mobiliario",
  en: "Forniture"
}], [Category_1.Category.HARDWARE, {
  pt: "Equipamentos",
  en: "Hardware"
}], [Category_1.Category.MACHINE, {
  pt: "Máquina",
  en: "Machine"
}], [Category_1.Category.OTHER, {
  pt: "Outro",
  en: "Other"
}], [Category_1.Category.REALESTATE, {
  pt: "Imóvel",
  en: "Real estate"
}], [Category_1.Category.VEHICLE, {
  pt: "Veículo",
  en: "Vehicle"
}]]);
exports.categoryTranslations = {
  pt: {
    name: "Categoria",
    value: translationObject.pt
  },
  en: {
    name: "Category",
    value: translationObject.en
  }
};