import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { ButtonType, ActionButton } from "./ActionButton";
import { SelectInputView } from "../forms/elements/SelectInputView";
import { LoadingButtonLocalized, LoadingButtonLocalizedProps } from "./LoadingButtonLocalized";
import { UiTestDescription, sleepableClick } from "modbin-web";

export const description:UiTestDescription = {
    id:"ActionButtonUiTest",
    groupId:"components",
    description:"lalal",
    title:"ActionButton component",
    parentId:"components:generic:index"
};

const LoadingButtonTestView = (props:{title:string} & LoadingButtonLocalizedProps) => {
    const {title,...rest} = props;
    return <div className="col-md-4">
        <h5>{title}</h5>
        <LoadingButtonLocalized {...rest} />
    </div>;
}

// const LocalizedButtonTestView = (props:{title:string} & {type:}) => {
//     const {title,...rest} = props;
//     return <div className="col-md-4">
//         <h5>{title}</h5>
//         <LoadingButton {...rest} />
//     </div>;
// }

export const createButtonTypeOptions = ()=>{
    const options = Object.entries(ButtonType).map((x:any) => {
        return { value:x[1], text:(ButtonType as any)[x[0]]};
    });
    return options;
}

@observer 
class LocalizedLoadingButtonsSection extends Component{
    @observable type:ButtonType = ButtonType.SAVE;

    render(){
        return <div className="container">
            <div className="row">
                <h2>Types of loading and localized buttons</h2>
                <div className="col-md-6">
                    
                    <SelectInputView
                        isLoading={false}
                        items={createButtonTypeOptions()}
                        label="Size"
                        onChange={(v:any)=> {this.type = v}}
                        key="selector:size"
                        selected={this.type as any}
                    ></SelectInputView>
                </div>
            </div>
            <div className="row">
                <ActionButton type={this.type} onClick={()=>{return sleepableClick()}} />
            </div>
        </div>
    }
}

@observer
export class ActionButtonUiTest extends Component<TranslationProps,{}>{
    
    @observable 

    logResult(result:any){
        console.log(result);
    }
    
    render(){
        return <div>
            <div className="col-md-12">
                <h2>Loading buttons</h2>
                <LoadingButtonTestView title="With loading text only" textKey="save" loadingTextKey="saveLoading" onClick={()=>{return sleepableClick()}}></LoadingButtonTestView>
                <LoadingButtonTestView title="With loading text and icon" textKey="save" loadingTextKey="saveLoading" icon="fa  fa-file-text-o" onClick={()=>{return sleepableClick()}}></LoadingButtonTestView>
                <LoadingButtonTestView title="With loading text and loading icon" textKey="save" loadingTextKey="saveLoading" showLoadingIcon={true} onClick={()=>{return sleepableClick()}}></LoadingButtonTestView>
                <LoadingButtonTestView title="With loading text, icon and loading icon" textKey="save" loadingTextKey="saveLoading" icon="fa  fa-file-text-o" showLoadingIcon={true} onClick={()=>{return sleepableClick()}}></LoadingButtonTestView>
            </div>
            <div>
                
                <LocalizedLoadingButtonsSection></LocalizedLoadingButtonsSection>
            </div>
        </div>;
    }
}