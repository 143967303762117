import React from "react";
import { inject, observer } from "mobx-react";
import { ViewDefinition } from "modbin-web";
import { isNotNullOrEmptyString } from "modbin-domain";
import { MainAppViewStore } from "../../../../configuration/temp/web/stores/MainAppViewStore";


@inject("mainAppViewStore") @observer
export class NavigationLink extends React.Component<React.HTMLAttributes<HTMLElement>& { to:string, state?:any, children:any, mainAppViewStore?:MainAppViewStore},{}> {

    onClick(ev:any){
        ev.preventDefault();
        ev.stopPropagation();
        this.props.mainAppViewStore!.navigateTo(this.props.to,{ state: this.props.state});
    }
    render(){
        const {className=""} = this.props;
        const activeClass = this.props.mainAppViewStore!.currentView!.name === this.props.to
        ? `${className} current`
        : `${className}`
        const urlPath = this.props.mainAppViewStore!.getViewUrlPath(this.props.to, this.props.state);
        const href = isNotNullOrEmptyString(urlPath, true)
            ? urlPath
            : "# ";
        return <a className={activeClass} href={href} onClick={(ev) => this.onClick(ev)}>{this.props.children}</a>;
    }
}

@inject("mainAppViewStore") @observer
export class NavigationBackLink extends React.Component<React.HTMLAttributes<HTMLElement>& { mainAppViewStore?:MainAppViewStore},{}> {
    onClick(ev:any){
        ev.preventDefault();
        this.props.mainAppViewStore!.navigateBack();
    }
    render(){
        const {mainAppViewStore, ...rest} = this.props;
        return <a {...rest} onClick={(ev) => this.onClick(ev)}>{this.props.children}</a>
    }
}

@inject("mainAppViewStore") @observer
export class ReturnableNavigationLink extends React.Component<React.HTMLAttributes<HTMLElement>& { to:string, children:any, mainAppViewStore?:MainAppViewStore},{}> {

    isReturnableLink(currentViewDefinition:ViewDefinition):boolean{
        const notReturnableViews = [
            "public:sessions:register",
            "public:sessions:register-completed", 
            "public:sessions:activation",
            "public:sessions:password-recovery-confirmation",
            "public:sessions:password-recovery-request",
            "public:sessions:noactivation",
            "public:sessions:reactivation"
        ];
        const viewName = currentViewDefinition.name;
        return notReturnableViews.findIndex(x => x === viewName) === -1; 
    }

    render(){
        const currentViewDefinition = this.props.mainAppViewStore!.currentView;
        const currentState = currentViewDefinition!.store !== undefined && currentViewDefinition!.store!.getState !== undefined
            ? currentViewDefinition!.store!.getState()
            : undefined;
        const url = this.isReturnableLink(currentViewDefinition!)
            ? this.props.mainAppViewStore!.createViewRouteUrl(currentViewDefinition!.name,{ state:currentState, escape:true})
            : "";
        return <NavigationLink to={this.props.to} state={{url:url}}>
                {this.props.children}
            </NavigationLink>
        }
}