import {observable} from "mobx";
import { SellingEventStore } from "../../business/events/Store";
import { clockEndDateSorter } from "../../business/components/details-common/Helpers";
import { BaseStore, IStore } from "modbin-web";
const moment = require("moment")

export class HomeViewStore extends BaseStore implements IStore{
    
    @observable isLoading:boolean = false;
    @observable featuredEvents:string[] = [];

    constructor(private sellingEventStore:SellingEventStore) {
        super();
        this.initialize();
    }
    
    initialize(){
        return Promise.resolve();
    }

    getFeaturedEvents(){
        return this.sellingEventStore
            .getFeaturedEvents()
            .sort(clockEndDateSorter);
    }

    reset(){
        this.isLoading = false;
        this.featuredEvents = [];
        return Promise.resolve();
    }
}