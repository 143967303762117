import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { MainAppViewStore } from '../../configuration/stores/MainAppViewStore';

@inject("mainAppViewStore")
export class ScreensSupportIndex extends Component<{mainAppViewStore?:MainAppViewStore},{}> {

    render(){
        const testsViewLink = <div><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:tests:index",{event:ev})}>Navigate to components sandbox</a></div>
            
        const propertiesViewLink = <div><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:properties",{event:ev})}>Navigate to properties listing</a></div>
        const adminViewLink = <div><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:administration",{event:ev})}>Navigate to administration listing</a></div>

        return (<div className="container margin-top-50">
                <h1>Development support utilities and information</h1>
                <p>
                    This page is intended to help developers during development and debugging.
                </p>
                <p>
                    Please see the following sections to find out what is available in more detail.
                </p>

                <h3 className="margin-top-40">Configuration properties</h3>
                <p>
                    Use this section to check which properties are being used to configure the current website instance.
                </p>
                {propertiesViewLink}  

                <h3 className="margin-top-40">Component library sandbox</h3>
                <p>
                    Use this section to try and test visual web components used across this website instance.
                </p>
                {testsViewLink}

                <h3 className="margin-top-40">Component library sandbox</h3>
                <p>
                    Use this section to try and test visual web components used across this website instance.
                </p>
                {adminViewLink}
            </div>
        );
    }
}