"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventState = void 0;
var EventState;

(function (EventState) {
  EventState["NEW"] = "new";
  EventState["LISTED"] = "listed";
  EventState["ACTIVE"] = "active";
  EventState["ENDED"] = "ended";
  EventState["SOLD"] = "sold";
  EventState["SOON"] = "soon";
  EventState["SHORTLY"] = "shortly";
  EventState["FEATURED"] = "featured";
  EventState["HOT"] = "hot";
  EventState["ENDINGSOON"] = "endingsoon";
})(EventState = exports.EventState || (exports.EventState = {}));