import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { ProfileForm } from "./ProfileForm";
import { emptyUser } from "./EmptyUser";
import { ButtonType } from "../../../../components/buttons/ActionButton";
import { UiTestDescription, sleepableClick } from "modbin-web";

export const description:UiTestDescription = {
    id:"ProfileFormUiTest",
    groupId:"components",
    description:"lalal",
    title:"ProfileForm component",
    parentId:"components"
};

export class ProfileFormUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:any){
        console.log(result);
    }

    handleClick(data:any){
        this.logResult(data);
        return sleepableClick();
    }

    render(){
        return <ProfileForm user={emptyUser} buttonType={ButtonType.REGISTER} onSave={(data:any)=> {return this.handleClick(data)}}/>;
    }
}