import { createLocalizedNotification } from "../../../../components/notifications/LocalizedNotificationView"
import { NotificationType } from "modbin-web";

export enum OperationState {
    UNKNOWN = "unknown",
    SAVING = "saving",
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error"
} 

export type OperationStateResult = {
    state:OperationState;
    notification?:JSX.Element;
    extra?:JSX.Element;
    name?:string;
}

export const loadingState:OperationStateResult = {
    state: OperationState.LOADING
}

export const unknownState:OperationStateResult = {
    state: OperationState.UNKNOWN
}

export const activationSuccessState:OperationStateResult = {
    state:OperationState.SUCCESS,
    notification:createLocalizedNotification({
        type:NotificationType.SUCCESS,
        headerKey:"screens:public.sessions.activationSuccessfulTitle",
        textKey:"screens:public.sessions.activationSuccessfulText"
    })
}

export const invalidActivationParametersState:OperationStateResult = {
    state: OperationState.ERROR, 
    notification: createLocalizedNotification({
        type:NotificationType.ERROR,
        headerKey:"common:words.unexpectedErrorTitle",
        textKey:"screens:public.sessions.activationInvalidValidationParameters"
    })
}

export const reactivationSuccessState:OperationStateResult = {
    state:OperationState.SUCCESS,
    notification:createLocalizedNotification({
        type:NotificationType.SUCCESS,
        headerKey:"screens:public.sessions.reactivationSuccessfulTitle",
        textKey:"screens:public.sessions.reactivationSuccessfulText"
    })
}

export const invalidPasswordRecoveryParametersState:OperationStateResult = {
    state: OperationState.ERROR, 
    notification: createLocalizedNotification({
        type:NotificationType.ERROR,
        headerKey:"common:words.unexpectedErrorTitle",
        textKey:"screens:public.sessions.passwordRecoveryInvalidRecoveryParameters"
    })
}

export const requestPasswordRecoverySuccessState:OperationStateResult = {
    state:OperationState.SUCCESS,
    notification:createLocalizedNotification({
        type:NotificationType.SUCCESS,
        headerKey:"screens:public.sessions.requestPasswordRecoverySuccessfulTitle",
        textKey:"screens:public.sessions.requestPasswordRecoverySuccessfulText"
    })
}

export const passwordRecoverySuccessState:OperationStateResult = {
    state:OperationState.SUCCESS,
    notification:createLocalizedNotification({
        type:NotificationType.SUCCESS,
        headerKey:"screens:public.sessions.passwordRecoverySuccessfulTitle",
        textKey:"screens:public.sessions.passwordRecoverySuccessfulText"
    })
}

export type RegistrationState = "unknown"|"first"|"second"|"saving"|"completed"|"error";
export type ActivationState = "unknown"|"loading"|"completed"|"error";

