"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEventDateStateFromSellingDates = exports.getClockDatesFromSellingDates = exports.getEventDateStateFromDates = void 0;

var Shared_types_1 = require("../../shared/Shared.types");

var moment = require("moment");

exports.getEventDateStateFromDates = function (start, end, now, endingTimeout) {
  var resultNow = now !== undefined ? now : moment();
  var resultEndingTimeout = endingTimeout !== undefined ? endingTimeout : 10000;

  if (moment(start).isAfter(resultNow)) {
    return Shared_types_1.SellingEventDateState.NOTSTARTED;
  }

  var endTime = moment(end);
  var diffInSeconds = endTime.diff(resultNow, "seconds");

  if (diffInSeconds > 86399) {
    return Shared_types_1.SellingEventDateState.DAYS;
  }

  if (diffInSeconds > 3599) {
    return Shared_types_1.SellingEventDateState.DAY;
  }

  if (diffInSeconds > 299) {
    //console.log(diffInSeconds)
    return Shared_types_1.SellingEventDateState.HOUR;
  }

  if (diffInSeconds > 0) {
    return Shared_types_1.SellingEventDateState.MINUTE;
  }

  if (moment(end).add(resultEndingTimeout, "milliseconds").isAfter(resultNow)) {
    return Shared_types_1.SellingEventDateState.VALIDATING;
  } //console.log("end2", moment(end));


  if (moment(end).isBefore(resultNow)) {
    return Shared_types_1.SellingEventDateState.ENDED;
  }

  return Shared_types_1.SellingEventDateState.ENDED;
};

exports.getClockDatesFromSellingDates = function (dates, now) {
  var startDate = dates.startDate,
      endDate = dates.endDate,
      extendedDate = dates.extendedDate,
      serverTime = dates.serverTime;
  var resultNow = now !== undefined ? now : serverTime !== undefined ? serverTime : new Date(Date.now());

  if (extendedDate !== undefined && extendedDate !== null) {
    if (endDate !== undefined && moment(endDate).isAfter(extendedDate)) {} else {
      endDate = extendedDate;
    }
  }

  if (endDate === undefined || endDate === null) {
    endDate = resultNow;
  }

  return {
    start: startDate,
    end: endDate,
    now: resultNow
  };
};

exports.getEventDateStateFromSellingDates = function (dates, endingTimeout) {
  var clockDate = exports.getClockDatesFromSellingDates(dates);
  return exports.getEventDateStateFromDates(clockDate.start, clockDate.end, clockDate.now, endingTimeout);
};