import { Component } from "react";
import { TranslationProps } from "modbin-domain";
import React from "react";

export class ScreensErrorsUnauthorized extends Component<TranslationProps,{}> {
    render() {
        
        return (
            <div className="notification warning margin-top-30">
				<p><span>No authorization to see this page!</span></p>
				<p><span>Sorry... you don´t have the necessary role to see this page.</span></p>
			</div>
        );
    }
}