"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultWebApplicationViewModule = void 0;

var DefaultWebApplicationViewModule =
/** @class */
function () {
  function DefaultWebApplicationViewModule(options) {
    this.navigator = options.navigator;
    this.provider = options.provider;
  }

  DefaultWebApplicationViewModule.prototype.getNavigator = function () {
    return this.navigator;
  };

  DefaultWebApplicationViewModule.prototype.getProvider = function () {
    return this.provider;
  };

  return DefaultWebApplicationViewModule;
}();

exports.DefaultWebApplicationViewModule = DefaultWebApplicationViewModule;