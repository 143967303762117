"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sleep = void 0;

exports.sleep = function (timeout) {
  var resultTimeout = timeout !== undefined ? timeout * 1000 : 3000;
  return new Promise(function (resolve) {
    return setTimeout(resolve, resultTimeout);
  });
};