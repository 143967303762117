import React,  { Component } from "react";
import { BaseLayout } from "./BaseLayout";
import { NavigationHeader } from "./header/NavigationHeader";

export class MenuLayout extends Component<{type?:"fluid"|"fixed"},{}> {

	render() {
        const { type, ...rest} = this.props;
		return (
                <BaseLayout header={<NavigationHeader></NavigationHeader>} type={type} {...rest}>
                    {this.props.children}
                </BaseLayout>
		);
	}
}