import React, { Component, Fragment } from 'react';
import { FeaturedView } from '../../business/events/components/FeaturedView';
import { TranslationProps } from "modbin-domain";
import { inject, observer } from 'mobx-react';
import { MainAppViewStore } from '../../../configuration/stores/MainAppViewStore';
import { Category } from 'ibase-auctions-domain';
import { HomeViewStore } from './ViewStore';
import { LoadingView } from '../../../components/loading/LoadingView';
import { HomeImageSwitcherView } from './HomeImageSwitcher';
import { LayoutStore } from './LayoutStore';
// import { MenuLayout } from '../../../components/layout/MenuLayout';
// import { LayoutType } from '../../../components/layout/Layouts.types';

const LookingForSection = inject("mainAppViewStore")((props:{t:any, mainAppViewStore?:MainAppViewStore})=> {
    const getTranslation = (key:string) => props.t(`screens:public.home.${key}`); 

    return <section className="fullwidth margin-top-105" data-background-color="#f7f7f7">
	    <h3 className="headline-box">{getTranslation("lookingForTitle")}</h3>
	<div className="container">
		<div className="row">
			<div className="col-md-3 col-sm-6">
                <div className="icon-box-1" 
                    onClick={(ev:any)=> {
                        props.mainAppViewStore!.navigateTo("business:lots",{state:{
                            category:Category.REALESTATE,
                            subCategory:"1"}})
                        }}
                >
					<div className="icon-container">
						<i className="im im-icon-Office"></i>
					</div>
					<h3>{getTranslation("lookingForApartments")}</h3>
				</div>
			</div>
			<div className="col-md-3 col-sm-6">
                <div className="icon-box-1"
                        onClick={(ev:any)=> {
                            props.mainAppViewStore!.navigateTo("business:lots",{state:{
                                category:Category.REALESTATE,
                                subCategory:"6"}})
                            }}
                    >
					<div className="icon-container">
						<i className="im im-icon-Home-2"></i>
					</div>
					<h3>{getTranslation("lookingForHouses")}</h3>
				</div>
			</div>
            <div className="col-md-3 col-sm-6"
                    onClick={(ev:any)=> {
                        props.mainAppViewStore!.navigateTo("business:lots",{state:{
                            category:Category.REALESTATE,
                            subCategory:"10"}})
                        }}
                >
				<div className="icon-box-1">
					<div className="icon-container">
						<i className="im im-icon-Landscape"></i>
					</div>
					<h3>{getTranslation("lookingForLands")}</h3>
				</div>
			</div>
			<div className="col-md-3 col-sm-6">
                <div className="icon-box-1"
                    onClick={(ev:any)=> {
                        props.mainAppViewStore!.navigateTo("business:lots",{state:{
                            category:Category.VEHICLE,
                            subCategory:"0"}})
                        }}
                >
					<div className="icon-container">
						<i className="im im-icon-Car-3"></i>
					</div>
					<h3>{getTranslation("lookingForVehicles")}</h3>
				</div>
			</div>		
		</div>
	</div>
</section>
});

@inject("homeViewStore","mainAppViewStore") @observer
export class ScreensHomeIndex extends Component<TranslationProps & {homeViewStore?:HomeViewStore, mainAppViewStore?:MainAppViewStore, layoutStore?:LayoutStore},{}> {
    render() {
         
        const featuredItems = this.props.homeViewStore!.getFeaturedEvents();
        return (
            <Fragment>
            <HomeImageSwitcherView t={this.props.t}></HomeImageSwitcherView>   
            <div className="container">
                <LoadingView isLoading={this.props.homeViewStore!.isLoading}
                 showFallback={featuredItems.length === 0} 
                 //showFallback={0 === 0} 
                 fallbackView={<Fragment></Fragment>}>
                    <FeaturedView 
                        items={featuredItems as any} 
                        t={this.props.t}
                        //items={[]} 
                        onClick={(id:string)=>{this.props.mainAppViewStore!.navigateTo("business:events:details",{state:{sellingEventId:id}})}}
                    />
                </LoadingView>
            </div>
            <LookingForSection t={this.props.t}></LookingForSection>
            </Fragment>
        );
    }
}
