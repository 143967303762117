import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { ScreensSessionsNoActivationView } from "./NoActivation";
import { UiTestDescription, sleepableConsoleLogger } from "modbin-web";

export const description:UiTestDescription = {
    id:"NoActivationViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"No activation page",
    parentId:"components"
};

export class NoActivationViewUiTest extends Component<TranslationProps,{}>{
        
    render(){
        return <ScreensSessionsNoActivationView
            onReactivate={()=>{return sleepableConsoleLogger({})}}
            t={this.props.t}
        />;
    }
}