import { ScreensBusinessEventsIndex } from "./events/Index";
import { ScreensBusinessEventsDetails } from "./events/screens/Details";
import { ScreensBusinessLotsDetails } from "./lots/screens/details/Index";
import { ScreensBusinessLotsIndex } from "./lots/Index";
import { ViewDefinition } from "modbin-web";

const eventsIndexView = {
    name:"business:events",
    component: ScreensBusinessEventsIndex,
    title:"Oportunidades",
    //store:appRoot.stores.sellingEventIndexViewStore,
    storeName:"sellingEventIndexViewStore",
    route:{
        name:"busines:events",
        template:"/events"
    },
    layout:{
        // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home"
};

const eventsDetailsView = {
    name:"business:events:details",
    component: ScreensBusinessEventsDetails,
    title:"Detalhes de leilão",
    //store: appRoot.stores.sellingEventDetailsViewStore,
    storeName: "sellingEventDetailsViewStore",
    route:{
        name:"business:events:details",
        template:"/events/:sellingEventId",
        parametersToStateMapper:{"0":"sellingEventId"},
       // stateToParametersMapper:{"selected":"eventId"}
    },
    layout:{
        // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"business:events"
};

const lotsDetailsView:ViewDefinition = {
    name:"business:lots:details",
    component: ScreensBusinessLotsDetails,
    title:"Detalhe de lote",
    //store: appRoot.stores.lotDetailsViewStore,
    storeName: "lotDetailsViewStore",
    route:{
        name:"business:lots:details",
        template:"/events/:eventId/lots/:lotId",
        parametersToStateMapper:{"0":"eventId","1":"lotId"},
    //    stateToParametersMapper:{"selected":"lotId"}
    },
    layout:{
        // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"business:lots"
}

const lotsIndexView = {
    name:"business:lots",
    component: ScreensBusinessLotsIndex,
    title:"Listagem de lotes",
    //store: appRoot.stores.lotIndexViewStore,
    storeName: "lotIndexViewStore",
    route:{
        name:"business:lots",
        template:"/lots",
        parametersToStateMapper:{
            "query":"term",
            "min":"minimumValue",
            "max":"maximumValue",
            "cat":"category",
            "subcat":"subCategory",
            "type":"sellingType",
            "region":"region",
            "county":"county",
            "parish":"parish"
        },
        stateToParametersMapper:{
            "term":"query",
            "minimumValue":"min",
            "maximumValue":"max",
            "category":"cat",
            "subCategory":"subcat",
            "sellingType":"type",
            "region":"region",
            "county":"county",
            "parish":"parish"
        }
    },
    layout:{
        // component:MenuLayout,
        props:{type:"fluid"}
    },
    parent:"public:home"
}

export const businessViewsDefinitions:ViewDefinition[] = [
    eventsIndexView,
    eventsDetailsView,
    lotsDetailsView,
    lotsIndexView
]