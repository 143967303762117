import React from "react"
import { NotificationType } from "modbin-web";
import { LocalizedNotificationView } from "../notifications/LocalizedNotificationView";

export const UnexpectedErrorMessage = ()=>{
    return <LocalizedNotificationView 
        type={NotificationType.ERROR} 
        headerKey="common:words.unexpectedErrorTitle"
        textKey="common:words.unexpectedErrorMessage">
    </LocalizedNotificationView>;
}
