"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntryType = void 0;
var EntryType;

(function (EntryType) {
  EntryType["File"] = "File";
  EntryType["Directory"] = "Directory";
})(EntryType = exports.EntryType || (exports.EntryType = {}));