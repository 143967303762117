import React from "react";
import { observer } from "mobx-react";
import { LoadingButtonLocalized } from "./LoadingButtonLocalized";

export enum ButtonType {
    SAVE="save",
    SUBSCRIBE="subscribe",
    BID="bid",
    REGISTER="register",
    SEND="send",
    LOGIN="login",
    GENERATEPROPOSAL="generateProposal",
    CONTINUE="continue",
    Donwload="download",
    RELOAD="reload"
} 

export const ActionButton = observer((props:{onClick:()=>Promise<void>,type:ButtonType} &  React.HTMLAttributes<HTMLButtonElement>)=>{
    const {type, ...rest} = props;
    return <LoadingButtonLocalized 
        textKey={type}
        loadingTextKey={`${type}Loading`}
        {...rest}
    />
});