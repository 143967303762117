import { DefaultStoreFactory } from "./IStoreFactory";
import { IViewDefinitionProvider, IViewNavigator, IStore } from "modbin-web";
import { IIocContainer, IKeyedObject, IBusinessEventService, IUserNotificationService, IRepository, ITimerService, ILogger } from "modbin-domain";
import { MainAppViewStore } from "./MainAppViewStore";
import { IMainAppViewStore } from "./IMainAppViewStore";
import { AddressStore } from "../../../../screens/business/components/search/AddressStore";
import { IAddressService, ISubCategoryRepository, SellingEvent, DocumentFile, ISessionService, User, Banner, NewsletterSubscription, NewsletterService } from "ibase-auctions-domain";
import { SearchStore } from "../../../../screens/business/components/search/SearchStore";
import { LotStore } from "../../../../screens/business/lots/Store";
import { ILotService } from "ibase-auctions-api";
import { LotIndexViewStore } from "../../../../screens/business/lots/ViewStore";
import { LotDetailsViewStore } from "../../../../screens/business/lots/screens/details/ViewStore";
import { IContextProvider } from "../application/ContextProvider";
import { SellingEventStore } from "../../../../screens/business/events/Store";
import { SellingEventIndexViewStore } from "../../../../screens/business/events/SellingEventIndexViewStore";
import { WebConfiguration } from "../../../Config.types";
import { SellingEventDetailsViewStore } from "../../../../screens/business/events/screens/ViewStore";
import { HomeViewStore } from "../../../../screens/public/home/ViewStore";
import { SessionStore } from "../../../../screens/public/sessions/SessionStore";
import { IRestApiClient } from "modbin-api";
import { LayoutStore } from "../../../../screens/public/home/LayoutStore";
import { SessionViewStore } from "../../../../screens/public/sessions/ViewStore";
import { ActivationViewStore } from "../../../../screens/public/sessions/screens/activation/ActivationViewStore";
import { LoginViewStore } from "../../../../screens/public/sessions/screens/LoginViewStore";
import { PasswordRecoveryViewStore } from "../../../../screens/public/sessions/screens/password-recovery/PasswordRecoveryViewStore";
import { NewsletterViewStore } from "../../../../screens/administration/newsletter/ViewStore";
import { ProfileBidsViewStore } from "../../../../screens/private/profile/screens/bids/ViewStore";
import { IServerTimeService } from "../../services/ServerTimeService";
import { ProfileViewStore } from "../../../../screens/private/profile/ViewStore";

type StoreCreationOptions = {
    container:IIocContainer,
    viewNavigator:IViewNavigator,
    viewProvider:IViewDefinitionProvider,
    stores:any,
    mainAppViewStore:IMainAppViewStore
}

export class IBaseStoreFactory extends DefaultStoreFactory {
    
    createMainViewStore(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider):IMainAppViewStore{
        return new MainAppViewStore(viewNavigator,viewProvider);
    }

    private createAddressAndSearchStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container} = options;
        const addressStore = new AddressStore(container.resolve<IAddressService>("addressService"),container.resolve<ILogger>("logger"));
        const searchViewStore = new SearchStore(container.resolve<ISubCategoryRepository>("subCategoryRepository"),addressStore);
        return {
            addressStore,
            searchViewStore
        }
    }

    private createLotsStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, stores, mainAppViewStore} = options;
        
        const lotStore = new LotStore(container.resolve<ILotService>("lotService"), container.resolve<IServerTimeService>("timeService"),container.resolve<ILogger>("logger"));
        const lotIndexViewStore = new LotIndexViewStore(stores.searchViewStore,lotStore, container.resolve<IBusinessEventService>("businessEventService"));
        const lotDetailsViewStore = new LotDetailsViewStore(
            lotStore,
            container.resolve<ILotService>("lotService"),
            container.resolve<IUserNotificationService>("userNotificationService"),
            container.resolve<IContextProvider>("contextProvider"),
            stores.sessionStore,
            mainAppViewStore,
            container.resolve<WebConfiguration>("webConfig").numberOfDaysToShowPastEvents,
            container.resolve<ILogger>("logger")
        );

        return {
            lotStore,
            lotIndexViewStore,
            lotDetailsViewStore
        }
    }

    private createSellingEventsStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, stores} = options;
        
        const sellingEventStore = new SellingEventStore(
            stores.lotStore, 
            container.resolve<IRepository<SellingEvent>>("sellingEventRepository"),
            container.resolve<IRepository<DocumentFile>>("sellingEventFileRepository"),
            container.resolve<ITimerService>("timeService"),
            container.resolve<ILogger>("logger"));
        const sellingEventIndexViewStore = new SellingEventIndexViewStore(sellingEventStore,container.resolve<WebConfiguration>("webConfig").numberOfDaysToShowPastEvents);
        const sellingEventDetailsViewStore = new SellingEventDetailsViewStore(sellingEventStore, stores.lotStore);

        return {
            sellingEventStore,
            sellingEventIndexViewStore,
            sellingEventDetailsViewStore
        }
    }

    private createLayoutsStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, stores} = options;
        const homeViewStore = new HomeViewStore(stores.sellingEventStore);
        const layoutStore = new LayoutStore(
            container.resolve<IRepository<Banner>>("bannerRepository"), 
            container.resolve<string>("homeDefaultBannerUrl"),
            container.resolve<ILogger>("logger"));
        return {
            homeViewStore,
            layoutStore
        };
    }

    private createSessionsStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, mainAppViewStore} = options; //stores
        
        const sessionStore = new SessionStore(
            container.resolve<ISessionService>("sessionService"),
            container.resolve<IRepository<User>>("userRepository"),
            container.resolve<IRestApiClient>("apiClient"),
            container.resolve<IBusinessEventService>("businessEventService"),
            container.resolve<IContextProvider>("contextProvider"),
            container.resolve<ILogger>("logger")
        )

        const logger = container.resolve<ILogger>("logger");
      
        const sessionViewStore = new SessionViewStore(
        mainAppViewStore,
            sessionStore,
            container.resolve<IUserNotificationService>("userNotificationService"));
        const activationViewStore = new ActivationViewStore(container.resolve<ISessionService>("sessionService"),logger);
        const loginViewStore = new LoginViewStore(mainAppViewStore,sessionStore,container.resolve<ISessionService>("sessionService"),container.resolve<IUserNotificationService>("userNotificationService"),container.resolve<IBusinessEventService>("businessEventService"),logger);
        const passwordRecoveryViewStore = new PasswordRecoveryViewStore(container.resolve<ISessionService>("sessionService"),logger);
        return {
            sessionStore,
            sessionViewStore,
            activationViewStore,
            loginViewStore,
            passwordRecoveryViewStore
        }
    }

    private createUsersStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, stores} = options;
        
        const profileBidsViewStore = new ProfileBidsViewStore(stores.lotStore,stores.sessionViewStore, stores.sessionStore);
        const profileViewStore = new ProfileViewStore(stores.sessionStore, container.resolve<IRepository<User>>("userRepository"));
        return {profileBidsViewStore, profileViewStore};
    }

    private createNewslettersStores(options:StoreCreationOptions):IKeyedObject<IStore>{
        const {container, stores} = options;
        
        const newsletterViewStore = new NewsletterViewStore(
            container.resolve<IRepository<NewsletterSubscription>>("newsletterRepository"),
            container.resolve<NewsletterService>("newsletterService"),
            container.resolve<IUserNotificationService>("userNotificationService"),
            container.resolve<IRestApiClient>("apiClient"),
            stores.sessionStore,
            container.resolve<ILogger>("logger"));
        return {newsletterViewStore};
    }

    createStores(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider, mainAppViewStore:IMainAppViewStore):IKeyedObject<IStore>{
        let stores = {};
        const options = {
            container:container,
            viewNavigator:viewNavigator,
            viewProvider:viewProvider,
            stores:stores,
            mainAppViewStore:mainAppViewStore
        }
        const addressAndSearchStores = this.createAddressAndSearchStores(options);
        options.stores = {...options.stores, ...addressAndSearchStores};       

        const sessionsStores = this.createSessionsStores(options);
        options.stores = {...options.stores, ...sessionsStores};

        const lotsStores = this.createLotsStores(options);
        options.stores = {...options.stores, ...lotsStores};

        const sellingEventsStores = this.createSellingEventsStores(options);
        options.stores = {...options.stores, ...sellingEventsStores};

        const layoutsStores = this.createLayoutsStores(options);
        options.stores = {...options.stores, ...layoutsStores};

        const usersStores = this.createUsersStores(options);
        options.stores = {...options.stores, ...usersStores};
    
        const newslettersStores = this.createNewslettersStores(options);
        options.stores = {...options.stores, ...newslettersStores};

        return options.stores;
    }
}
