"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areValidParameters = exports.areRequiredParametersPresent = exports.isValidParameter = exports.isValidParameterValue = exports.getEndpointParametersDefinitions = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.getEndpointParametersDefinitions = function (parameters) {
  var result = new Map();
  var required = [];
  parameters.forEach(function (x) {
    if (modbin_domain_1.isNotNullOrEmptyString(x)) {
      result.set(x, undefined);
    } else {
      var parameterDefinition = x;
      result.set(parameterDefinition.name, parameterDefinition.values.map(function (x) {
        return x + "";
      }));

      if (parameterDefinition.required !== undefined && parameterDefinition.required === true) {
        required.push(parameterDefinition.name);
      }
    }
  });
  return {
    required: required,
    definitions: result
  };
};

exports.isValidParameterValue = function (value, allowedValues) {
  return allowedValues === undefined || allowedValues.indexOf(value + "") > -1;
};

exports.isValidParameter = function (name, value, parameters) {
  if (!parameters.has(name)) {
    return false;
  }

  return exports.isValidParameterValue(value, parameters.get(name));
};

exports.areRequiredParametersPresent = function (required, queryParameters) {
  var result = true;

  for (var i = 0; i < required.length; i++) {
    if (!queryParameters.includes(required[i])) {
      result = false;
      break;
    }
  }

  return result;
};

exports.areValidParameters = function (queryParameters, endpoint) {
  var parameters = endpoint.parameters;

  if (parameters === undefined || parameters === null || parameters.length === 0) {
    return true;
  }

  if (queryParameters === undefined || queryParameters === null) {
    return true;
  }

  var parametersDefinitions = exports.getEndpointParametersDefinitions(endpoint.parameters);
  var keys = Object.keys(queryParameters);

  if (!exports.areRequiredParametersPresent(parametersDefinitions.required, keys)) {
    return false;
  }

  var result = true;

  for (var i = 0; i < keys.length; i++) {
    var parameterName = keys[i];

    if (!exports.isValidParameter(parameterName, queryParameters[parameterName], parametersDefinitions.definitions)) {
      result = false;
      break;
    }
  }

  return result;
};