"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultObjectMapper = void 0;

var Activator_1 = require("./Activator");

function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array;
}

var DefaultObjectMapper =
/** @class */
function () {
  function DefaultObjectMapper(options) {
    this.maps = new Map();

    if (options === undefined || options.definitions === undefined && options.profiles === undefined) {
      throw new Error("Object mapper cannot be initialized without type definitions.");
    }

    this.addTypeDefinitions(options.definitions);
    this.addMapProfiles(options.profiles);
  }

  DefaultObjectMapper.prototype.getTypeMappingKey = function (sourceType, destinationType) {
    return sourceType + ":::" + destinationType;
  };

  DefaultObjectMapper.prototype.addMapProfiles = function (profiles) {
    if (profiles !== undefined && profiles.length > 0) {
      var definitions = [].concat.apply([], profiles.map(function (x) {
        return x.definitions;
      }));
      this.addTypeDefinitions(definitions);
    }
  };

  DefaultObjectMapper.prototype.addTypeDefinitions = function (definitions) {
    var _this = this;

    if (definitions !== undefined) {
      definitions.forEach(function (x) {
        _this.maps.set(_this.getTypeMappingKey(x.sourceType, x.targetType), x);
      });
    }
  };

  DefaultObjectMapper.prototype.validateMappingExists = function (mappingKey) {
    if (!this.maps.has(mappingKey)) {
      throw new Error("Type " + mappingKey + " is not defined");
    }
  };

  DefaultObjectMapper.prototype.internalMap = function (sourceObj, definition, targetConstructor) {
    return __awaiter(this, void 0, void 0, function () {
      var result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , definition.converter(sourceObj, this)];

          case 1:
            result = _a.sent(); //console.log("DefaultOBjectMapper:internalMap:constructor",targetConstructor);
            //console.log("DefaultOBjectMapper:internalMap:result",result);

            if (targetConstructor !== undefined) {
              result = Activator_1.activator(targetConstructor, result); //console.log("DefaultOBjectMapper:internalMap:constructorResult",result);
            }

            return [2
            /*return*/
            , Promise.resolve(result)];
        }
      });
    });
  };

  DefaultObjectMapper.prototype.internalMapSingleObject = function (sourceObj, options) {
    return __awaiter(this, void 0, void 0, function () {
      var source, target, targetConstructor, mappingKey, mappingDefinition, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            source = options.source, target = options.target, targetConstructor = options.targetConstructor;
            mappingKey = this.getTypeMappingKey(source, target);
            this.validateMappingExists(mappingKey);
            mappingDefinition = this.maps.get(mappingKey);
            return [4
            /*yield*/
            , this.internalMap(sourceObj, mappingDefinition, targetConstructor)];

          case 1:
            result = _a.sent();
            return [2
            /*return*/
            , Promise.resolve(result)];
        }
      });
    });
  };

  DefaultObjectMapper.prototype.internalMapCollection = function (sourceObj, options) {
    return __awaiter(this, void 0, void 0, function () {
      var source, target, targetConstructor, mappingKey, mappingDefinition, result;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (sourceObj === undefined || sourceObj === null) {
              return [2
              /*return*/
              , []];
            }

            source = options.source, target = options.target, targetConstructor = options.targetConstructor;
            mappingKey = this.getTypeMappingKey(source, target);
            this.validateMappingExists(mappingKey);
            mappingDefinition = this.maps.get(mappingKey);
            return [4
            /*yield*/
            , Promise.all(sourceObj.map(function (o) {
              return _this.internalMap(o, mappingDefinition, targetConstructor);
            }))];

          case 1:
            result = _a.sent();
            return [2
            /*return*/
            , Promise.resolve(result)];
        }
      });
    });
  };

  DefaultObjectMapper.prototype.map = function (sourceObj, options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2
        /*return*/
        , this.internalMapSingleObject(sourceObj, options)];
      });
    });
  };

  DefaultObjectMapper.prototype.mapCollection = function (sourceObj, options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2
        /*return*/
        , this.internalMapCollection(sourceObj, options)];
      });
    });
  };

  DefaultObjectMapper.prototype.hasMap = function (sourceType, destinationType) {
    var mappingKey = this.getTypeMappingKey(sourceType, destinationType);
    var mappingDefinition = this.maps.get(mappingKey);
    return mappingDefinition !== undefined;
  };

  return DefaultObjectMapper;
}();

exports.DefaultObjectMapper = DefaultObjectMapper;