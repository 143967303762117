"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultWebApplicationStoreModule = void 0;

var DefaultWebApplicationStoreModule =
/** @class */
function () {
  function DefaultWebApplicationStoreModule(stores) {
    this.storesMapper = new Map();
    this.initialize(stores);
  }

  DefaultWebApplicationStoreModule.prototype.initialize = function (stores) {
    var _this = this;

    Object.keys(stores).forEach(function (x) {
      _this.storesMapper.set(x, stores[x]);
    });
  };

  DefaultWebApplicationStoreModule.prototype.getStore = function (identifier) {
    return this.storesMapper.get(identifier);
  };

  DefaultWebApplicationStoreModule.prototype.getStores = function () {
    return Array.from(this.storesMapper.entries()).reduce(function (main, _a) {
      var _b;

      var key = _a[0],
          value = _a[1];
      return __assign(__assign({}, main), (_b = {}, _b[key] = value, _b));
    }, {});
  };

  DefaultWebApplicationStoreModule.prototype.getMainViewStore = function () {
    return this.storesMapper.get("mainAppViewStore");
  };

  return DefaultWebApplicationStoreModule;
}();

exports.DefaultWebApplicationStoreModule = DefaultWebApplicationStoreModule;