"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertfromInformationRequest = exports.convertToInformationRequest = void 0; // "proponent_id": 471,
// "proponent_name": "Ricardo",
// "proponent_email": "mail.r.ferreira@gmail.com",
// "auction_id": 204,
// "lot_id": 6827,
// "provider": "Website C.Paraiso",
// "email_text": "texto texto texto texto texto texto",
// "email_subject": "assunto 1"

exports.convertToInformationRequest = function (request) {
  var result = {};
  return result;
};

exports.convertfromInformationRequest = function (request) {
  var result = {
    proponent_name: request.name,
    proponent_email: request.email,
    provider: "Website C.Paraiso",
    email_text: request.message
  };

  if (request.userId !== undefined) {
    result.proponent_id = request.userId;
  }

  if (request.eventId !== undefined) {
    result.auction_id = request.eventId;
  }

  if (request.lotId !== undefined) {
    result.lot_id = request.lotId;
  }

  if (request.subject !== undefined) {
    result.email_subject = request.subject;
  }

  return result;
};