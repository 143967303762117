"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseLogger = void 0;

var LogLevel_1 = require("./LogLevel");

var BaseLogger =
/** @class */
function () {
  function BaseLogger(logLevel) {
    this.logLevel = logLevel;
  }

  BaseLogger.prototype.isActiveLogLevel = function (messageLogLevel, currentLogLevel) {
    return messageLogLevel < currentLogLevel;
  };

  BaseLogger.prototype.logMessageBasedOnCurrentLogLevel = function (messageLogLevel, message) {
    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    if (this.isActiveLogLevel(messageLogLevel, this.logLevel)) {
      this.onLog.apply(this, __spreadArrays([message], data));
    }
  };

  BaseLogger.prototype.fatal = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.error = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.warning = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.info = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.debug = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.trace = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([LogLevel_1.LogLevel.FATAL, message], data));
  };

  BaseLogger.prototype.log = function (logLevel, message) {
    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([logLevel, message], data));
  };

  return BaseLogger;
}();

exports.BaseLogger = BaseLogger;