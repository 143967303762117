import { IUiTestService,UiTestSpecification,UiTestDescription } from "modbin-web";
import { IKeyedObject } from "modbin-domain";

export type UiTestFileContext = __WebpackModuleApi.RequireContext & {
    description:UiTestDescription
}

const createUiTestSpecificationFromWebpackContext = (fileContext:UiTestFileContext):UiTestSpecification => {
    const componentName = Object.keys(fileContext).filter(x => x.endsWith("UiTest"))[0];
    const component = (fileContext as any)[componentName];
    return {
        Component:component,
        ...fileContext.description
    }
}

const getComponentsContext = function(){
    return require.context("../../../../components",true,/.*UiTest\.tsx/)
}

const getScreensContext = function(){
    return require.context("../../../../screens",true,/.*UiTest\.tsx/)
}

const loadAllTestsFromDirectory = (testFileContexts:any) => {
    const testFileNames = testFileContexts.keys();
    const tests = testFileNames.map((n:any) => createUiTestSpecificationFromWebpackContext(testFileContexts(n)))
    return tests;
}

export class WebpackLoaderUiTestService implements IUiTestService {
    tests:IKeyedObject<UiTestSpecification[]> = {components:[], screens:[]};
    
    constructor(){
        this.loadTests();
    }
    
    private loadTests(){
        this.tests.components = loadAllTestsFromDirectory(getComponentsContext());
        this.tests.screens = loadAllTestsFromDirectory(getScreensContext());
    }
    
    get(group:string){
        return this.tests[group];
    }

    getAll(){
        return [...this.tests.components, ...this.tests.screens];
    }
}

export const uiTestService = new WebpackLoaderUiTestService();