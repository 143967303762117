import { ScreensErrorsNotFound } from "./errors/NotFound"

import { ScreensErrorsUnauthorized } from "./errors/Unauthorized"
import { ViewDefinition } from "modbin-web"
import { ScreensErrorsUnexpectedError } from "./errors/UnexpectedError"
import { LayoutType } from "../../components/layout/Layouts.types"
import { ScreensErrorsAppLoadingError } from "./errors/AppLoadingError"

const notFoundView = {
    name:"common:errors:notfound",
    component: ScreensErrorsNotFound,
    title:"Not found",
    route:{
        name:"common:errors:notfound",
        template:"/not-found"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fixed"}
    }
}

const unauthorizedView = {
    name:"common:errors:unauthorized",
    component: ScreensErrorsUnauthorized,
    title:"Unauthorized",
    route:{
        name:"common:errors:unauthorized",
        template:"/unauthorized"
    },  
    layout:{
        //component:MenuLayout,
        props:{type:"fixed"}
    }
}

const unexpectedErrorView = {
    name:"common:errors:unexpected",
    component: ScreensErrorsUnexpectedError,
    title:"Unexpected",
    route:{
        name:"common:errors:unexpected",
        template:"/unexpected"
    },  
    layout:{
        component:LayoutType.MENU,
        props:{type:"fixed"}
    }
}

const appLoadingErrorView = {
    name:"common:errors:app-loading-error",
    component: ScreensErrorsAppLoadingError,
    title:"Loading error",
    route:{
        name:"common:errors:app-loading-error",
        template:"/loading-error"
    },  
    layout:{
        component:LayoutType.BASE,
        props:{type:"fixed"}
    }
}

export const commonViewsDefinitions:ViewDefinition[] = [
    notFoundView,
    unauthorizedView,
    unexpectedErrorView,
    appLoadingErrorView
]