"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cloner = void 0;

var Cloner =
/** @class */
function () {
  function Cloner() {}

  Cloner.withObjectAssign = function (source) {
    return Object.assign({}, source);
  };

  Cloner.withJson = function (source) {
    return JSON.parse(JSON.stringify(source));
  };

  return Cloner;
}();

exports.Cloner = Cloner;