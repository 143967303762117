import React, { Fragment } from "react"
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { SellingTypeSelector } from "./SellingTypeSelector";
import { CategorySelector, TypeSelector } from "./CategorySelector";
import { SearchStore } from "./SearchStore";
import { AddressSelector } from "./AddressSelector";
import { ValueInput } from "./ValueInput";
import { TranslationProps } from "modbin-domain";

@inject("searchViewStore") @observer
export class InnerExtendedSearchForm  extends React.Component<{searchViewStore?:SearchStore} & TranslationProps & { onSearch:()=>void},{}> {
  
    onKeyPressed(event:any){
        const code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.props.onSearch();
        } 
    }

    render(){
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`);
        const getTranslationWords = (key:string) => this.props.t(`common:words.${key}`); 
        const getTranslationContacts = (key:string) => this.props.t(`domain:contacts.address.${key}`); 
        const showMoreText = this.props.searchViewStore!.showMore ? getTranslationButtons("showLess") : getTranslationButtons("showMore");
        const showMoreStyle = this.props.searchViewStore!.showMore ? {display:"block"} : {};
        const query = this.props.searchViewStore!.query;
        return <Fragment>
            <div className="main-search-box no-shadow" onKeyPress={(ev:any)=>{this.onKeyPressed(ev)}}>
                <div className="row with-forms">
                    <div className="col-md-4">  
                        <SellingTypeSelector
                            selected={this.props.searchViewStore!.getDefaultableFieldValue("sellingType")}
                            onChange={(v:string) => {this.props.searchViewStore!.onDefaultableFormFieldChange("sellingType",v)}}
                        ></SellingTypeSelector>     
                    </div>
                    <div className="col-md-8">
                        <div className="main-search-input">
                            <input type="text"  
                                placeholder={getTranslationWords("searchHint")} 
                                value={query.term !== undefined ? query.term : ""}
                                onChange={(ev:any) => {this.props.searchViewStore!.onFormFieldChange("term",ev.target.value);}}
                            />
                            <button className="button" onClick={(ev:any)=>{this.props.onSearch()}}>{getTranslationButtons("search")}</button>
                        </div>
                    </div>
                </div>
                <AddressSelector
                    selectorClass="col-md-4"
                    countyPlaceHolderText={getTranslationContacts("county")}
                    parishPlaceHolderText={getTranslationContacts("parish")}
                    regionPlaceHolderText={getTranslationContacts("region")}
                ></AddressSelector>
            </div>
            <a href="# " className="more-search-options-trigger margin-top-10" onClick={(ev) => this.props.searchViewStore!.onShowMoreChange(ev)}>{showMoreText}</a>

            <div className="more-search-options relative" style={showMoreStyle}> 
                <div className="more-search-options-container">
                    <div className="row with-forms">
                        <div className="col-md-4">
                        <CategorySelector selected={this.props.searchViewStore!.getDefaultableFieldValue("category")} onChange={(x:string) => {this.props.searchViewStore!.onCategoryChange(x);}}></CategorySelector>
                        </div>
                        <div className="col-md-4">
                        <TypeSelector selected={this.props.searchViewStore!.getDefaultableFieldValue("subCategory")} selectedCategory={this.props.searchViewStore!.getDefaultableFieldValue("category")} items={this.props.searchViewStore!.subCategoryItems} onChange={(x:string) => {this.props.searchViewStore!.onDefaultableFormFieldChange("subCategory", x);}}></TypeSelector>                    
                        </div>
                        <div className="col-md-2">
                            <ValueInput 
                                placeHolder={getTranslationWords("minimumValue")}
                                value={query.minimumValue}
                                onChange={(v:string) => {this.props.searchViewStore!.onFormFieldChange("minimumValue",v)}}
                            ></ValueInput>
                        </div>
                        <div className="col-md-2">
                            <ValueInput 
                                placeHolder={getTranslationWords("maximumValue")}
                                value={query.maximumValue as any}
                                onChange={(v:string) => {this.props.searchViewStore!.onFormFieldChange("maximumValue",v)}}
                            ></ValueInput>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

export const ExtendedSearchForm = (withTranslation(["common"])(InnerExtendedSearchForm as any) as any) as (props:any) => JSX.Element;