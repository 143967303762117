import React from "react";
import { TranslationProps } from "modbin-domain";
import { NewCarouselView } from "../../../../components/carousel/NewCarouselView";
import { SellingEventSummaryView } from "./SellingEventSummaryView";
import { SellingEvent } from "ibase-auctions-domain";

export const FeaturedView = (props:TranslationProps&{items:SellingEvent[], onClick:(id:string)=>void}) => {
    return <div className="row">
	    <div className="col-md-12">
            <h3 className="headline margin-bottom-25 margin-top-65">{props.t("screens:public.home.featured")}</h3>
        </div>
        <div className="col-md-12 featured-events">
            <NewCarouselView 
                infinite={true}
                autoPlay={true}
                items={props.items.map(x => {return <SellingEventSummaryView key={x.getId()} item={x} onClick={(id)=>{props.onClick(id)}}/>})}
                slidesPerStep={1}
                visibleItems={3}
                showDots={false}
                onSlideChange={(index)=>{}}
                selectedSlide={1}
            >
            </NewCarouselView>
        </div>
    </div>
}