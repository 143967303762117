import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { WebConfiguration } from "../../configuration/Config.types";
import { application } from "../../Startup";
import { UiTestDescription } from "modbin-web";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export const description:UiTestDescription = {
    id:"CarouselViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Carousel component",
    parentId:"components"
};

const items = [
    "/images/single-property-01.jpg",
    "/images/single-property-02.jpg",
    // "/images/single-property-03.jpg",
    "/images/single-property-04.jpg",
    "/images/single-property-05.jpg",
].map(x => {return {
    original:`${application.getContainer().resolve<WebConfiguration>("webConfig").baseUrl}${x}`, 
    thumbnailClass:"thumb",
    thumbnail:`${application.getContainer().resolve<WebConfiguration>("webConfig").baseUrl}${x}`
}});

export class NewCarouselViewUiTest extends Component<TranslationProps,{}>{
    
    logResult(result:any){
        console.log(result);
    }
    
    render(){
        // return <div><NewCarouselView 
        //     visibleItems={1}
        //     slidesPerStep={1}
        //     items={items.map(x => {return <div><img src={x}></img></div>})}
        //     infinite={true}
        //     autoPlay={true}
        //     showDots={true}
        //     onSlideChange={(index)=>{}}
        //     selectedSlide={1}
        // /></div>;
        return <ImageGallery items={items} />;
    }
}