import React, { Component } from "react";
import { ContactFormModalView } from "./ContactFormModalView";
import { TranslationProps } from "modbin-domain";
import { UiTestDescription,sleepableConsoleLogger } from "modbin-web";

export const description:UiTestDescription = {
    id:"ContactFormModalViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Different views for a single item",
    parentId:"components"
};

export class ContactFormModalViewUiTest extends Component<TranslationProps,{}>{
    render(){
        
        return <ContactFormModalView t={this.props.t} onSubmit={(request:any) => {return sleepableConsoleLogger(request)}}></ContactFormModalView>
    }
}