import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { NewsletterSubscription } from 'ibase-auctions-domain';
import { TranslationProps } from 'modbin-domain';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import moment from "moment";
import { DownloadButton } from '../../../components/files/DownloadButton';
import { LoadingView } from '../../../components/loading/LoadingView';
import { NoResultsView } from '../../../components/loading/NoResultsView';
import { observer } from 'mobx-react';

@observer
export class ScreensNewsletterIndexView extends Component<TranslationProps & {isLoading:boolean, newsletterSubscriptions:NewsletterSubscription[],newslettersFileUrl:string},{}> {
    
    createItemView(item:NewsletterSubscription){
        return <tr>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{moment(item.getCreatedDate()).format("DD/MM/YYYY HH:mm")}</td>
        </tr>; 
    }

    createListView(newsletterSubscriptions:NewsletterSubscription[]){
        const getTranslation = (key:string) => this.props.t(`screens:administration.newsletters.${key}`); 
        const head = <thead>
            <tr>
                <th style={{width:"40%"}}>{getTranslation("nameColTitle")}</th>
                <th style={{width:"40%"}}>{getTranslation("emailColTitle")}</th>
                <th style={{width:"20%"}}>{getTranslation("subscriptionDateColTitle")}</th>
            </tr>
        </thead>;

        const body = <tbody>
            {newsletterSubscriptions.map(x => this.createItemView(x))}
        </tbody>;

        return <Table striped hover responsive className="basic-table">
            {head}
            {body}
        </Table>;
    }
    
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:administration.newsletters.${key}`); 
        const fileName = `${moment(new Date(Date.now())).format("DD-MM-YYYY")} - Newsletters.csv`;
        const {newsletterSubscriptions, isLoading} = this.props;
        return (
            <div>
                <TitleBar title={getTranslation("title")}></TitleBar>
                <div className="container">
                    <div style={{float:"right"}}>
                        <DownloadButton icon="fa fa-download" url={this.props.newslettersFileUrl} fileName={fileName} mimeType="text/csv" name={getTranslation("downloadButton")}/>
                    </div>
                    <div className="clearfix"></div>
                    <div className="margin-top-30">
                        <LoadingView 
                            isLoading={isLoading} 
                            showFallback={newsletterSubscriptions.length === 0} 
                            fallbackView={<NoResultsView text="Ainda não existem subscrições de newsletters."></NoResultsView>}
                        >
                            {this.createListView(this.props.newsletterSubscriptions)}
                        </LoadingView>                        
                    </div>
                </div>
            </div>
        );
    }
}