import { IUserProperties } from "ibase-auctions-domain";
import { MainAppViewStore } from "../../../configuration/stores/MainAppViewStore";
import { computed } from "mobx";
import { IUserNotificationService } from "modbin-domain";
import { SessionStore } from "./SessionStore";
import { application } from "../../../Startup";
import { AuthorizationType, BaseStore, IStore } from "modbin-web";
import { IMainAppViewStore } from "../../../configuration/temp/web/stores/IMainAppViewStore";

export class SessionViewStore extends BaseStore implements IStore {
    
    constructor(
            private mainAppViewStore:IMainAppViewStore,
            private sessionStore:SessionStore,
            private userNotificationService:IUserNotificationService,
        ){       
            super();
    }
    
    @computed
    get isLoaded(){
        return this.sessionStore.user !== undefined;
    }

    @computed 
    get displayName(){
        return this.isLoaded ? this.sessionStore.user!.firstName : "";
    }
    onLogout(){
        this.sessionStore.logoutUser();
        const currentView = this.mainAppViewStore!.currentView;
        if(currentView!.authorization !== undefined && currentView!.authorization !== AuthorizationType.NOTREQUIRED){
            this.mainAppViewStore.navigateTo("public:home");
        }
    }

    onUserUpdate(user:IUserProperties):Promise<void>{
        return this.sessionStore.updateUser(user).then(x => {
            this.userNotificationService.success(application.translations.translate("common:words.changesSuccessfullySaved")); 
        });
    }
}