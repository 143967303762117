import { BaseStore, IStore, WebApplication } from "modbin-web";
import { BrowserFetchHttpClient, FetchHttpClientOptions, HttpContentType, jsonContentTypeFormatter, BaseHttpClient } from "modbin-api";
import { isNotNullOrEmptyString } from "modbin-domain";
import { application } from "./Startup";

export type ApplicationVersion = string;

export const currentApplicationVersion:ApplicationVersion = "1.0.35";
console.log(`Application version: ${currentApplicationVersion}`);

export class VersionStore extends BaseStore implements IStore {
    
    initialize():Promise<void>{
    
        const fetchHttpClientOptions:FetchHttpClientOptions = {
            formatters:[[HttpContentType.JSON,jsonContentTypeFormatter]]
        } as any;
    
        const httpClient = new BaseHttpClient(new BrowserFetchHttpClient(fetchHttpClientOptions));
    
        return httpClient.get(`/version.json?current=${currentApplicationVersion}`)
        .then(x => {
            try {
                const body = x.body!;
                if(body !== undefined && body !== null && isNotNullOrEmptyString(body.version)){
                    if(currentApplicationVersion !== body.version){
                        window.location.reload();
                    }
                }
            }
            catch(e){
                (application as WebApplication<any>)!.getLogger().error(`Error in reloading version. Version: ${currentApplicationVersion}`,e);
            }
        })
        .catch((x) => {
            try{
                (application as WebApplication<any>)!.getLogger().error(`Error in fetching version. Version: ${currentApplicationVersion}`,x);
            }
            catch(e){
                console.log(e);
            }
        });
    }
}