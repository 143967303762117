import React from 'react';
import { TranslationProps } from "modbin-domain";
import { observer } from 'mobx-react';
import { NavigationLink, ReturnableNavigationLink } from '../../../../../components/layout/navigation/menus/NavigationLink';

export const NotLoggedInView = observer((props:TranslationProps) => { 
    return <div>
        <span>{props.t("screens:business.lots.details.needLoginToBid")}</span>
        <ReturnableNavigationLink to="public:sessions:login">
        <button className="button fullwidth margin-top-30">{props.t("common:buttons.login")}</button>
        </ReturnableNavigationLink>
    </div>
})