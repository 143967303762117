import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { UiTestDescription, sleepableClick } from "modbin-web";

export const description:UiTestDescription = {
    id:"CalculateRefreshTimeoutUiTest",
    groupId:"components",
    description:"lalal",
    title:"CalculateRefreshTimeout function",
    parentId:"components"
};

@observer
export class CalculateRefreshTimeoutUiTest extends Component<TranslationProps,{}>{
    
    @observable nowDate = "";
    @observable startDate = "";
    @observable endDate = "";

    logResult(result:any){
        console.log(result);
    }

    handleClick(data:any){
        this.logResult(data);
        return sleepableClick();
    }

    render(){
        return <div>
           
        </div>;
    }
}