import React, { Fragment } from "react";
import { BadgeType } from "../../components/badges/Badge";
import { SellingEvent, SellingType } from "ibase-auctions-domain";
import { SummaryView } from "../../components/photos/SummaryView";
import { EndDateView } from "../../components/time/EndDateView";

export const SellingEventSummaryView = (props:{item:SellingEvent, onClick?:(id:string)=>void}) => {
    const { item, item:{thumbnail,type, dates},onClick=()=>{}} = props;   
    const info = item.type !== SellingType.PRIVATENEGOTIATION 
        ? <EndDateView endDate={dates.endDate} extendedDate={dates.extendedDate}/>
        : <Fragment/>
    const resultPhotos = [thumbnail]; 
    return <SummaryView 
        item={item}
        photos={resultPhotos}
        sellingType={type}
        badges={[BadgeType.FEATURED, BadgeType.ENDED, BadgeType.SOON]}
        info={info}
        onClick={(id) => { onClick(id)}}
    />
}