import React, { Fragment } from "react";
import { Currency } from "ibase-auctions-domain";
import { getFormatedPrice } from "../../../components/helpers/Price";

export const PriceHeaderView = (props:{
        label:string,
        value?:any,
        currency?:Currency
    }) => {
    const priceStyle:any = {
        float:"right",
        marginTop:"20px"
    }
    const {label,value,currency} = props;
    let labelElem, priceElem;
    if(value !== undefined && value !== null && currency !== undefined && currency !== null){
        labelElem = <div>{label}:</div>;
        priceElem = <div style={priceStyle}>{getFormatedPrice(props.value,currency)}</div>
    }
    else {
        labelElem = <span>{label}</span>;
        priceElem = <Fragment />;
    }
    return <Fragment>
        <div className="margin-top-35" style={{
            backgroundColor: "rgb(59, 193, 237)",
            color: "white",
            fontSize: "30px",
            padding: "20px",
        }}>
            {labelElem}
            {priceElem}
            <div className="clearfix"></div>
        </div>
    </Fragment>;
};