"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpMethod = void 0;
var HttpMethod;

(function (HttpMethod) {
  HttpMethod["GET"] = "get";
  HttpMethod["POST"] = "post";
  HttpMethod["PUT"] = "put";
  HttpMethod["DELETE"] = "delete";
  HttpMethod["HEAD"] = "head";
  HttpMethod["PATCH"] = "patch";
  HttpMethod["OPTIONS"] = "options";
})(HttpMethod = exports.HttpMethod || (exports.HttpMethod = {}));