"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpHeaders = void 0;

var HttpContentType_1 = require("./HttpContentType");

var modbin_domain_1 = require("modbin-domain");

var HttpHeaders =
/** @class */
function () {
  function HttpHeaders(initialElements) {
    if (initialElements === void 0) {
      initialElements = [];
    }

    this.headers = new Map(initialElements.map(function (x) {
      return [x.name.toLowerCase(), x.value];
    }));
  }

  HttpHeaders.prototype.delete = function (name) {
    this.headers.delete(name);
  };

  HttpHeaders.prototype.get = function (name) {
    return this.headers.get(name.toLowerCase());
  };

  HttpHeaders.prototype.has = function (name) {
    return this.headers.has(name.toLowerCase());
  };

  HttpHeaders.prototype.set = function (name, value) {
    this.headers.set(name.toLowerCase(), value);
  };

  HttpHeaders.prototype.convertToArray = function () {
    var result = [];
    this.headers.forEach(function (val, key) {
      result.push({
        name: key,
        value: val
      });
    });
    return result;
  };

  HttpHeaders.prototype.convertToObject = function () {
    var result = {};
    this.headers.forEach(function (val, key) {
      result[key] = val;
    });
    return result;
  };

  HttpHeaders.prototype.setJsonContentType = function () {
    this.set("Content-Type".toLowerCase(), HttpContentType_1.HttpContentType.JSON);
  };

  HttpHeaders.prototype.getContentType = function () {
    var contentType = this.get("Content-Type".toLowerCase());

    if (modbin_domain_1.isNullOrEmptyString(contentType)) {
      return undefined;
    }

    if (contentType.indexOf(";") > 0) {
      var parts = contentType.split(";");
      return parts[0];
    }

    return contentType;
  };

  return HttpHeaders;
}();

exports.HttpHeaders = HttpHeaders;