import React from "react";
import  logo_all_small  from "../../../resources/images/logo_all_small.png";
import { NavigationLink } from "../navigation/menus/NavigationLink";
import { MainMenu } from "../navigation/menus/MainMenu";

export const NavigationHeader = () => {
    return <div id="header">
        <MainMenu 
            logoElem={<NavigationLink to="public:home"><img src={logo_all_small } alt=""/></NavigationLink>}
        />
    </div>;
}