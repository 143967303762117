import React, { Fragment, Component } from "react";
import { observer, inject } from "mobx-react";
import { application } from "../../Startup";
import { ApplicationConfiguration } from "../../configuration/temp/AplicationConfiguration";
import { observable } from "mobx";
import { MainAppViewStore } from "../../configuration/stores/MainAppViewStore";
import { ApiConfiguration } from "../../configuration/api/ApiConfig.types";
import { WebConfiguration } from "../../configuration/Config.types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export type ImformationBarProps = {
    appConfig:ApplicationConfiguration,
    apiConfig:ApiConfiguration,
    webConfig:WebConfiguration,
}

export const getInformationBarProps = ():ImformationBarProps => {
    const appConfig = application.getContainer().resolve<ApplicationConfiguration>("appConfig");
    const apiConfig = application.getContainer().resolve<ApiConfiguration>("apiConfig");
    const webConfig = application.getContainer().resolve<WebConfiguration>("webConfig");
    return {
        appConfig,
        apiConfig,
        webConfig
    };
}


const DevelopmentBarPropertyView = (props:{label:string, value:string}) => {
    return <span style={{marginRight:"50px"}}><strong>{props.label}: </strong><span style={{color:"white"}}>{props.value}</span></span>
}

@inject("mainAppViewStore") @observer
export class DevelopmentBar extends Component<{mainAppViewStore?:MainAppViewStore, properties:ImformationBarProps},{}> {
    @observable isVisible:boolean = false;
    
    toggleVisibility(ev:any){
        ev.preventDefault();
        this.isVisible = !this.isVisible;
    }

    render() {
        const {appConfig, webConfig, apiConfig} = this.props.properties;
        const showBar = application.getContainer().resolve<ApplicationConfiguration>("appConfig").features.showInformationBar;
        const activeClass = this.isVisible ? "active" : "";
        const bar = <div className={`information-bar ${activeClass}`}>
            <OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip:information-bar`}>Development toolbar</Tooltip>}>
                <div className="information-bar-trigger" onClick={(ev) => {this.toggleVisibility(ev)}}/>
            </OverlayTrigger>
            <div className="information-bar-content">
                <div className="container-fluid">                    
                    <DevelopmentBarPropertyView label="Environment" value={appConfig.environment}/>
                    <DevelopmentBarPropertyView label="Data source" value={webConfig.dataSource}/>
                    <DevelopmentBarPropertyView label="Logger (level)" value={`${appConfig.logging.name} (${appConfig.logging.level})`}/>
                    <DevelopmentBarPropertyView label="Proxy" value={apiConfig.baseUrl}/>
                    <span style={{float:"right"}}><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:index",{event:ev})}>More settings</a></span>
                    
                </div>
            </div>
        </div>;
        return showBar ? bar : <Fragment/>;        
    }
}