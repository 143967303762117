import { observer } from "mobx-react";
import { TranslationProps, IKeyedObject } from "modbin-domain";
import { application } from "../../../Startup";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { Fragment } from "react";
import { FormInput } from "../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../components/buttons/ActionButton";
import { FormSelect } from "../../../components/forms/elements/FormSelect";

export enum ProxyType {
    PASSTHROUGH = "passthrough",
    CUSTOM = "custom"
}

export type Settings = {
    proxyType: ProxyType,
    proxy:string,
    officeId:string,
    authorizationHeaderName?:string,
    authorizationToken?:string,
    imageServiceUrl?:string,
    environment:string,
    proxies:IKeyedObject<{text:string,value:string,url:string, imageServiceUrl:string}>
}

export const SettingsFormView = observer((props:TranslationProps & {settings:Settings, onSave:(ev:any)=>Promise<void>}) => {
    const getTranslationFooter = (key:string) => props.t(`common:footer.${key}`); 
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const settingsSchema = Yup.object().shape({
            proxy: Yup.string()
              .required(createRequiredMessage(getTranslationFooter("emailField"))),
            authorizationHeaderName: Yup.string()
              .required(createRequiredMessage(getTranslationFooter("nameField")))
          });
    const proxyTypeOptions = [{
        value:"passthrough",
        text:"Pass through"
    },{
        value:"custom",
        text:"Costum"
    }];
    const environmentOptions = Object.keys(props.settings.proxies).map((x) => {return {value:props.settings.proxies[x].value, text:props.settings.proxies[x].text}});
    console.log(environmentOptions)
      return (
        <Formik
                initialValues={{...props.settings}}
                validationSchema={settingsSchema}
                onSubmit={(values, actions) => {
                    return props.onSave(values as any);
                }}
                 
              >
                {(formProps) => { 
                    const showMore = formProps.values.proxyType === ProxyType.PASSTHROUGH;
                    const showMoreview = true
                        ? <Fragment>
                             <div className="form-row form-row-wide">
                                <FormInput type="text" name="authorizationToken" label="Authorization Token" placeHolder="Authorization token" formikState={formProps} />
                            </div>
                            <div className="form-row form-row-wide">
                                <FormInput type="number" name="officeId" label="Office Id" formikState={formProps} />
                            </div>
                            </Fragment>
                        : <Fragment></Fragment> ;
                    return (
                
                  <Form>
                      <div className="form-row form-row-wide">
                        <FormSelect name="environment" label="Environment" options={environmentOptions} formikState={formProps} onChange={(op:any)=> { 
                            formProps.setFieldValue("environment",op.currentTarget.value);
                            if(op.currentTarget.value !== "other"){
                                formProps.setFieldValue("proxy",props.settings.proxies[op.currentTarget.value].url);
                                formProps.setFieldValue("imageServiceUrl",props.settings.proxies[op.currentTarget.value].imageServiceUrl);
                            }
                        }}/>
                    </div>
                      <div className="form-row form-row-wide">
                        <FormInput type="text" name="proxy" label="Proxy" placeHolder="Proxy" formikState={formProps} />
                      </div>
                      <div className="form-row form-row-wide">
                        <FormInput type="text" name="imageServiceUrl" label="Image service url" placeHolder="Image service" formikState={formProps} />
                    </div>
                      {/* <div className="form-row form-row-wide">
                        <FormInput type="text" name="authorizationHeaderName" label="Authorization header name" placeHolder="Authorization header name" formikState={formProps} />
                      </div> */}
                        <div className="form-row form-row-wide">
                        <FormSelect name="proxyType" label="Proxy type" options={proxyTypeOptions} formikState={formProps} />
                    </div>
                      {showMoreview}
                     
                     <div className="form-row">
                        <ActionButton type={ButtonType.SAVE} onClick={()=>{return formProps.submitForm()}}  style={{float:"right",marginTop: "20px"}} />
                    </div>
                  </Form>
                )}}
              </Formik>
      );
  });