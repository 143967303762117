import React, { Fragment, ReactElement } from "react";
import { TranslationProps } from "modbin-domain";
import { GeographicLocationView } from "../../../../components/location/GeographicLocationView";
import { Lot, SellingType, SellingEventDateState } from "ibase-auctions-domain";
import { inject, observer } from "mobx-react";
import { HeaderView } from "../../../components/HeaderView";
import { InformationRequestView } from "../../../components/info/InformationRequestView";
import { VisitBookingView } from "../../../components/bookings/VisitBookingView";
import { DescriptionView } from "../../../components/DescriptionView";
import { DocumentsView } from "../../../components/documents/DocumentsView";
import { VirtualVisitView } from "../../../components/virtual_visit/VirtualVisitView";
import { BidView } from "../../../components/bid/BidView";
import { SessionStore } from "../../../../../public/sessions/SessionStore";
import { ImageGalleryView } from "../../../../../../components/carousel/ImageGalleryView";
import { getItemVisibilityState } from "../../../../components/helpers/VisibilityPerStateMapper";
import {application} from "../../../../../../Startup";
import {ServicesConfiguration, WebConfiguration} from "../../../../../../configuration/Config.types";

export type LotDetailsViewProps = {
    titleView?:React.ReactElement;
    timeView?:React.ReactElement;
    biddingView?: {
        loggedIn: React.ReactElement,
        notLoggedIn?: React.ReactElement
    },
    priceView:React.ReactElement;
    infoView?:React.ReactElement;
    bookingsView?:React.ReactElement;
    informationRequestView?:React.ReactElement;
    item:Lot;
    dateState:SellingEventDateState;
    isLoadingPrice:boolean;
}

@inject("sessionStore") @observer
export class LotDetailsView  extends React.Component<TranslationProps & LotDetailsViewProps & {sessionStore?:SessionStore},{}>{

    getDefaultTitleView(isLoadingPrices:boolean){
        return <HeaderView t={this.props.t} item={this.props.item} isLoading={isLoadingPrices}></HeaderView>;
    }

    getDefaultBookingsView(){
        return <div className="widget margin-bottom-30">
            <VisitBookingView t={this.props.t} bookings={this.props.item.bookings} isLoading={!this.props.item.bokingsLoaded}></VisitBookingView>
        </div>;
    }

    getDefaultInformationRequestView(){
        return <InformationRequestView lot={this.props.item}></InformationRequestView>;
    }

    getViewIfVisible(isVisible:boolean, view:any){
        return isVisible ? view : <Fragment></Fragment>;
    }

    getBidView(isVisible:boolean, isAuthenticated:boolean, sellingType:SellingType,biddingView?:{loggedIn:ReactElement,notLoggedIn?:ReactElement}){
        if(biddingView === undefined || biddingView.loggedIn === undefined){
            return <Fragment></Fragment>;
        }
        return this.getViewIfVisible(isVisible,<BidView isAuthenticated={isAuthenticated} t={this.props.t} biddingView={biddingView!} sellingType={sellingType}/>);
    }

    getInfoView(infoView:any){
        if(infoView === undefined){
            return <Fragment></Fragment>;
        }
        return infoView;
    }

    getTimeView(timeView:any){
        if(timeView === undefined){
            return <Fragment></Fragment>;
        }
        return timeView;
    }

    render(){
        let { 
            titleView=this.getDefaultTitleView(this.props.isLoadingPrice), 
            timeView, 
            biddingView, 
            priceView, 
            infoView,
            bookingsView = this.getDefaultBookingsView(), 
            informationRequestView = this.getDefaultInformationRequestView(), 
            item
        } = this.props;
        const viewVisibility = getItemVisibilityState(this.props.dateState)!;

        // @ts-ignore
        return <div>
            {titleView}
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7 sp-content">
                        <div>
                            <ImageGalleryView photos={this.props.item.photos}/>
                        </div>
                        <div className="property-description">
                            <DescriptionView t={this.props.t} item={this.props.item}></DescriptionView>
                            <GeographicLocationView t={this.props.t} location={this.props.item.location as any}/>
                            {/*<iframe width="100%" height="400" src={`${application.getContainer().resolve<ServicesConfiguration>("servicesConfig").visits.baseUrl}` + this.props.item.visita_virtual  + "\\index.html"} frameBorder="0" scrolling="no"></iframe>*/}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 sp-sidebar">
                        <div className="sidebar sticky right">
                            <div className="widget margin-bottom-30">
                                {this.getTimeView(timeView)}
                                {this.getBidView(viewVisibility.bidView,this.props.sessionStore!.isAuthenticated,item.event.type,biddingView)}  
                                {this.getInfoView(infoView)}      
                                {priceView}
                                {this.props.item.eventId == "6850" ?
                                    <div className="boxed-widget booking-widget margin-top-20"><p>VALOR FINAL SUJEITO A APROVAÇÃO</p></div>
                                :
                                    null
                                }
                                {/*{this.props.item.eventId == "6865" ?*/}
                                {/*    <div className="boxed-widget booking-widget margin-top-20"><p>VALOR FINAL SUJEITO A APROVAÇÃO</p></div>*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                                {/*{this.props.item.eventId == "6867" ?*/}
                                {/*    <div className="boxed-widget booking-widget margin-top-20"><p>VALOR FINAL SUJEITO A APROVAÇÃO</p></div>*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                            </div>
                                {this.getViewIfVisible(viewVisibility.bookingsView,bookingsView)}
                            <div className="widget margin-bottom-30">
                                { this.props.item.visita_virtual !== null && this.props.item.visita_virtual !== undefined ?
                                <VirtualVisitView t={this.props.t} visita_virtual={`${application.getContainer().resolve<ServicesConfiguration>("servicesConfig").visits.baseUrl}` + this.props.item.visita_virtual  + "/index.html"}/>
                                    :
                                    <VirtualVisitView t={this.props.t} visita_virtual={"null"}/>
                                }
                            </div>
                            <div className="widget margin-bottom-30">
                                <DocumentsView t={this.props.t} files={this.props.item.documents} isLoading={!this.props.item.documentsLoaded} />
                            </div>
                                {this.getViewIfVisible(viewVisibility.informationRequestView,informationRequestView)}
                        </div>
                    </div>

                </div>
            </div>
        </div>;
    }
}
