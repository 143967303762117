import React, {ErrorInfo} from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { ErrorUI, ErrorDetailView } from "./ErrorDetailView";
import { ILogger } from "modbin-domain";
import { application } from "../../../Startup";
import { configProperties } from "../../../configuration/ConfigProperties";
import { ConfigProperties } from "../../../configuration/Config.types";
import { ScreensErrorsUnexpectedError } from "./UnexpectedError";
import { BaseLayout } from "../../../components/layout/BaseLayout";


@observer
export class GlobalErrorBoundary extends React.Component<{logger?:ILogger}, {}> {

    @observable hasError = false;
    @observable uiError: ErrorUI | undefined = undefined;
  
    componentDidCatch(error:Error, erroInfo:ErrorInfo) {
      // You can also log the error to an error reporting service
      const err = this.createError(error,erroInfo);
      this.setError(err);
      this.handleErrorForLog(err);
    }

    createError(error:Error, errorInfo:ErrorInfo):ErrorUI{
        return { title:"An unexpected error occurred.", message:"", type:"error", js:error, react:errorInfo};
    }

    setError(error:ErrorUI) {        
        this.uiError = error;
        this.hasError = true;
    }

    handleErrorForLog(error:ErrorUI) {
        if(this.props.logger){
            //alert(error);
            const jsMessage =  error.js !== undefined ? error.js.message : "";
            const reactMessage = error.react !== undefined ? "React error!" : "";
            const message = `${error.title} ${jsMessage} ${reactMessage}`;
            const errorToPost = {
                js:error.js,
                react:error.react
            }
            try {
                this.props.logger.error(message,errorToPost);
            }
            catch(e){
                console.log(e);
            }
        }
    }
  
    render() {
        if (this.hasError) { 
            const showDevelopementMessages = application.getSettings()!.appConfig.features.showDevelopementMessages;
            if(!showDevelopementMessages){
                return <BaseLayout><ScreensErrorsUnexpectedError></ScreensErrorsUnexpectedError></BaseLayout>;
            }
            else{
                return <ErrorDetailView error={this.uiError!}></ErrorDetailView>;
            }
        }
        return this.props.children; 
    }
}

export const withErrorBoundary = (WrappedComponent:any, logger:ILogger) => {
    return <GlobalErrorBoundary logger={logger}><WrappedComponent></WrappedComponent></GlobalErrorBoundary>
}