"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseBrowserRouter = void 0;

var UrlParametersConverter_1 = require("./UrlParametersConverter");

var BaseBrowserRouter =
/** @class */
function () {
  function BaseBrowserRouter(viewNavigator) {
    this.viewNavigator = viewNavigator;
  }

  BaseBrowserRouter.prototype.updateUrlOnStateChange = function () {
    var _a = this.viewNavigator.currentView,
        route = _a.route,
        title = _a.title,
        store = _a.store;
    var state = store !== undefined && store.getState !== undefined ? store.getState() : undefined;
    var path = UrlParametersConverter_1.convertStateToUrlPath(route, state); //console.log("PATH:", path);

    if (path !== window.location.pathname) window.history.pushState(null, title, path);
  };

  return BaseBrowserRouter;
}();

exports.BaseBrowserRouter = BaseBrowserRouter;