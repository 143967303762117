"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sellingTypeTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var SellingType_1 = require("./SellingType");

exports.sellingTypeTranslations = modbin_domain_1.createTranslationObjectForEnum([[SellingType_1.SellingType.CLOSEDLETTER, {
  pt: "Carta fechada",
  en: "Closed letter"
}], [SellingType_1.SellingType.ONLINEAUCTION, {
  pt: "Leilão online",
  en: "Online auction"
}], //[ SellingType.ONLINEANDPRESENTIALAUCTION,{pt:"Leilão presencial e online",en:"Online and presential auction"}],
[SellingType_1.SellingType.PRESENTIALAUCTION, {
  pt: "Leilão presencial",
  en: "Presential auction"
}], [SellingType_1.SellingType.PRIVATENEGOTIATION, {
  pt: "Negociação particular",
  en: "Private negotiation"
}]]);