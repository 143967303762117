import React from "react";
import { observer } from "mobx-react";

export const ValueInput = observer((props:{placeHolder:string, value:string|undefined, onChange:(v:string)=>void}) => {
    const getValue = () => {
        return props.value === undefined ? "" : props.value;
    }

    return <div className="select-input">
        <input 
            type="number" 
            placeholder={props.placeHolder} 
            data-unit="USD"
            value={getValue()}
            onChange={(ev:any) => {props.onChange(ev.target.value);}}
        />
    </div>;
});
