import moment from "moment";
import { SellingEvent, SellingType } from "ibase-auctions-domain";

export const events:SellingEvent[] = [
    new SellingEvent({
        id:"1",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.ONLINEAUCTION,
        title:"Exemplo site atual 1",
        description:"Viatura Peugeot e Viatura Chevrolet; Máquinas de Ourivesaria e Joalharia, GUIMARÃES",
        dates: {
            startDate: moment("2019-10-30T09:00:00", 'DD-MM-YYYY').toDate(),
            endDate: moment("2019-11-20T11:15:00", 'DD-MM-YYYY').toDate(),
        },
        featured: true,
        published: true,
        thumbnail:"/images/samples/deals/deal1-1.jpg",
        photos:[],
        address:undefined as any
    }),
    new SellingEvent({
        id:"2",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.ONLINEAUCTION,
        title:"Exemplo site atual 2",
        description:"Material de escritório e Máquinas e Equipamentos de Contrução Civil - TORRES VEDRAS",
        dates: {
            startDate: moment("2019-10-22T09:00:00").toDate(),
            endDate: moment("2019-11-18T12:00:00").toDate(),
        },
        featured: true,
        published: true,
        thumbnail:"/images/samples/deals/deal2-1.jpg",
        photos:[],
        address:undefined as any
    }),
    new SellingEvent({
        id:"10",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.CLOSEDLETTER,
        title:"Exemplo de modalidade carta fechada",
        description:"Descrição de evento de modalidade carta fechada",
        dates: {
            startDate: new Date(),
            endDate: new Date()
        },
        featured: true,
        published: true,
        thumbnail:"/images/single-property-05.jpg",
        photos:[],
        address:{
            city: "Lisboa",
            country:"PT",
            street:"Rua Andrade, 2 R/C Dto",
            zipCode1:"1170",
            zipCode2:"015"
        },
    }),
    new SellingEvent({
        id:"11",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.CLOSEDLETTER,
        title:"Exemplo de modalidade leilão online",
        description:"Descrição de evento de modalidade leilão online",
        dates: {
            startDate: new Date(),
            endDate: new Date()
        },
        featured: true,
        published: true,
        thumbnail:"/images/single-property-05.jpg",
        photos:[],
        address:{
            city: "Lisboa",
            country:"PT",
            street:"Rua Andrade, 2 R/C Dto",
            zipCode1:"1170",
            zipCode2:"015"
        },
    }),
    new SellingEvent({
        id:"12",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.PRESENTIALAUCTION,
        title:"Exemplo de modalidade leilão presencial",
        description:"Descrição de evento de modalidade leilão presencial",
        dates: {
            startDate: new Date(),
            endDate: new Date()
        },
        featured: true,
        published: true,
        thumbnail:"/images/single-property-05.jpg",
        photos:[],
        address:{
            city: "Lisboa",
            country:"PT",
            street:"Rua Andrade, 2 R/C Dto",
            zipCode1:"1170",
            zipCode2:"015"
        },
    }),
    new SellingEvent({
        id:"13",
        officeId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        type:SellingType.PRIVATENEGOTIATION,
        title:"Exemplo de modalidade negociação privada",
        description:"Descrição de evento de modalidade negociação privada",
        dates: {
            startDate: new Date(),
            endDate: new Date()
        },
        featured: true,
        published: true,
        thumbnail:"/images/single-property-05.jpg",
        photos:[],
        address:{
            city: "Lisboa",
            country:"PT",
            street:"Rua Andrade, 2 R/C Dto",
            zipCode1:"1170",
            zipCode2:"015"
        },
    })
];
