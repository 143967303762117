"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerType = void 0;
var BannerType;

(function (BannerType) {
  BannerType["TOP"] = "top";
  BannerType["POPUP"] = "popup";
  BannerType["EVENTAD"] = "eventad";
})(BannerType = exports.BannerType || (exports.BannerType = {}));