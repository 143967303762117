import React from "react";
import { MapProps } from "./MapView";
import { GeographicLocation } from "ibase-auctions-domain";

const createLocationQueryValue = (location:GeographicLocation) => {
    return `${location.latitude},${location.longitude}`;
}
const googleMapsSinglePointQueryBuilder = (location:GeographicLocation, zoom:number) => {
    return `https://maps.google.com/maps?q=${createLocationQueryValue(location)}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`;
}

export const GoogleMap = (props:MapProps) => {
    if(props.type === "business"){
        return <iframe 
            title="Business map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.793086345568!2d-9.134997084387631!3d38.72255926483577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19338fbb8adc6f%3A0x848e4d61d57e1d80!2sAgencia%20De%20Leil%C3%B5es%20C.Paraiso%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1572985772285!5m2!1spt-PT!2spt" 
            width={props.width} 
            height={props.height} 
            style={{border:"0"}} />;
    }
    if(props.type === "single"){
        const singlePoint = props.markers[0];
        return <iframe 
            title="Single map"
            width={props.width} 
            height={props.height}
            id='mapcanvas' 
            src={googleMapsSinglePointQueryBuilder(singlePoint.location,props.zoom)}
            frameBorder='0' 
            scrolling='no' />;
    }
}





