import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { NotificationType } from 'modbin-web';

export const NotificationView = (props: { className?:string, type: NotificationType, header?:string, content:JSX.Element|string }): JSX.Element => {
    const {className="notification", type, header, content} = props;
    const resultHeader = header !== undefined 
        ? <h4>{header}</h4>
        : <Fragment></Fragment>;  
    const resultType = type === "error" ? "danger" : type;
    return <Alert bsStyle={resultType} className={className}>
        {resultHeader}
        {content}
    </Alert>;
}