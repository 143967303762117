import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { NotificationView } from "./NotificationView";
import { ReactToastifyUserNotificationService } from "./ReactToastifyUserNotificationService";
import { UiTestDescription, NotificationType } from "modbin-web";

export const description:UiTestDescription = {
    id:"ToastViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Different views for a single item",
    parentId:"components"
};

export class NotificationViewUiTest extends Component<{},{}>{
    
    messages: { type: NotificationType, header:any, content:any}[] = [
        {
            type: NotificationType.WARNIG,
            header: "This is a warning",
            content: "This is my content"
        },
        {
            type: NotificationType.INFO,
            header: "This is an info",
            content: "This is my content"
        },
        {
            type: NotificationType.ERROR,
            header: "This is an error",
            content: "This is my content"
        },
        {
            type: NotificationType.SUCCESS,
            header: "This is a success",
            content: "This is my content"
        }
    ];

    showToast = (type: string) => {
        var item = this.messages.filter(m => m.type === type)[0];
        toast(<NotificationView className="toast" type={item.type} header={item.header} content={item.content}></NotificationView>);
    };

    render() {
        const userNotificationService = new ReactToastifyUserNotificationService();
        return <div>
            <div>
                <h2>Generic toasts</h2>
                {this.messages.map(m => <NotificationView type={m.type} header={m.header} content={m.content}></NotificationView>)}
            </div>
            <div>
                <h2>Simple example with react-toastify</h2>

                <button onClick={() => this.showToast("info")}>Show info</button>
                <button onClick={() => this.showToast("success")}>Show success</button>
                <button onClick={() => this.showToast("warning")}>Show warning</button>
                <button onClick={() => this.showToast("error")}>Show error</button>
                
            </div>

            <div>
                <h2>Using service</h2>

                <button onClick={() => userNotificationService.info("This is info")}>Show info</button>
                <button onClick={() => userNotificationService.success("This is success")}>Show success</button>
                <button onClick={() => userNotificationService.warning("This is warning")}>Show warning</button>
                <button onClick={() => userNotificationService.error("This is error")}>Show error</button>
                
            </div>
            <ToastContainer hideProgressBar={true} closeButton={false} />
        </div>;
    }
}