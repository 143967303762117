"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Entry = void 0;

var FileSystem_types_1 = require("../model/FileSystem.types");

var Path_1 = require("./Path");

var Entry =
/** @class */
function () {
  function Entry(path, type, stats) {
    this._path = this.parsePath(path);
    this._stats = stats ? stats : undefined;
    this._type = type;
  }

  Entry.prototype.parsePath = function (path) {
    return typeof path === "string" ? new Path_1.Path(path) : path;
  };

  Entry.prototype.getPath = function () {
    return this._path.path;
  };

  Entry.prototype.getPathSegments = function () {
    return this._path;
  };

  Entry.prototype.getStats = function () {
    return this._stats;
  };

  Entry.prototype.isStatsLoaded = function () {
    return this._stats !== undefined;
  };

  Entry.prototype.isDirectory = function () {
    return this._type === FileSystem_types_1.EntryType.Directory;
  };

  Entry.prototype.isFile = function () {
    return this._type === FileSystem_types_1.EntryType.File;
  };

  return Entry;
}();

exports.Entry = Entry;