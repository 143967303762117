import React from "react";
import { NavigationItem } from "./NavigationItem";
import { withTranslation } from 'react-i18next';
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";

export const InnerPublicMenu = observer((props:TranslationProps) => {
    return <nav id="navigation" className="style-1">
        <ul>
            <NavigationItem text={props.t("menu.home")} to="public:home" />
            <NavigationItem text={props.t("menu.events")} to="business:events" />
            <NavigationItem text={props.t("menu.lots")} to="business:lots" />
            <NavigationItem text={props.t("menu.company")} to="public:company" />
            <NavigationItem text={props.t("menu.professionals")} to="public:professionals" />
            <NavigationItem text={props.t("menu.contacts")} to="public:contacts" />
        </ul>
    </nav>;
})

export const PublicMenu = withTranslation(["common"])(InnerPublicMenu as any) as any;

// export const InnerPublicMenu = observer((props:TranslationProps) => {
//     return <Navbar id="navigation" className="style-1">
//         <Navbar.Header>
//     <Navbar.Toggle />
//   </Navbar.Header>
//   <Navbar.Collapse >
//     <Nav>
//             <NavigationItem text={props.t("menu.home")} to="public:home" />
//             <NavigationItem text={props.t("menu.events")} to="business:events" />
//             <NavigationItem text={props.t("menu.lots")} to="business:lots" />
//             <NavigationItem text={props.t("menu.company")} to="public:company" />
//             <NavigationItem text={props.t("menu.professionals")} to="public:professionals" />
//             <NavigationItem text={props.t("menu.contacts")} to="public:contacts" />
    
//         </Nav>
//   </Navbar.Collapse>
// </Navbar>;
// })

// export const PublicMenu = withTranslation(["common"])(InnerPublicMenu as any) as any;