"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultIocContainer = void 0;

var DefaultIocContainer =
/** @class */
function () {
  function DefaultIocContainer(logger) {
    this.dependencies = new Map();
    this.dependencies.set("logger", logger);
  }

  DefaultIocContainer.prototype.getLogger = function () {
    return this.getDependency("logger");
  };

  DefaultIocContainer.prototype.register = function (identifier, element) {
    if (!this.dependencies.has(identifier)) {
      this.getLogger().trace("IOC: Registered " + identifier);
      this.dependencies.set(identifier, element);
      return;
    }

    throw new Error("IOC: Dependency " + identifier + " is already registered!");
  };

  DefaultIocContainer.prototype.replace = function (identifier, element) {
    if (this.dependencies.has(identifier)) {
      this.getLogger().trace("IOC: Replaced " + identifier);
      this.dependencies.set(identifier, element);
      return;
    }

    throw new Error("IOC: Dependency " + identifier + " is not registered!");
  };

  DefaultIocContainer.prototype.registerElementInfo = function (serviceDescriptor) {
    this.register(serviceDescriptor.name, serviceDescriptor.instance);
  };

  DefaultIocContainer.prototype.registerModule = function (module) {
    var _this = this;

    this.getLogger().trace("IOC: Starting register for module -> " + module.name);
    var bindings = module.createBindings(this);

    try {
      bindings.forEach(function (elem) {
        _this.registerElementInfo(elem);
      });
      this.getLogger().trace("IOC: Ended register for module -> " + module.name);
    } catch (error) {
      throw new Error("IOC: Error on registering dependencies from module " + module.name + ". Inner error: " + error);
    }
  };

  DefaultIocContainer.prototype.getDependency = function (identifier) {
    if (this.dependencies.has(identifier)) {
      return this.dependencies.get(identifier);
    }

    throw new Error("IOC: Dependency " + identifier + " is not registered!");
  };

  DefaultIocContainer.prototype.resolve = function (identifier) {
    return this.getDependency(identifier);
  };

  return DefaultIocContainer;
}();

exports.DefaultIocContainer = DefaultIocContainer;
;