import { observer } from "mobx-react";
import { TranslationProps, IKeyedObject, LogLevel, isNotNullOrEmptyString, ILoggerFactory } from "modbin-domain";
import { application } from "../../../Startup";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { Fragment } from "react";
import { FormInput } from "../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../components/buttons/ActionButton";
import { FormSelect } from "../../../components/forms/elements/FormSelect";
import { observable } from "mobx";

export enum ProxyType {
    PASSTHROUGH = "passthrough",
    CUSTOM = "custom"
}

export type Settings = {
    proxyType: ProxyType,
    proxy:string,
    officeId:string,
    authorizationHeaderName?:string,
    authorizationToken?:string,
    imageServiceUrl?:string,
    environment:string,
    proxies:IKeyedObject<{text:string,value:string,url:string, imageServiceUrl:string}>
}

export const createLogLevelOptions = ()=>{
    const levelOptions = [{
        value:"0",
        text:"Off"
    },{
        value:"100",
        text:"Fatal"
    },{
        value:"200",
        text:"Error"
    },{
        value:"300",
        text:"Warning"
    },{
        value:"400",
        text:"Info"
    },{
        value:"500",
        text:"Debug"
    },{
        value:"600",
        text:"Trace"
    },{
        value:"700",
        text:"All"
    }];
    return levelOptions;
}

export const LoggingFormView = observer((props:TranslationProps & {
    current:{
        name:string,
        level:LogLevel
    }, 
    loggerFactory:ILoggerFactory, 
    onSave:(ev:any)=>Promise<void>
}) => {
    const getTranslationFooter = (key:string) => props.t(`common:footer.${key}`); 
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loggingSchema = Yup.object().shape({
            name: Yup.string()
              .required(createRequiredMessage(getTranslationFooter("emailField"))),
            level: Yup.string()
              .required(createRequiredMessage(getTranslationFooter("nameField")))
          });
    const levelOptions = createLogLevelOptions();
    const loggerOptions = props.loggerFactory.getProviders().map(x => { return {value:x.getIdentifier(), text:x.getIdentifier()}});
    const initialState = {
        name:props.current.name,
        level:props.current.level,
    }
      return (
          <div>
              <h2>Logging</h2>
                <Formik
                initialValues={initialState}
                validationSchema={loggingSchema}
                onSubmit={(values, actions) => {
                    return props.onSave(values as any);
                }}
                 
              >
                {(formProps) => { 
                    const selected:any = props.loggerFactory.getProvider(formProps.values.name);               
                    const Elem = selected.element !== undefined 
                        ? selected.element! 
                        : undefined;
                    const propertiesView = Elem !== undefined 
                        ? <div>
                            <Elem {...selected.properties}></Elem>
                        </div>
                        : <Fragment></Fragment>
                    return (
                
                  <Form>
                      <div className="form-row form-row-wide">
                        <FormSelect name="name" label="Logger" options={loggerOptions} formikState={formProps} />
                      </div>
                      <div className="form-row form-row-wide">
                        <FormSelect name="level" label="Level" options={levelOptions} formikState={formProps} />
                      </div>                      
                      {propertiesView}
                     <div className="form-row">
                        <ActionButton type={ButtonType.SAVE} onClick={()=>{alert("sad");return formProps.submitForm()}}  style={{float:"right",marginTop: "20px"}} />
                    </div>
                  </Form>
                )}}
              </Formik>
            </div>
      );
  });