"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.otherSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var OtherSubCategory_1 = require("./OtherSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[OtherSubCategory_1.OtherSubCategory.FOOTWEAR, {
  pt: "Calçado",
  en: "Footewear"
}], [OtherSubCategory_1.OtherSubCategory.GOLDJEWELRY, {
  pt: "Artigos de ourivesaria",
  en: "Gold jewelry"
}], [OtherSubCategory_1.OtherSubCategory.CLOTHING, {
  pt: "Vestuário",
  en: "Clothing"
}], [OtherSubCategory_1.OtherSubCategory.STOCK, {
  pt: "Stock",
  en: "Stock"
}], [OtherSubCategory_1.OtherSubCategory.PETS, {
  pt: "Animais",
  en: "Pets"
}], [OtherSubCategory_1.OtherSubCategory.MISCELLANEOUS, {
  pt: "Diversos",
  en: "Miscellaneous"
}], [OtherSubCategory_1.OtherSubCategory.ART, {
  pt: "Arte",
  en: "Art"
}], [OtherSubCategory_1.OtherSubCategory.RAWMATERIAL, {
  pt: "Matéria prima",
  en: "Raw material"
}], [OtherSubCategory_1.OtherSubCategory.UNDEFINED, {
  pt: "Indefinido",
  en: "Undefined"
}], [OtherSubCategory_1.OtherSubCategory.ANTIQUES, {
  pt: "Antiguidades",
  en: "Antiques"
}], [OtherSubCategory_1.OtherSubCategory.TECHNOLOGY, {
  pt: "Tecnologia",
  en: "Technology"
}], [OtherSubCategory_1.OtherSubCategory.MUMISMATICSANDPHILATELY, {
  pt: "Mumismática e filatelia",
  en: "Mumismatics and philately"
}], [OtherSubCategory_1.OtherSubCategory.WATCHESANDJEWELRY, {
  pt: "Relógios e jóias",
  en: "Watches and jewelry"
}], [OtherSubCategory_1.OtherSubCategory.LAMPS, {
  pt: "Candeeiros",
  en: "Lamps"
}], [OtherSubCategory_1.OtherSubCategory.DISKSANDBOOKS, {
  pt: "Discos e livros",
  en: "Disks and books"
}], [OtherSubCategory_1.OtherSubCategory.COMPUTERSANDGAMES, {
  pt: "Computadores e jogos",
  en: "Computers and games"
}], [OtherSubCategory_1.OtherSubCategory.WINESANDDRINKS, {
  pt: "Vinho e bebidas",
  en: "Wine and drinks"
}] //57
]);
exports.otherSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de outros",
    value: translationObject.pt
  },
  en: {
    name: "Other subcategory",
    value: translationObject.en
  }
};