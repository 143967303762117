import React from "react"
import { withTranslation } from "react-i18next";
import { TranslationProps} from 'modbin-domain';
import { NotificationView } from "../../../../../components/notifications/NotificationView";
import { NotificationType } from "modbin-web";

export const InnerRegistrationSuccessfulMessage = (props:TranslationProps)=>{
    const getTranslationSessions = (key:string) => props.t(`screens:public.sessions.${key}`); 
    return <NotificationView type={NotificationType.SUCCESS} header={getTranslationSessions("registerSuccessfulTitle")} content={<p>{getTranslationSessions("registerSuccessfulText")}</p>} ></NotificationView>;
}

export const RegistrationSuccessfulMessage = (withTranslation(["common"])(InnerRegistrationSuccessfulMessage as any) as any) as (props:any) => JSX.Element;