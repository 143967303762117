"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eventsProfile = void 0;

var EventQueryConverter_1 = require("./EventQueryConverter");

var EventConverter_1 = require("./EventConverter");

var BidConverter_1 = require("../bids/BidConverter");

var sellingEventQuery = {
  sourceType: "sellingEventQuery",
  targetType: "auctionQuery",
  converter: EventQueryConverter_1.convertfromSellingEventQuery
};
var auctionToSellingEvent = {
  sourceType: "auction",
  targetType: "sellingEvent",
  converter: EventConverter_1.convertToEvent
};
var bidToLicitacao = {
  sourceType: "bid",
  targetType: "licitacao",
  converter: BidConverter_1.convertFromBid
};
var licitacaoToBid = {
  sourceType: "licitacao",
  targetType: "bid",
  converter: BidConverter_1.convertToBid
};
var bidToLicitacaoEnglish = {
  sourceType: "bid",
  targetType: "licitacaoeng",
  converter: BidConverter_1.convertFromBidToLicitacaoEnglish
};
exports.eventsProfile = {
  name: "Events",
  definitions: [auctionToSellingEvent, sellingEventQuery, bidToLicitacao, licitacaoToBid, bidToLicitacaoEnglish]
};