"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAuthorized = exports.getPathForEndpoint = exports.getEndpointDefinitionIfMatched = exports.isRouteAndEndpointMatch = exports.isRouteAndPathTemplateMatch = exports.extractPathOnly = void 0;

var modbin_domain_1 = require("modbin-domain");

var match = require("path-to-regexp").match;

exports.extractPathOnly = function (path) {
  var queryIndex = path.indexOf("?");

  if (queryIndex > 0) {
    return path.substring(0, queryIndex);
  }

  return path;
};

exports.isRouteAndPathTemplateMatch = function (route, template) {
  var matcher = match(template);
  var matched = matcher(route);
  return matched !== false;
};

exports.isRouteAndEndpointMatch = function (route, endpoint) {
  var hasRelativePath = modbin_domain_1.isNotNullOrEmptyString(endpoint.relativePath);
  var endpointPathTemplate = modbin_domain_1.removeHeadAndtrailingSlash(endpoint.path);
  route = modbin_domain_1.removeHeadAndtrailingSlash(route);
  var matched = exports.isRouteAndPathTemplateMatch(route, endpointPathTemplate);

  if (matched) {
    return true;
  }

  return hasRelativePath ? exports.isRouteAndPathTemplateMatch(route, modbin_domain_1.removeHeadAndtrailingSlash(endpoint.relativePath) + "/" + endpointPathTemplate) : false;
};

exports.getEndpointDefinitionIfMatched = function (url, allowedEndpoints) {
  var urlPath = exports.extractPathOnly(url);
  var result = undefined;

  for (var i = 0; i < allowedEndpoints.length; i++) {
    var currEndpoint = allowedEndpoints[i];

    if (exports.isRouteAndEndpointMatch(urlPath, currEndpoint)) {
      result = currEndpoint;
      break;
    }
  } //console.log("Endpoint match -> path: ",urlPath, result)


  return result;
};

exports.getPathForEndpoint = function (path, endpoint, templateParameters) {
  var newPath = modbin_domain_1.removeHeadAndtrailingSlash(path);

  if (endpoint.relativePath !== undefined) {
    var newRelativePath = modbin_domain_1.removeHeadAndtrailingSlash(endpoint.relativePath);

    if (!newRelativePath.startsWith(newPath.split("/")[0])) {
      newPath = "/" + modbin_domain_1.buildUrlPathFromPathTemplate(newRelativePath, templateParameters, {
        begin: ":"
      }) + "/" + newPath;
      return newPath;
    }
  }

  return "/" + newPath;
};

exports.isAuthorized = function (req, endpoint) {
  return endpoint.methods.filter(function (m) {
    return m === req.method;
  });
};