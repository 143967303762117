import React from 'react';
import { downloadFile } from './Download';
import { LoadingButton } from '../buttons/LoadingButton';

export class DownloadButton extends React.Component<{
    url: string, 
    downloadFile?:(url:string,fileName:string,mimeType?:string)=>Promise<void>,
    name: string,
    fileName: string, 
    icon?:string;
    mimeType?: string | undefined},{}> {

    onClick(){
        const resultDownloadFile = this.props.downloadFile !== undefined
            ? this.props.downloadFile
            : downloadFile
        return resultDownloadFile(this.props.url,this.props.fileName,this.props.mimeType);
    }

    render() {
        const {icon="fa  fa-file-text-o"} = this.props;
        return <LoadingButton 
            text={this.props.name} 
            icon={icon}
            showLoadingIcon={true} 
            onClick={()=>{return this.onClick()}}
        />
    }
}
