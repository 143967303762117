import { Environment, RollbarLogger } from "modbin-domain-extensions";
import { LogLevel, ILoggerProvider, ILogger } from "modbin-domain";
import { BrowserRollbarLoggerService, RollbarLoggerServiceOptions } from "./BrowserRollbarLogger";
import { IContextProvider } from "../../configuration/temp/web/application/ContextProvider";

const rollbarProperties:RollbarLoggerServiceOptions = {
    accessToken: "84f2b6ddc484473ea155d859ff0897a6",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload:{
        environment:Environment.DEVELOPMENT,
        server:{
            host:"localhost:3000"
        }
    }
}

export class RollbarLoggerProvider implements ILoggerProvider {
    
    constructor (private contextProvider:IContextProvider){

    }

    getIdentifier(): string {
        return "Rollbar";
    }

    create(level:LogLevel, properties: any):ILogger {
        const props:RollbarLoggerServiceOptions = {
            ...properties,
            getUserContext:()=>{
                return this.contextProvider.getContext().user
            }
        };
        const rollbarLoggerService = new BrowserRollbarLoggerService(props);
        rollbarLoggerService.configure();
        const consoleLogger = new RollbarLogger(level,rollbarLoggerService);
        return consoleLogger;
    }
}