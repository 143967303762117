import React from "react";
import moment from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export const EndDateView = (props:{endDate:Date, extendedDate?:Date}) => {
    const {endDate,extendedDate} = props;
    const finalDate = extendedDate !== undefined 
        ? extendedDate
        : endDate;
    const info = <i className="fa fa-calendar" style={{color:"red"}}>
        <span style={{
            fontSize: "16px",
            marginLeft: "10px",
            fontWeight: "bold"}}
        >
            {moment(finalDate).format("DD/MM/YYYY HH:mm")}
        </span>
    </i>;
    const dateView = <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip:date`}>Data de fim </Tooltip>}>
        {info}
    </OverlayTrigger>;
    return dateView;
}