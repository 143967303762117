import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Lot, InformationRequest, User } from "ibase-auctions-domain";
import {ContactFormFieldsView} from "./ContactForm";
import { ActionButton, ButtonType } from "../../buttons/ActionButton";
import { getStringValueOrDefault, getNumberValueAsStringOrDefault } from "modbin-domain";


const ContactFormModalViewTrigger = (props:{onClick:()=>Promise<void>, otherProps:any}) => {
    return  <Modal.Footer>
        <ActionButton type={ButtonType.SEND} onClick={()=>{return props.onClick()}} />
        {/* <Button onClick={(ev:any) => this.handleClose(ev)}>{getTranslation("close")}</Button> */}
        {/* <Button onClick={(ev:any) => this.handleSubmitForm(ev)}>{getTranslation("send")}</Button>
        <Button onClick={(ev:any) => this.handleClose(ev)}>{getTranslation("close")}</Button> */}
    </Modal.Footer>;
}

@observer
export class ContactFormModalView extends React.Component<TranslationProps & {selectedLot?:Lot, activeUser?:User, onSubmit:(request:InformationRequest)=>Promise<void>},{}> {
    @observable visible:boolean = false;
    
    getUserData(){
        return (this.props.activeUser !== undefined)
            ? {name: `${this.props.activeUser!.firstName} ${this.props.activeUser!.lastName}`, id: this.props.activeUser!.getId(), email:this.props.activeUser!.contact.email}   
            : {name:"",userId:"",email:""};
    }

    getLotData(){
        if(this.props.selectedLot === undefined){
            return {subject:"",lotId:""};
        }
        const {number,processReference,processId} = this.props.selectedLot!;
        const lotId = this.props.selectedLot!.getId();
        const lotIdentifier = getNumberValueAsStringOrDefault(number,lotId);
        let processIentifier = getStringValueOrDefault(processReference,getStringValueOrDefault(processId,""));
        
        const loteInfo = `Lote: ${lotIdentifier}`;
        const processInfo = `Processo: ${processIentifier}.`;
        return (this.props.selectedLot !== undefined
            ? {subject:`Pedido de informação. ${processInfo} ${loteInfo}`, lotId:this.props.selectedLot!.getId()}   
            : {subject:"",lotId:""});
    }

    getRequest(){
        return {...this.getUserData(),...this.getLotData(),message:""};
    }

    onSubmit(request:InformationRequest):Promise<void>{
        return this.props.onSubmit(new InformationRequest(request)).then(x =>{
            this.visible = false;
            //console.log(request);     
            return Promise.resolve();
        }).catch(x =>{this.visible=false;});
    }

    handleShow(ev:any){
        ev.preventDefault();
        this.visible = true;
    }

    handleClose(ev?:any){
        if(ev !== undefined) {
            ev.preventDefault();
        }
        this.visible = false;
    }

    render(){
        const getTranslation = (key:string) => this.props.t(`common:buttons.${key}`); 
        const initialValues = this.getRequest();
        return 	<Fragment>
            <a href="contact.html" 
                className="button fullwidth margin-top-20" 
                onClick={(ev:any) => this.handleShow(ev)}>
                    <i className="fa fa-envelope-o"></i>
                    {getTranslation("informationRequest")}
            </a>

            <Modal show={this.visible} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{getTranslation("informationRequest")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactFormFieldsView 
                        trigger={{
                            Component:ContactFormModalViewTrigger
                        }} 
                        initialValues={initialValues as any} t={this.props.t} onSubmit={(req:any)=>{return this.onSubmit(req)}}></ContactFormFieldsView>
                </Modal.Body>
                {/* <Modal.Footer>
                    <ActionButton type={ButtonType.SEND} onClick={()=>{return this.handleSubmitForm()}} />
                     <Button onClick={(ev:any) => this.handleSubmitForm(ev)}>{getTranslation("send")}</Button>
                    <Button onClick={(ev:any) => this.handleClose(ev)}>{getTranslation("close")}</Button> 
                </Modal.Footer> */}
            </Modal>
        </Fragment>; 
    }
}