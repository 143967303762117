import React from "react";
import { NewsletterSubscriptionFormView } from "./NewsletterSubscriptionForm";
import { TranslationProps } from "modbin-domain";
import { UiTestDescription, sleepableConsoleLogger } from "modbin-web";

export const description:UiTestDescription = {
    id:"NewsletterSubscriptionFormUiTest",
    groupId:"components",
    description:"lalal",
    title:"Different views for a single item",
    parentId:"components"
};

export class NewsletterSubscriptionFormUiTest extends React.Component<TranslationProps,{}> {

    render(){
        return <NewsletterSubscriptionFormView 
            t={this.props.t}
            onSubscribe={(s)=>{return sleepableConsoleLogger(s)}}
        />; 
    }
}
