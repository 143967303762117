import { IRollbarLoggerService, Environment } from "modbin-domain-extensions";
//import Rollbar from "../dts/rollbar";
var Rollbar = require('rollbar');

export type RollbarLoggerServiceOptions = {
    accessToken: string,
    captureUncaught: boolean,
    captureUnhandledRejections: boolean,
    payload:{
        environment:Environment,
        server:{
            host:string
        }
    },
    getUserContext?():{id:string, email?:string}
}

export class BrowserRollbarLoggerService implements IRollbarLoggerService {

    rollbar:any;
    options:RollbarLoggerServiceOptions;
    userContext?:{id:string, email?:string} = undefined;
    getUserContext?:()=>{id:string, email?:string} = undefined;
    //contextChanged?:(context:{id:string, email?:string})=>Promise<void> = undefined;

    constructor(options:RollbarLoggerServiceOptions) {
        const {getUserContext,...rest}  = options;
        this.rollbar = new Rollbar(rest);
        this.options = options;
        this.getUserContext = getUserContext;
    }

    configure() {
        const person = this.userContext !== undefined 
            ? { id: this.userContext.id, email:this.userContext.email}
            : null;
        this.rollbar.configure({
            payload: {
                person: person
            }
        });
    }

    checkUserContext(){
        try {
            if(this.getUserContext !== undefined){
                const context = this.getUserContext();
                if(context !== undefined && context !== null){
                    if(this.userContext === undefined || this.userContext === null){
                        this.userContext = context;
                        this.configure();
                    }
                    else {
                        if(context.id !== this.userContext.id){
                            this.userContext = context;
                            this.configure();
                        }
                    }
                }
            }
        }
        catch(e){
            console.log(e);
        }
    }

    critical(message: string, ...data: any){
        this.checkUserContext();
        this.rollbar.critical(message, ...data);
    }

    debug(message: string, ...data: any) {
        try{
            this.checkUserContext();
            this.rollbar.debug(message, ...data);
        }
        catch(e){
            console.log(e);
        }
    }
    
    info(message: string, ...data: any) {
        try{
            this.checkUserContext();
            this.rollbar.info(message, ...data);
        }
        catch(e){
            console.log(e);
        }
    }

    warning(message: string, ...data: any) {
        try{
            this.checkUserContext();
            this.rollbar.warning(message, ...data);
        }
        catch(e){
            console.log(e);
        }
    }

    error(message: string, ...data: any) {
        try{
            this.checkUserContext();
            this.rollbar.error(message, ...data);
        }
        catch(e){
            console.log(e);
        }
    }
}