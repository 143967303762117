"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDoubleConverter = void 0;

exports.createDoubleConverter = function (toTypeConverter) {
  var fromTypeConverter = {};
  Object.keys(toTypeConverter).forEach(function (x) {
    fromTypeConverter[toTypeConverter[x]] = x;
  });
  return {
    toType: toTypeConverter,
    fromType: fromTypeConverter
  };
};