"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServerTimeService = void 0;

var ServerTimeService =
/** @class */
function () {
  function ServerTimeService(apiClient, url) {
    this.apiClient = apiClient;
    this.url = url;
    this.serverTime = undefined;
    this.initialized = false;
    this.clientTimeDifference = 0;
  }

  ServerTimeService.prototype.initialize = function () {
    var _this = this;

    return this.getServerTime().then(function (x) {
      _this.initialized = true;
      return x;
    });
  };

  ServerTimeService.prototype.getServerTime = function () {
    var _this = this;

    return this.apiClient.get(this.url).then(function (x) {
      _this.serverTime = x;
      _this.clientTimeDifference = _this.serverTime - Date.now();
      return x;
    });
  };

  ServerTimeService.prototype.getCurrentDateInUtc = function () {
    if (this.serverTime === undefined) {
      //throw new Error("Server time service: not initialized yet. Please call getServerTime first.");
      console.log("Supressed error on getCurrentDateInUtc: Server time service: not initialized yet. Please call getServerTime first.");
    }

    return new Date(new Date(Date.now() + this.clientTimeDifference).toISOString());
  };

  ServerTimeService.prototype.getCurrentDate = function () {
    if (this.serverTime === undefined) {
      //throw new Error("Server time service: not initialized yet. Please call getServerTime first.");
      console.log("Supressed error on getCurrentDate: Server time service: not initialized yet. Please call getServerTime first.");
    }

    return new Date(Date.now() + this.clientTimeDifference);
  };

  return ServerTimeService;
}();

exports.ServerTimeService = ServerTimeService;