"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createImageUrlMapper = exports.createImageUrlConverter = void 0;

exports.createImageUrlConverter = function (fileService) {
  var converter = function converter(externalImagePath) {
    //console.log("ImageUrlConverter:externalImagePath", externalImagePath);
    var result = fileService.getImageUrl(externalImagePath); //console.log("ImageUrlConverter:result", result);

    return result;
  };

  return converter;
};

exports.createImageUrlMapper = function (fileService) {
  return {
    sourceType: "foto",
    targetType: "imageUrl",
    converter: exports.createImageUrlConverter(fileService)
  };
};