"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebApplication = void 0;

var modbin_domain_1 = require("modbin-domain");

var WebApplication =
/** @class */
function (_super) {
  __extends(WebApplication, _super);

  function WebApplication(options) {
    var _this = this;

    var stores = options.stores,
        views = options.views,
        contextProvider = options.contextProvider,
        translations = options.translations,
        rest = __rest(options, ["stores", "views", "contextProvider", "translations"]);

    _this = _super.call(this, rest) || this;
    _this.stores = stores;
    _this.views = views;
    _this.translations = translations;
    _this.contextProvider = contextProvider;

    _this._internalInitialize(options);

    return _this;
  }

  WebApplication.prototype._internalInitialize = function (options) {
    this.contextProvider.setContext({
      user: {
        id: undefined,
        isAuthenticated: false,
        roles: []
      }
    });
  };

  WebApplication.prototype.getContext = function () {
    return this.contextProvider.getContext();
  };

  WebApplication.prototype.setContext = function (context) {
    this.contextProvider.setContext(context);
  };

  return WebApplication;
}(modbin_domain_1.Application);

exports.WebApplication = WebApplication;