"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestApiClient = void 0;

var modbin_domain_1 = require("modbin-domain");

var RestApiClient =
/** @class */
function () {
  function RestApiClient(options) {
    this._httpClient = options.httpClient;
    this._baseUrl = options.baseUrl;
    this._userAuthentication = options.userAuthentication;
    this._apiAuthentication = options.apiAuthentication;
    this._defaultContentType = options.defaultContentType;
    this._errorHandler = options.errorHandler;
  }

  RestApiClient.prototype.changeOptions = function (options) {
    this._baseUrl = options.baseUrl;
    this._userAuthentication = options.userAuthentication;
    this._apiAuthentication = options.apiAuthentication;
  };

  RestApiClient.prototype.createEndpointUrl = function (path) {
    if (path.startsWith(this._baseUrl)) {
      return path;
    }

    var url = modbin_domain_1.removeHeadAndtrailingSlash(this._baseUrl) + "/" + modbin_domain_1.removeHeadAndtrailingSlash(path);
    return url;
  };

  RestApiClient.prototype.createCommonHeaders = function () {
    var headers = {};
    headers["Content-Type"] = this._defaultContentType;

    if (this._apiAuthentication !== undefined && this._apiAuthentication.token !== undefined) {
      headers[this._apiAuthentication.header] = this._apiAuthentication.token;
    }

    if (this._userAuthentication !== undefined && this._userAuthentication.getToken() !== undefined) {
      headers[this._userAuthentication.header] = this._userAuthentication.getToken();
    }

    return headers;
  };

  RestApiClient.prototype.createRequestHeaders = function (options) {
    var commonHeaders = this.createCommonHeaders();

    if (options === undefined) {
      return {
        headers: commonHeaders
      };
    }

    options.headers = Object.assign(commonHeaders, options.headers);
    return options;
  }; // handleResponse: (response: HttpResponse) => Promise<any> = response => 
  //     new Promise((resolve:any) => { 
  //         if(response.isError){
  //             return resolve(response.body);
  //         }
  //         const errorMessage = this._errorHandler.
  //         Promise.reject(this._errorHandler.handleError(response))
  //     });


  RestApiClient.prototype.get = function (path, options) {
    var _this = this;

    var url = this.createEndpointUrl(path);
    var resultOptions = this.createRequestHeaders(options);
    return this._httpClient.get(url, resultOptions).then(function (response) {
      return response.body;
    }, function (reason) {
      var error = _this._errorHandler.map(reason);

      return _this._errorHandler.handleError(error);
    });
  };

  RestApiClient.prototype.post = function (path, body, options) {
    var _this = this;

    var url = this.createEndpointUrl(path);
    var resultOptions = this.createRequestHeaders(options);
    return this._httpClient.post(url, body, resultOptions).then(function (response) {
      return response.body;
    }, function (reason) {
      var error = _this._errorHandler.map(reason);

      return _this._errorHandler.handleError(error);
    });
  };

  RestApiClient.prototype.put = function (path, body, options) {
    var _this = this;

    var url = this.createEndpointUrl(path);
    var resultOptions = this.createRequestHeaders(options);
    return this._httpClient.put(url, body, resultOptions).then(function (response) {
      return response.body;
    }, function (reason) {
      var error = _this._errorHandler.map(reason);

      return _this._errorHandler.handleError(error);
    });
  };

  RestApiClient.prototype.delete = function (path, options) {
    var _this = this;

    var url = this.createEndpointUrl(path);
    var resultOptions = this.createRequestHeaders(options);
    return this._httpClient.delete(url, resultOptions).then(function (response) {
      return response.body;
    }, function (reason) {
      var error = _this._errorHandler.map(reason);

      return _this._errorHandler.handleError(error);
    });
  };

  return RestApiClient;
}();

exports.RestApiClient = RestApiClient;