"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Fuel = void 0;
var Fuel;

(function (Fuel) {
  Fuel["GASOLINE"] = "gasoline";
  Fuel["DIESEL"] = "diesel";
  Fuel["LPG"] = "lpg";
  Fuel["HYBRID"] = "hybrid";
  Fuel["ELETRIC"] = "eletric";
})(Fuel = exports.Fuel || (exports.Fuel = {}));