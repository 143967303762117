import React from "react";
import { observer } from "mobx-react";

export type ActionButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
    disabled?:boolean;
    text:string;
    icon?:string;
    forwardedRef?:any;
    tReady?:any;
}

export const BasicButton = observer((props:ActionButtonProps) => {
    let {disabled=false,icon,text, forwardedRef,tReady,...rest} = props;
    const iconElem = icon !== undefined ? <i className={icon} /> : "";
    return  <button
        disabled={disabled}
        {...rest}
        >{iconElem}<span>{text}</span>
    </button>;
})