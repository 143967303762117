import { ApiRepositoryFactory, ResourceMapperFactory, UserAuthenticationDataStorageService,IBaseRestApiClientOptions, IBaseRestApiClient, IBaseErrorMessageMapper } from "ibase-auctions-api";
import { FileService } from "ibase-auctions-domain";
import { configProperties } from "../ConfigProperties";
import { ReactToastifyUserNotificationService } from "../../components/notifications/ReactToastifyUserNotificationService";
import { IUserNotificationService } from "modbin-domain";
import { DataSourceType, WebConfiguration } from "../Config.types";
import { IResourceMapper } from "modbin-domain-extensions";
import { IBaseApiErrorHandler } from "../temp/IBaseApiErrorHandler";
import { ConsoleLogger, LogLevel, ILogger, IIocContainer, IIocModule, IServiceDescriptor, ServiceDescriptor, BaseModule, ILoggerFactory } from "modbin-domain";
import { ApplicationConfiguration } from "../temp/AplicationConfiguration";
import { DeveloperReactToastifyUserNotificationService } from "../../components/notifications/DeveloperReactToastifyUserNotificationService";
import { IContextProvider } from "../temp/web/application/ContextProvider";
import { ConsoleEventService } from "../temp/ConsoleEventService";
import { GoogleAnalyticsEventService } from "../temp/GoogleAnalyticsEventService";

export class GlobalModule extends BaseModule implements IIocModule {
    dependencies:IServiceDescriptor[] = [];

    constructor(name:string, private contextProvider:IContextProvider){
        super(name);
    }

    createApiDataSourceBindings(resourceMapper:IResourceMapper, authenticationTokenGetter:()=>string, userNotificationService:IUserNotificationService, logger:ILogger, appConfiguration:ApplicationConfiguration):IServiceDescriptor[]{
        const apiConfig = {
            appDomain:"localhost:3000",
            endpoints:configProperties.apiConfig.endpoints,
            baseUrl:configProperties.apiConfig.baseUrl,
            authorizationToken:configProperties.apiConfig.authorizationToken,
            getUserAuthenticationToken:authenticationTokenGetter
        };
        
        const iBaseRestApiClientOptions:any = {
            baseUrl:configProperties.apiConfig.baseUrl,
            userAuthentication:{
               getToken:authenticationTokenGetter,
                //token:configProperties.apiConfig.authorizationWebToken,
            },
            apiAuthentication:{
                token:configProperties.apiConfig.authorizationToken
            },
            errorHandler: new IBaseApiErrorHandler(
                new IBaseErrorMessageMapper(),
                logger,
                userNotificationService,
                appConfiguration.features.showErrorDetails,
                appConfiguration.features.showDevelopementMessages
            )
        }
        const apiClient = new IBaseRestApiClient(iBaseRestApiClientOptions);
        const apiRepositoryFactory = new ApiRepositoryFactory(resourceMapper,apiClient);
        return [
            ServiceDescriptor.create("apiConfig",apiConfig),
            //ServiceDescriptor.create("httpClient",new FetchHttpClient()),
            ServiceDescriptor.create("apiClient",apiClient),
            ServiceDescriptor.create("apiRepositoryFactory",apiRepositoryFactory)
        ];
    }

    createWebConfigBinding():IServiceDescriptor{
        const readDataSource = configProperties.webConfig.dataSource;
        const dataSource = readDataSource === DataSourceType.API 
            ? DataSourceType.API
            : DataSourceType.LOCAL
        const { numberOfDaysToShowPastEvents=7, baseUrl, files} = configProperties.webConfig;
        const webConfig:WebConfiguration = {
            dataSource:dataSource,
            numberOfDaysToShowPastEvents: numberOfDaysToShowPastEvents,
            baseUrl: baseUrl,
            files:files
        }
        return ServiceDescriptor.create("webConfig",webConfig);
    }

    createAppConfigBinding():IServiceDescriptor{
        const appConfig = configProperties.appConfig;
        return ServiceDescriptor.create("appConfig",appConfig);
    }

    createCompanyInfoConfigBinding():IServiceDescriptor{
        const companyInfoConfig = configProperties.companyInfo;
        return ServiceDescriptor.create("companyInfoConfig",companyInfoConfig);
    }

    createServicesConfigBinding():IServiceDescriptor{
        const servicesConfig = configProperties.services;
        return ServiceDescriptor.create("servicesConfig",servicesConfig);
    }

    
    createBindings(options?: any):IServiceDescriptor[]{
        const appConfigBinding = this.createAppConfigBinding();
        const webConfigBinding = this.createWebConfigBinding();
        const servicesConfigBinding = this.createServicesConfigBinding();
        const companyInfoConfigBinding = this.createCompanyInfoConfigBinding();
        const imageBaseUrl = ServiceDescriptor.create("imageBaseUrl", configProperties.services.images.baseUrl);
        const visitBaseUrl = ServiceDescriptor.create("visitBaseUrl", configProperties.services.visits.baseUrl);
        const noImageUrl = ServiceDescriptor.create("noImageUrl", configProperties.services.images.noImageUrl);
        const homeDefaultBannerUrl = ServiceDescriptor.create("homeDefaultBannerUrl", configProperties.services.images.homeDefaultBannerUrl);
        const fileService = ServiceDescriptor.create("fileService", 
            new FileService(
                { 
                    basePath:imageBaseUrl.instance,
                    visitBasePath: visitBaseUrl.instance,
                    downloadUrlTemplate:`${configProperties.apiConfig.baseUrl}${configProperties.apiConfig.endpoints.sales.events.documentsDownload}`,
                    assetDownloadUrlTemplate:`${configProperties.apiConfig.baseUrl}${configProperties.apiConfig.endpoints.assets.lots.documentsDownload}`,
                    noImageAvailable:noImageUrl.instance
                }));
       // const logger = ServiceDescriptor.create("logger", new ConsoleLogger(LogLevel.ALL));   
        const resourceMapper = ServiceDescriptor.create("resourceMapper",ResourceMapperFactory.create(fileService.instance));
        const userNotificationService = ServiceDescriptor.create("userNotificationService", new ReactToastifyUserNotificationService());
        const developerNotificationService = ServiceDescriptor.create("developerNotificationService", new DeveloperReactToastifyUserNotificationService());   
        const userAuthenticationDataStorageService = ServiceDescriptor.create("userAuthenticationDataStorageService", new UserAuthenticationDataStorageService());

        const contextProvider = ServiceDescriptor.create("contextProvider", this.contextProvider);
        const businessEventService = ServiceDescriptor.create("businessEventService", new GoogleAnalyticsEventService(appConfigBinding.instance.googleAnalytics.code));

        //const loggerFactoryBinding = ServiceDescriptor.create("loggerFactory",this.container.resolve<ILoggerFactory>("loggerFactory"));

        let resultBindings = [
            appConfigBinding,
            webConfigBinding,
            servicesConfigBinding,
            companyInfoConfigBinding,
            imageBaseUrl,
            visitBaseUrl,
            homeDefaultBannerUrl,
            fileService,
            resourceMapper,
            userNotificationService,
            developerNotificationService,
            userAuthenticationDataStorageService,
        //    logger,
            businessEventService,
            contextProvider
        ];
        if(webConfigBinding.instance.dataSource === DataSourceType.API){
            const apiBindings = this.createApiDataSourceBindings(
                resourceMapper.instance,
                userAuthenticationDataStorageService.instance.getAuthenticationToken, 
                developerNotificationService.instance, 
                this.container.resolve<ILogger>("logger"), 
                appConfigBinding.instance);
            resultBindings = resultBindings.concat(apiBindings);
        }
        return resultBindings;
    }
}
