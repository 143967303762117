"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApiRepositoryFactory = void 0;

var modbin_api_1 = require("modbin-api"); // import { IApiBase } from "../domain/http/IApiBase";
// import { IResourceMapper } from "../domain/resources/IResourceMapper";
// import { ApiRepository } from "./ApiRepository";


var ApiRepositoryFactory =
/** @class */
function () {
  function ApiRepositoryFactory(mapper, apiClient) {
    this._resourceMapper = mapper;
    this._apiClient = apiClient;
  }

  ApiRepositoryFactory.prototype.createRepository = function (templates, mapperOptions, templateGetter) {
    return new modbin_api_1.RestApiRepository({
      template: templates,
      mappings: {
        mapper: this._resourceMapper,
        options: mapperOptions
      },
      httpClient: this._apiClient,
      templateGetter: templateGetter
    });
  };

  return ApiRepositoryFactory;
}();

exports.ApiRepositoryFactory = ApiRepositoryFactory;