"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNumberValueAsStringOrDefault = exports.getNumberValueOrDefault = exports.isNotNullOrEmptyNumber = exports.isNullOrEmptyNumber = exports.isNumber = void 0;

var GetValueOrDefault_1 = require("../functions/GetValueOrDefault");

exports.isNumber = function (number) {
  var result = typeof number === 'number' || number instanceof Number;
  return result;
};

exports.isNullOrEmptyNumber = function (number, includeZero) {
  if (includeZero === void 0) {
    includeZero = false;
  }

  var result = number === undefined || number === null || !exports.isNumber(number) || includeZero && number === 0;
  return result;
};

exports.isNotNullOrEmptyNumber = function (number, includeZero) {
  return !exports.isNullOrEmptyNumber(number, includeZero);
};

exports.getNumberValueOrDefault = function (value, defaultValue, options) {
  var includeZero = false;
  var selector = undefined;

  if (options !== undefined) {
    if (options.includeZero !== undefined) {
      includeZero = options.includeZero;
    }

    if (options.selector !== undefined) {
      selector = options.selector;
    }
  }

  var validator = function validator(v) {
    return exports.isNotNullOrEmptyNumber(value, includeZero);
  };

  var resultOptions = {
    selector: selector,
    validator: validator
  };
  return GetValueOrDefault_1.getValueOrDefault(value, defaultValue, resultOptions);
};

exports.getNumberValueAsStringOrDefault = function (value, defaultValue, includeZero) {
  return exports.getNumberValueOrDefault(value, defaultValue, {
    includeZero: includeZero,
    selector: function selector(x) {
      return x.toString();
    }
  });
};