import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TranslationProps } from "modbin-domain";
import { SessionViewStore } from '../../ViewStore';
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { createLocalizedNotification } from '../../../../../components/notifications/LocalizedNotificationView';
import { NotificationType } from 'modbin-web';
import { MainAppViewStore } from '../../../../../configuration/stores/MainAppViewStore';

@observer
export class ScreensSessionsNoActivationView extends Component<TranslationProps & {onReactivate:()=>void},{}> {
    
    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`); 
        const body = createLocalizedNotification({
            type:NotificationType.WARNIG,
            headerKey:"screens:public.sessions.activationInvalidNotificationTitle",
            textKey:"screens:public.sessions.activationInvalidNotificationText"
        });
        return <div>
            <TitleBar title={getTranslationSessions("activationInvalidTitle")}></TitleBar>
            <div className="container">
                {body}
                <div style={{textAlign:"center"}}>
                    <button className="button" onClick={()=>{this.props.onReactivate()}}>{getTranslationButtons("resendEmail")}</button>
                </div>
            </div>
        </div>
    }
}

@inject("mainAppViewStore") @observer
export class ScreensSessionsNoActivation extends Component<TranslationProps & {mainAppViewStore?:MainAppViewStore},{}> {
    
    render() {
        return <ScreensSessionsNoActivationView 
            t={this.props.t}
            onReactivate={() => { this.props.mainAppViewStore!.navigateTo("public:sessions:reactivation")}}
        />;
    }
}