import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { UiTestDescription, sleepableConsoleLogger } from "modbin-web";
import { ScreensSessionsReactivationView } from "./Reactivation";
import { reactivationSuccessState } from "../../operations/States";

export const description:UiTestDescription = {
    id:"ReactivationViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Reactivation page",
    parentId:"components"
};

export class NoActivationViewUiTest extends Component<TranslationProps,{}>{
        
    render(){
        return <ScreensSessionsReactivationView
        reactivationState = {reactivationSuccessState}
            onResend={(email:string)=>{return sleepableConsoleLogger(email)}}
            t={this.props.t}
        />;
    }
}