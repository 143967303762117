import { ScreensSupportProperties } from "./properties/Propertiex";
import { testViewsDefinitions } from "./tests/screens/UiTest.definitions";
import { ViewDefinition } from "modbin-web";
import { ScreensSupportIndex } from "./Index";
import { SupportLayout } from "./components/SupportLayout";
import { LayoutType } from "../../components/layout/Layouts.types";
import { ScreensAdministrationIndex } from "./administration/Index";

const screensSupportProperties:ViewDefinition = {
    name:"support:properties",
    component: ScreensSupportProperties,
    title:"Suporte properties", 
    route:{
        name:"support:properties",
        template:"/restricted/support/properties"
    },
    layout:{
        component: LayoutType.SUPPORT,
        props: {
            type:"fluid"
        }
    },
    parent:"support:index",
    nameTranslationKey:"Config properties"
    //,authorization:AuthorizationType.ADMINISTRATOR

};

const screensSupportAdministration:ViewDefinition = {
    name:"support:administration",
    component: ScreensAdministrationIndex,
    title:"Administration", 
    route:{
        name:"support:administration",
        template:"/restricted/support/administration"
    },
    layout:{
        component: LayoutType.SUPPORT,
        props: {
            type:"fluid"
        }
    },
    parent:"support:index",
    nameTranslationKey:"Config properties"
    //,authorization:AuthorizationType.ADMINISTRATOR

};

const supportIndexView:ViewDefinition = {
    name:"support:index",
    component: ScreensSupportIndex,
    title:"Suporte index", 
    route:{
        name:"support:index",
        template:"/restricted/support"
    },
    layout:{
        component: LayoutType.SUPPORT,
        props: {
            type:"fluid"
        }
    },
    parent:"public:home",
    nameTranslationKey:"Suport index"
    //,authorization:AuthorizationType.ADMINISTRATOR
};

export const supportViewDefinitions:ViewDefinition[] = [
    screensSupportProperties,
    supportIndexView,
    screensSupportAdministration,
    ...testViewsDefinitions
]