import { IIocContainer, IServiceDescriptor, ServiceDescriptor, BaseModule } from "modbin-domain";
import { ApiRepositoryFactory, UserAuthenticationDataStorageService, InMemoryUserAuthenticationService, UserAuthenticationService, SessionService } from "ibase-auctions-api";
import { InMemoryRepository } from "modbin-domain-extensions";
import { User, UserService } from "ibase-auctions-domain";
import { ApiConfiguration } from "../api/ApiConfig.types";
import { IRestApiClient } from "modbin-api";
import { users } from "../../screens/support/tests/data/Users";
import { IBaseModule } from "./IBaseModule";

export class UsersModule extends IBaseModule {

    constructor(){
        super("users");
    }
    
    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const userRepository = new InMemoryRepository<User>({initialElements:users});
        const userAuthenticationDataStorageService = kernel.resolve<UserAuthenticationDataStorageService>("userAuthenticationDataStorageService");
        const userAuthenticationService = new InMemoryUserAuthenticationService(userAuthenticationDataStorageService,userRepository as any);

        return [
            ServiceDescriptor.create("userRepository",userRepository),
            ServiceDescriptor.create("userAuthenticationService",userAuthenticationService)
        ];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
        const apiRepositoryFactory:ApiRepositoryFactory = kernel.resolve<ApiRepositoryFactory>("apiRepositoryFactory");
        
        const userRepository = apiRepositoryFactory!.createRepository<User>(
            undefined as any,
            {source:"proponente",target:"user",targetConstructor:User},
            () => {
                return {collection:apiConfig.endpoints.users.profiles.collection};
            }
        );
        
        const apiClient = kernel.resolve<IRestApiClient>("apiClient");
        const userAuthenticationDataStorageService = kernel.resolve<UserAuthenticationDataStorageService>("userAuthenticationDataStorageService");
        const userAuthenticationService = new UserAuthenticationService(apiClient!,userAuthenticationDataStorageService,{
            loginUrlTemplate: apiConfig.endpoints.users.sessions.login,
            activationUrlTemplate: apiConfig.endpoints.users.sessions.activate,
            reactivationUrlTemplate: apiConfig.endpoints.users.sessions.reactivate,
            passwordRecoveryUrlTemplate: apiConfig.endpoints.users.sessions.passwordRecovery,
            passwordRecoveryRequestUrlTemplate: apiConfig.endpoints.users.sessions.passwordRecoveryRequest
        });

        return [
            ServiceDescriptor.create("userRepository",userRepository),
            ServiceDescriptor.create("userAuthenticationService",userAuthenticationService)
        ];
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[] {
        const userService = new UserService(
            this.getDescriptor("userRepository")
        );
        const sessionService =  new SessionService(
            this.getDescriptor("userRepository"),
            this.getDescriptor("userAuthenticationService")
        );
        return [
            ServiceDescriptor.create("userService",userService),
            ServiceDescriptor.create("sessionService",sessionService),
        ];
    }
}