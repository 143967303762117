export const businessTranslations = {
  pt: {
    events: {
      index: {
        activeTitle: "Lista de leilões",
        activeFilter: "Leilões ativos",
        endedTitle: "Lista de leilões terminados",
        noActiveEvents:
          "De momento não existem leilões a decorrer. Em breve teremos mais oportunidades.",
        noEndedEvents: "De momento não existem leilões terminados.",
      },
      details: {
        title: "Detalhes do leilão",
        noLots: "O presente evento não tem lotes associados.",
        lotsTitle: "Lista de lotes",
      },
    },
    lots: {
      index: {
        title: "Pesquisa de lotes",
        noResults:
          "Não foram encontrados resultados com os parâmetros de pesquisa fornecidos. Por favor tente com outros parâmetros.",
      },
      details: {
        title: "Detalhes do lote",
        noTitle: "Não foi adicionado um título",
        descriptionTitle: "Descrição",
        noDescription: "Não foi adicionada uma descrição",
        locationTitle: "Localização",
        noLocation: "Não foram adicionados dados de localização geográfica.",
        documentsTitle: "Documentos",
        noDocuments: "Não existem documentos associados.",
        virtualVisitTitle: "Visita Virtual",
        noVirtualVisit: "Não existe visita virtual.",
        bookingsTitle: "Visitas",
        bookingsPerson: "Responsável",
        bookingsDates: "Datas",
        bookingsHours: "Horas",
        bookingsAddress: "Morada",
        noBookings: "Não foram adicionados dados de visita.",
        needLoginToBid: "Precisa de fazer login para licitar!",
        processDetails: "Detalhes do processo",
        bid: "Licitação",
        bidSuccessfulMessage:
          "A sua licitação foi recebida com sucesso! É neste momento a mais alta!",
        bidOutbidMessage:
          "A sua licitação foi superada. Neste momento já não é a mais alta!",
        bidHighestBidderText: "A maior licitação é sua.",
        bidNoBidsYetText: "Ainda não licitou neste lote.",
        bidOutbidText: "A sua licitação foi superada!",
        bidConfirmationTitle: "Confirmação de valor",
        bidConfirmationText: "Por favor confirme o valor proposto",
        bidConfirmationValueText: "Valor proposto:",
        proposalTemplateDownloadText: "Minuta de proposta",
        process: "Processo",
        insolvent: "Insolvente",
        seller: "Vendedor",
      },
    },
  },
  en: {
    events: {
      index: {
        activeTitle: "Auctions list",
        activeFilter: "Active auctions",
        endedTitle: "Ended auctions list",
        noActiveEvents:
          "There are no auctions at the moment. Soon we will have more opportunities.",
        noEndedEvents: "There are no ended auctions at the moment.",
      },
      details: {
        title: "Auction details",
        noLots: "The current event has no lots associated with it.",
        lotsTitle: "Lot list",
      },
    },
    lots: {
      index: {
        title: "Search lots",
        noLots:
          "No results were found using the provided parameter. Please try again with a different set of parameters.",
      },
      details: {
        title: "Lot details",
        noTitle: "A title was not provided",
        descriptionTitle: "Description",
        noDescription: "A description was not provided.",
        locationTitle: "Location",
        noLocation: "No geographic location data was provided.",
        documentsTitle: "Documents",
        noDocuments: "There are no documents on this item.",
        virtualVisitTitle: "Virtual Visit",
        noVirtualVisit: "There are no virtual visit on this item.",
        bookingsTitle: "Bookings",
        bookingsPerson: "Responsible",
        bookingsDates: "Dates",
        bookingsHours: "Hours",
        bookingsAddress: "Address",
        noBookings: "No data was provided for scheduling a visit.",
        needLoginToBid: "You need to be logged in to bid!",
        processDetails: "Legal process details",
        bid: "Bid",
        bidSuccessfulMessage:
          "Bid successfully received! You are the highest bidder!",
        bidOutbiddMessage:
          "You were outbid. You are no longer the highest bidder! ",
        bidHighestBidderText: "You are the highest bidder!",
        bidNoBidsYetText: "You have no bids on this lot yet.",
        bidOutbidText: "You were outbid.",
        bidConfirmationTitle: "Bid confirmation",
        bidConfirmationText: "Please confirm proposed value",
        bidConfirmationValueText: "Value:",
        proposalTemplateDownloadText: "Proposal template",
        process: "Legal process",
        insolvent: "Insovent",
        seller: "Seller",
      },
    },
  },
};
