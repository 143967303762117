import React from "react";
import moment from "moment";
import { TranslationProps } from "modbin-domain";

export const DateInformationView = (props:TranslationProps&{startDate:Date, endDate:Date}) => {
    const datesStyle:any = {
        float:"right"
    };
    const pStyle = {
        margin:"0px"
    }
    return <div className="row with-forms  margin-top-0">
        
        <p style={pStyle}>
            <span><strong>{props.t("common:words.start")}:</strong></span>
            <span style={datesStyle}>{moment(props.startDate).format("DD/MM/YYYY HH:mm")}</span>
        </p>
        <p style={pStyle}>
            <span><strong>{props.t("common:words.end")}:</strong></span>
            <span style={datesStyle}>{moment(props.endDate).format("DD/MM/YYYY HH:mm")}</span>
        </p>
    </div>;
};