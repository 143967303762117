import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SelectInputView } from "../forms/elements/SelectInputView";
import { SpinnerType, Spinner } from "./Spinner";
import { LoadingView, ElementPosition } from "./LoadingView";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"SpinnerUiTest",
    groupId:"components",
    description:"lalal",
    title:"Spinner and loading view component",
    parentId:"components"
};

export const createSpinnerTypeOptions = ()=>{
    const options = Object.entries(SpinnerType).map((x:any) => {
        return { value:x[1], text:(SpinnerType as any)[x[0]]};
    });
    return options;
}


@observer
export class SpinnerUiTest extends Component<TranslationProps,{}>{
    
    @observable type:SpinnerType = SpinnerType.SPIN;
    @observable size:"small" | "medium" | "large" = "medium";
    @observable position:ElementPosition = ElementPosition.CENTER;

    render(){
        return <div className="container-fluid">
            <div className="row">
                <h2>Types of spinners</h2>
                <div className="col-md-6">
                    
                    <SelectInputView
                        isLoading={false}
                        items={createSpinnerTypeOptions()}
                        label="Type"
                        onChange={(v:any)=> {this.type = v}}
                        key="selector:type"
                        selected={this.type as any}
                    ></SelectInputView>
                     <SelectInputView
                        isLoading={false}
                        items={[{value:"small",text:"small"},{value:"medium",text:"medium"},{value:"large",text:"large"}]}
                        label="Size"
                        onChange={(v:any)=> {this.size = v}}
                        key="selector:size"
                        selected={this.size as any}
                    ></SelectInputView>
                </div>
            </div>
            <div className="row">
                <Spinner type={this.type} size={this.size} color={"#1c95a7"} />
            </div>
            <div className="row">
                <h2>Loading view</h2>
                <SelectInputView
                        isLoading={false}
                        items={[{value:"left",text:"left"},{value:"center",text:"center"},{value:"right",text:"right"}]}
                        label="Position"
                        onChange={(v:any)=> {this.position = v}}
                        key="selector:size"
                        selected={this.position as any}
                    ></SelectInputView>
            </div>
            <div className="row" style={{backgroundColor:"lightgray"}}>
            <LoadingView isLoading={true} position={this.position} spinner={{type:this.type,size:this.size}}><div>Results list</div></LoadingView>
            </div>
        </div>
    }
}