"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormatNotSupportedError = exports.MediaTypeNotSupportedError = void 0;

var DomainError_1 = require("./DomainError");

var Errors_types_1 = require("./Errors.types");

var MediaTypeNotSupportedError =
/** @class */
function (_super) {
  __extends(MediaTypeNotSupportedError, _super);

  function MediaTypeNotSupportedError(message) {
    var _this = _super.call(this, Errors_types_1.DomainErrorType.MediaTypeNotSupported, message) || this;

    Object.setPrototypeOf(_this, MediaTypeNotSupportedError.prototype);
    return _this;
  }

  return MediaTypeNotSupportedError;
}(DomainError_1.DomainError);

exports.MediaTypeNotSupportedError = MediaTypeNotSupportedError;

var createFormatNotSupportedErrorMessage = function createFormatNotSupportedErrorMessage(format, supportedFormats) {
  return "Uploaded format is not supported: " + format + ". Please, use one of the following supported formats: " + supportedFormats.join(", ") + ".";
};

var FormatNotSupportedError =
/** @class */
function (_super) {
  __extends(FormatNotSupportedError, _super);

  function FormatNotSupportedError(format, supportedFormats, message) {
    var _this = _super.call(this, Errors_types_1.DomainErrorType.MediaTypeNotSupported, createFormatNotSupportedErrorMessage(format, supportedFormats)) || this;

    _this.format = format;
    _this.supportedFormats = supportedFormats;
    Object.setPrototypeOf(_this, FormatNotSupportedError.prototype);
    return _this;
  }

  return FormatNotSupportedError;
}(DomainError_1.DomainError);

exports.FormatNotSupportedError = FormatNotSupportedError;