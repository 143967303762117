"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToEventDates = exports.convertToDate = void 0;

exports.convertToDate = function (date) {
  if (date === undefined || date === null || date === 0 || date === "") {
    return undefined;
  }

  var result = new Date(date);
  return result;
};

exports.convertToEventDates = function (source) {
  var dates = {
    startDate: undefined,
    endDate: undefined,
    extendedDate: undefined
  };
  var date_start = source.date_start,
      date_end = source.date_end,
      date_end_extended = source.date_end_extended,
      server_time = source.server_time;

  if (date_start !== undefined && date_start !== null && date_start !== 0) {
    dates.startDate = new Date(date_start);
  }

  if (date_end !== undefined && date_end !== null && date_end !== 0) {
    dates.endDate = new Date(date_end);
  }

  if (date_end_extended !== undefined && date_end_extended !== null && date_end_extended !== 0) {
    dates.extendedDate = new Date(date_end_extended);
  }

  if (server_time !== undefined && server_time !== null && server_time !== 0) {
    dates.serverTime = new Date(server_time);
  }

  return dates;
};