import React from "react";
import { TranslationProps } from "modbin-domain";
import { LotDetailsView } from "../LotDetailsView";
import { Lot, SellingEventDateState } from "ibase-auctions-domain";
import { observer, inject } from "mobx-react";
import { SessionStore } from "../../../../../../public/sessions/SessionStore";
import { LotDetailsViewStore } from "../../ViewStore";
import { PrivateNegotiationBidForm } from "./PrivateNegotiationBidForm";
import { DefaultBasePriceView } from "../../../../components/price/DefaultBasePriceView";
import { application } from "../../../../../../../Startup";
import { DocumentDownloadView } from "../../../../components/bid/DocumentDownloadView";

export const PrivateNegotiationDetailsView = inject("sessionStore","lotDetailsViewStore")(observer((props:TranslationProps & {
        item:Lot,
        dateState:SellingEventDateState,
        sessionStore?:SessionStore,
        lotDetailsViewStore?:LotDetailsViewStore 
    }) => { 
    const notLoggedInFile = application.getSettings()!.webConfig.files.privateNegotiationTemplate;
    return <LotDetailsView
        item={props.item}
        biddingView={{
            loggedIn: <PrivateNegotiationBidForm 
                onBid={(value:number)=>props.lotDetailsViewStore!.onGenerateProposal(value, props.sessionStore!.user)} 
                t={props.t} 
                minimumBid={{value:1,default:props.item.price.nextBid!,currency:props.item.price.currency}}/>,
            notLoggedIn: <DocumentDownloadView 
                t={props.t} 
                url={notLoggedInFile} 
                name={props.t("screens:business.lots.details.proposalTemplateDownloadText")} 
                fileName="CParaiso-minuta.doc"/>,
        }}
        t={props.t}
        priceView={<DefaultBasePriceView t={props.t} price={props.item.price}></DefaultBasePriceView>}
        dateState={props.dateState}
        isLoadingPrice={props.lotDetailsViewStore!.isLoadingPrices}
    ></LotDetailsView>
}))