"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contactsProfile = void 0;

var RegionConverter_1 = require("./RegionConverter");

var CountyConverter_1 = require("./CountyConverter");

var ParishConverter_1 = require("./ParishConverter");

var InformationRequestConverter_1 = require("./InformationRequestConverter"); ///Distritos


var distritoToRegion = {
  sourceType: "distrito",
  targetType: "region",
  converter: RegionConverter_1.convertToRegion
};
var regionQuery = {
  sourceType: "regionQuery",
  targetType: "distritoQuery",
  converter: RegionConverter_1.convertFromRegionQuery
}; //Concelhos

var concelhoToCounty = {
  sourceType: "concelho",
  targetType: "county",
  converter: CountyConverter_1.convertToCounty
};
var countyQuery = {
  sourceType: "countyQuery",
  targetType: "concelhoQuery",
  converter: CountyConverter_1.convertFromCountyQuery
}; ///Freguesias

var freguesiaToParish = {
  sourceType: "freguesia",
  targetType: "parish",
  converter: ParishConverter_1.convertToParish
};
var parishQuery = {
  sourceType: "parishQuery",
  targetType: "freguesiaQuery",
  converter: ParishConverter_1.convertFromParishQuery
};
var informationRequestToPedido = {
  sourceType: "informationrequest",
  targetType: "pedido",
  converter: InformationRequestConverter_1.convertfromInformationRequest
};
var pedidoToInformationRequest = {
  sourceType: "pedido",
  targetType: "informationrequest",
  converter: InformationRequestConverter_1.convertToInformationRequest
};
exports.contactsProfile = {
  name: "Contacts",
  definitions: [distritoToRegion, regionQuery, concelhoToCounty, countyQuery, freguesiaToParish, parishQuery, informationRequestToPedido, pedidoToInformationRequest]
};