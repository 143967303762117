"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultLoggerFactory = void 0;

var DefaultLoggerFactory =
/** @class */
function () {
  function DefaultLoggerFactory(providers) {
    var _this = this;

    this.providers = new Map();

    if (providers !== undefined) {
      providers.forEach(function (x) {
        _this.providers.set(x.getIdentifier(), x);
      });
    }
  }

  DefaultLoggerFactory.prototype.create = function (options) {
    var level = options.level,
        provider = options.provider,
        name = options.name,
        properties = options.properties;
    var loggerProvider = this.providers.get(provider);
    var logger = loggerProvider.create(level, properties);
    var result = {
      level: level,
      logger: logger,
      name: name,
      provider: provider,
      properties: properties
    };
    return result;
  };

  DefaultLoggerFactory.prototype.getProviders = function () {
    return Array.from(this.providers.values());
  };

  DefaultLoggerFactory.prototype.getProvider = function (identifier) {
    return this.providers.get(identifier);
  };

  DefaultLoggerFactory.prototype.addProvider = function (provider) {
    this.providers.set(provider.getIdentifier(), provider);
  };

  return DefaultLoggerFactory;
}();

exports.DefaultLoggerFactory = DefaultLoggerFactory;