import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MainAppViewStore } from '../../../configuration/stores/MainAppViewStore';
import { SettingsFormView, Settings, ProxyType } from './SettingsForm';
import { TranslationProps, ILoggerFactory } from 'modbin-domain';
import { application } from '../../../Startup';
import { IRestApiClient } from 'modbin-api';
import { configProperties } from '../../../configuration/ConfigProperties';
import { loadApiConfiguration } from '../../../configuration/api/ApiConfig.types';
import { IKeyedObject } from 'modbin-domain';
import { LoggingFormView } from './LoggingForm';
import { FeaturesFormView } from './FeaturesForm';

@inject("mainAppViewStore") @observer
export class ScreensAdministrationIndex extends Component<TranslationProps & {mainAppViewStore?:MainAppViewStore},{}> {

    onLogginSave(values:any):Promise<void>{
        const loggerFactory= application.getContainer().resolve<ILoggerFactory>("loggerFactory");
        const logger = loggerFactory.create({name:values.name,provider:values.name,level:values.level, properties:values.properties});
        application.logging.setLogging(logger);
        return Promise.resolve();
    }

    onFeaturesSave(values:any):Promise<void>{
        const {numberOfDaysOfPastEvents, ...rest} = values;
        configProperties.webConfig.numberOfDaysToShowPastEvents = numberOfDaysOfPastEvents;
        configProperties.appConfig.features = rest;
        application.setSettings(configProperties);
        return Promise.resolve();
    }

    onSave(ev:Settings):Promise<void>{
        const restClient = application.getContainer().resolve<IRestApiClient>("apiClient");
        const newApiConfigProperties = configProperties.apiConfig;
        newApiConfigProperties.authorizationToken = ev.authorizationToken!;
        newApiConfigProperties.proxyType = ev.proxyType;
        newApiConfigProperties.baseUrl = ev.proxyType === ProxyType.PASSTHROUGH 
            ? (ev.proxy.endsWith("/proxy") ? ev.proxy : ev.proxy + "/proxy")
            : ev.proxy
        newApiConfigProperties.officeId = ev.officeId;
        configProperties.services.images.baseUrl = ev.imageServiceUrl!;
        configProperties.apiConfig = loadApiConfiguration(newApiConfigProperties);
        
        application.setSettings(configProperties);
        const restOptions = {
            baseUrl:newApiConfigProperties.baseUrl,
            apiAuthentication:{
                token:newApiConfigProperties.authorizationToken,
                header:"Authorization"
            },
        }
        restClient.changeOptions(restOptions);
        return Promise.resolve().then(x => {application.restart(); this.props.mainAppViewStore!.navigateTo("public:home")});
    }
    render(){
        const testsViewLink = <div><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:tests:index",{event:ev})}>Navigate to components sandbox</a></div>
            
        const propertiesViewLink = <div><a href="# " onClick={(ev) => this.props.mainAppViewStore!.navigateTo("support:properties",{event:ev})}>Navigate to properties listing</a></div>
        const apiConfig = application.getSettings()!.apiConfig;
        const proxies:IKeyedObject<any> = {
            local:{text:"Local",value:"local", url:"http://localhost:8081/api",imageServiceUrl:"http://www.ibase.com.pt:8082/ibase/img"},
            testing:{text:"Testing",value:"testing", url:"https://modbin-ibase-paraiso-proxy-testing.azurewebsites.net/api",imageServiceUrl:"http://www.ibase.com.pt:8082/ibase/img"},
            production:{text:"Production",value:"production", url:"https://modbin-ibase-paraiso-proxy-staging.azurewebsites.net/api",imageServiceUrl:"http://www.gestaodeativos.pt:8080/ibase/img"},
            other:{text:"Other",value:"other"}
        }; 
        const matchedUrls = Object.keys(proxies).filter((x:any) => proxies[x].url === apiConfig.baseUrl || proxies[x].url === apiConfig.baseUrl+"/proxy");
        const environment = matchedUrls.length > 0
            ? proxies[matchedUrls[0]].value
            : proxies["other"].value;
        const settings: Settings = {
            proxyType: apiConfig.proxyType,
            proxy:apiConfig.baseUrl,
            officeId:apiConfig.officeId,
            authorizationToken:apiConfig.authorizationToken,
            authorizationHeaderName:"Authorization",
            imageServiceUrl:application.getSettings()!.services.images.baseUrl,
            proxies: proxies,
            environment: environment
        }
        const features = application.getSettings()!.appConfig.features;
        const numberOfDaysOfPastEvents = application.getSettings()!.webConfig.numberOfDaysToShowPastEvents;
        const allFeatures =  {...features, numberOfDaysOfPastEvents}; 
        return (<div className="container margin-top-50">
                <h1>Development support utilities and information</h1>
                <p>
                    This page is intended to help developers during development and debugging.
                </p>
                <SettingsFormView t={this.props.t} settings={settings} onSave={(ev) => {return this.onSave(ev)}} />
                <LoggingFormView t={this.props.t} loggerFactory={application.getContainer().resolve<ILoggerFactory>("loggerFactory")} current={application.logging.getLogging()}
                    onSave={(ev:any)=>{return this.onLogginSave(ev)}}
                />
                <FeaturesFormView features={allFeatures} onSave={(ev:any)=>{return this.onFeaturesSave(ev)}} t={this.props.t}
                
                ></FeaturesFormView>
            </div>
        );
    }
}