import { IUserProperties, MaritalStatus, UserAccountState, CompanyRepresentative } from "ibase-auctions-domain";

export const emptyUser:IUserProperties = {
    firstName: "",
    lastName: "",
    contact: {
        mobilePhoneNumber: "",
        landPhoneNumber: "",
        faxNumber: "",
        email: "",
    },
    address: {
        street:"",
        city:"",
        zipCode1:"",
        zipCode2:""
    },
    title: "",
    maritalStatus: MaritalStatus.SINGLE,
    identificationCard: {
        number: "",
        issuedAt: "",
        expiration: undefined,
        vatNumber: ""
    },
    company:{
        isRepresentative:false,
        name:"",
        vatNumber:""
    },
    birthDate:undefined,
    bornAt:"",
    accountState: UserAccountState.UNACTIVATED,
    provider: "",
    officeId: ""
}