import React from 'react';
import "react-image-lightbox/style.css";
import { observer } from 'mobx-react';
import { Photo } from 'ibase-auctions-domain';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export const ImageGalleryView = observer((
    props:{
        photos:Photo[]
    }) => {
        const items = props.photos.sort(function(a, b){return a.order-b.order}).map(x => {return {
            original:x.url, 
            thumbnailClass:"thumb",
            thumbnail:x.url,
            originalClass:"lala"
        }});
        return <ImageGallery items={items}/>;
});