import React from "react";
import { ContactFormModalView } from "../../../../../components/forms/contact/ContactFormModalView";
import { observer, inject } from "mobx-react"
import { Lot, IContactService, InformationRequest } from "ibase-auctions-domain";
import { withTranslation } from "react-i18next";
import { TranslationProps, IUserNotificationService, ILogger } from "modbin-domain";
import { SessionStore } from "../../../../public/sessions/SessionStore";
import { application } from "../../../../../Startup";

export const InnerInformationRequestView = inject("sessionStore")(observer((props:TranslationProps &{
    lot?:Lot,
    sessionStore?:SessionStore
}) => {
    const getTranslation = (key:string) => props.t(`common:contactform.${key}`); 
    const onSubmit = (request:InformationRequest):Promise<void> => {
        const contactService = application.getContainer().resolve<IContactService>("contactService");
        const userNotificationService = application.getContainer().resolve<IUserNotificationService>("userNotificationService");
        const logger = application.getContainer().resolve<ILogger>("logger");
        return contactService.sendInformationRequest(request).then((x:any)=> {
            return userNotificationService.success("Pedido enviado com sucesso!");
        },(e:any)=>{
            logger.error("Error in onSubmit sending informationRequest in InnerInformationRequestView componnet.",e)
            userNotificationService.error("Erro");
        })
    }
    return <div className="widget">
        <h3>{getTranslation("haveAquestion")}</h3>
        <div className="info-box margin-bottom-10">
            {/*<p>{getTranslation("feelFreeToAsk")}</p>*/}
            <ContactFormModalView 
                selectedLot={props.lot}
                onSubmit={(request:any)=>{return onSubmit(request)}}
                activeUser={props.sessionStore!.user}
                t={props.t}
            ></ContactFormModalView>
        </div>
    </div>;
}))

export const InformationRequestView = (withTranslation(["common"])(InnerInformationRequestView as any) as any) as (props:any) => JSX.Element;
