import React from 'react';
import "./resources/template/Template"
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Provider, observer } from 'mobx-react';
import { CurrentView } from './configuration/views/CurrentView';
// import { administrationiewsDefinitions } from './screens/administration/Administration.definitions';
// import { privateViewsDefinitions } from './screens/private/Private.definitions';
// import { publicViewsDefinitions } from './screens/public/Public.definitons';
// import { businessViewsDefinitions } from './screens/business/Business.definitions';
//import { appRoot } from './configuration/AppRoot';
// import { commonViewsDefinitions } from './screens/common/Common.definitions';
import { ToastContainer } from 'react-toastify';
import { ApplicationConfiguration } from './configuration/temp/AplicationConfiguration';
import { supportViewDefinitions } from './screens/support/Support.definitions';
import { application } from './Startup';
import { LoadingView } from './components/loading/LoadingView';
import { ImagePopup } from './components/carousel/ImagePopup';
import { BannerType, Banner } from 'ibase-auctions-domain';
import { MainAppViewStore } from './configuration/stores/MainAppViewStore';
import { LayoutStore } from './screens/public/home/LayoutStore';
import { ILogger } from 'modbin-domain';
//import { DirectorRouter } from 'modbin-web-extensions';
// const history = createBrowserHistory();

// const renderPageWithLayout = (Component:any, Layout:any) => {
//     return (p:any) => <Layout {...p}><Component></Component></Layout>
// }
// appRoot.stores.mainAppViewStore.registerViews(administrationiewsDefinitions);
// appRoot.stores.mainAppViewStore.registerViews(privateViewsDefinitions);
// appRoot.stores.mainAppViewStore.registerViews(publicViewsDefinitions);
// appRoot.stores.mainAppViewStore.registerViews(businessViewsDefinitions);
// appRoot.stores.mainAppViewStore.registerViews(commonViewsDefinitions);


// (appRoot.application as any).registerViews(
//     [
//         ...administrationiewsDefinitions,
//         ...privateViewsDefinitions,
//         ...publicViewsDefinitions,
//         ...businessViewsDefinitions,
//         ...commonViewsDefinitions
//     ]);



const showTestPage = application.getContainer().resolve<ApplicationConfiguration>("appConfig").features.enableTestsPage;
// if(showTestPage){
//     appRoot.stores.mainAppViewStore.registerViews(supportViewDefinitions);
// }
if(showTestPage){
    application.views.getProvider().registerViews(supportViewDefinitions);
}

// const renderPage = (Component:any, options?:{Layout?:any, type?:"fluid"|"fixed"}) => {
//     return (p:any) => {
//         let type = "fixed";
//         let Layout = MenuLayout;
//         if(options !== undefined){
//             type = options.type !== undefined 
//                 ? options.type
//                 : type;
//             Layout = options.Layout !== undefined
//                 ? options.Layout
//                 : Layout
//         } 
//         return <Layout type={type} {...p}><Component></Component></Layout>
//     }
// }


application.start().catch((e: any) => {
    try {
        application.getContainer().resolve<ILogger>("logger").error("Error in application start sad fasd",e);
    }
    catch(e){
        throw new Error("Error in application start.")
    }
});

const App: React.FC = observer(() => {
    const storeModule = application.stores;
    const mainAppViewStore = storeModule.getStore<MainAppViewStore>("mainAppViewStore");
    const stores = storeModule.getStores();
    const layout = storeModule.getStore<LayoutStore>("layoutStore")!.layout;
    let popupImages:any[] = [];
    if(layout !== undefined){
        popupImages = layout.banners.filter((x:Banner) => x.type === BannerType.POPUP);
    }
    return (
        <Provider {...stores}>
            {/* <I18nextProvider i18n={i18n}> */}
                <div>   
                    <LoadingView isLoading={!mainAppViewStore.isLoaded} vAligned={true}>
                        <CurrentView current={mainAppViewStore.currentView!}></CurrentView>
                        <ImagePopup visible={mainAppViewStore.showPopup} images={popupImages} onClose={()=> {mainAppViewStore.closePopup()}}></ImagePopup>
                    </LoadingView>
                    <ToastContainer hideProgressBar={true} closeButton={false} />
                </div>
            {/* </I18nextProvider> */}
        </Provider>
  );
})

export default App;

// const App: React.FC = observer(() => {
//     const stores = application.getStores();
//     const mainAppViewStore = stores.mainAppViewStore;
//     let currentView = <CurrentView current={mainAppViewStore.currentView}></CurrentView>;
//     if(mainAppViewStore.showPopup){
//         const layout = stores.layoutStore!.layout;
//         let popupImages = [];
//         if(layout !== undefined){
//             popupImages = layout.banners.filter((x:Banner) => x.type === BannerType.POPUP);
//         }
//         currentView = <ImagePopup visible={mainAppViewStore.showPopup} images={popupImages} onClose={()=> {mainAppViewStore.closePopup()}}></ImagePopup>
//     }
//     return (
//         <Provider {...stores}>
//             {/* <I18nextProvider i18n={i18n}> */}
//                 <div>   
//                     <LoadingView isLoading={!mainAppViewStore.isLoaded} vAligned={true}>
//                         {currentView}
//                     </LoadingView>
//                     <ToastContainer hideProgressBar={true} closeButton={false} />
//                 </div>
//             {/* </I18nextProvider> */}
//         </Provider>
//   );
// })

// export default App;
