"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SellingType = void 0;
var SellingType;

(function (SellingType) {
  SellingType["ONLINEAUCTION"] = "onlineauction";
  SellingType["PRESENTIALAUCTION"] = "presentialauction";
  SellingType["CLOSEDLETTER"] = "closedletter";
  SellingType["PRIVATENEGOTIATION"] = "privatenegotiation"; //  ONLINEANDPRESENTIALAUCTION = "onlineandpresentialauction",  
})(SellingType = exports.SellingType || (exports.SellingType = {}));