import { Address } from "ibase-auctions-domain";
import React from "react";
import { isNotNullOrEmptyString, isNullOrEmptyString } from "modbin-domain";

export const formatZipCode = (addr:Address) => {
    const { zipCode1,zipCode2} = addr;
    let zip = [];
    if(isNotNullOrEmptyString(zipCode1,true)){
        zip.push(zipCode1);
    }
    if(isNotNullOrEmptyString(zipCode2!,true)){
        zip.push(zipCode2);
    }
    return zip.join("-");
}

export const formatAddress = (addr:Address):string => {
    if(addr === undefined){
        return "N/A";
    }
    let result = [];
    const { street,city} = addr;

    if(isNotNullOrEmptyString(street,true) ){
        result.push(street);
    }
    const zip = formatZipCode(addr);
    if(isNotNullOrEmptyString(zip,true)){
        result.push(zip);
    }
    if(isNotNullOrEmptyString(city,true)){
        result.push(city);
    }
    return result.join(", ")
}

export const ShortTextAddressView = (props:{address:Address}) => {
    const {street, city, zipCode1,zipCode2} = props.address;
    if(props.address === undefined || (isNullOrEmptyString(street,true) && isNullOrEmptyString(city,true) && isNullOrEmptyString(zipCode1,true) && isNullOrEmptyString(zipCode2!,true)) || props.address.street.length == 1){
        return  <span className="listing-address"/>;
    }
    return  <span className="listing-address">
        <i className="fa fa-map-marker"></i>
        {formatAddress(props.address)}
    </span>;
}
