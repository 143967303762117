"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateComparer = void 0;

exports.dateComparer = function (first, second) {
  if (!first) return -1;
  if (!second) return 1;
  return new Date(first).getTime() < new Date(second).getTime() ? -1 : 1;
};