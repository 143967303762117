import { ITimerService, isNotNullOrEmptyNumber } from "modbin-domain";

export class DefaultTimeService implements ITimerService {

    private clientTimeDifference: number;

    constructor(currentTime?:number){
        this.clientTimeDifference = this.createTimeDifference(currentTime);
    }

    private createTimeDifference(currentTime?:number){
        const clientTime = this.getClientCurrentTime();
        let result = 0
        if(isNotNullOrEmptyNumber(currentTime!)){
            result = currentTime! - clientTime
        }
        return result; 
    }

    private getClientCurrentTime(){
        return new Date(Date.now()).getTime();
    }

    getCurrentDateInUtc(){
        return new Date(new Date().toISOString());
    }

    getCurrentDate(){
        return new Date(new Date(this.getClientCurrentTime() + this.clientTimeDifference).toISOString());
    }
}