import React from "react";
import { LanguageSelectorView } from "../../forms/LanguageSelectorView";
import { withTranslation } from "react-i18next";
import { configProperties } from "../../../configuration/ConfigProperties";
import { observer } from "mobx-react";

export const InnerTopHeader = observer((props: any) => {
  const getTranslationWords = (key: string) => props.t(`common:words.${key}`);

  return (
    <div id="top-bar" style={{ zIndex: 999 }}>
      <div className="container">
        <div className="left-side">
          <ul className="top-bar-menu">

            <li>
              {getTranslationWords("support")}:{" "}
              <i className="fa fa-phone"></i>
              <i style={{fontSize: "10px"}}>{getTranslationWords("contactInfo")}</i>
              {configProperties.companyInfo.headquarters.contacts.mobilePhoneNumber}
            </li>


            <li>
              <i className="fa fa-envelope"></i>
              {configProperties.companyInfo.headquarters.contacts.email}
            </li>
          </ul>
        </div>
        <div className="right-side">
          <LanguageSelectorView></LanguageSelectorView>
          {/*<ul className="social-icons">*/}
          {/*  <li>*/}
          {/*    <a className="facebook" href="/not-found">*/}
          {/*      <i className="icon-facebook"></i>*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </div>
    </div>
  );
});

export const TopHeader = withTranslation(["common"])(InnerTopHeader) as any as (
  props: any
) => JSX.Element;
