import React, { Fragment } from "react";
import { NavigationItem } from "./NavigationItem";
import { MenuProps, MenuWithTranslationProps } from "../Navigation.types";
import { withTranslation } from "react-i18next";
import { SessionViewStore } from "../../../../screens/public/sessions/ViewStore";
import { inject, observer } from "mobx-react";

export const UserWidget = inject("sessionViewStore")(observer((props:{hi:string, sessionViewStore?:SessionViewStore}) => {
    return <a href="# ">{`${props.hi}${props.sessionViewStore!.displayName}!`}</a>;
}))


const LogoutNavigationItem = inject("sessionViewStore")((props:{text:string, sessionViewStore?:SessionViewStore}) => {
    return <li key={props.text}>
        <a href="# " onClick={(ev) => { 
            ev.preventDefault();
            props.sessionViewStore!.onLogout();
        }}
        >{props.text}</a>
    </li>;
});

export const InnerAccountMenu = (props:MenuWithTranslationProps) => {
    const children = props.children !== undefined ? props.children : <Fragment></Fragment>;
    return <nav id="navigation" className="style-1">
            <ul>
                <li>
                    <UserWidget hi={props.t("menu.hi")}/>
                    <ul>
                        
                        <NavigationItem text={props.t("menu.userProfile")} to="private:profile"/>
                        <NavigationItem text={props.t("menu.userBids")} to="private:profile:bids"/>
                        {children as any}
                        <LogoutNavigationItem text={props.t("menu.logout")}></LogoutNavigationItem>
                    </ul>
                </li>
            </ul>
    </nav>;
} 


export const AccountMenu = (withTranslation(["common"])(InnerAccountMenu as any) as any) as (props:MenuProps) => JSX.Element;