
export const administrationTranslations = {
    pt: {
        newsletters:{
            title:"Subscrições de newsletter",
            emailColTitle:"Email",
            nameColTitle:"Nome",
            subscriptionDateColTitle:"Data de subscrição",
            downloadButton:"Descarregar em csv"
        }
    },
    en:{
        newsletters:{
            title:"Newsletter subscription",
            emailColTitle:"Email",
            nameColTitle:"Name",
            subscriptionDateColTitle:"Subscription date",
            downloadButton:"Download in csv"
        }
    }
}