import React, { Component } from "react";
import { BaseLayout } from "../../../components/layout/BaseLayout";
import { SupportMenu } from "./SupportMenu";

export class SupportLayout extends Component {

	render() {
		return <BaseLayout
            type="fluid"
            >          
            <SupportMenu />
            <div>
                {this.props.children}
            </div>
        </BaseLayout>;
	}
}