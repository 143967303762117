import {observable, computed} from "mobx";
import { LotStore } from "../../lots/Store";
import { SellingEventStore } from "../Store";
import { IViewStore, BaseStore } from "modbin-web";

export class SellingEventDetailsViewStore extends BaseStore implements IViewStore {

    @observable
    _selected:string|undefined = undefined; 
    @observable isLoading:boolean = false;
    constructor(private selllingEventStore:SellingEventStore, private lotStore:LotStore) {
        super();
    }

   
    getSelected(){
        return this.selllingEventStore.getEventById(this._selected!);
    } 

    @computed
    get props(){
        return {
            selected:this.getSelected(),
            isLoading: this.isLoading
        }
    }

    setState(params:{sellingEventId:string}){
        this._selected = params.sellingEventId;
        this.isLoading = true;
        this.selllingEventStore.fetchSellingEventAndComponentsById(this._selected)
        .then((x) => {
            this.isLoading = false;
        })
        .catch((x) => {
            this.isLoading = false;
        });
    }

    getState(){
        return { sellingEventId: this._selected};
    }
}