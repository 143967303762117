import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { TranslationProps } from "modbin-domain";
import { BasicLoadingButtonProps, LoadingButton } from "./LoadingButton";

export type LoadingButtonLocalizedProps = BasicLoadingButtonProps & {
    textKey:string;
    loadingTextKey?:string;
}

export const InnerLoadingButtonLocalized = observer((props:TranslationProps & {i18n:any} & LoadingButtonLocalizedProps)=>{
    const {textKey,loadingTextKey,t,i18n, ...rest} = props;
    const text = t(`common:buttons.${textKey}`);
    const loadingText = loadingTextKey !== undefined
        ? t(`common:buttons.${loadingTextKey}`)
        : undefined;
    return <LoadingButton text={text} loadingText={loadingText} {...rest}/>
})

export const LoadingButtonLocalized = (withTranslation(["common"])(InnerLoadingButtonLocalized as any) as any) as (props:any) => JSX.Element;