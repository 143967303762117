import { notStartedViewVisibility, ViewVisibility, startedViewVisibility, endedViewVisibility, validatingViewVisibility } from "./ViewVisibility";
import { SellingEventDateState } from "ibase-auctions-domain";

const visibilityPerStateMapper = new Map<SellingEventDateState,ViewVisibility>([
    [SellingEventDateState.NOTSTARTED,notStartedViewVisibility],
    [SellingEventDateState.DAYS,startedViewVisibility],
    [SellingEventDateState.DAY,startedViewVisibility],
    [SellingEventDateState.HOUR,startedViewVisibility],
    [SellingEventDateState.MINUTE,startedViewVisibility],
    [SellingEventDateState.VALIDATING,validatingViewVisibility],
    [SellingEventDateState.ENDED,endedViewVisibility]
]);

export const getItemVisibilityState = (state:SellingEventDateState) => {
    return visibilityPerStateMapper.get(state);
}