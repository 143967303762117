"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lotsProfile = exports.lotQuery = void 0;

var LotConverter_1 = require("./LotConverter");

var LotQueryConverter_1 = require("./LotQueryConverter");

var LotUpdateDataConverter_1 = require("./LotUpdateDataConverter");

var loteToLot = {
  sourceType: "lote",
  targetType: "lot",
  converter: LotConverter_1.convertToLot
};
var loteUpdateToLotUpdateData = {
  sourceType: "loteUpdateData",
  targetType: "lotUpdateData",
  converter: LotUpdateDataConverter_1.convertToLotUpdateData
};
exports.lotQuery = {
  sourceType: "lotQuery",
  targetType: "loteQuery",
  converter: LotQueryConverter_1.convertfromLotQuery
};
exports.lotsProfile = {
  name: "Lots",
  definitions: [loteToLot, exports.lotQuery, loteUpdateToLotUpdateData]
};