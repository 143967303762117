import React from "react";
import { BaseUserNotificationService, UserNotification } from "modbin-web";
import { NotificationView } from "./NotificationView";
import { toast } from "react-toastify";
import { isNotNullOrEmptyString } from "modbin-domain";

export class ReactToastifyUserNotificationService extends BaseUserNotificationService {

    sanitizeNotification(notification:UserNotification):UserNotification {
        if(isNotNullOrEmptyString(notification.message,true)){
            return notification;
        }
        if(notification.error === undefined || notification.error.status === undefined){
            notification.message = "Unknown error";
            return notification;
        }
        const {status} = notification.error;
        if(status >= 500 ){
            notification.message = "Internal server error";
            return notification;
        }
        if(status >= 400){
            switch (status) {
                case 401 :
                    notification.message = "Unauthorized";
                    return notification;
                case 403 :
                    notification.message = "Unauthorized";
                    return notification;
                case 404 :
                    notification.message = "Unauthorized";
                    return notification;
            }
        }
        return notification;
    }

    createNotification(notification:UserNotification){
        notification = this.sanitizeNotification(notification);
        const {type,title=undefined,message} = notification;
        return <NotificationView className="toast" type={type!} content={message!} header={title}></NotificationView>;
    }

    showNotification(notification:UserNotification){
        const options = {
            
        };
        toast(notification.component,options);
    }
}

