"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) {
    if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DomainErrorType = void 0;
var DomainErrorType;

(function (DomainErrorType) {
  DomainErrorType["FileNotFound"] = "FileNotFound";
  DomainErrorType["EntityNotFound"] = "EntityNotFound";
  DomainErrorType["EntityNotValid"] = "EntityNotValid";
  DomainErrorType["MethodNotSupported"] = "MethodNotSupported";
  DomainErrorType["ConfigurationNotValid"] = "ConfigurationNotValid";
  DomainErrorType["MediaTypeNotSupported"] = "MediaTypeNotSupported";
})(DomainErrorType = exports.DomainErrorType || (exports.DomainErrorType = {}));

__exportStar(require("./DomainError"), exports);

__exportStar(require("./FileNotFoundError"), exports);

__exportStar(require("./ConfigurationNotValidError"), exports);

__exportStar(require("./MethodNotSupportedError"), exports);

__exportStar(require("./MediaTypeNotSupported"), exports);