"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationTaskModule = void 0;

var ApplicationTaskModule =
/** @class */
function () {
  function ApplicationTaskModule(taskProvider, taskRunner) {
    this.taskProvider = taskProvider;
    this.taskRunner = taskRunner;
  }

  ApplicationTaskModule.prototype.getProvider = function () {
    return this.taskProvider;
  };

  ApplicationTaskModule.prototype.getRunner = function () {
    return this.taskRunner;
  };

  return ApplicationTaskModule;
}();

exports.ApplicationTaskModule = ApplicationTaskModule;