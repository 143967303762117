"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseDomainService = void 0;

var BaseDomainService =
/** @class */
function () {
  function BaseDomainService(repository) {
    this._repository = repository;
  }

  return BaseDomainService;
}();

exports.BaseDomainService = BaseDomainService;