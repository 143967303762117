import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { User } from 'ibase-auctions-domain';
import { TitleBar } from '../../../components/layout/header/TitleBar';

export class ScreensUsersIndexView extends Component<{users:User[]},{}> {
    
    createItemView(item:User){
        return <tr>
            <td>{item.getId()}</td>
            <td>{`${item.firstName} ${item.lastName}`}</td>
            <td>{item.identificationCard.vatNumber}</td>
            <td>as</td>
        </tr>; 
    }

    createListView(users:User[]){
        const head = <thead>
            <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Created date</th>
            </tr>
        </thead>;

        const body = <tbody>
            {users.map(x => this.createItemView(x))}
        </tbody>;

        return <Table striped hover responsive className="basic-table">
            {head}
            {body}
        </Table>;
    }

    render() {
        return (
            <div>
                <TitleBar title="Utilizadores registados"></TitleBar>
                <div className="container">
                    {this.createListView(this.props.users)}
                </div>
            </div>
        );
    }
}