import React, { Fragment, Component } from "react";
import Countdown from 'react-countdown-now';
import { observable } from "mobx";
import { observer } from "mobx-react";

export type CountDownClockSize = "small"|"medium"|"large";
export type CountDownClockType = "simple"|"extended";

export type CountDownClockProps = {
    endDate:Date,
    nowDate?:()=>number,
    onTick?():void;
    onComplete?():void;
    type?:CountDownClockType,
    size?:CountDownClockSize,
    cssClass?:string
}

const ExtendedClockView = observer((props:{children:any}) => {
    return <Fragment>
        <i className="im im-icon-Alarm-Clock"/>
        <span>{props.children}</span>
    </Fragment>;
});

@observer
export class CountDownClockView extends Component<CountDownClockProps,{}> {

    getNowDate(){
        if(this.props.nowDate !== undefined){
            return this.props.nowDate();
        }
        return new Date(Date.now()).getTime();
    }

    createClock(){
        const newProps:any = {
            date:this.props.endDate.getTime(),
            intervalDelay:0,            
            precision:3,
            onTick:this.props.onTick,
            onComplete:this.props.onComplete,
            now: () => this.getNowDate(),
            renderer: (props:any) => {
            return <span>{`${props.days}d ${props.hours}h ${props.minutes}m ${props.seconds}s`}</span>;
            }
        };
        const clockView = <Countdown {...newProps}/>;
   //     const clockView =  <Countdown date={Date.now() + 10000} />;
        return clockView; 
    }

    createBody(){
        const clock = this.createClock();
        if(this.props.type === "extended"){
            return <ExtendedClockView>{clock}</ExtendedClockView>
        }
        return clock;
    }

    getClass(){
        const {size="small", type="simple", cssClass =""} = this.props;
        return `auction-clock ${type} ${size} ${cssClass} `
    }

    render(){ 
        return <div className={this.getClass()}>
            {this.createBody()}
        </div>;        
    }
}