"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.machineSubCategoryTranslations = void 0;

var modbin_domain_1 = require("modbin-domain");

var MachineSubCategory_1 = require("./MachineSubCategory");

var translationObject = modbin_domain_1.createTranslationObjectForEnum([[MachineSubCategory_1.MachineSubCategory.FARMING, {
  pt: "Agrícolas",
  en: "Farmming"
}], [MachineSubCategory_1.MachineSubCategory.CONSTRUCTION, {
  pt: "Construção",
  en: "Construction"
}], [MachineSubCategory_1.MachineSubCategory.HARDWARE, {
  pt: "Ferramentas",
  en: "Hardware"
}], [MachineSubCategory_1.MachineSubCategory.INDUSTRY, {
  pt: "Industria",
  en: "Industry"
}], [MachineSubCategory_1.MachineSubCategory.OTHER, {
  pt: "Outro",
  en: "Other"
}] //25
]);
exports.machineSubCategoryTranslations = {
  pt: {
    name: "Subcategoria de máquinas",
    value: translationObject.pt
  },
  en: {
    name: "Machine subcategory",
    value: translationObject.en
  }
};