"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultQueryBuilderFactory = void 0;

var DefaultQueryBuilder_1 = require("./DefaultQueryBuilder");

var BuilderOptions_1 = require("./BuilderOptions");

var cloneDeep = require('lodash.clonedeep');

var DefaultQueryBuilderFactory =
/** @class */
function () {
  function DefaultQueryBuilderFactory(defaultOptions) {
    this.defaultOptions = defaultOptions;
  }

  DefaultQueryBuilderFactory.prototype.createBuilder = function (options) {
    var mergedOptions = options;

    var _defaultOptionsClone = cloneDeep(this.defaultOptions);

    if (!options) {
      return new DefaultQueryBuilder_1.DefaultQueryBuilder(_defaultOptionsClone);
    }

    if (options && _defaultOptionsClone) {
      mergedOptions = BuilderOptions_1.builderOptionsMerger(options, _defaultOptionsClone);
    }

    return new DefaultQueryBuilder_1.DefaultQueryBuilder(mergedOptions);
  };

  return DefaultQueryBuilderFactory;
}();

exports.DefaultQueryBuilderFactory = DefaultQueryBuilderFactory;