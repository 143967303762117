import React, { Component, Fragment } from 'react';
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { TranslationProps} from 'modbin-domain';
import { inject, observer } from 'mobx-react';
import { ProfileForm } from '../../../../private/profile/components/ProfileForm';
import { ButtonType } from '../../../../../components/buttons/ActionButton';
import { LoginViewStore } from '../LoginViewStore';


@inject("loginViewStore") @observer
export class ScreensSessionsRegister extends Component<TranslationProps & {loginViewStore:LoginViewStore},{}> {
       
    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const user = this.props.loginViewStore!.registerUser;
        return <Fragment>
            <TitleBar title={getTranslationSessions("registerTitle")}></TitleBar>
            <div className="container">
                <ProfileForm 
                    user={user} 
                    buttonType={ButtonType.REGISTER}
                    onSave={(user:any)=> {return this.props.loginViewStore!.onRegisterCompleted(user)}}
                    {...this.props}
                />
            </div>
        </Fragment>;
    }
}