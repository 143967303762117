import React from "react";
import { TranslationProps } from "modbin-domain";
import { LotDetailsView } from "../LotDetailsView";
import { Lot, SellingEventDateState } from "ibase-auctions-domain";
import { observer, inject } from "mobx-react";
import { SessionViewStore } from "../../../../../../public/sessions/ViewStore";
import { LotDetailsViewStore } from "../../ViewStore";
import { InfoView } from "./InfoView";
import { DefaultBasePriceView } from "../../../../components/price/DefaultBasePriceView";

export const PresentialAuctionDetailsView = inject("sessionViewStore","lotDetailsViewStore")(observer((props:TranslationProps & {
        item:Lot,
        dateState:SellingEventDateState,
        sessionViewStore?:SessionViewStore,
        lotDetailsViewStore?:LotDetailsViewStore 
    }) => { 
    return <LotDetailsView
        item={props.item}
        infoView={<InfoView lot={props.item} t={props.t}/>}
        t={props.t}
        priceView={<DefaultBasePriceView t={props.t} price={props.item.price}></DefaultBasePriceView>}
       // timeView={<TimeInformationView t={props.t} item={props.item} showClock={true} showDate={true}></TimeInformationView>}
        dateState={props.dateState}
        isLoadingPrice={props.lotDetailsViewStore!.isLoadingPrices}
    ></LotDetailsView>
}))