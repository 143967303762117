import React from 'react';
import { NavigationItemProps } from '../Navigation.types';
import { NavigationLink } from './NavigationLink';


export const NavigationItem: React.SFC<NavigationItemProps> = (props:NavigationItemProps) => {
    const icon = props.icon !== undefined 
        ? <i className={props.icon}></i>
        : "";
    return  <li key={props.text}>
        {icon}
        <NavigationLink to={props.to}>{props.text}</NavigationLink>
    </li>;
};