import {observable, action} from "mobx";
import { Lot, Category, SubCategory, SellingType, Booking, DocumentFile, LotUpdateData, SellingEvent, Bid } from "ibase-auctions-domain";
import { PageableResult,IQueryRepresentation, ILogger } from "modbin-domain";
import {ILotService} from "ibase-auctions-api";
import { BaseStore, IStore } from "modbin-web";
import { IServerTimeService } from "../../../configuration/temp/services/ServerTimeService";

// const test:any = {_id: "2866", _createdDate: null, _modifiedDate: null, officeId: null, userId: null, address: {street: undefined, zipCode1: undefined, zipCode2: undefined, city: undefined, country: undefined}, bokingsLoaded: true, bookings: [], category: "realestate", description: "", documents: [], documentsLoaded: false, event: {dates: {}, type: "onlineauction"}, eventId: "79", featured: true, location: undefined, number: 1, photos: [], price: {market: 15000, reserve: 10000, starting: 0, current: null, nextBid: 0}, processId: "17964",processInsolvent: "SDfgsfdg sfd ",processReference: "DEMO BRUNO", published: null, reference: "ref1", sold: false, subCategory: "apartment",thumbnail: "http://www.ibase.com.pt:8082/ibase/imgnull",title: "Lote de teste",updateData: null, id: "30"};
//const test2:any = observable.box(lots[0]);

export type LotQuery = IQueryRepresentation & {
    category?:Category;
    subCategory?:SubCategory;
    minimumValue?:string;
    maximumValue?:string;
    region?:string;
    county?:string;
    parish?:string;
    sellingType?:SellingType;
    term?:string;
    active:boolean;
}

export class LotStore extends BaseStore implements IStore {

    @observable lots:Map<string,Lot> = new Map<string,Lot>(); 

    @observable eventLots:Map<string,Lot[]> = new Map<string,Lot[]>();
    
    lotEventFetcher: (id:string)=>Promise<SellingEvent> = undefined as any;

    constructor(private lotService:ILotService, private serverTimeService:IServerTimeService, private logger:ILogger){
        super();
        //this.lots.set("30",test);
   //     this.lots.set("123",test2.get());
    }

    setLotEventFetcher(func:(id:string)=>Promise<SellingEvent>){
        this.lotEventFetcher = func;
    }

    private updateEventLot(lot:Lot){
        const { eventId } = lot;
        if(eventId !== undefined){
            let currentEventLots = this.eventLots.get(eventId);
            currentEventLots = currentEventLots !== undefined 
                ? currentEventLots.concat([lot])
                : [lot];
            this.eventLots.set(eventId,currentEventLots);
        }
    }

    private updateSingleLot(lot:Lot):Lot{
        let storedLot = this.lots.get(lot.getId())!;
        if(storedLot === undefined){
            this.lots.set(lot.getId(),lot);
            storedLot = lot;
        }
        else {
            storedLot.photos = lot.photos;
        }
        return storedLot;
    }

    private updateSingleLotFromEvent(id:string,event:SellingEvent){
        const storedLot = this.lots.get(id)!;
        if(storedLot !== undefined){
            storedLot.processId = event.processId;
            storedLot.processReference = event.processReference;
            storedLot.processType = event.processType;
            storedLot.processInsolvent = event.processInsolvent;
            storedLot.address = event.address;
        }
    }

    fetchLotById(id:string, eventId:string): Promise<Lot|undefined> {
        return this.lotService.getLotById(id,eventId).then((x:any) => {
            const lot = this.updateSingleLot(x);
            this.lotEventFetcher(x.eventId).then(e => {
                this.updateSingleLotFromEvent(id,e)
            });
            this.fetchLotDocuments(id);
            this.fetchLotBookings(id);
            return lot;
        },
        (e) => {
            this.logger.error(`Error in fetchLotById method on LotStore (EventId: ${eventId}), (LotId: ${id}): `,e);
            throw e;
        });
    }

    fetchLotUpdateData(id:string, eventId:string): Promise<LotUpdateData|undefined> {
        return this.lotService.getLotUpdateData(id,eventId).then((x:any) => {
            const lot = this.getLotById(id)!;
            if(lot !== undefined){
                // console.log("---SERVERTIME---");
                // console.log(x.dates.serverTime)
                lot.price = x.price;
                lot.event.dates = x.dates;
                lot.bids = x.bids;
                lot.updateDataLoaded = true;
                if(lot.event.dates.serverTime !== undefined){
                    this.serverTimeService.setTime(lot.event.dates.serverTime.getTime());
                    //console.log("LOTSERVERDATE:", lot.event.dates);
                    //console.log("SERVERTIMEDATE:", this.serverTimeService.getCurrentDate());
                }
            }
            return x;
        },
        (e) => {
            this.logger.error(`Error in fetchLotUpdateData method on LotStore (EventId: ${eventId}), (LotId: ${id}): `,e);
            throw e;
        });
    }

    fetchLots(query:LotQuery): Promise<Lot[]> {
        return this.lotService.getAllPageableLots(query).then((x:any) => {
            let r:any[] = [];
            x.items.forEach((lot:Lot) => {
                const l = this.updateSingleLot(lot);
                this.lotEventFetcher(lot.eventId).then(event => {
                    this.updateSingleLotFromEvent(lot.getId(),event)
                });
                r.push(l);
            });            
            return r;
        },
        (e) => {
            this.logger.error(`Error in fetchLots method on LotStore: `,e);
            throw e;
        });
    }

    fetchLotBookings(lotId:string):Promise<Booking[]>{
        return this.lotService.getLotBookings(lotId).then((x:PageableResult<Booking>) => {
            this.loadBookingsFromServer(lotId,x.items);
            return x.items;
        },
        (e) => {
            this.logger.error(`Error in fetchLotBookings method on LotStore (LotId: ${lotId}): `,e);
            throw e;
        });
    }

    fetchLotDocuments(lotId:string):Promise<DocumentFile[]>{
        // this.lots.get(lotId)!.documentsLoaded = true;
        // return Promise.resolve([]);
        return this.lotService.getLotDocuments(lotId).then((x:PageableResult<DocumentFile>) => {
            this.loadDocumentsFromServer(lotId,x.items);
            return x.items;
        },
        (e) => {
            this.logger.error(`Error in fetchLotDocuments method on LotStore (LotId: ${lotId}): `,e);
            throw e;
        });
    }

    fetchEventLots(eventId:string, event?:SellingEvent): Promise<Lot[]> {
        return this.lotService.getAllPageableLots({eventId:eventId}).then((x:any) => {
            x.items.forEach((e:Lot) => {
                this.updateSingleLot(e);
                if(event !== undefined){
                    this.updateSingleLotFromEvent(e.getId(),event);
                }
            });
            return x;
        },
        (e) => {
            this.logger.error(`Error in fetchEventLots method on LotStore (EventId: ${eventId}): `,e);
            throw e;
        });
    }

    fetchUserFavoriteLots(userId:string): Promise<Lot[]> {
        return this.lotService.getUserFavoriteLots(userId).then((x:any) => {
            let r:any[] = [];
            x.items.forEach((lot:Lot) => {
                const l = this.updateSingleLot(lot);
                this.lotEventFetcher(lot.eventId).then(event => {
                    this.updateSingleLotFromEvent(lot.getId(),event)
                });
                r.push(l);
            });            
            return r;
        },
        (e) => {
            this.logger.error(`Error in fetchUserFavoriteLots method on LotStore (UserId: ${userId}): `,e);
            throw e;
        });
    }

    @action
    loadBookingsFromServer(lotId:string, bookings:Booking[]){
        const lot = this.getLotById(lotId)!;
        if(lot !== undefined) {
            if(!lot.bokingsLoaded){
                bookings.forEach(b => {
                    lot.bookings.push(b);
                })                
                lot.bokingsLoaded = true;
                this.lots.set(lot.getId(),lot);
            }
        }
    }

    @action
    loadDocumentsFromServer(lotId:string, files:DocumentFile[]){
        const lot = this.getLotById(lotId)!;
        if(lot !== undefined && !lot.documentsLoaded){
            files.forEach(b => {
                lot.documents.push(b);
            })
            lot.documentsLoaded = true;
            this.lots.set(lot.getId(),lot);
        }
    }

    getLots(){
        return Array.from(this.lots.values());
    }

    getLotById(id:string):Lot|undefined{
        let l = this.lots.get(id);
        return l;
    }

    getEventLots(eventId:string):Lot[]{
        const dLots = this.eventLots.get(eventId);
        return (dLots !== undefined)
            ? dLots
            : [];
    }

    updateSuccessfulBidValue(lotId:string,bid:Bid){
        const lot = this.getLotById(lotId)!;
        lot.price.current = bid.value;
    }

    reset(){
        this.lots = new Map<string,Lot>(); 
        this.eventLots = new Map<string,Lot[]>();
        return Promise.resolve();
    }
}
