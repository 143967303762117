"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceDescriptor = void 0;

var ServiceDescriptor =
/** @class */
function () {
  function ServiceDescriptor(name, instance) {
    this.name = name;
    this.instance = instance;
  }

  ServiceDescriptor.create = function (name, instance) {
    return new ServiceDescriptor(name, instance);
  };

  return ServiceDescriptor;
}();

exports.ServiceDescriptor = ServiceDescriptor;