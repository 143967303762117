"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemorySubCategoryRepository = void 0;

var ibase_auctions_domain_1 = require("ibase-auctions-domain");

var modbin_domain_extensions_1 = require("modbin-domain-extensions");

var InMemorySubCategoryRepository =
/** @class */
function (_super) {
  __extends(InMemorySubCategoryRepository, _super);

  function InMemorySubCategoryRepository(options) {
    var _this = _super.call(this, {
      initialElements: []
    }) || this;

    _this.categories = new Map();
    _this.resourceMapper = options.resourceMapper;

    _this.mapElements(options.elements);

    return _this;
  }

  InMemorySubCategoryRepository.prototype.mapElements = function (initialElements) {
    var _this = this;

    this.resourceMapper.mapCollection(initialElements, {
      source: "tipo_bem",
      target: "subcategory",
      targetConstructor: ibase_auctions_domain_1.SubCategory
    }).then(function (x) {
      x.forEach(function (e) {
        _super.prototype.create.call(_this, e);
      });

      _this.loadCategories(x);
    });
  };

  InMemorySubCategoryRepository.prototype.loadCategories = function (initialElements) {
    var _this = this;

    Object.keys(ibase_auctions_domain_1.Category).forEach(function (x) {
      _this.categories.set(ibase_auctions_domain_1.Category[x], []);
    });
    initialElements.forEach(function (x) {
      if (x.parentId !== undefined) {
        if (_this.categories.get(x.parentId) !== undefined) {
          _this.categories.set(x.parentId, _this.categories.get(x.parentId).concat(x));
        }
      }
    });
  };

  InMemorySubCategoryRepository.prototype.findAllSubCategoriesFromCategory = function (category) {
    var cat = this.categories.get(category);
    return cat !== undefined ? this.categories.get(category) : [];
  };

  return InMemorySubCategoryRepository;
}(modbin_domain_extensions_1.InMemoryRepository);

exports.InMemorySubCategoryRepository = InMemorySubCategoryRepository;