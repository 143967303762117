import { ClockDate } from "ibase-auctions-domain";

export enum SortDirection {
    DESC = "desc",
    ASC = "asc"
}

export const dateComparer = (first:any, second:any) => {
    if (!first)
            return -1;
    if (!second)
        return 1;
    return new Date(first).getTime() < new Date(second).getTime() ? -1 : 1;
}

export const sorter = (comparer:any, direction:SortDirection) => {
    const f = function (fst:any,snd:any){
        let result = comparer(fst,snd);
        if(direction === SortDirection.DESC){
            result = -result;
        }
        return result;
    }
    return f;
} 

export const clockEndDateSorter = (fst:any&{clockDate:ClockDate}, snd:any&{clockDate:ClockDate}) => {
    return sorter(dateComparer,SortDirection.ASC)(fst.clockDate.end,snd.clockDate.end);
}