"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserAuthenticationDataStorageService = void 0;

var UserAuthenticationDataStorageService =
/** @class */
function () {
  function UserAuthenticationDataStorageService() {}

  UserAuthenticationDataStorageService.prototype.getAuthenticationData = function () {
    var userId = localStorage.getItem("user_id");
    var token = localStorage.getItem("user_token");
    var authenticationData = {
      userId: userId !== null ? userId : undefined,
      token: token !== null ? token : undefined
    }; //console.log("UserAuthenticationDataStorageService: getting authentication data from local storage",authenticationData);

    return authenticationData;
  };

  UserAuthenticationDataStorageService.prototype.getAuthenticationToken = function () {
    var token = localStorage.getItem("user_token");
    return token !== null ? token : undefined;
  };

  UserAuthenticationDataStorageService.prototype.saveAuthenticationData = function (authenticationData) {
    //console.log("UserAuthenticationDataStorageService: saving authentication data to local storage",authenticationData);
    localStorage.setItem("user_id", authenticationData.userId);
    localStorage.setItem("user_token", authenticationData.token);
  };

  UserAuthenticationDataStorageService.prototype.deleteAuthenticationData = function () {
    //console.log("UserAuthenticationDataStorageService: deleting authentication data from local storage");
    localStorage.removeItem("user_token");
    localStorage.removeItem("user_id");
  };

  return UserAuthenticationDataStorageService;
}();

exports.UserAuthenticationDataStorageService = UserAuthenticationDataStorageService;