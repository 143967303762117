"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EndpointStore = void 0;

var modbin_domain_1 = require("modbin-domain");

var EndpointValidation_1 = require("./EndpointValidation");

var EndpointStore =
/** @class */
function () {
  function EndpointStore(options) {
    this._elements = new Map();

    if (options === undefined) {
      return;
    }

    if (!modbin_domain_1.arrayIsNullOrEmpty(options.initialElements)) {
      this.addEndpoints(options.initialElements);
    }
  }

  EndpointStore.prototype.getEndpoint = function (name) {
    var endpoint = this._elements.get(name);

    if (endpoint === undefined) {
      throw new Error("Endpoint with name " + name + " is not defined!");
    }

    return endpoint;
  };

  EndpointStore.prototype.getEndpoints = function () {
    return Array.from(this._elements.values());
  };

  EndpointStore.prototype.addEndpoints = function (endpoints) {
    var _this = this;

    endpoints.forEach(function (x) {
      if (_this._elements.get(x.name) !== undefined) {
        throw new Error("Endpoint with name " + x.name + " is already defined!");
      }

      _this._elements.set(x.name, x);
    });
  };

  EndpointStore.prototype.getEndpointForRouteUrl = function (route) {
    return EndpointValidation_1.getEndpointDefinitionIfMatched(route, this.getEndpoints());
  };

  return EndpointStore;
}();

exports.EndpointStore = EndpointStore;