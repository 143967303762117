"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromParishQuery = exports.convertToParish = void 0;

exports.convertToParish = function (externalParish) {
  return {
    id: externalParish.freguesia_id.toString(),
    code: externalParish.freguesia_cod,
    regionCode: externalParish.distrito_cod,
    countyCode: externalParish.concelho_cod,
    name: externalParish.freguesia
  };
};

exports.convertFromParishQuery = function (query) {
  return {
    concelho_cod: query.countyCode,
    distrito_cod: query.regionCode
  };
};