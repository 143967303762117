"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Application = void 0;

var ApplicationState_1 = require("./ApplicationState");

var Application =
/** @class */
function () {
  function Application(options) {
    this.state = ApplicationState_1.ApplicationState.DOWN;
    this.name = options.name;
    this.container = options.container;
    this.tasks = options.tasks;
    this.logging = options.logging;
    this.settings = options.settings;
    this.changeState(ApplicationState_1.ApplicationState.UP);
    this.internalInitialize(options);
  }

  Application.prototype.internalInitialize = function (options) {}; //configuration properties


  Application.prototype.getSettings = function () {
    return this.settings;
  };

  Application.prototype.setSettings = function (settings) {
    this.settings = settings;
  };

  Application.prototype.getName = function () {
    return this.name;
  }; //state


  Application.prototype.changeState = function (state) {
    var previousState = this.state;

    if (state !== this.state) {
      this.state = state; //this.logger.trace(`Application state changed: from ${previousState} => ${this.state}`);
    }
  };

  Application.prototype.getState = function () {
    return this.state;
  }; //logging


  Application.prototype.getLogger = function () {
    return this.container.resolve("logger");
  };

  Application.prototype.setLogging = function (loggingDescription) {
    this.loggingDescription = loggingDescription;
    this.container.replace("logger", this.loggingDescription.logger);
  };

  Application.prototype.getLogging = function () {
    return this.loggingDescription;
  }; //IOC


  Application.prototype.getContainer = function () {
    return this.container;
  }; //events


  Application.prototype.beforeLoad = function () {
    return this.tasks.getRunner().runBeforeLoad();
  };

  Application.prototype.load = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.changeState(ApplicationState_1.ApplicationState.LOADING);
            return [4
            /*yield*/
            , this.beforeLoad()];

          case 1:
            _a.sent();

            return [4
            /*yield*/
            , this.tasks.getRunner().runLoad()];

          case 2:
            _a.sent();

            return [4
            /*yield*/
            , this.afterLoad()];

          case 3:
            _a.sent();

            this.changeState(ApplicationState_1.ApplicationState.LOADED);
            return [2
            /*return*/
            , Promise.resolve()];
        }
      });
    });
  };

  Application.prototype.afterLoad = function () {
    return this.tasks.getRunner().runAfterLoad();
  };

  Application.prototype.beforeStart = function () {
    return this.tasks.getRunner().runBeforeStart();
  };

  Application.prototype.start = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.changeState(ApplicationState_1.ApplicationState.STARTING);
            return [4
            /*yield*/
            , this.load()];

          case 1:
            _a.sent();

            return [4
            /*yield*/
            , this.beforeStart()];

          case 2:
            _a.sent();

            return [4
            /*yield*/
            , this.tasks.getRunner().runStart()];

          case 3:
            _a.sent();

            this.changeState(ApplicationState_1.ApplicationState.STARTED);
            return [4
            /*yield*/
            , this.afterStart()];

          case 4:
            _a.sent();

            this.changeState(ApplicationState_1.ApplicationState.RUNNING);
            return [2
            /*return*/
            , Promise.resolve()];
        }
      });
    });
  };

  Application.prototype.afterStart = function () {
    return this.tasks.getRunner().runAfterStart();
  };

  Application.prototype.beforeStop = function () {
    return this.tasks.getRunner().runBeforeStop();
  };

  Application.prototype.stop = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.changeState(ApplicationState_1.ApplicationState.STOPPING);
            return [4
            /*yield*/
            , this.beforeStop()];

          case 1:
            _a.sent();

            return [4
            /*yield*/
            , this.tasks.getRunner().runStop()];

          case 2:
            _a.sent();

            return [4
            /*yield*/
            , this.afterStop()];

          case 3:
            _a.sent();

            this.changeState(ApplicationState_1.ApplicationState.STOPPED);
            return [2
            /*return*/
            , Promise.resolve()];
        }
      });
    });
  };

  Application.prototype.afterStop = function () {
    return this.tasks.getRunner().runAfterStop();
  };

  Application.prototype.beforeRestart = function () {
    return this.tasks.getRunner().runBeforeRestart();
  };

  Application.prototype.restart = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.changeState(ApplicationState_1.ApplicationState.RESTARTING);
            return [4
            /*yield*/
            , this.beforeStop()];

          case 1:
            _a.sent();

            return [4
            /*yield*/
            , this.tasks.getRunner().runRestart()];

          case 2:
            _a.sent();

            return [4
            /*yield*/
            , this.afterRestart()];

          case 3:
            _a.sent();

            this.changeState(ApplicationState_1.ApplicationState.RESTARTED);
            this.changeState(ApplicationState_1.ApplicationState.RUNNING);
            return [2
            /*return*/
            , Promise.resolve()];
        }
      });
    });
  };

  Application.prototype.afterRestart = function () {
    return this.tasks.getRunner().runAfterRestart();
  };

  return Application;
}();

exports.Application = Application;