import { observable } from "mobx";
import { RegistrationState, OperationStateResult, unknownState, loadingState, reactivationSuccessState, OperationState } from "../operations/States";
import { IUserProperties, LoginData, ISessionService } from "ibase-auctions-domain";
import { emptyUser } from "../../../private/profile/components/EmptyUser";
import { MainAppViewStore } from "../../../../configuration/stores/MainAppViewStore";
import { IUserNotificationService, isNotNullOrEmptyString, IBusinessEventService, ILogger } from "modbin-domain";
import { SessionStore } from "../SessionStore";
import { application } from "../../../../Startup";
import { RegisterUserFirstStepEvent, RegisterUserCompletedEvent } from "../business-events/RegisterEvent";
import { BaseStore, IStore, NotificationType } from "modbin-web";
import { IMainAppViewStore } from "../../../../configuration/temp/web/stores/IMainAppViewStore";
import { createLocalizedNotification } from "../../../../components/notifications/LocalizedNotificationView";

export class LoginViewStore extends BaseStore implements IStore {
    
    @observable registrationState:RegistrationState = "unknown";
    @observable registerUser:IUserProperties|undefined = undefined;
    @observable reactivationState:OperationStateResult= unknownState;

    @observable returnUrl:string|undefined;

    constructor(
            private mainAppViewStore:IMainAppViewStore,
            private sessionStore:SessionStore,
            private sessionService:ISessionService, 
            private userNotificationService:IUserNotificationService,
            private businessEventService:IBusinessEventService,
            private logger:ILogger
        ){
            super();
    }

    onRegister(loginData:LoginData):Promise<void>{
        this.registerUser = emptyUser;
        this.registerUser.contact.email = loginData.email;
        this.registerUser.password = loginData.password;
        this.mainAppViewStore.navigateTo("public:sessions:register");
        this.businessEventService.sendTrackingEvent(new RegisterUserFirstStepEvent(loginData.email));
        return Promise.resolve();
    }

    onRegisterCompleted(user:IUserProperties):Promise<void>{
        this.registrationState = "saving";
        return this.sessionService.registerUser(user).then((x) => {
            this.registrationState = "completed";
            this.mainAppViewStore.navigateTo("public:sessions:register-completed");
            this.businessEventService.sendTrackingEvent(new RegisterUserCompletedEvent(user.contact.email));
            
        },(x)=>{
            this.logger.error(`Error in onRegisterCompleted method in LoginViewStore. Email (${user.contact.email}):`,x);
            this.userNotificationService.error(x.message);  
        });
        
    }

    onLogin(loginData:LoginData){
        return this.sessionService.loginUser(loginData)
        .then(
            (x) => {
                this.sessionStore.loginUser(x);
                this.userNotificationService.success(application.translations.translate("screens:public.sessions.loginSuccessfulTitle"));  
                this.navigateAfterLogin();
            },
            (x)=> {
                this.logger.warning(`Error in login method in LoginViewStore. Email (${loginData.email}):`,x);
                if(x.message === "Account not activated"){
                    this.mainAppViewStore.navigateTo("public:sessions:noactivation");
                }
                else{
                    this.userNotificationService.error(x.message);  
                }
            }
        );
    }

    
    onReactivateAccount(email:string){
        this.reactivationState = loadingState;
        return this.sessionService.reactivateAccount(email)
        .then(
            (x) => {
                this.reactivationState = reactivationSuccessState;   
            },
            (x)=> {
                this.logger.error(`Error in requestPasswordRecovery method in PasswordRecoveryViewStore. Not validated email yet (${email}):`,x);
                this.reactivationState = {
                    state: OperationState.ERROR, 
                    notification: createLocalizedNotification({
                        type:NotificationType.ERROR,
                        headerKey:"common:words.unexpectedErrorTitle",
                        text:x.message
                    })};    
                this.userNotificationService.error(x.message);  
            }
        );
    }

    navigateAfterLogin(){
        if(isNotNullOrEmptyString(this.returnUrl, true)){
            this.mainAppViewStore.navigateToFromUrl(this.returnUrl!);
            this.returnUrl = undefined;
        }
        else{
            this.mainAppViewStore.navigateTo("public:home");
        }
    }

    setState(params:{url:string}){
        if(params !== undefined && isNotNullOrEmptyString(params.url)){
            this.returnUrl = params.url;
        }
    }

    getState(){
        if(isNotNullOrEmptyString(this.returnUrl)){
            return {url:this.returnUrl}
        }
        return undefined;
    }

    leave(){
        this.reactivationState = unknownState;
    }
}