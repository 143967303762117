import React from "react";
import { withTranslation } from "react-i18next";
import { Category } from "ibase-auctions-domain";
import { observer } from "mobx-react";


export const InnerCategorySelector = (props:any) => {
    const placeHolder = [<option key={`category:name`} value="0">{props.t(`domain:categories.main.name`)}</option>]; 
    const options = Object.entries(Category).map((x:any) => {
        return <option key={`category:${x}`} value={x[1]}>{props.t(`domain:categories.main.value.${(Category as any)[x[0]]}`)}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>;;
} 


export const CategorySelector = (withTranslation(["domain"])(InnerCategorySelector) as any) as (props:any) => JSX.Element;


export const InnerTypeSelector = observer((props:any) => {  
    const placeHolder = [<option key={`type:name`} value="0">Tipo</option>]; 
    const options = props.items.map((x:any) => { 
        return <option key={`subcategory:${x.description}`} value={x.getId()}>{props.t(`domain:categories.${props.selectedCategory}.value.${x.description}`)}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>;;
}) 


export const TypeSelector = (withTranslation(["domain"])(InnerTypeSelector) as any) as (props:any) => JSX.Element;

