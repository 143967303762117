import React from "react";
import { NavigationItem } from "./NavigationItem";
import { MenuWithTranslationProps, MenuProps } from "../Navigation.types";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";

export const InnerAdministrationMenu = observer((props:MenuWithTranslationProps) => {
    return <nav id="navigation" className="style-1" style={{float:"left"}}>
        <ul>
            <li>
                <a href="# ">{props.t("menu.admin.index")}</a>
                <ul>
                    {/* <NavigationItem text={props.t("menu.admin.users")}  to="administration:users"/> */}
                    <NavigationItem text={props.t("menu.admin.newsletters")}  to="administration:newsletters"/>
                </ul>
            </li>
        </ul>
    </nav>;
})

export const AdministrationMenu = (withTranslation(["common"])(InnerAdministrationMenu as any) as any) as (props:MenuProps) => JSX.Element;