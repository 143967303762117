import React, { Fragment } from "react";
import "react-image-lightbox/style.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { observable } from "mobx";
import { observer } from "mobx-react";
import { ImageBoxView } from "./ImageBoxView";

export type CarouselProps = {
    visibleItems:number;
    slidesPerStep:number;
    selectedSlide:number;
    autoPlay:boolean;
    infinite:boolean;
    items:any[];
    showDots:boolean;
    lightbox?:boolean;
    onRef?:(el:any)=>void;
    onItemClick?:(index:number)=>void;
    onSlideChange:(currentSlide:number) => void;
}

// const CustomButtonGroup = (props:{ next?:any, previous?:any, goToSlide?:any, carouselState?:any }) => {
//     const { totalItems, currentSlide } = props.carouselState;
//     return (
//       <div className="custom-button-group">
//         <div>Current slide is {currentSlide}</div>
//         <button onClick={() => props.previous()}>Previous slide</button>
//         <button onClick={() => props.next()}>Next slide</button>
//         <button
//           onClick={() => props.goToSlide(Math.floor(Math.random() * totalItems + 1))}
//         >
//           Go to a random slide
//         </button>
//       </div>
//     );
//   };

 

@observer
export class NewCarouselView extends React.Component<CarouselProps,{}>{    
    
    @observable lightboxVisible = false;

    onAfterChange = (index:number,state:any) => {
        
        // this.selectedSlide = index;
        if(this.props.onSlideChange !== undefined && this.props.selectedSlide !== state.currentSlide){
            this.props.onSlideChange(state.currentSlide);
        }
    }

    createLightbox(lightbox:boolean){
        return (lightbox) 
            ?   <ImageBoxView 
                    key="image-box"
                    images={this.props.items.map(x => {return {url:x}})}
                    photoIndex={this.props.selectedSlide} 
                    visible={this.lightboxVisible} 
                    onClose={() => {this.lightboxVisible = false}}
                    onIndexChange={(index:any) => {this.props.onSlideChange(index);}}
                ></ImageBoxView>
            : <Fragment key="pagination-empty"></Fragment>;
    }

    onClick(index:any){
        if(this.props.lightbox){
            this.lightboxVisible = true;
        }
        if(this.props.onItemClick !== undefined){
            (this.props.onItemClick as any)(index);
        }
    }

    render(){
        const CustomDot = (cprops:{
            index?:any,
            onClick?:any,
            active?:any
          }) => {
            return (
              <button
                onClick={e => {
                  cprops.onClick();
                  e.preventDefault();
                }}
                className={cprops.active ? "custom-dot custom-dot--active":"custom-dot"}
              >
                {this.props.items[cprops.index]}
              </button>
            );
          };
        const responsive = {
            desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: this.props.visibleItems,
            slidesToSlide: this.props.slidesPerStep, // optional, default to 1.
            },
            tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
            },
            mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
            },
        };
       
        let resultItems = this.props.lightbox 
            ? this.props.items.map((x,index) => {return React.cloneElement(x,{onClick:()=>{this.onClick(index)}})})
            : this.props.items;

        return <Fragment>
            <Carousel
                afterChange={(i:number,l:any)=> {this.onAfterChange(i,l);}}
                swipeable={false}
                draggable={false}
                showDots={this.props.showDots}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={this.props.infinite}
                autoPlay={this.props.autoPlay}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType="desktop"
                dotListClass="custom-dot-list-style"
                itemClass="custom-carousel-item"
                customDot={<CustomDot />}
                renderDotsOutside={true}
            >
                {resultItems}
            </Carousel>
            {/* {this.createLightbox(lightbox)} */}
        </Fragment>;
    }
}