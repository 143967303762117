import React, { Component } from "react";
import { MapProps, MapView } from "./MapView";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"GoogleMapUiTest",
    groupId:"components",
    description:"lalal",
    title:"Different views for a single item",
    parentId:"components"
};

export class GoogleMapUiTest extends Component<{},{}>{
    
    createBusinessLocationMap(){
        const props:MapProps = {
            center: {
                latitude:"38.7198502",
                longitude:"-9.1331314"
            },
            markers: [
                {
                    location:{
                        latitude:"38.7198502",
                        longitude:"-9.1331314"
                    }
                }
            ],
            width:"100%",
            height:"100%",
            type:"business",
            zoom:10
        }
        return <MapView {...props}></MapView>
    }

    createSingleLocationMap(){
        const props:MapProps = {
            center: {
                latitude:"41.568263",
                longitude:"-8.3854032"
            },
            markers: [
                {
                    location:{
                        latitude:"41.568263",
                        longitude:"-8.3854032"
                    }
                }
            ],
            width:"100%",
            height:400,
            type:"single",
            zoom:10
        }
        return <MapView {...props}></MapView>
    }
    render(){
        return <div> 
            <div className="row" style={{height:"480px"}}>
                {this.createBusinessLocationMap()}
            </div>
            <div className="row">
                {this.createSingleLocationMap()}
            </div>
            <div>
            <iframe title="Google maps test" width='100%' height='100%' id='mapcanvas' src='https://maps.google.com/maps?q=38.7225593,-9.1349971&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed' frameBorder='0' scrolling='no' ><div className="zxos8_gm"><a href="https://sites.google.com/site/topsonglyricsforwomen/toms-co-uk-voucher">this</a></div><div><div id='gmap_canvas'></div></div><div><small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a></small></div></iframe>
            </div>
        </div>;
    }
}