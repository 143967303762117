"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LevelDispatcherLoggerDecorator = void 0;

var LogLevel_1 = require("./LogLevel");

var LevelDispatcherLoggerDecorator =
/** @class */
function () {
  function LevelDispatcherLoggerDecorator(innerLogger, logLevel) {
    this.innerLogger = innerLogger;
    this.logLevel = logLevel;
  }

  LevelDispatcherLoggerDecorator.prototype.isActiveLogLevel = function (messageLogLevel, currentLogLevel) {
    return messageLogLevel < currentLogLevel;
  };

  LevelDispatcherLoggerDecorator.prototype.logMessageBasedOnCurrentLogLevel = function (messageLogLevel, message) {
    var _a;

    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    if (this.isActiveLogLevel(messageLogLevel, this.logLevel)) {
      (_a = this.innerLogger.log)[messageLogLevel].apply(_a, __spreadArrays([message], data));
    }
  };

  LevelDispatcherLoggerDecorator.prototype.fatal = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.error = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.warning = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.info = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.debug = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.trace = function (message) {
    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel(LogLevel_1.LogLevel.FATAL, message, data);
  };

  LevelDispatcherLoggerDecorator.prototype.log = function (logLevel, message) {
    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    this[logLevel](message, data);
  };

  return LevelDispatcherLoggerDecorator;
}();

exports.LevelDispatcherLoggerDecorator = LevelDispatcherLoggerDecorator;