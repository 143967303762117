import React, { Component } from "react";
import { observer } from "mobx-react";
import { AppLoadingErrorMessage } from "../../../components/errors/AppLoadingErrorMessage";
import { ActionButton, ButtonType } from "../../../components/buttons/ActionButton";

@observer
export class ScreensErrorsAppLoadingError extends Component<{},{}> {
    
    handleOnClick(){
        window.history.pushState(null, "title", "/");
        window.location.reload(true);
        return Promise.resolve()
    }
    
    render() {
        
        return (
            <div className="margin-top-100">
                <AppLoadingErrorMessage></AppLoadingErrorMessage>
                <div style={{textAlign:"center"}}>
                    <ActionButton type={ButtonType.RELOAD} onClick={()=>{return this.handleOnClick()}} />
                </div>
            </div>
        );
    }
}