import { IIocContainer, ServiceDescriptor, BaseModule, IServiceDescriptor, ILogger } from "modbin-domain";
import { DefaultTimerService } from "modbin-domain";
import { ApiConfiguration } from "../api/ApiConfig.types";
import { ApiRepositoryFactory } from "ibase-auctions-api";
import { IRestApiClient } from "modbin-api";
import { Banner } from "ibase-auctions-domain";
import { IBaseModule } from "./IBaseModule";
import { ServerTimeService } from "../temp/services/ServerTimeService";

export class SettingsModule extends IBaseModule {

    constructor(){
        super("settings");
    }
    
    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const timeService = new DefaultTimerService();
        return [
            ServiceDescriptor.create("timeService",timeService)
        ];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
        const timeService = new ServerTimeService(kernel.resolve<IRestApiClient>("apiClient"), apiConfig.endpoints.settings.serverTime, kernel.resolve<ILogger>("logger"));
      //  timeService.initialize();

        const apiRepositoryFactory:ApiRepositoryFactory = kernel.resolve<ApiRepositoryFactory>("apiRepositoryFactory");
        
        const bannerRepository = apiRepositoryFactory!.createRepository<Banner>(
            undefined as any,
            {source:"bannerServer",target:"banner",targetConstructor:Banner},
            ()=>{
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");        
                return {
                    collection:apiConfig.endpoints.settings.banners
                }
            });
        return [
            ServiceDescriptor.create("timeService",timeService),
            ServiceDescriptor.create("bannerRepository",bannerRepository)
        ];
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[] {
        return [
        ];
    }
}
