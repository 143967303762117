import React, { Component } from "react";
import { AuthenticationView } from "./AuthenticationView";
import { LoginData } from "ibase-auctions-domain";
import { sleepableConsoleLogger, UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"AuthenticationViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"Login and register component",
    parentId:"components:ibase:index"
};

export class AuthenticationViewUiTest extends Component<{},{}>{
    
    logResult(result:LoginData){
        console.log(result);
    }
    
    render(){
        return <AuthenticationView 
            onLogin={(data:LoginData)=>{return sleepableConsoleLogger(data)}}
            onRegister={(data:LoginData)=>{return sleepableConsoleLogger(data)}}
        />;
    }
}