"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composePromise = exports.compose = void 0;

exports.compose = function () {
  var fns = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    fns[_i] = arguments[_i];
  }

  return fns.reduceRight(function (prevFn, nextFn) {
    return function () {
      var args = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }

      return nextFn(prevFn.apply(void 0, args));
    };
  }, function (value) {
    return value;
  });
};

exports.composePromise = function () {
  var functions = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    functions[_i] = arguments[_i];
  }

  return function (initialValue) {
    return functions.reduceRight(function (sum, fn) {
      return Promise.resolve(sum).then(fn);
    }, initialValue);
  };
};