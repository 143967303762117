"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultApplicationTaskProvider = void 0;

var DefaultApplicationTaskProvider =
/** @class */
function () {
  function DefaultApplicationTaskProvider(options) {
    if (options === void 0) {
      options = {
        taskDefinitions: [],
        executionDefinitions: []
      };
    }

    this.taskDefinitions = new Map();
    this.executionDefinitions = new Map();

    if (options !== undefined) {
      this.addTaskDefinitions(options.taskDefinitions);
      this.addTaskExecutionDefinitions(options.executionDefinitions);
    }
  }

  DefaultApplicationTaskProvider.prototype.initialize = function (application) {
    this.application = application;
  };

  DefaultApplicationTaskProvider.prototype.addTaskDefinitions = function (tasks) {
    var _this = this;

    tasks.forEach(function (x) {
      _this.taskDefinitions.set(x.identifier, x);
    });
  };

  DefaultApplicationTaskProvider.prototype.getTaskDefinition = function (identifier) {
    return this.taskDefinitions.get(identifier);
  };

  DefaultApplicationTaskProvider.prototype.addExecutionDefinitionByExecutionTime = function (definition) {
    var definitions = this.executionDefinitions.get(definition.time);

    if (definitions === undefined) {
      this.executionDefinitions.set(definition.time, [definition]);
    } else {
      this.executionDefinitions.set(definition.time, __spreadArrays(definitions, [definition]));
    }
  };

  DefaultApplicationTaskProvider.prototype.addExecutionDefinition = function (definition) {
    var task = this.taskDefinitions.get(definition.taskIdentifier);

    if (task === undefined) {
      throw new Error("Task provider: You are trying to add an execution definiton with a non registered task. Application task " + definition.taskIdentifier + " was not registered yet!");
    }

    this.addExecutionDefinitionByExecutionTime(definition);
  };

  DefaultApplicationTaskProvider.prototype.addTaskExecutionDefinitions = function (definitions) {
    var _this = this;

    definitions.forEach(function (x) {
      _this.addExecutionDefinition(x);
    });
  };

  DefaultApplicationTaskProvider.prototype.createApplicationTask = function (executionDefinition) {
    var taskDefinition = this.taskDefinitions.get(executionDefinition.taskIdentifier);
    var applicationTask = {
      identifier: taskDefinition.identifier,
      mode: executionDefinition.mode,
      time: executionDefinition.time,
      run: taskDefinition.builder(this.application)
    };
    return applicationTask;
  };

  DefaultApplicationTaskProvider.prototype.getTasks = function (query) {
    var _this = this;

    if (query === undefined) {
      var definitionsByTime = Array.from(this.executionDefinitions.values()); //   console.log("Definitions by time:", definitionsByTime);

      var result = [].concat.apply([], definitionsByTime); //   console.log("Result:", result);

      return result.map(function (x) {
        return _this.createApplicationTask(x);
      });
    }

    if (query.time !== undefined) {
      var definitionsByTime = query.time.map(function (x) {
        return _this.executionDefinitions.get(x);
      }).filter(function (x) {
        return x !== undefined;
      }); //   console.log("Definitions by time:", definitionsByTime);

      var result = [].concat.apply([], definitionsByTime); //   console.log("Result:", result);

      return result.map(function (x) {
        return _this.createApplicationTask(x);
      });
    }

    return [];
  };

  return DefaultApplicationTaskProvider;
}();

exports.DefaultApplicationTaskProvider = DefaultApplicationTaskProvider;