import React, { ReactNode } from 'react';

export const EmptyLayout = (props:{
    header:JSX.Element,
    children:ReactNode|undefined,
    footer:JSX.Element,
}) => {
    return (<div>
                {props.header}
                {props.children}
                {props.footer}
        </div>
    );
}