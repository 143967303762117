"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tipology = void 0;
var Tipology;

(function (Tipology) {
  Tipology["T1"] = "t1";
  Tipology["T2"] = "t2";
  Tipology["T3"] = "t3";
  Tipology["T4"] = "t4";
  Tipology["T5"] = "t5";
  Tipology["T6"] = "t6";
  Tipology["T7"] = "t7";
  Tipology["T8"] = "t8";
  Tipology["T9"] = "t9";
  Tipology["T10"] = "t10";
  Tipology["NOTAPPLICABLE"] = "notapplicable";
})(Tipology = exports.Tipology || (exports.Tipology = {}));