import React, { Fragment, Component } from "react";
import Countdown from 'react-countdown-now';
import { observer, inject } from "mobx-react";
import { CountDownClockType, CountDownClockSize, CountDownClockProps, CountDownClockView } from "./CountDownClockView";
import { SellingEventDateState, getEventDateStateFromDates, ClockDate } from "ibase-auctions-domain";
import { TranslationProps, ITimerService } from "modbin-domain";
import { observable } from "mobx";
import { Spinner } from "../loading/Spinner";

export type IBaseCountDownClockProps = {
    clockDate:ClockDate,
    validatingTimeout:number,
    type?:CountDownClockType,
    size?:CountDownClockSize
}



const timeToCsssClassMapper = new Map<SellingEventDateState,string>(
    [
        [SellingEventDateState.NOTSTARTED,"notstarted"],
        [SellingEventDateState.DAYS,"days"],
        [SellingEventDateState.DAY,"day"],
        [SellingEventDateState.HOUR,"hour"],
        [SellingEventDateState.MINUTE,"minute"],
        [SellingEventDateState.ENDED,"ended"],
        [SellingEventDateState.VALIDATING,"validating"]
    ]
);


@inject("timeService") @observer
export class IBaseClockCountDownView extends Component<TranslationProps & IBaseCountDownClockProps & {timeService:ITimerService},{}> {

    @observable clockDateState:SellingEventDateState |undefined = undefined;
    @observable extendedEndDate:number|undefined = undefined;

    createClockDateState(){
        return getEventDateStateFromDates(this.props.clockDate.start,this.props.clockDate.end,this.props.timeService.getCurrentDate(), this.props.validatingTimeout);
    }

    getClockDateState(){
        if(this.clockDateState === undefined){
            return this.createClockDateState();
        }            
        return this.clockDateState;
    }

    getEndDate():Date{
        if(this.extendedEndDate !== undefined){
            return new Date(this.extendedEndDate);
        }
        return this.props.clockDate.end; 
    }

    onTick(){
        const newClockDateState = this.createClockDateState();
        if(newClockDateState !== this.clockDateState){
            this.clockDateState = newClockDateState;
        }
    }

    // onComplete(){
    //     this.extendedEndDate = this.props.clockDate.end.getTime() + this.props.validatingTimeout;
    // }

    createClock(){
            const cssClass = timeToCsssClassMapper.get(this.clockDateState!);
            const endDate = this.getEndDate();
            return <CountDownClockView
                size={this.props.size}
                type={this.props.type}
                endDate={endDate}
                nowDate={() => this.props.timeService.getCurrentDate().getTime()}
                cssClass={cssClass}
                onTick={()=>{this.onTick()}}
            //    onComplete={()=>{this.onComplete()}}
        ></CountDownClockView>;
    }

    getClass(clockDateState:SellingEventDateState){
        const cssClass = timeToCsssClassMapper.get(clockDateState!);
        const {size="small", type="simple"} = this.props;
        return `auction-clock ${type} ${size} ${cssClass} `
    }

    createNonClockComponent(clockDateState:SellingEventDateState, component:JSX.Element){
        const cssClass = this.getClass(clockDateState!);
        return <div className={cssClass}>
            {component}
        </div>;
    }

    handleOnRefresh(){
        window.location.reload(true);
        return Promise.resolve()
    }

    createBody(clockDateState:SellingEventDateState){
        const getTranslation = (key:string) => this.props.t(`common:words.${key}`); 
        if(clockDateState === SellingEventDateState.NOTSTARTED){
            const soonComponent = <span>{getTranslation("soon")}</span>;
            return this.createNonClockComponent(clockDateState,soonComponent);
        }
        if(clockDateState === SellingEventDateState.VALIDATING){
            const {size="small"} = this.props;
            const validatingComponent = <div>
                <div>
                    <Spinner size={size} className="spinner-validating"/>
                    <span>{getTranslation("validating")}</span>
                </div>
                <div style={{textAlign:"center"}}>
                <button className="button" style={{padding:"5px", fontSize:"30px"}} onClick={(ev:any)=>{this.handleOnRefresh()}}>{getTranslation("refreshNow")}</button>
                </div> 
            </div>;
            return this.createNonClockComponent(clockDateState,validatingComponent);
        }
        if(clockDateState === SellingEventDateState.ENDED){
            const finishedComponent = <span>{getTranslation("finished")}</span>;
            return this.createNonClockComponent(clockDateState,finishedComponent);
        }
        return this.createClock();
    }

    render(){ 
        const clockDateState = this.createClockDateState();
        return this.createBody(clockDateState);
    }
}