import React, { Component } from 'react';
import { configProperties } from '../../../configuration/ConfigProperties';
import { CompanyInfo } from '../../../configuration/info/CompanyInfo';
import { MapProps, MapView } from '../../../components/maps/MapView';
import ourOfficeImage from "../../../resources/images/contacts-our-office.jpg";
import {  IContactService, InformationRequest } from 'ibase-auctions-domain';
import { ContactForm } from '../../../components/forms/contact/ContactForm';
import { IUserNotificationService, TranslationProps } from 'modbin-domain';
import { application } from '../../../Startup';

const createBusinessMap = (companyInfo:CompanyInfo) => {
    const location = companyInfo.headquarters.location!;
    const props:MapProps = {
        center: location,
        markers: [
            {
                location:location
            }
        ],
        width:"100%",
        height:"100%",
        type:"business",
        zoom:10
    }
    return <MapView {...props}></MapView>
}

export class ScreensContactsIndex extends Component<TranslationProps,{}> {
    _submitForm = undefined;

    handleSubmitForm = (e:any) => {
        if (this._submitForm !== undefined) {
            (this._submitForm as any)(e);
        }
    };

    bindSubmitForm = (submitForm:any) => {
        this._submitForm = submitForm;
    };

    onSubmit = (request:InformationRequest):Promise<void> => {
        const contactService = application.getContainer().resolve<IContactService>("contactService");
        const userNotificationService = application.getContainer().resolve<IUserNotificationService>("userNotificationService");
        return contactService.sendInformationRequest(request).then((x:any)=> {
            return userNotificationService.success("Pedido enviado com sucesso!");
        },(x:any)=>{
            userNotificationService.error("Erro");
        })
    }

    render() {
        
        const getTranslation = (key:string) => this.props.t(`screens:public.contacts.${key}`); 
        return <div>
            <div className="contact-map margin-bottom-55">
                <div className="google-map-container">
                    {createBusinessMap(configProperties.companyInfo)}
                </div>
                <div className="address-box-container" style={{minWidth:"480px"}}>
                    <div className="address-container" style={{backgroundImage:`url(${ourOfficeImage})`}}>
                        <div className="office-address">
                        <h3>{getTranslation("headquarters")}</h3>
                        <ul>
                            <li>{configProperties.companyInfo.headquarters.address.street}</li>
                            <li>{configProperties.companyInfo.headquarters.address.zipCode1}</li>
                            <li style={{fontSize: "10px"}}>{getTranslation("contactInfo")} </li>
                            <li>Tel: {configProperties.companyInfo.headquarters.contacts.mobilePhoneNumber} </li>
                            <li>Email: {configProperties.companyInfo.headquarters.contacts.email} </li>
                        </ul>
                        <h3 style={{marginTop:"40px"}}>{getTranslation("warehouse_office")}</h3>
                        <ul>
                            <li>{configProperties.companyInfo.warehouse_office.address.street}</li>
                            <li>{configProperties.companyInfo.warehouse_office.address.zipCode1}</li>
                            <li style={{fontSize: "10px"}}>{getTranslation("contactInfo")} </li>
                            <li>Tel: {configProperties.companyInfo.warehouse_office.contacts.landPhoneNumber} </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-2"><span></span></div>
                    <div className="col-md-8 offset-md-2">
                        <section id="contact">
                            <ContactForm t={this.props.t} onSubmit={(request:any)=>{return this.onSubmit(request)}}></ContactForm>
                        </section>        
                    </div>
                    <div className="col-md-2"><span></span></div>
                </div>
            </div>
        </div>;
    }
}
