"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultApiErrorHandler = void 0;

var DefaultApiErrorHandler =
/** @class */
function () {
  function DefaultApiErrorHandler(errorMessageMapper) {
    this.errorMessageMapper = errorMessageMapper;
  }

  DefaultApiErrorHandler.prototype.map = function (sourceErrorMessage) {
    return this.errorMessageMapper.map(sourceErrorMessage);
  };

  DefaultApiErrorHandler.prototype.handleError = function (error) {
    var status = error.status;

    if (status >= 500) {
      return this.handleServerError(error);
    }

    if (status >= 400) {
      switch (status) {
        case 401:
          return this.handleUnauthenticated(error);

        case 403:
          return this.handleUnauthorized(error);

        case 404:
          return this.handleNotFound(error);

        default:
          return this.handleApplicationError(error);
      }
    }

    if (status >= 300) {
      return this.handleApplicationError(error);
    }
  };

  DefaultApiErrorHandler.prototype.handleNotFound = function (error) {
    return Promise.reject(error);
  };

  DefaultApiErrorHandler.prototype.handleUnauthorized = function (error) {
    return Promise.reject(error);
  };

  DefaultApiErrorHandler.prototype.handleUnauthenticated = function (error) {
    return Promise.reject(error);
  };

  DefaultApiErrorHandler.prototype.handleApplicationError = function (error) {
    return Promise.reject(error);
  };

  DefaultApiErrorHandler.prototype.handleServerError = function (error) {
    return Promise.reject(error);
  };

  return DefaultApiErrorHandler;
}();

exports.DefaultApiErrorHandler = DefaultApiErrorHandler;