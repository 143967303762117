import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import {MainMenu} from "./MainMenu";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"MenuUiTest",
    groupId:"components",
    description:"lalal",
    title:"ProfileForm component",
    parentId:"components"
};

export class MainMenuUiTest extends Component<TranslationProps,{}>{
    render(){
        return <MainMenu />;
    }
}
