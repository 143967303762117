import React from 'react';
import { TranslationProps } from "modbin-domain";
import { NotificationView } from './NotificationView';
import { withTranslation } from 'react-i18next';
import { NotificationType } from "modbin-web";

export const InnerLocalizedNotificationView = (props: TranslationProps & { type: NotificationType, headerKey?:string, content?:JSX.Element, textKey?:string, text?:string}): JSX.Element => {
    const {headerKey, content,textKey, text="",type,t} = props;
    
    const header = headerKey !== undefined 
        ? t(headerKey)
        : undefined;  
        
    const resultContent = content !== undefined 
        ? content
        : (textKey !== undefined 
            ? <p>{t(textKey)}</p>
            :<p>{text}</p>
        );

    return <NotificationView type={type} header={header}  content={resultContent}/>;
}

export const LocalizedNotificationView = (withTranslation(["common"])(InnerLocalizedNotificationView as any) as any) as (props:any) => JSX.Element;

export const createLocalizedNotification = (options:{ type:NotificationType, headerKey?:string,content?:JSX.Element,textKey?:string, text?:string}) => {
    return <LocalizedNotificationView {...options}/>;
}