"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SessionService = void 0;

var SessionService =
/** @class */
function () {
  function SessionService(userRepository, userAuthenticationService) {
    this._userRepository = userRepository;
    this._userAuthenticationService = userAuthenticationService;
  }

  SessionService.prototype.getAuthenticationData = function () {
    return this._userAuthenticationService.getAuthenticationData();
  };

  SessionService.prototype.isAuthenticated = function () {
    return this._userAuthenticationService.isAuthenticated();
  };

  SessionService.prototype.registerUser = function (user) {
    return this._userRepository.create(user);
  };

  SessionService.prototype.updateUser = function (user) {
    return this._userRepository.update(user);
  };

  SessionService.prototype.activateAccount = function (activationData) {
    return this._userAuthenticationService.activate(activationData);
  };

  SessionService.prototype.reactivateAccount = function (email) {
    return this._userAuthenticationService.reactivate(email);
  };

  SessionService.prototype.loginUser = function (loginData) {
    return this._userAuthenticationService.login(loginData);
  };

  SessionService.prototype.logoutUser = function () {
    return this._userAuthenticationService.logout();
  };

  SessionService.prototype.recoverUserPassword = function (passwordRecoveryData) {
    return this._userAuthenticationService.recoverPassword(passwordRecoveryData);
  };

  SessionService.prototype.requestUserPasswordRecovery = function (email) {
    return this._userAuthenticationService.requestUserPasswordRecovery(email);
  };

  return SessionService;
}();

exports.SessionService = SessionService;