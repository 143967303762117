import {observable, toJS} from "mobx";
import { Lot } from "ibase-auctions-domain";
import { SearchStore } from "../components/search/SearchStore";
import { LotStore, LotQuery } from "./Store";
import { LotSearchEvent } from "../business-events/LotSearchEvent";
import { IBusinessEventService } from "modbin-domain";
import { BaseStore, IStore } from "modbin-web";

export class LotIndexViewStore extends BaseStore implements IStore{
    
    @observable urlParametersState = {};
    @observable isLoading:boolean = true;
    @observable visibleItems:Lot[] = []; 

    constructor(private searchViewStore:SearchStore,private lotStore:LotStore, private businessEventService:IBusinessEventService) {
        super();
    }
    
    getLots(){
        return this.visibleItems;
    }

    setState(param:LotQuery){
        this.isLoading = true;
        this.searchViewStore.initializeFromQuery(param).then((x)=>{
            const query = toJS(this.searchViewStore.query);        
            this.urlParametersState = toJS(this.searchViewStore.query);
            this.lotStore.fetchLots(query)
            .then(x => {                
                this.visibleItems = x;
                this.isLoading = false;
            },
            x => {
                this.isLoading = false;
            });
        });
        
        // this.searchViewStore.selectedSubCategory = param.subCategory;
    }

    getState(){
        return toJS(this.urlParametersState);
    }

    onSearch(){
        this.isLoading = true;
        const query = toJS(this.searchViewStore.query);
        this.businessEventService.sendTrackingEvent(new LotSearchEvent(query as any));
        this.urlParametersState = query;
        this.lotStore.fetchLots(query).then(x => {
            this.isLoading = false;
            this.visibleItems = x;
        });
    }

    reset(){
        this.urlParametersState = {};
        this.isLoading = true;
        this.visibleItems = []; 
        return Promise.resolve();
    }
}