"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RollbarLogger = void 0;

var modbin_domain_1 = require("modbin-domain");

var RollbarLogger =
/** @class */
function () {
  function RollbarLogger(level, rollbarLoggerService) {
    this.rollbarLoggerService = rollbarLoggerService;
    this.logLevel = level;
  }

  RollbarLogger.prototype.isActiveLogLevel = function (messageLogLevel, currentLogLevel) {
    return messageLogLevel <= currentLogLevel;
  };

  RollbarLogger.prototype.logMessageBasedOnCurrentLogLevel = function (messageLogLevel, func, message) {
    var data = [];

    for (var _i = 3; _i < arguments.length; _i++) {
      data[_i - 3] = arguments[_i];
    }

    if (this.isActiveLogLevel(messageLogLevel, this.logLevel)) {
      return func.apply(void 0, __spreadArrays([message], data));
    }
  };

  RollbarLogger.prototype.fatal = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.FATAL, function (m, d) {
      return _this.rollbarLoggerService.critical(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.trace = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.TRACE, function (m, d) {
      return _this.rollbarLoggerService.debug(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.debug = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.DEBUG, function (m, d) {
      return _this.rollbarLoggerService.debug(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.info = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.INFO, function (m, d) {
      return _this.rollbarLoggerService.info(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.warning = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.WARN, function (m, d) {
      return _this.rollbarLoggerService.warning(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.error = function (message) {
    var _this = this;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    this.logMessageBasedOnCurrentLogLevel.apply(this, __spreadArrays([modbin_domain_1.LogLevel.ERROR, function (m, d) {
      return _this.rollbarLoggerService.error(m, d);
    }, message], data));
  };

  RollbarLogger.prototype.log = function (logLevel, message) {
    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    this[logLevel](message, data);
  };

  return RollbarLogger;
}();

exports.RollbarLogger = RollbarLogger;