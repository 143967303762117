import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { BasicButton } from "./BasicButton";

export type BasicLoadingButtonProps = React.HTMLAttributes<HTMLButtonElement> &{
    onClick:()=>Promise<void>,
    //beforeLoading?:()=>Promise<boolean>,
    icon?:string,
    showLoadingIcon?:boolean
}

export type LoadingButtonProps = BasicLoadingButtonProps & {
    text:string,
    loadingText?:string
}

const loadingIcon = "fa fa-spinner fa-spin";

@observer
export class LoadingButton extends React.Component<LoadingButtonProps,{}>{
    @observable loading = false;
    @observable disabled = false;
    @observable icon:string|undefined = undefined;

    handleClick(ev:any){
        if(this.loading){
            ev.preventDefault();
            ev.stopPropagation();
        }
        else {
            ev.preventDefault();
            ev.stopPropagation();
            this.changeToLoadingState();
            this.props.onClick().then(
            ()=>{
                this.changeToNormalState();
            },
            ()=>{
                this.changeToNormalState();
            })
        }
    }

    changeToLoadingState(){
        this.loading = true;
        this.disabled = true;
        this.icon = this.props.showLoadingIcon
            ? loadingIcon
            : this.props.icon;
    }

    changeToNormalState(){
        this.loading = false;
        this.disabled = false;
        this.icon = this.props.icon;
    }

    getText(){
        if(!this.loading){
            return this.props.text;
        }
        return (this.props.loadingText !== undefined) ? this.props.loadingText : this.props.text;
    }

    getIcon(){
        return (this.icon === undefined) ? this.props.icon : this.icon;
    }

    render(){
        const {text,loadingText, onClick,showLoadingIcon, className, ...rest} = this.props;
        let buttonClassName = "btn button loading"; 
        buttonClassName = className !== undefined 
            ? `${buttonClassName} ${className}`
            : buttonClassName; 
        return <BasicButton 
            className={buttonClassName} 
            disabled={this.disabled} 
            text={this.getText()}
            icon={this.getIcon()}
            onClick={(ev:any)=>{this.handleClick(ev)}}
            {...rest}
        />;
    }
}

 