"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookingsProfile = void 0;

var BookingConverter_1 = require("./BookingConverter");

var visitaToBooking = {
  sourceType: "visita",
  targetType: "booking",
  converter: BookingConverter_1.convertToBooking
};
exports.bookingsProfile = {
  name: "Bookings",
  definitions: [visitaToBooking]
};