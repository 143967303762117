import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { Fragment, CSSProperties } from "react";
import { TranslationProps } from "modbin-domain";
import { User } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../components/forms/elements/FormInput";
import { toJS } from "mobx";
import { createMaritalStatusOptions, FormSelect, FormZipCode } from "../../../../components/forms/elements/FormSelect";
import { FormDatePicker } from "../../../../components/forms/elements/FormDatePicker";
import { ActionButton, ButtonType } from "../../../../components/buttons/ActionButton";
import { application } from "../../../../Startup";
import { Left } from "react-bootstrap/lib/Media";

const ProfileSubTitle = (props:{children:any}) => {
    const style = {
        margin: "20px 0 0 0",
        borderBottom:"2px solid #274abb"
    }
    return <h3 style={style}>{props.children}</h3>
}

const BasicInfoSection = observer((props:{formikState:any}&TranslationProps) => {
    const getTranslation = (key:string) => props.t(`screens:private.profile.${key}`); 
    const getTranslationUser = (key:string) => props.t(`domain:users.user.${key}`); 
    const {formikState} = props;
    const maritalStatusOptions = createMaritalStatusOptions(props);

    return <div className="col-md-12 my-profile">
        <ProfileSubTitle>{getTranslation("basicInfoTitle")}</ProfileSubTitle>
        <div className="col-md-6">
            <FormInput type="text" name="firstName" label={getTranslationUser("firstName")+ " *"} placeHolder={props.t("domain:users.user.firstName")} formikState={formikState} />
        </div>
        <div className="col-md-6">
            <FormInput type="text" name="lastName" label={getTranslationUser("lastName")+ " *"} placeHolder={props.t("domain:users.user.lastName")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="title" label={getTranslationUser("title")} placeHolder={props.t("domain:users.user.title")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormSelect name="maritalStatus" label={getTranslationUser("maritalStatus")} options={maritalStatusOptions} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormDatePicker label={getTranslationUser("birthDate")} name="birthDate" maxDate={new Date(Date.now())}></FormDatePicker>
            {/* <FormInput type="text" name="birthDate" label={getTranslationUser("birthDate")} placeHolder={props.t("domain:users.user.birthDate")} formikState={formikState} /> */}
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="bornAt" label={getTranslationUser("bornAt")} placeHolder={props.t("domain:users.user.bornAt")} formikState={formikState} />
        </div>
    </div>;
});  

const CompanyData = observer((props:{formikState:any}&TranslationProps) => {
    const getTranslationCompany = (key:string) => props.t(`domain:users.company.${key}`); 
    const {formikState} = props;
    
    return <Fragment>
        <div className="col-md-3">
            <FormInput type="text" name="company.name" label={getTranslationCompany("name")+ " *"} placeHolder={getTranslationCompany("name")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="company.vatNumber" label={getTranslationCompany("vatNumber")+ " *"} placeHolder={getTranslationCompany("vatNumber")} formikState={formikState} />
        </div>
    </Fragment>;
}); 


const CompanyRepresentative = ({ formikState, t }:any) => {
    const getCommonTranslation = (key:string) => t(`common:words.${key}`);
    const getTranslationCompany = (key:string) => t(`domain:users.company.${key}`); 

    const { setFieldValue } = useFormikContext();
    
    const companyData = formikState.values.company.isRepresentative 
        ? <CompanyData formikState={formikState} t={t}></CompanyData>
        : <Fragment></Fragment>;
    const radioStyle:CSSProperties = {
        width:"20px", 
        height:"20px", 
        float:"left"
    };
    const labelStyle:CSSProperties = { 
        float:"left",
        marginTop:"0px",
        marginLeft:"5px",
        marginRight:"30px"
    };
    return <Fragment>
        <div className="col-md-3" >
            <div className="form-group">
                <label htmlFor="company.isRepresentative">{getTranslationCompany("representative")}</label>
                <Field   name="company.isRepresentative">
                    {({field}:any) => {
                        return (
                            <div>
                                <div>
                                    <input                                
                                        style={radioStyle}
                                        type="radio"                                    
                                        checked={!formikState.values.company.isRepresentative}
                                        value="no"
                                        onChange={() => {setFieldValue("company.isRepresentative",false)}}
                                        
                                        />
                                    <label htmlFor="no" style={labelStyle}>{getCommonTranslation("no")}</label>
                                </div>
                                <div>
                                    <input                 
                                        style={radioStyle}
                                        type="radio"                                    
                                        checked={formikState.values.company.isRepresentative}
                                        value="yes"
                                        onChange={() => {setFieldValue("company.isRepresentative",true)}}                                    
                                    />
                                    <label htmlFor="yes" style={labelStyle}>{getCommonTranslation("yes")}</label>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        
                        )
                    }}
                    
                </Field>
         </div>
        </div>
        {companyData}
    </Fragment>;
}; 

const IdentificationSection = observer((props:{formikState:any}&TranslationProps) => {
    const getTranslation = (key:string) => props.t(`screens:private.profile.${key}`);
    const getTranslationIdentificationCard = (key:string) => props.t(`domain:users.identificationCard.${key}`); 
    const {formikState} = props;
    return <div className="col-md-12 my-profile">
        <ProfileSubTitle>{getTranslation("identificationCardTitle")}</ProfileSubTitle>
        <div className="col-md-3">
            <FormInput type="text" name="identificationCard.vatNumber" label={getTranslationIdentificationCard("vatNumber")+" *"} placeHolder={getTranslationIdentificationCard("vatNumber")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="identificationCard.number" label={getTranslationIdentificationCard("number")} placeHolder={getTranslationIdentificationCard("number")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="identificationCard.issuedAt" label={getTranslationIdentificationCard("issuedAt")} placeHolder={getTranslationIdentificationCard("issuedAt")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormDatePicker label={getTranslationIdentificationCard("expiration")} name="identificationCard.expiration"></FormDatePicker>
            {/* <FormInput type="text" name="identificationCard.expiration" label={getTranslationIdentificationCard("expiration")} placeHolder={getTranslationIdentificationCard("expiration")} formikState={formikState} /> */}
        </div>
        <CompanyRepresentative {...props}></CompanyRepresentative>
    </div>;
});  

const AddressSection = observer((props:{formikState:any}&TranslationProps) => {
    const getTranslation = (key:string) => props.t(`screens:private.profile.${key}`); 
    const getTranslationAddress = (key:string) => props.t(`domain:contacts.address.${key}`);
    const {formikState} = props;
    return <div className="col-md-12 my-profile">
        <ProfileSubTitle>{getTranslation("addressTitle")}</ProfileSubTitle>
        <div className="col-md-3">
            <FormInput type="text" name="address.street" label={getTranslationAddress("street")} placeHolder={getTranslationAddress("street")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="address.city" label={getTranslationAddress("city")} placeHolder={getTranslationAddress("city")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormZipCode label={getTranslationAddress("zipCode")+ " *"} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="address.country" label={getTranslationAddress("country")} placeHolder={getTranslationAddress("country")} formikState={formikState} />
        </div>
    </div>;
});  

const ContactsSection = observer((props:{formikState:any}&TranslationProps) => {
    const getTranslation = (key:string) => props.t(`screens:private.profile.${key}`); 
    const getTranslationContact = (key:string) => props.t(`domain:contacts.contact.${key}`);
    const {formikState} = props;
    return <div className="col-md-12 my-profile">
        <ProfileSubTitle>{getTranslation("contactsTitle")}</ProfileSubTitle>
        <div className="col-md-3">
            <FormInput type="text" name="contact.email" label={getTranslationContact("email")+ " *"} placeHolder={getTranslationContact("email")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="contact.mobilePhoneNumber" label={getTranslationContact("mobilePhoneNumber")+ " *"} placeHolder={getTranslationContact("mobilePhoneNumber")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="contact.landPhoneNumber" label={getTranslationContact("landPhoneNumber")} placeHolder={getTranslationContact("landPhoneNumber")} formikState={formikState} />
        </div>
        <div className="col-md-3">
            <FormInput type="text" name="contact.faxNumber" label={getTranslationContact("faxNumber")} placeHolder={getTranslationContact("faxNumber")} formikState={formikState} />
        </div>
    </div>;
});

const checkValueLength = (length:number,value:number) => {
    if(value === undefined || value === null){
        return true;
    }
    else {
        return value.toString().length === length;
    }
}

const ProfileFormView = observer((props:TranslationProps & {user:User, buttonType:ButtonType, onSave:(ev:any)=>Promise<void>}) => {
    if(props.user === undefined){
        return <Fragment></Fragment>;
    }
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loginSchema = Yup.object().shape({
            firstName: Yup.string()
                .required(createRequiredMessage("domain:users.user.firstName")),
            lastName: Yup.string()
                .required(createRequiredMessage("domain:users.user.lastName")),
            identificationCard: Yup.object().shape({
                vatNumber: Yup.string()
                    .required(createRequiredMessage("domain:users.identificationCard.vatNumber"))
            }),
            address:Yup.object().shape({
                zipCode1: Yup.string()
                    .matches(/[0-9]+/gi, props.t("domain:contacts.address.zipCode1MustBeNumber"))
                    .test('len1',props.t("domain:contacts.address.zipCode1Length"), val => checkValueLength(4,val))
                    .test('positive',props.t("domain:contacts.address.zipCodeMustBePositiveNumber"), val => val > "0000"),
                zipCode2: Yup.string()
                    .matches(/[0-9]+/gi, props.t("domain:contacts.address.zipCode2MustBeNumber"))
                    .test('len2',props.t("domain:contacts.address.zipCode2Length"), val => checkValueLength(3,val))
                    .test('positive',props.t("domain:contacts.address.zipCodeMustBePositiveNumber"), val => val > "000"),
                    //.max(3,props.t("domain:contacts.address.zipCode2MaxLength"))
            }),
            contact: Yup.object().shape({
                email: Yup.string()
                    .email(props.t("domain:users.user.emailInvalid"))
                    .required(createRequiredMessage("domain:users.user.email")),
                mobilePhoneNumber: Yup.string()
                    .required(createRequiredMessage("domain:contacts.contact.mobilePhoneNumber"))
            }),
            company: Yup.object().shape({
                isRepresentative: Yup.boolean().oneOf([true,false],"must be boolean"),
                name: Yup.string().when('isRepresentative',
                (isRepresentative:boolean)=>{
                   if(!isRepresentative){
                     return Yup.string();
                   }else{
                     return Yup.string().required(createRequiredMessage("domain:users.company.name"));
                   }
                  }),
                vatNumber: Yup.string().when('isRepresentative',
                  (isRepresentative:boolean)=>{
                     if(!isRepresentative){
                       return Yup.string();
                     }else{
                       return Yup.string().required(createRequiredMessage("domain:users.company.vatNumber"));
                     }
                    })
            })
          });
    const userProfile = toJS(props.user);
      return (
        <Formik
                initialValues={{...userProfile}}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                  return props.onSave(values).then((x)=>{
                        actions.setSubmitting(false);
                  });
                }}
              >
                {(formProps) => (
                  <Form>
                      <BasicInfoSection t={props.t} formikState={formProps}></BasicInfoSection>
                      <IdentificationSection t={props.t} formikState={formProps}></IdentificationSection>
                      <AddressSection t={props.t} formikState={formProps}></AddressSection>
                      <ContactsSection t={props.t} formikState={formProps}></ContactsSection>
                      <div className="col-md-12 my-profile">
                        <ActionButton type={props.buttonType} onClick={()=>{return formProps.submitForm()}} style={{float:"right"}} className="border margin-top-10"/>
                      </div>
                  </Form>
                )}
              </Formik>
      );
  });
  
  export const ProfileForm = (withTranslation(["common"])(ProfileFormView as any) as any) as (props:any) => JSX.Element;
