import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { MainAppViewStore } from '../../../configuration/stores/MainAppViewStore';
import { ExtendedSearchForm } from '../components/search/ExtendedSearchForm';
import { Lot } from 'ibase-auctions-domain';
import { LotIndexViewStore } from './ViewStore';
import { LotListItemView } from './components/LotListItemView';
import { NoResultsView } from '../../../components/loading/NoResultsView';
import { LoadingView } from '../../../components/loading/LoadingView';

const ListView = (props:{ items:(Lot)[], onClick:(id:string, eventId:string)=>void}) => {
    return <Fragment>{props.items.map(x => <LotListItemView key={x.getId()} item={x} onClick={props.onClick}></LotListItemView>)}</Fragment>;
}

@inject("mainAppViewStore", "lotIndexViewStore") @observer
export class ScreensBusinessLotsIndex extends Component<{mainAppViewStore?:MainAppViewStore, lotIndexViewStore?:LotIndexViewStore},{}> {
    // componentDidMount() {
    //     throw new Error("An error has occured in Buggy component!");
    //   }
    render() {
        const items = this.props.lotIndexViewStore!.getLots();
        return (<Fragment>
                <section className="search margin-bottom-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="search-title">Pesquisa de lotes</h3>
                                <ExtendedSearchForm onSearch={() => {this.props.lotIndexViewStore!.onSearch()}}/>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row fullwidth-layout">
                        <div className="col-md-12">
                            <div className="listings-container list-layout">
                                <LoadingView 
                                    isLoading={this.props.lotIndexViewStore!.isLoading} 
                                    showFallback={items.length === 0} 
                                    fallbackView={<NoResultsView text="Não foram encontrados resultados com os parâmetros de pesquisa fornecidos."></NoResultsView>}
                                >
                                    <ListView items={this.props.lotIndexViewStore!.getLots()} onClick={(id:string, eventId:string) => {this.props.mainAppViewStore!.navigateTo("business:lots:details",{state:{lotId:id, eventId:eventId}})}}></ListView>
                                </LoadingView>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}






