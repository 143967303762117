import { IIocContainer, BaseModule, ServiceDescriptor, IServiceDescriptor } from "modbin-domain";
import { ApiRepositoryFactory } from "ibase-auctions-api";
import { InMemoryRepository } from "modbin-domain-extensions";
import { DocumentFile, SellingEvent, SellingEventService } from "ibase-auctions-domain";
import { ApiConfiguration } from "../api/ApiConfig.types";
import { events } from "../../screens/support/tests/data/Events";
import { IBaseModule } from "./IBaseModule";

export class SaleEventsModule extends IBaseModule {

    constructor(){
        super("sales");
    }
    
    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const sellingEventRepository = new InMemoryRepository({initialElements:events});
        const sellingEventFileRepository = new InMemoryRepository({initialElements:[]});

        return [
            ServiceDescriptor.create("sellingEventRepository",sellingEventRepository),
            ServiceDescriptor.create("sellingEventFileRepository",sellingEventFileRepository)
        ];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const apiRepositoryFactory:ApiRepositoryFactory = kernel.resolve<ApiRepositoryFactory>("apiRepositoryFactory");
        
        const sellingEventRepository = apiRepositoryFactory!.createRepository<SellingEvent>(
            undefined as any,
            {source:"auction",target:"sellingEvent",targetConstructor:SellingEvent},
            ()=>{
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {
                    collection:apiConfig.endpoints.sales.events.collection,
                    get:apiConfig.endpoints.sales.events.single
                };
            }
        )
        const sellingEventFileRepository =  apiRepositoryFactory!.createRepository<DocumentFile>(
            undefined as any,
            {source:"documento",target:"documentFile",targetConstructor:DocumentFile},
            ()=>{
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {collection:apiConfig.endpoints.sales.events.documents};
            });

        return [
            ServiceDescriptor.create("sellingEventRepository",sellingEventRepository),
            ServiceDescriptor.create("sellingEventFileRepository",sellingEventFileRepository)
        ];
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[] {
        const sellingEventService = new SellingEventService(
            this.getDescriptor("sellingEventRepository"),
            20000
        );
        return [
            ServiceDescriptor.create("sellingEventService",sellingEventService)
        ];
    }
}