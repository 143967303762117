export const contactFormTranslations = {
    pt: {
        name:"Nome",
        subject:"Assunto",
        message:"Mensagem",
        email:"Email",
        haveAquestion:"Tem alguma pergunta?",
        feelFreeToAsk:"Se tem alguma questão, esteja à vontade para nos perguntar."
    },
    en: {
        name:"Nome",
        subject:"Assunto",
        message:"Mensagem",
        email:"Email",
        haveAquestion:"Got any questions?",
        feelFreeToAsk:"If you are having any questions, please feel free to ask.."
    }
}
