"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageableResult = void 0;

var PageableResult =
/** @class */
function () {
  function PageableResult(page, size, total, items) {
    this.page = page;
    this.size = size;
    this.total = total;
    this.items = items;
  }

  return PageableResult;
}();

exports.PageableResult = PageableResult;