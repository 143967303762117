"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filesProfile = void 0;

var DocumentFileConverter_1 = require("./DocumentFileConverter");

var PhotoConverter_1 = require("./PhotoConverter");

var documentoToDocumentFile = {
  sourceType: "documento",
  targetType: "documentFile",
  converter: DocumentFileConverter_1.convertToDocumentFile
};
var fotoToPhoto = {
  sourceType: "foto",
  targetType: "photo",
  converter: PhotoConverter_1.convertToPhoto
};
exports.filesProfile = {
  name: "Files",
  definitions: [documentoToDocumentFile, fotoToPhoto]
};