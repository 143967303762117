"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseStoreFactory = void 0;

var BaseStoreFactory =
/** @class */
function () {
  function BaseStoreFactory() {}

  BaseStoreFactory.prototype.create = function (container, viewNavigator, viewProvider) {
    var mainAppViewStore = this.createMainViewStore(container, viewNavigator, viewProvider);
    var stores = this.createStores(container, viewNavigator, viewProvider, mainAppViewStore);
    mainAppViewStore.configure(stores);

    var finalStores = __assign(__assign({}, stores), {
      mainAppViewStore: mainAppViewStore
    });

    return finalStores;
  };

  return BaseStoreFactory;
}();

exports.BaseStoreFactory = BaseStoreFactory;