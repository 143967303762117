import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { Lot, ClockDate, getClockDatesFromSellingDates } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { TimeInformationView } from "../../../components/time/TimeInformationView";
import { application } from "../../../../../Startup";
import { IServerTimeService } from "../../../../../configuration/temp/services/ServerTimeService";

export const LotTimeInformationView = observer((props:TranslationProps&{item:Lot,showClock:boolean,showDate:boolean}) => {
    const {item,...rest} = props;
    const timeService = application.getContainer().resolve<IServerTimeService>("timeService");
    if(item.event.dates.serverTime == undefined){
        return <Fragment></Fragment>;
    }
    const clockDate:ClockDate = getClockDatesFromSellingDates(item.event.dates, timeService.getCurrentDate());
    return <TimeInformationView clockDate={clockDate} {...rest} />;
});