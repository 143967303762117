import React, { Component } from 'react';
import { Nav} from 'react-bootstrap';
import { NavigationItem } from '../../../../../components/layout/navigation/menus/NavigationItem';
import { UiTestSpecification } from 'modbin-web';
import { uiTestService } from '../../helpers/WebpackLoaderUiTestService';
import { SupportLayout } from '../../../components/SupportLayout';
import { BreadcrumbView } from '../../../../../components/layout/navigation/breadcrums/BreadcrumbView';
import { inject } from 'mobx-react';
import { MainAppViewStore } from '../../../../../configuration/stores/MainAppViewStore';

const SectionMenu = (props:{sectionName:string, elements:UiTestSpecification[]}) => {
    return <div>
        <h3>{props.sectionName}</h3>
        <Nav bsStyle="pills" stacked>
            {props.elements.map(x => {
                // return <NavItem key={x.id} eventKey={x.id} href={`/tests/${x.id}`} >
                //     {x.id}
                // </NavItem>
                return <NavigationItem text={x.title} to={`support:tests:${x.parentId}:${x.id}`}/>
            })}
        </Nav>
    </div>;
}

@inject("mainAppViewStore")
export class UiTestLayout extends Component<{mainAppViewStore?:MainAppViewStore},{}> {

	render() {
        const nodes = this.props.mainAppViewStore!.currentPath !== undefined
        ? this.props.mainAppViewStore!.currentPath!.map(x => { return {...x, text:x.viewId}})
        : [];
        
		return <SupportLayout
            >
            <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            <SectionMenu sectionName="Components" elements={uiTestService.get("components")}></SectionMenu>
                            <SectionMenu sectionName="Screens" elements={uiTestService.get("screens")}></SectionMenu>
                        </div>
                        <div className="col-md-10">
                            {this.props.children}
                        </div>
                    </div>
                </div>
        </SupportLayout>;
	}
}