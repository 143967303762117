"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.layoutsProfile = void 0;

var BannerConverter_1 = require("./BannerConverter");

var bannerServerToBanner = {
  sourceType: "bannerServer",
  targetType: "banner",
  converter: BannerConverter_1.convertToBanner
};
exports.layoutsProfile = {
  name: "Layouts",
  definitions: [bannerServerToBanner]
};