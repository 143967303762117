import i18n from "../../../../i18n";
import { isNotNullOrEmptyString } from "modbin-domain";

export const getProperty = (object:any,property:string,noPropertyDefinedMsg:string) => {
    return isNotNullOrEmptyString(object[property])
        ? object[property]
        : noPropertyDefinedMsg;
}

export const getTitle = (object:any) => {
    return getProperty(object,"title",i18n.t("screens:business.lots.details.noTitle"));
}

export const getDescription = (object:any) => {
    return getProperty(object,"description",i18n.t("screens:business.lots.details.noDescription"));
}