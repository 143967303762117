"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LotService = void 0;

var LotService =
/** @class */
function () {
  function LotService(lotRepository, bookingRepository, documentsRepository, lotUpdateDataRepository, bidService, singleLotTemplate, lotUpdateDataTemplate, userFavoriteLotTemplate) {
    this.lotRepository = lotRepository;
    this.bookingRepository = bookingRepository;
    this.documentsRepository = documentsRepository;
    this.lotUpdateDataRepository = lotUpdateDataRepository;
    this.bidService = bidService;
    this.singleLotTemplate = singleLotTemplate;
    this.lotUpdateDataTemplate = lotUpdateDataTemplate;
    this.userFavoriteLotTemplate = userFavoriteLotTemplate;
  }

  LotService.prototype.getLotById = function (lotId, eventId) {
    return this.lotRepository.findById({
      loteId: lotId,
      auctionId: eventId
    });
  };

  LotService.prototype.makeBidOnLot = function (cmd) {
    return this.bidService.makeBidOnLot(cmd);
  };

  LotService.prototype.getAllPageableLots = function (query) {
    return this.lotRepository.findAllPageableByRepresentation(query);
  };

  LotService.prototype.getLotBookings = function (lotId) {
    return this.bookingRepository.findAllPageableByRepresentation({
      loteId: lotId
    });
  };

  LotService.prototype.getLotUpdateData = function (lotId, eventId) {
    return this.lotUpdateDataRepository.findById({
      loteUpdateDataId: lotId,
      auctionId: eventId
    });
  };

  LotService.prototype.getLotsFromEvent = function (eventId) {
    return this.lotRepository.findAllPageableByRepresentation({
      "auction_id": eventId
    });
  };

  LotService.prototype.getLotDocuments = function (lotId) {
    return this.documentsRepository.findAllPageableByRepresentation({
      loteId: lotId,
      published: true
    });
  };

  LotService.prototype.getUserFavoriteLots = function (userId) {
    return this.lotRepository.findAllPageableByRepresentation(undefined, {
      template: this.userFavoriteLotTemplate,
      params: {
        userId: userId
      }
    });
  };

  return LotService;
}();

exports.LotService = LotService;