export type TranslationProps = {t:(str:string)=>string};

export enum Language {
    PT = "pt",
    EN = "en"
}

export type TranslationDefinition = {
    [key in Language]: any;
}

export const translationKeyGetter = (base:string) => {
    return (key:string) => {
        return `${base}.${key}`;
    }
}

