import { IIocContainer, BaseModule, ServiceDescriptor, IServiceDescriptor } from "modbin-domain";
import { ApiRepositoryFactory } from "ibase-auctions-api";
import { ApiConfiguration } from "../api/ApiConfig.types";
import { InMemoryRepository } from "modbin-domain-extensions";
import { Region, County, Parish, AddressService, NewsletterService, InformationRequest, ContactService } from "ibase-auctions-domain";
import { newsletterSubscriptions } from "../../screens/support/tests/data/NewsletterSubscriptions";
import { IBaseModule } from "./IBaseModule";

export class ContactsModule extends IBaseModule {

    constructor(){
        super("contacts");
    }
    
    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const regionRepository = new InMemoryRepository({initialElements:[]});
        const countyRepository = new InMemoryRepository({initialElements:[]});
        const parishRepository = new InMemoryRepository({initialElements:[]});
        return [
            ServiceDescriptor.create("regionRepository",regionRepository),
            ServiceDescriptor.create("countyRepository",countyRepository),
            ServiceDescriptor.create("parishRepository",parishRepository),
        ];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
        const apiRepositoryFactory:ApiRepositoryFactory = kernel.resolve<ApiRepositoryFactory>("apiRepositoryFactory");
        
        const regionRepository = apiRepositoryFactory!.createRepository<Region>(
            {collection:apiConfig.endpoints.contacts.regions},{source:"distrito",target:"region",targetConstructor:Region}, undefined as any);
        const countyRepository = apiRepositoryFactory!.createRepository<County>({collection:apiConfig.endpoints.contacts.counties},{source:"concelho",target:"county",targetConstructor:County}, undefined as any);
        const parishRepository = apiRepositoryFactory!.createRepository<Parish>({collection:apiConfig.endpoints.contacts.parishes},{source:"freguesia",target:"parish",targetConstructor:Parish}, undefined as any);

        const informationRequestRepository = apiRepositoryFactory!.createRepository<Parish>(undefined as any, {source:"pedido",target:"informationrequest",targetConstructor:InformationRequest},() => {
            const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
            return {collection:apiConfig.endpoints.contacts.informationRequest};
        });
        
        return [
            ServiceDescriptor.create("regionRepository",regionRepository),
            ServiceDescriptor.create("countyRepository",countyRepository),
            ServiceDescriptor.create("parishRepository",parishRepository),
            ServiceDescriptor.create("informationRequestRepository",informationRequestRepository),
        ];
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[] {
        const addressService = new AddressService(
            this.getDescriptor("regionRepository"),
            this.getDescriptor("countyRepository"),
            this.getDescriptor("parishRepository")
        );

        const newsletterRepository = new InMemoryRepository({initialElements:newsletterSubscriptions});
        const newsletterService = new NewsletterService(newsletterRepository);        

        const contactService = new ContactService(this.getDescriptor("informationRequestRepository"));
        return [
            ServiceDescriptor.create("addressService",addressService),
            ServiceDescriptor.create("newsletterRepository",newsletterRepository),
            ServiceDescriptor.create("newsletterService",newsletterService),
            ServiceDescriptor.create("contactService",contactService)
        ];
    }
}
