"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.priceTranslations = void 0;
exports.priceTranslations = {
  pt: {
    market: "Valor de mercado",
    base: "Valor base",
    reserve: "Valor mínimo",
    starting: "Valor de abertura",
    current: "Lance atual",
    nextBid: "Próxima licitação",
    noBids: "Sem licitações",
    buyItNow: "Compre já",
    vat: "IVA",
    sold: "Valor de venda",
    currency: "Moeda"
  },
  en: {
    market: "Market value",
    base: "Base value",
    reserve: "Reserve value",
    starting: "Starting value",
    current: "Current bid",
    nextBid: "Next bid",
    noBids: "No bids",
    buyItNow: "Buy it now",
    vat: "VAT",
    sold: "Sold value",
    currency: "Currency"
  }
};