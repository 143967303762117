import { LogLevel, ApplicationTaskDefinition, IApplication, ApplicationTaskExecutionDefinition, ExecutionTime, ExecutionMode, DefaultLoggerFactory, ConsoleLoggerProvider, ILogger } from "modbin-domain";
import { ConfigProperties } from "./configuration/Config.types";
import { GlobalModule } from "./configuration/modules/GlobalModule";
import { SettingsModule } from "./configuration/modules/SettingsModule";
import { DefaultContextProvider } from "./configuration/temp/web/application/ContextProvider";
import { ContactsModule } from "./configuration/modules/ContactsModule";
import { UsersModule } from "./configuration/modules/UsersModule";
import { LotsModule } from "./configuration/modules/LotsModule";
import { SaleEventsModule } from "./configuration/modules/SaleEventsModule";
import { configProperties } from "./configuration/ConfigProperties";
import { administrationiewsDefinitions } from "./screens/administration/Administration.definitions";
import { privateViewsDefinitions } from "./screens/private/Private.definitions";
import { publicViewsDefinitions } from "./screens/public/Public.definitons";
import { businessViewsDefinitions } from "./screens/business/Business.definitions";
import { commonViewsDefinitions } from "./screens/common/Common.definitions";
import { InMemorySubCategoryRepository } from "ibase-auctions-api";
import {WebApplicationFactoryOptions, WebApplication, WebApplicationCreationOptions, IMainAppViewStore} from "modbin-web";
import { DirectorRouter} from "modbin-web-extensions";
import {IBaseWebApplicationFactory} from "./configuration/IBaseWebApplicationFactory";
import i18n from "./i18n";
import { IBaseStoreFactory } from "./configuration/temp/web/stores/IBaseStoreFactory";
import { VersionStore } from "./VersionStore";
import { ServerTimeService } from "./configuration/temp/services/ServerTimeService";
import { RollbarLoggerProvider } from "./infrastructure/logging/RollbarLoggerProvider";
import { throws } from "assert";
export *  from "./infrastructure/Mobx";


const defaultContextProvider = new DefaultContextProvider();

const logging = {
    factory: new DefaultLoggerFactory([new ConsoleLoggerProvider(), new RollbarLoggerProvider(defaultContextProvider)]),
    providers: []
};

const options:WebApplicationFactoryOptions = {
    targetConstructor:WebApplication,
    contextProvider: defaultContextProvider,
    storeFactory: new IBaseStoreFactory(),
    logging:logging
}

const factory = new IBaseWebApplicationFactory(options);

const modules = [
    new GlobalModule("global", defaultContextProvider),
    new SettingsModule(),
    new ContactsModule(),
    new UsersModule(),
    new LotsModule(),
    new SaleEventsModule()
];

const views = [
    ...administrationiewsDefinitions,
    ...privateViewsDefinitions,
    ...publicViewsDefinitions,
    ...businessViewsDefinitions,
    ...commonViewsDefinitions
]

type ApplicationLoadingState = {
    getErrors():number,
    addError():void,
    resetErrors():void,
    isValid():boolean
}

const applicationLoadingState:ApplicationLoadingState = (() => {
    let numberOfErrors = 0;
    const getErrors = () => { return numberOfErrors; };
    const addError = () => { numberOfErrors++; };
    const resetErrors = () => { numberOfErrors = 0; };
    const isValid = () => { return numberOfErrors === 0};

    return {
        getErrors:getErrors,
        addError:addError,
        resetErrors:resetErrors,
        isValid:isValid
    }
})();


const taskDefinitions:ApplicationTaskDefinition[] = [
    {
        identifier:"check_version",
        builder:(application:IApplication<any>):any => { 
            const run = () => {
                const versionStore = new VersionStore();
                return versionStore.initialize()
                .catch(x => {                 
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: check_version",x);
                    applicationLoadingState.addError();
                    return Promise.resolve();
                });
            };
            return run;
        }
    },
    {
        identifier:"load_events",
        builder:(application:IApplication<any>):any => { 
            const run = () => {
                const sellingEventStore = (application as WebApplication<any>)!.stores!.getStore("sellingEventStore")!;
                return sellingEventStore.initialize!()
                .catch(x => {
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: load_events",x);
                    applicationLoadingState.addError();
                    return Promise.resolve();
                });
            };
            return run;
        }
    },
    {
        identifier:"load_subcategories",
        builder:(application:IApplication<any>):any => { 
            const run = () => {
                return (application.getContainer().resolve<InMemorySubCategoryRepository>("subCategoryRepository") as any).loadCategories()
                .catch((x:any) => {
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: load_subcategories",x);
                    applicationLoadingState.addError();
                    return Promise.resolve();
                });
            };
            return run;
        }
    },
    {
        identifier:"load_server_time",
        builder:(application:IApplication<any>):any => { 
            const run = () => {
                return application.getContainer().resolve<ServerTimeService>("timeService").initialize()
                .catch((x: any) => {
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: load_server_time",x);
                    applicationLoadingState.addError();
                    return Promise.resolve();
                });
            };
            return run;
        }
    },
    {
        identifier:"load_home_banner",
        builder:(application:WebApplication<any>):any => { 
            const run = () => {
                const layoutStore = (application as WebApplication<any>)!.stores!.getStore("layoutStore")!;
                return layoutStore.initialize!()                
                    .catch(x => {
                        (application as WebApplication<any>)!.getLogger().error("Error in loading app: load_home_banner",x);
                        applicationLoadingState.addError();
                        return Promise.resolve();
                    });
            };
            return run;
            
        }
    },
    {
        identifier:"load_user_if_authenticated",
        builder:(application:WebApplication<any>):any => { 
            const run = () => {
                const sessionStore = (application as WebApplication<any>)!.stores!.getStore("sessionStore")!;
                return sessionStore.initialize!()                
                    .catch(x => {
                        (application as WebApplication<any>)!.getLogger().error("Error in loading app: load_user_if_authenticated",x);
                        applicationLoadingState.addError();
                        return Promise.resolve();
                    });
            };
            return run;
        }
    },
    {
        identifier:"initialize_router",
        builder:(application:IApplication<any>):any => {  
            const run = () => {
                try {
                    const app = application as WebApplication<any>;
                    const router = new DirectorRouter(app.views.getProvider(),app.views.getNavigator());
                    router.initialize();
                }
                catch(x){
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: initialize_router",x);
                }
                return Promise.resolve()
            };
            return run;
        }
    },
    {
        identifier:"reset_stores",
        builder:(application:WebApplication<any>):any => { 
            const run = () => {
                try {
                    Object.keys(application.stores.getStores()).forEach((x) => {
                        const store = application.stores.getStore(x)!;
                        if(store.reset !== undefined){
                            store.reset();
                        }
                    })
                }
                catch(x){
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: reset_stores",x);
                }
                return Promise.resolve();
            };
            return run;
        }
    },
    {
        identifier:"set_app_loaded",
        builder:(application:WebApplication<any>):any => { 
            const run = () => {
                try {
                    const mainViewStore = (application as WebApplication<any>).stores.getMainViewStore();
                    (mainViewStore as any).setLoaded();
                    if(!applicationLoadingState.isValid()){
                        (mainViewStore as IMainAppViewStore).navigateTo("common:errors:app-loading-error");
                    }
                }
                catch(x){
                    (application as WebApplication<any>)!.getLogger().error("Error in loading app: set_app_loaded",x);
                }
                return Promise.resolve();
            };
            return run;
        }
    }
];

const taskExecutionDefinitions:ApplicationTaskExecutionDefinition[] = [
    {
        taskIdentifier:"check_version",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.BEFORE_LOAD
    },
    {
        taskIdentifier:"load_events",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.BEFORE_LOAD
    },
    {
        taskIdentifier:"load_subcategories",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.BEFORE_LOAD
    },
    {
        taskIdentifier:"load_server_time",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.LOAD
    },
    {
        taskIdentifier:"load_home_banner",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.LOAD
    },
    {
        taskIdentifier:"load_user_if_authenticated",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.LOAD
    },
    {
        taskIdentifier:"initialize_router",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.LOAD
    },
    {
        taskIdentifier:"set_app_loaded",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.AFTER_START
    },
    {
        taskIdentifier:"reset_stores",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.BEFORE_RESTART
    },
    {
        taskIdentifier:"load_server_time",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.RESTART
    },
    {
        taskIdentifier:"load_home_banner",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.RESTART
    },
    {
        taskIdentifier:"load_user_if_authenticated",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.RESTART
    },
    {
        taskIdentifier:"initialize_router",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.RESTART
    },
    {
        taskIdentifier:"set_app_loaded",
        mode:ExecutionMode.AWAITABLE,
        time:ExecutionTime.AFTER_RESTART
    }
]

const loggingDefinition = configProperties.appConfig.logging;
const x = LogLevel[configProperties.appConfig.logging.level]
loggingDefinition.level = x as any;

const applicationOptions:WebApplicationCreationOptions<ConfigProperties> = {
    name:"Paraíso web",
    logging:loggingDefinition,
    settings:configProperties,
    modules:modules,
    views:views,
    tasks:{
        definitions:taskDefinitions,
        executionDefinitions:taskExecutionDefinitions
    },
    translations:{
        translate:(key:string,fields?:any)=>{
            return i18n.t(key, fields);
        },
        createRequiredErrorMsg(key:string){
            return i18n.t("domain:validation.requiredMessage",{field:i18n.t(key)});
        }        
    }
};


export const application = factory.createApplication(applicationOptions) as WebApplication<ConfigProperties>;