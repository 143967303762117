"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IBaseRestApiClient = void 0;

var modbin_api_1 = require("modbin-api");

var deepmerge = require('deepmerge');

var fetchHttpClientOptions = {
  formatters: [[modbin_api_1.HttpContentType.JSON, modbin_api_1.jsonContentTypeFormatter]]
};
var httpClient = new modbin_api_1.BaseHttpClient(new modbin_api_1.BrowserFetchHttpClient(fetchHttpClientOptions));
var defaultIBaseRestApiClientOptions = {
  defaultContentType: modbin_api_1.HttpContentType.JSON,
  httpClient: httpClient,
  //errorHandler: new DefaultApiErrorHandler(new IBaseErrorMessageMapper()),
  userAuthentication: {
    header: "Authorization_website"
  },
  apiAuthentication: {
    header: "Authorization"
  }
};

var IBaseRestApiClient =
/** @class */
function (_super) {
  __extends(IBaseRestApiClient, _super);

  function IBaseRestApiClient(options) {
    return _super.call(this, deepmerge(defaultIBaseRestApiClientOptions, options, {
      clone: false
    })) || this;
  }

  return IBaseRestApiClient;
}(modbin_api_1.RestApiClient);

exports.IBaseRestApiClient = IBaseRestApiClient;