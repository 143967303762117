import React, { Component, Fragment } from 'react';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { TranslationProps } from '../../../translations/Translations.types';

export class ScreensRalIndex extends Component<TranslationProps,{}> {
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:public.ral.${key}`); 
        return (<Fragment>
            <TitleBar title={getTranslation("title")}></TitleBar>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{textAlign: "justify"}}>
                        <h3>Entidade reguladora</h3>
                        <ul>
                            <li><a href="https://www.cnpd.pt" target="_blank" rel="noopener noreferrer">Comissão Nacional de Protecção de Dados</a></li>
                        </ul>
                        <p></p>
                        <h3>Entidade de resolução alternativa de litígios de consumo</h3>
                        <p>Em caso de litígio o consumidor pode recorrer a uma Entidade de Resolução Alternativa de Litígios de Consumo:</p>
                        <ul>
                            <li>Centro de Arbitragem de Conflitos de Consumo de Lisboa, telefone 218 80 70 30</li>
                            <li>Centro de Arbitragem de Conflitos de Consumo do Vale do Ave/Tribunal Arbitral, telefone 253 422 410</li>
                            <li>CIAB - Centro de Informação, Mediação e Arbitragem de Consumo (Tribunal Arbitral de Consumo), telefone 707 788 787</li>
                            <li>Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra, telefone 707 788 787</li>
                            <li>Centro de Informação, Mediação e Arbitragem de Conflitos de Consumo do Algarve, telefone 707 788 787</li>
                            <li>Centro de Informação de Consumo e Arbitragem do Porto, telefone 22 550 83 49</li>
                            <li>CNIACC - Centro Nacional de Informação e Arbitragem de Conflitos de Consumo</li>
                            
                        </ul>
                        <p>Mais informações em Portal do Consumidor <a href="http://www.consumidor.pt" target="_blank" rel="noopener noreferrer">www.consumidor.pt</a></p>
                        <p>(Informação actualizada em 01/09/2017)</p>
                    </div>
                </div>
            </div>
        </Fragment>);
    }
}