import React from "react"
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { SellingTypeSelector } from "./SellingTypeSelector";
import { CategorySelector,TypeSelector } from "./CategorySelector";
import { withTranslation } from "react-i18next";
import { MainAppViewStore } from "../../../../configuration/stores/MainAppViewStore";
import { SearchStore } from "./SearchStore";
import { AddressSelector } from "./AddressSelector";
import { ValueInput } from "./ValueInput";
import { TranslationProps } from "modbin-domain";

@inject("mainAppViewStore","searchViewStore") @observer
export class InnerCompactedSearchForm extends React.Component<{mainAppViewStore?:MainAppViewStore, searchViewStore:SearchStore}&TranslationProps,{}>{
    @observable showMore:boolean = false;

    toggleShowMore(ev:any){
        ev.preventDefault();
        this.showMore = !this.showMore;
    }

    onClick(ev:any){
        this.props.mainAppViewStore!.navigateTo("business:lots",{state:this.props.searchViewStore!.query, event:ev});
    }

    render(){
        const getTranslationButtons = (key:string) => this.props.t(`common:buttons.${key}`);
        const getTranslationWords = (key:string) => this.props.t(`common:words.${key}`); 
        const getTranslationContacts = (key:string) => this.props.t(`domain:contacts.address.${key}`); 
        const showMoreText = this.showMore ? getTranslationButtons("showLess") : getTranslationButtons("showMore");
        const showMoreStyle = this.showMore ? {display:"block"} : {};
        const query = this.props.searchViewStore!.query;

        return <form className="main-search-form">
            <div className="main-search-box">
                <div className="main-search-input larger-input">
                    <input type="text" className="ico-01" placeholder={getTranslationWords("searchHint")} 
                        value={query.term !== undefined ? query.term : ""}
                        onChange={(ev:any) => {this.props.searchViewStore!.onFormFieldChange("term",ev.target.value);}}
                    />
                    <button className="button" onClick={(ev)=>{this.onClick(ev)}}>{getTranslationButtons("search")}</button>
                </div>
                <a href="# " className="more-search-options-trigger" onClick={(ev) => this.toggleShowMore(ev)}>{showMoreText}</a>
                <div className="more-search-options relative" style={showMoreStyle}> 
                    <div className="more-search-options-container">
                        <AddressSelector
                            selectorClass="col-md-4"
                            countyPlaceHolderText={getTranslationContacts("county")}
                            parishPlaceHolderText={getTranslationContacts("parish")}
                            regionPlaceHolderText={getTranslationContacts("region")}
                        ></AddressSelector>
                        <div className="row with-forms">
                            <div className="col-md-6">
                                <CategorySelector selected={this.props.searchViewStore!.getDefaultableFieldValue("category")} onChange={(x:string) => {this.props.searchViewStore!.onCategoryChange(x);}}></CategorySelector>
                            </div>
                            <div className="col-md-6">
                                <TypeSelector selected={this.props.searchViewStore!.getDefaultableFieldValue("subCategory")} selectedCategory={this.props.searchViewStore!.getDefaultableFieldValue("category")} items={this.props.searchViewStore!.subCategoryItems} onChange={(x:string) => {this.props.searchViewStore!.onDefaultableFormFieldChange("subCategory", x);}}></TypeSelector>                    
                            </div>
                        </div>
                        <div className="row with-forms">
                            <div className="col-md-6">
                                <SellingTypeSelector
                                    selected={this.props.searchViewStore!.getDefaultableFieldValue("sellingType")}
                                    onChange={(v:string) => {this.props.searchViewStore!.onDefaultableFormFieldChange("sellingType",v)}}
                                ></SellingTypeSelector>                                
                            </div>
                            <div className="col-md-3">
                                <ValueInput 
                                    placeHolder={getTranslationWords("minimumValue")}
                                    value={query.minimumValue}
                                    onChange={(v:string) => {this.props.searchViewStore!.onFormFieldChange("minimumValue",v)}}
                                ></ValueInput>
                            </div> 
                            <div className="col-md-3">
                                <ValueInput 
                                    placeHolder={getTranslationWords("maximumValue")}
                                    value={query.maximumValue as any}
                                    onChange={(v:string) => {this.props.searchViewStore!.onFormFieldChange("maximumValue",v)}}
                                ></ValueInput>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>                         
        </form>
    };
}

export const CompactedSearchForm = (withTranslation(["common"])(InnerCompactedSearchForm as any) as any) as (props:any) => JSX.Element;