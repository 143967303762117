import React, { Fragment } from "react";
import { Field, ErrorMessage, getIn } from "formik";

export const FormInput = (props:{as?:string, label?:string,type:string,name:string,placeHolder?:string,formikState:any})=>{
    const {touched,errors} = props.formikState;
    const {label, type, name, placeHolder="", formikState, ...rest} = props;
    const labelElement = label !== undefined ? <label htmlFor={name}>{label}</label> : <Fragment></Fragment>;
    return <div className={`form-group ${
        getIn(touched,name) && getIn(errors,name) ? "has-error" : ""
        }`}>
        {labelElement}
        <Field
            as={props.as}
            type={type}
            name={name}
            placeholder={placeHolder}
            className={`form-control ${
                getIn(touched,name) && getIn(errors,name) ? "is-invalid" : ""
            }`}
            {...rest}
        />
        <ErrorMessage
            component="div"
            name={props.name}
            className="help-block invalid-feedback"
        />
    </div>;
}