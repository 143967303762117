import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import {  Currency } from "ibase-auctions-domain";
import { PriceHeaderView } from "./PriceHeaderView";

export const PriceInformationView = (props:TranslationProps&{
        label:string,
        value:any,
        bids?: number,
        currency:Currency,
        moreView?:React.ReactElement
    }) => {
    const {moreView,bids, ...rest} = props;
    const resultMoreView = moreView !== undefined
        ? <div className="boxed-widget booking-widget">
            {props.moreView}
        </div>
        : <Fragment></Fragment>;
    return <Fragment>
        <PriceHeaderView {...rest}></PriceHeaderView>
        {resultMoreView}
    </Fragment>;
};
