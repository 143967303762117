"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToAddress = void 0;

var modbin_domain_1 = require("modbin-domain");

var AddressStreetConverter_1 = require("./AddressStreetConverter");

exports.convertToAddress = function (source) {
  var street = AddressStreetConverter_1.convertToAddressStreet(source);
  var zipCode1 = modbin_domain_1.getStringValueOrDefault(source["codigo_postal_4"], undefined);
  var zipCode2 = modbin_domain_1.getStringValueOrDefault(source["codigo_postal_3"], undefined);
  var city = modbin_domain_1.getStringValueOrDefault(source["codigo_postal_designacao"], undefined);
  var country = modbin_domain_1.getStringValueOrDefault(source["pais"], undefined);
  return {
    street: street,
    zipCode1: zipCode1,
    zipCode2: zipCode2,
    city: city,
    country: country
  };
};