"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Currency = void 0;
var Currency;

(function (Currency) {
  Currency["METICAL"] = "metical";
  Currency["KWANZA"] = "kwanza";
  Currency["DOLLAR"] = "dollar";
  Currency["EURO"] = "euro";
})(Currency = exports.Currency || (exports.Currency = {}));