export const homeTranslations = {
    pt: {
        title:"Início",
        slogan1:"Paraíso, há 50 anos consigo",
        slogan2:"Para lhe mostrar os melhores negócios",
        featured:"Leilões a decorrer",
        lookingForTitle:"Do que está à procura?",
        lookingForApartments:"Apartamentos",
        lookingForHouses:"Moradias",
        lookingForLands:"Terrenos",
        lookingForVehicles:"Veículos"
    },
    en:{
        title:"Home",
        slogan1:"Paraíso, with you for over 50 years",
        slogan2:"Presenting you the best deals",
        featured:"Ongoing auctions",
        lookingForTitle:"What are you looking for?",
        lookingForApartments:"Apartments",
        lookingForHouses:"Houses",
        lookingForLands:"Lands",
        lookingForVehicles:"Vehicles"
    }
}