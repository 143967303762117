import { NewsletterSubscription, CreateNewsletterSubscriptionCmd, INewsletterService } from "ibase-auctions-domain";
import {observable} from "mobx";
import { IUserNotificationService, buildUrlPathFromPathTemplate, IRepository, ILogger } from "modbin-domain";
import { configProperties } from "../../../configuration/ConfigProperties";
import { IRestApiClient } from "modbin-api";
import { SessionStore } from "../../public/sessions/SessionStore";
import { application } from "../../../Startup";
import { BaseStore, IStore } from "modbin-web";

export class NewsletterViewStore extends BaseStore implements IStore{

    @observable
    subscriptions = new Map<string, NewsletterSubscription>();
    @observable isLoading:boolean = false;

    constructor(
        private newsletterRepository:IRepository<NewsletterSubscription>, 
        private newsletterService:INewsletterService,
        private userNotificationService:IUserNotificationService,
        private apiClient:IRestApiClient,
        private sessionStore:SessionStore,
        private logger:ILogger) {
            //this.fetch();
            super();
    }

    private addSubscription(s:NewsletterSubscription){
        this.subscriptions.set(s.getId(),s);
    }

    setState(){
        this.fetch();
    }

    fetch(){
        this.isLoading = true;
        // this.newsletterRepository.findAll().then(x => {
        //     x.forEach((e) => {
        //         this.addSubscription(e);
        //     });
        // });
        const url = this.getListSubscriptionsUrl();
        return this.apiClient.get<any>(url).then(x => {
                x.forEach((e:any) => {
                    this.addSubscription(new NewsletterSubscription(e));
                });
                this.isLoading = false;
            },
            (e:any) => {
                this.isLoading = false;
                this.logger.error(`Error in fetch method on NewsletterViewStore: `,e);
                throw e;
            });
    }

    getSubscriptions(){
        return Array.from(this.subscriptions.values());
    }

    getFileUrl(){
        //todo:remove when a solution for getting userId from token on the api is provided
        const userId = this.sessionStore.userId;
        const url = buildUrlPathFromPathTemplate(configProperties.apiConfig.endpoints.administration.newsletters.download,{userId:userId});
        return url;
    }

    getSubscribeUrl(){
        const url = configProperties.apiConfig.endpoints.administration.newsletters.subscriptions;
        return url;
    }

    getListSubscriptionsUrl(){
        const userId = this.sessionStore.userId;
        const url = buildUrlPathFromPathTemplate(configProperties.apiConfig.endpoints.administration.newsletters.collection,{userId:userId});
        return url;
    }

    subscribe(subsComd:CreateNewsletterSubscriptionCmd):Promise<void>{
       const url = this.getSubscribeUrl();

        return this.apiClient.post(url,subsComd).then(x => {
            this.userNotificationService.success(application.translations.translate("common:footer.successfulSubscriptionSentMessage"));
        },
        (e:any) => {
            this.logger.error(`Error in subscribe method on NewsletterViewStore: `,e);
            throw e;
        });
    }

    reset(){
        this.isLoading = false;
        this.subscriptions = new Map<string, NewsletterSubscription>();
        return Promise.resolve();
    }
}

