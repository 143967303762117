import React, { Fragment } from "react";
import { PublicMenu } from "./PublicMenu";
import { LoginAccountWidget } from "./LoginAccountWidget";
import { observer } from "mobx-react";
import { AdministrationWidget } from "./AdministrationWidget";

export const MainMenu = observer((props:{logoElem?:any}) => {
    const logoElem = props.logoElem !== undefined   
        ? <div>{props.logoElem}</div>
        : <Fragment></Fragment>;
    return <div className="container">
         <div className="row">
            <div className="col-md-2">
                {logoElem}
                <div className="clearfix"></div> 
            </div>
            <div className="col-md-7">
                <PublicMenu></PublicMenu>
                <div className="clearfix"></div> 
            </div>
            <div className="col-md-3">
                <div className="header-widget" style={{float:"right"}}><LoginAccountWidget></LoginAccountWidget></div>
                <div className="header-widget" style={{float:"right"}}><AdministrationWidget /></div>
                <div className="clearfix"></div> 
            </div>
        </div>
    </div>;
});