import { Context } from "./Context";

export interface IContextProvider {
    registerListener:(listener:(context:Context)=>void)=>void;
    setContext:(context:Context)=>void;
    getContext:()=>Context;
    hasContext(verifier?:(context:Context)=>boolean):boolean;
}

export class DefaultContextProvider implements IContextProvider {
    private context:any = undefined;
    subscribers:((context:Context)=>void)[] = [];
    
    registerListener(listener:(context:Context)=>void){
        this.subscribers.push(listener);
    }

    setContext(context:Context){
        this.context = context;
        this.subscribers.forEach((x) => {
            x(context);
        }) 
    }

    getContext():Context{ 
        return this.context!;
    }

    hasContext(verifier?:(context:Context)=>boolean):boolean{
        if(this.context === undefined) {
            return false;
        }
        if(verifier !== undefined) {
            return verifier(this.context);
        }
        return true;
    }
}
