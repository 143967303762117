import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SellingEventDateState } from "ibase-auctions-domain";
import { UiTestDescription } from "modbin-web";
import { CountDownClockSize, CountDownClockType, CountDownClockView } from "./CountDownClockView";
import { SelectInputView } from "../forms/elements/SelectInputView";
import { DefaultTimeService } from "../../configuration/temp/DefaultTimeService";

export const description:UiTestDescription = {
    id:"CountDownClockViewUiTest",
    groupId:"components",
    description:"Simple count downd clock component. Can be configured by using: size, type and class.",
    title:"Count down clock",
    parentId:"components"
};

const dateStateTest:any = {
    "ended" : {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T12:00:00')
    },
    "days":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:58:50')
    },
    "day":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-14T10:59:50')
    },
    "hour":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T09:59:50') 
    },
    "minute":{
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-15T11:00:00'),
        now: new Date('2019-12-15T10:59:50') 
    },
    "notstarted": {
        start: new Date('2019-12-09T10:00:00'),
        end: new Date('2019-12-09T11:00:00'),
        now: new Date('2019-12-09T09:00:00')
    }
};

@observer
export class AuctionClockViewUiTest extends React.Component<{},{}>{
    @observable size: CountDownClockSize = "small";
    @observable type:CountDownClockType = "extended"
    @observable cssClass:string = "notstarted"

    render(){
        const newDateState = dateStateTest[SellingEventDateState.NOTSTARTED];
        const timeService = new DefaultTimeService(newDateState.now.getTime());
        return <div>
                <div className="col-md-12">
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"small",text:"small"},{value:"medium", text:"medium"},{value:"large",text:"large"}]}
                            label="Size"
                            onChange={(v:any)=> {this.size = v}}
                            selected={this.size}
                            key="selector:size"
                        ></SelectInputView>
                    </div>
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[{value:"extended",text:"extended"},{value:"simple", text:"simple"}]}
                            label="Size"
                            onChange={(v:any)=> {this.type = v}}
                            selected={this.type}
                            key="selector:type"
                        ></SelectInputView>
                    </div>
                    <div className="col-md-6">
                        <SelectInputView
                            isLoading={false}
                            items={[
                                {
                                    value:"notstarted",
                                    text:"Not started yet (color)"
                                },
                                {
                                    value:"days", 
                                    text:"More than one day (color)"
                                },
                                {
                                    value:"day", 
                                    text:"Less than one day (color)"
                                },
                                {
                                    value:"hour", 
                                    text:"Less than one hour (color)"
                                },
                                {
                                    value:"minute", 
                                    text:"Less than one day (color)"
                                },
                                {
                                    value:"ended", 
                                    text:"Ended (color)"
                                }
                            ]}
                            label="Class"
                            onChange={(v:any)=> {this.cssClass = v}}
                            selected={this.cssClass}
                            key="selector:type"
                        ></SelectInputView>
                    </div>
                </div>
                <div className="col-md-12">
                    <h4>Result clock component:</h4>                 
                    <CountDownClockView
                    size={this.size}
                    type={this.type}
                    endDate={newDateState.end}
                    nowDate={() => timeService.getCurrentDate().getTime()}
                    cssClass={this.cssClass}
                ></CountDownClockView>
                </div>
        </div>
    }
};