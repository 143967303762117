import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { TextSwitcherView } from "./TextSwitcherView";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"TextSwitcherViewUiTest",
    groupId:"components",
    description:"lalal",
    title:"TextSwitcherView component",
    parentId:"components"
};

@observer
export class TextSwitcherViewUiTest extends Component<TranslationProps,{}>{
    
    render(){
        return <div>
            <div>
                <TextSwitcherView texts={["Paraíso, há 50 anos consigo","Para lhe mostrar os melhores negócios","Os melhores leilões perto de si"]} timeout={5000}/>
            </div>
        </div>;
    }
}