"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromIdentificationCard = exports.convertToIdentificationCard = void 0;

var modbin_domain_1 = require("modbin-domain");

var DateConverter_1 = require("../shared/DateConverter");

exports.convertToIdentificationCard = function (externalUser) {
  if (modbin_domain_1.isNullOrEmptyString(externalUser.nif, true) && modbin_domain_1.isNullOrEmptyString(externalUser.cartao_cidadao, true)) {
    return undefined;
  }

  var result = {};

  if (modbin_domain_1.isNotNullOrEmptyString(externalUser.nif)) {
    result.vatNumber = externalUser.nif;
  }

  var cartao_cidadao = externalUser.cartao_cidadao,
      cartao_cidadao_local_emissao = externalUser.cartao_cidadao_local_emissao,
      cartao_cidadao_validade = externalUser.cartao_cidadao_validade;

  if (modbin_domain_1.isNotNullOrEmptyString(cartao_cidadao)) {
    result.number = cartao_cidadao, result.issuedAt = modbin_domain_1.isNotNullOrEmptyString(cartao_cidadao_local_emissao) ? cartao_cidadao_local_emissao : undefined, result.expiration = cartao_cidadao_validade !== undefined && cartao_cidadao_validade !== null ? DateConverter_1.convertToDate(cartao_cidadao_validade) : undefined;
  }

  return result;
};

exports.convertFromIdentificationCard = function (identificationCard, target) {
  if (identificationCard !== undefined) {
    target.nif = identificationCard.vatNumber, target.cartao_cidadao = identificationCard.number, target.cartao_cidadao_local_emissao = identificationCard.issuedAt, target.cartao_cidadao_validade = identificationCard.expiration !== undefined && identificationCard.expiration !== null ? identificationCard.expiration.getTime() : undefined;
  }

  return target;
};