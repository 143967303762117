"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildUrlPathFromPathTemplate = exports.getLastPathSegmenOfUrl = exports.buildUrl = exports.buildPathSegment = exports.buildQueryStringSegment = exports.removeHeadAndtrailingSlash = void 0;

var IsNullOrEmpty_1 = require("../arrays/IsNullOrEmpty");

var RemoveFirstAndLastIfEqualsTo_1 = require("../strings/RemoveFirstAndLastIfEqualsTo");

exports.removeHeadAndtrailingSlash = function (target) {
  return RemoveFirstAndLastIfEqualsTo_1.removeFirstAndLastIfEqualsTo(target, "/");
};

exports.buildQueryStringSegment = function (query) {
  if (IsNullOrEmpty_1.arrayIsNullOrEmpty(query)) {
    return "";
  }

  var result = query.map(function (x) {
    return x[0] + "=" + x[1];
  }).join("&");
  return "?" + result;
};

exports.buildPathSegment = function (path) {
  if (IsNullOrEmpty_1.arrayIsNullOrEmpty(path)) {
    return "";
  }

  var result = path.map(function (x) {
    return exports.removeHeadAndtrailingSlash(x);
  }).join("/");
  return "/" + result;
};

exports.buildUrl = function (options) {
  var resultHost = exports.removeHeadAndtrailingSlash(options.host);
  var resultPath = exports.buildPathSegment(options.path);
  var resultQueryString = exports.buildQueryStringSegment(options.query);
  return "" + resultHost + resultPath + resultQueryString;
};

exports.getLastPathSegmenOfUrl = function (url) {
  var questionMarkIndex = url.indexOf("?");
  var lastIndex = questionMarkIndex >= 0 ? questionMarkIndex : url.length;
  var lastIndexOfSlash = url.lastIndexOf("/");
  return url.slice(lastIndexOfSlash + 1, lastIndex);
};

exports.buildUrlPathFromPathTemplate = function (pathTemplate, substitutionParameters, placeHolder) {
  var urlPath = pathTemplate;
  var queryString = [];
  var resultPlaceHolder = {
    begin: "{",
    end: "}"
  };

  if (placeHolder !== undefined) {
    var _a = placeHolder.begin,
        begin = _a === void 0 ? "" : _a,
        _b = placeHolder.end,
        end = _b === void 0 ? "" : _b;
    resultPlaceHolder.begin = begin;
    resultPlaceHolder.end = end;
  }

  for (var param in substitutionParameters) {
    if (substitutionParameters.hasOwnProperty(param) && substitutionParameters[param] !== undefined) {
      var parameterKey = "" + resultPlaceHolder.begin + param + resultPlaceHolder.end;

      if (urlPath.includes(parameterKey)) {
        urlPath = urlPath.replace(parameterKey, substitutionParameters[param]);
      } else {
        queryString.push(param + "=" + substitutionParameters[param]);
      }
    }
  }

  if (queryString.length > 0) {
    urlPath = urlPath + "?" + queryString.join("&");
  }

  return urlPath;
};