import { Formik, Form } from "formik";
import * as Yup from "yup";
import React from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../../components/buttons/ActionButton";
import { NavigationLink } from "../../../../components/layout/navigation/menus/NavigationLink";
import { application } from "../../../../Startup";

const LoginFormView = observer((props:TranslationProps & {onLogin:(ev:any)=>Promise<void>}) => {
    const getTranslationSessions = (key:string) => props.t(`screens:public.sessions.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loginSchema = Yup.object().shape({
            email: Yup.string()
              .email(props.t("domain:users.user.emailInvalid"))
              .required(createRequiredMessage("domain:users.user.email")),
            password: Yup.string()
              .min(3, "Password must be 3 characters at minimum")
              .required(createRequiredMessage("domain:users.user.password"))
          });
      return (
        <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                    return props.onLogin(values).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
              >
                {(formProps) => (
                  <Form>
                      <div className="form-row form-row-wide">
                        <FormInput type="email" name="email" label={getTranslationSessions("email")} placeHolder={props.t("domain:users.user.email")} formikState={formProps} />
                        </div>
                        <div className="form-row form-row-wide">
                      <FormInput type="password" name="password" label={getTranslationSessions("password")} placeHolder={props.t("domain:users.user.password")} formikState={formProps} />
                      </div>
                    
                      <div className="form-row">
                        <ActionButton type={ButtonType.LOGIN} onClick={()=>{return formProps.submitForm()}} className="border margin-top-10"/>
                    </div>
                    <div className="margin-top-20">
                        <NavigationLink to={"public:sessions:password-recovery-request"}>{getTranslationSessions("passwordLost")}</NavigationLink>
                    </div>
                  </Form>
                )}
              </Formik>
      );
  });
  
  export const LoginForm = (withTranslation(["common"])(LoginFormView as any) as any) as (props:any) => JSX.Element;