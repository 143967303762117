"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValueOrDefault = void 0;

var defaultNullValidator = function defaultNullValidator(value) {
  return value !== undefined && value !== null;
};

var defaultSelector = function defaultSelector(value) {
  return value;
};

exports.getValueOrDefault = function (value, defaultValue, options) {
  if (defaultValue === void 0) {
    defaultValue = undefined;
  }

  var validator = options !== undefined && options.validator !== undefined ? options.validator : defaultNullValidator;
  var selector = options !== undefined && options.selector !== undefined ? options.selector : defaultSelector;
  var result = validator(value) ? selector(value) : defaultValue;
  return result;
};