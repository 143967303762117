"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadSettingsFromMultipleFiles = exports.loadSettingsFromFile = void 0;

var path = require("path");

exports.loadSettingsFromFile = function (fileName, reader) {
  var currentPath = process.cwd(); // console.log("DRs",currentPath)
  // console.log("DRs",currentPath.split("\\").slice(0,currentPath.length-2))

  var configFileName = path.join(currentPath, fileName); //console.log("ConfigFileNAme", configFileName)

  var result = reader(configFileName);
  return result;
};

exports.loadSettingsFromMultipleFiles = function (files) {
  var result = {};
  files.map(function (x) {
    result[x.targetProp] = exports.loadSettingsFromFile(x.fileName, x.reader);
  });
  return result;
};