import React from "react";
import { TranslationProps } from "modbin-domain";
import { Field, ErrorMessage } from "formik";
import { MaritalStatus } from "ibase-auctions-domain";

export const FormSelect = (props:{name:string,label:string,options:{value:string,text:string}[], formikState:any, onChange?:(option:any)=>void})=>{
    const {touched,errors} = props.formikState;
    const {label, name, onChange} = props;
    const otherProp = onChange !== undefined
        ? { onChange:onChange}
        : {};
    return <div className={`form-group ${
                touched[name] && errors[name] ? "has-error" : ""
            }`}>
            <label htmlFor={name}>{label}</label>
            <Field as="select" name={props.name} {...otherProp}>
                {props.options.map(x => {
                    return <option value={x.value}>{x.text}</option>
                })}
            </Field>
            <ErrorMessage
                component="div"
                name={props.name}
                className="help-block invalid-feedback"
            />
        </div>;

} 

export const createMaritalStatusOptions = (props:TranslationProps)=>{
    const options = Object.entries(MaritalStatus).map((x:any) => {
        return { value:x[1], text:props.t(`domain:users.maritalStatus.${(MaritalStatus as any)[x[0]]}`)};
    });
    return options;
}

export const FormZipCode = (props:{label:string, formikState:any})=>{
    const {touched,errors} = props.formikState;
    const {label} = props;

    return <div className={`form-group ${
        touched.address && touched.address.zipCode1 && errors.address && errors.address.zipCode1 ? "has-error" : ""
        }`}>
        <label htmlFor="zipCode">{label}</label>
        <div className="col-md-6">
            <Field
                type="text"
                name="address.zipCode1"
                className={`form-control ${
                    touched.address && touched.address.zipCode1 && errors.address && errors.address.zipCode1 ? "is-invalid" : ""
                }`}
            />
            <ErrorMessage
            component="div"
            name="address.zipCode1"
            className="help-block invalid-feedback"
        />    
        </div>
        <div className="col-md-6">
            <Field
                type="text"
                name="address.zipCode2"
                className={`form-control ${
                    touched.address && touched.address.zipCode2 && errors.address && errors.address.zipCode2 ? "is-invalid" : ""
                }`}
            /><ErrorMessage
            component="div"
            name="address.zipCode2"
            className="help-block invalid-feedback"
        />
        </div>
    </div>;
} 