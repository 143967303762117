"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertPropertiesToArray = exports.convertPropertiesToPropertyValueArray = exports.eachProperty = exports.getPropertyOrUndefined = exports.propertyExists = exports.singlePropertyExists = exports.setIfUndefined = void 0;

exports.setIfUndefined = function (propertyName, first, second, third) {
  var result = first !== undefined && first[propertyName] !== undefined ? first[propertyName] : second !== undefined && second[propertyName] !== undefined ? second[propertyName] : third !== undefined && third[propertyName] !== undefined ? third[propertyName] : undefined;
  return result;
};

exports.singlePropertyExists = function (property, element) {
  if (element[property] !== undefined) {
    return true;
  }

  return false;
};

exports.propertyExists = function (property, element) {
  var path = property.split(".");
  var elem = element,
      result = false;

  for (var i = 0; i < path.length; i++) {
    result = exports.singlePropertyExists(path[i], elem);

    if (!result) {
      break;
    }

    elem = elem[path[i]];
  }

  return result;
};

exports.getPropertyOrUndefined = function (property, element) {
  if (element === undefined) {
    return undefined;
  }

  return exports.singlePropertyExists(property, element) ? element[property] : undefined;
};

exports.eachProperty = function (element, f) {
  var properties = Object.getOwnPropertyNames(element);
  return properties.map(function (x) {
    return f(element[x], x);
  });
};

exports.convertPropertiesToPropertyValueArray = function (element) {
  return exports.eachProperty(element, function (value, prop) {
    return {
      property: prop,
      value: value
    };
  });
};

exports.convertPropertiesToArray = function (element) {
  return exports.eachProperty(element, function (value, prop) {
    return value;
  });
};