import { observer } from "mobx-react";
import { TranslationProps, IKeyedObject, LogLevel, isNotNullOrEmptyString } from "modbin-domain";
import { application } from "../../../Startup";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { Fragment } from "react";
import { FormInput } from "../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../components/buttons/ActionButton";

export const FeaturesFormView = observer((props:TranslationProps & {
    features:{
        showInformationBar: boolean,
        showErrorDetails: boolean,
        showDevelopementMessages: boolean,
        enableTestsPage: boolean,
        numberOfDaysOfPastEvents:number
    },
    onSave:(ev:any)=>Promise<void>
}) => {
    const getTranslationFooter = (key:string) => props.t(`common:footer.${key}`); 
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    
      return (
          <div>
              <h2>Features</h2>
                <Formik
                initialValues={{...props.features}}
                onSubmit={(values, actions) => {
                    return props.onSave(values as any);
                }}
                 
              >
                {(formProps) => { 
                    return (
                  <Form>
                      <div className="form-row form-row-wide">
                      <label style={{fontSize: "15px", display: "inline", margin: "0px 40px 0px 10px", verticalAlign: "text-bottom"}}>Show information bar</label>
                        <Field
                                type="checkbox"
                                name="showInformationBar"
                                style={{width: "20px", height: "20px"}}
                            />
                      </div>
                      <div className="form-row form-row-wide">
                      <label style={{fontSize: "15px", display: "inline", margin: "0px 40px 0px 10px", verticalAlign: "text-bottom"}}>Show erro details</label>
                        <Field
                                    type="checkbox"
                                    name="showErrorDetails"
                                    style={{width: "20px", height: "20px"}}
                                />
                      </div>          
                      <div className="form-row form-row-wide">
                      <label style={{fontSize: "15px", display: "inline", margin: "0px 40px 0px 10px", verticalAlign: "text-bottom"}}>Show development messages</label>
                        <Field
                                    type="checkbox"
                                    name="showDevelopementMessages"
                                    style={{width: "20px", height: "20px"}}
                                />
                      </div>          
                      <div className="form-row form-row-wide">
                      <label style={{fontSize: "15px", display: "inline", margin: "0px 40px 0px 10px", verticalAlign: "text-bottom"}}>Enable tests page</label>
                        <Field
                                    type="checkbox"
                                    name="enableTestsPage"
                                    style={{width: "20px", height: "20px"}}
                                />
                      </div>         
                      <div className="form-row form-row-wide">
                        <FormInput type="text" name="numberOfDaysOfPastEvents" label="Number of days to show previous events" placeHolder="Authorization token" formikState={formProps} />
                      </div>         
                      
                     <div className="form-row">
                        <ActionButton type={ButtonType.SAVE} onClick={()=>{return formProps.submitForm()}}  style={{float:"right",marginTop: "20px"}} />
                    </div>
                  </Form>
               )}} 
              </Formik>
            </div>
      );
  });