"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Natureza = void 0;
var Natureza;

(function (Natureza) {
  Natureza["VEICULO"] = "VEICULO";
  Natureza["IMOVEL"] = "IMOVEL";
  Natureza["MOBILIARIO"] = "MOBILIARIO";
  Natureza["MAQUINA"] = "MAQUINA";
  Natureza["EQUIPAMENTO"] = "EQUIPAMENTO";
  Natureza["DIREITO"] = "DIREITO";
  Natureza["OUTRO"] = "OUTRO";
})(Natureza = exports.Natureza || (exports.Natureza = {}));