"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromRegionQuery = exports.convertToRegion = void 0;

exports.convertToRegion = function (externalRegion) {
  return {
    id: externalRegion.distrito_id.toString(),
    code: externalRegion.distrito_cod,
    name: externalRegion.distrito
  };
};

exports.convertFromRegionQuery = function (query) {
  return {
    distrito_id: query.getId(),
    distrito_cod: query.code
  };
};