import React, { Component } from 'react';
import { ScreensUsersIndexView } from './IndexView';
import { users } from '../../support/tests/data/Users';

export class ScreensUsersIndex extends Component {
    render() {
        return (
            <ScreensUsersIndexView 
                users={users}
            />
        );
    }
}