"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEndpoint = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.isEndpoint = function (endpoint) {
  return endpoint && modbin_domain_1.isNotNullOrEmptyString(endpoint.name) && modbin_domain_1.isNotNullOrEmptyString(endpoint.path) && endpoint.methods && Array.isArray(endpoint.methods) && (endpoint.authentication === undefined || endpoint.authentication && typeof endpoint.authentication === "boolean") && (endpoint.authorization === undefined || endpoint.authorization && typeof endpoint.authorization === "boolean");
};