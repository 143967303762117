"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromEntity = exports.convertToEntity = void 0;

var DateConverter_1 = require("./DateConverter");

exports.convertToEntity = function (item) {
  //console.log("EntityConverter:convertToEntity:item",item);
  var result = {
    id: item.id.toString(),
    createdDate: DateConverter_1.convertToDate(item.date_created),
    modifiedDate: DateConverter_1.convertToDate(item.date_updated)
  };
  return result;
};

exports.convertFromEntity = function (entity) {
  var result = {};

  if (entity.getId !== undefined) {
    result.id = parseInt(entity.getId());

    if (entity.getCreatedDate !== undefined) {
      result.date_created = entity.getCreatedDate().getTime();
    }

    if (entity.getModifiedDate !== undefined) {
      result.date_updated = entity.getModifiedDate().getTime();
    }
  }

  return result;
};