export const newsletterFooterTranslations = {
    pt: {
        subscriptionTitle: "Subscreva a nossa newsletter",
        informationTitle:"Informação",
        subscriptionText:"Quero obter informação da Agência de Leilões C. Paraíso e suas afiliadas sobre produtos, serviços e eventos via email, sms ou qualquer outro canal de comunicação, incluindo as últimas soluções, dicas e ofertas exclusivas. Use o formulário ao lado para subscrever.",
        cancelText:"Pode cancelar a subscrição a qualquer momento.",
        readPolicy:"Por favor consulte a ",
        privacyLinkText:"Politica de Privacidade e Tratamento de Dados Pessoais",
        readAndAccepted:"Li e aceito a ",
        readAndAcceptedRequiredMessage:"Tem que aceitar a política de privacidade.",
        nameField:"Nome",
        emailField:"Email",
        successfulSubscriptionMessage:"Obrigado por subscrever a nossa newsletter!",
        successfulSubscriptionSentMessage:"O pedido de subscrição foi enviado com sucesso!",
        complaintsBookLinkText:"Livro de reclamações online",
        authorizationLicenseText:" A leiloeira Paraiso exerce a sua atividade, mediante titulo de autorização nos termos do DL 155/2015 de 10 agosto. É uma das fundadoras da Associação Portuguesa de Estabelecimentos de leilão (APDEL).",
        insuranceText:"A atividade encontra-se segura pela apólice n.º 2505515 da Hiscox Insurance Company, Ltd."
    },
    en:{
        subscriptionTitle: "Subscribe our newsletter",
        informationTitle:"Information",
        subscriptionText:"I want to obtain information about products, services and events of LEILÕES C PARAÍSO AGENCY and it´s affiliates by email, sms, or any other comunication channel, including informations about latest solutions, tips and exclusive offers.",
        cancelText:"You can cancel your subscription at any moment.",
        readPolicy:"Please read our ",
        privacyLinkText:"Privacy policy and personal data processing",
        readAndAccepted:"Read and accept ",
        readAndAcceptedRequiredMessage:"You must accept the privacy policy.",
        nameField:"Name",
        emailField:"Email",
        termsField:"Terms",
        successfulSubscriptionMessage:"Thank you for subscribing our newsletter!",
        successfulSubscriptionSentMessage:"Your subscription request was successfully sent!",
        complaintsBookLinkText:"Online complaints book",
        authorizationLicenseText:" A leiloeira Paraiso exerce a sua atividade, mediante titulo de autorização nos termos do DL 155/2015 de 10 agosto. É uma das fundadoras da Associação Portuguesa de Estabelecimentos de leilão (APDEL).",
        insuranceText:"A atividade encontra-se segura pela apólice n.º 2505515 da Hiscox Insurance Company, Ltd."
    }
}