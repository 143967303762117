import React from "react"
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Money, Currency } from "ibase-auctions-domain";
import { BidForm, MinimumBid } from "../../../../components/bid/BidForm";
import { ButtonType } from "../../../../../../../components/buttons/ActionButton";


export const ClosedLetterBidForm = observer((props:TranslationProps & {minimumBid?:MinimumBid, onBid:(value:number)=>Promise<void>}) => {
    return <BidForm buttonType={ButtonType.GENERATEPROPOSAL} {...props}/>
});