import React from "react";
import { TranslationProps } from "modbin-domain";
import { Price, IEventSelleableItem } from "ibase-auctions-domain";
import { Badge } from "react-bootstrap";
import { NavigationBackLink } from "../../../../components/layout/navigation/menus/NavigationLink";
import { observer } from "mobx-react";
import { getCurrentBid, getFormatedPrice } from "../../components/helpers/Price";
import { getTitle } from "../../components/helpers/Properties";
import { Spinner } from "../../../../components/loading/Spinner";

export const HeaderPricing = observer((props:{price:Price, isLoading:boolean}) => {
    const {price, isLoading} = props;
    if(props.isLoading){
        return <div className="property-pricing" style={{display:"inline"}}>
            <Spinner size="small"/>
        </div>
    }
    const actual = getCurrentBid(price);
    // const subPrice = actual !== price.starting
    //     ? <div className="sub-price">{`${price.starting} ${currencyMapper.get(price.currency)!.symbol}`}</div>
    //     : <Fragment></Fragment> ;
    return <div className="property-pricing"  style={{display:"inline", position:"relative"}}>
        <div className="property-price">{getFormatedPrice(actual.value,actual.currency)}</div>
        {/* {subPrice} */}
    </div>;
})

export const HeaderView = observer((props:TranslationProps & {item:IEventSelleableItem, isLoading:boolean}) => {
    return <div id="titlebar" className="property-titlebar margin-bottom-0">
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <NavigationBackLink className="back-to-listings"></NavigationBackLink>
                    <div className="property-title">
                        <h2>{getTitle(props.item)} <Badge></Badge></h2>
                        <span className="listing-address"/>
                        {/* <ShortTextAddressView address={props.item.address}></ShortTextAddressView> */}
                    </div>
                </div> 
                <div className="col-md-2">
                    <HeaderPricing price={props.item.price} isLoading={props.isLoading}></HeaderPricing>
                </div>
            </div>
        </div>
    </div>;
});





