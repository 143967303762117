import { menuTranslations } from "../components/layout/navigation/menus/Menu.translations";
import { buttonsTranslations } from "../components/buttons/Buttons.translations";
import { newsletterFooterTranslations } from "../components/layout/footer/NewsletterFooter.translations";
import { contactFormTranslations } from "../components/forms/contact/ContactForm.translations";

export const commonTranslations = {
    pt: {
        menu: menuTranslations.pt,
        buttons: buttonsTranslations.pt,
        contactform:contactFormTranslations.pt,
        footer: newsletterFooterTranslations.pt,
        words: {
            support:"Suporte",
            searchHint:"ex. Moradia T3, Terreno rústico",
            minimumValue:"Mínimo",
            maximumValue:"Máximo",
            soon:"Brevemente",
            validating:"A validar licitações",
            refreshing:"A atualizar",
            refreshNow:"Atualizar agora",
            finished:"Terminou",
            start:"Início",
            end:"Fim",
            noResultsHeader:"Não foram encontrados resultados",
            unexpectedErrorTitle:"Ocorreu um erro inesperado...",
            unexpectedErrorMessage:"A nossa equipa já foi alertada. Se o problema persistir, por favor, reporte o problema. Pedimos descula pelo incómodo. Obrigado.",
            notFoundErrorTitle:"Página não encontrada!",
            notFoundErrorMessage:"A página que procura não foi encontrada. Pedimos descula pelo incómodo. Obrigado.",
            appLoadingErrorTitle:"Erro ao carregar a página...",
            appLoadingErrorMessage:"Ocorreu um erro inesperado ao carregar a página. Por favor, tente novamente dentro de alguns segundos. Pedimos descula pelo incómodo. Obrigado.",
            changesSuccessfullySaved:"Alterações guardadas com sucesso!",
            yes:"Sim",
            no:"Não",
            contactInfo: "CHAMADA PARA A REDE FIXA NACIONAL"
        }
    },
    en: {
        menu:menuTranslations.en,
        buttons: buttonsTranslations.en,
        contactform:contactFormTranslations.en,
        footer: newsletterFooterTranslations.en,
        words: {
            support:"Support",
            searchHint:"eg. Moradia T3, Terreno rústico",
            minimumValue:"Minimum",
            maximumValue:"Maximum",
            soon:"Soon",
            validating:"Validating bids",
            refreshing:"Refreshing",
            refreshNow:"Refresh now",
            finished:"Finished",
            start:"Starts",
            end:"Ends",
            noResultsHeader:"No results were found",
            unexpectedErrorTitle:"An unexpected error has occurred...",
            unexpectedErrorMessage:"Our team was notified. If the problem continues, please, contact us. Sorry for the inconvenience. Thank you.",
            notFoundErrorTitle:"Page not found!",
            notFoundErrorMessage:"The page you are looking for was not found. Sorry for the inconvenience. Thank you.",
            appLoadingErrorTitle:"Page loading error...",
            appLoadingErrorMessage:"An unexpected error has occurred during page loading. Please, try again in a few seconds. Sorry for the inconvenience. Thank you.",
            changesSuccessfullySaved:"Changes successfully saved!",
            yes:"Yes",
            no:"No",
            contactInfo: "LANDLINE CALL"
        }
    }
}
