"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromCountyQuery = exports.convertToCounty = void 0;

exports.convertToCounty = function (externalCounty) {
  return {
    id: externalCounty.concelho_id.toString(),
    code: externalCounty.concelho_cod,
    regionCode: externalCounty.distrito_cod,
    name: externalCounty.concelho
  };
};

exports.convertFromCountyQuery = function (query) {
  return {
    concelho_cod: query.code,
    distrito_cod: query.regionCode
  };
};