import React, { Fragment } from "react"
import { observer } from "mobx-react";
import { TranslationProps } from "modbin-domain";
import { Lot, SellingType} from "ibase-auctions-domain";
import { OnlineAuctionDetailsView } from "./types/online-auction/OnlineAuctionDetailsView";
import { PrivateNegotiationDetailsView } from "./types/private-negotiation/PrivateNegotiationDetailsView";
import { PresentialAuctionDetailsView } from "./types/presential-auction/PresentialAuctionDetailsView";
import { ClosedLetterDetailsView } from "./types/closed-letter/ClosedLetterDetailsView";
import { getEventDateState } from "../../../components/helpers/Dates";


export const DetailsView = observer((props:TranslationProps & {item:Lot}) => {
    if(props.item === undefined){
        return <Fragment></Fragment>;
    }
    const state = getEventDateState(props.item);
    let lotView = undefined;
    switch(props.item.event.type){
        case SellingType.ONLINEAUCTION:
            lotView = <OnlineAuctionDetailsView dateState={state} item={props.item} t={props.t}></OnlineAuctionDetailsView>;
            break;
        case SellingType.PRIVATENEGOTIATION:
            lotView = <PrivateNegotiationDetailsView dateState={state} item={props.item} t={props.t}></PrivateNegotiationDetailsView>;
            break;
        case SellingType.PRESENTIALAUCTION:
            lotView = <PresentialAuctionDetailsView dateState={state} item={props.item} t={props.t}></PresentialAuctionDetailsView>;
            break;
        case SellingType.CLOSEDLETTER:
            lotView = <ClosedLetterDetailsView dateState={state} item={props.item} t={props.t}></ClosedLetterDetailsView>;
            break;
        default:
            lotView = <Fragment></Fragment>
    }
    return lotView;
});