import { companyTranslations } from "./company/Company.translations";
import { contactsTranslations } from "./contacts/Contacts.translations";
import { homeTranslations } from "./home/Home.translations";
import { privacyTranslations } from "./privacy-policy/Privacy.translations";
import { professionalsTranslations } from "./professionals/Professionals.translations";
import { ralTranslations } from "./ral/Ral.translations";
import { sessionsTranslations } from "./sessions/Sessions.translations";

export const publicTranslations = {
    pt: {
        company:companyTranslations.pt,
        contacts:contactsTranslations.pt,
        home:homeTranslations.pt,
        privacyPolicy:privacyTranslations.pt,
        professionals:professionalsTranslations.pt,
        ral:ralTranslations.pt,
        sessions:sessionsTranslations.pt
    },
    en:{
        company:companyTranslations.en,
        contacts:contactsTranslations.en,
        home:homeTranslations.en,
        privacyPolicy:privacyTranslations.en,
        professionals:professionalsTranslations.en,
        ral:ralTranslations.en,
        sessions:sessionsTranslations.en
    }
}