import React from "react";
import { withTranslation } from "react-i18next";
import { SellingType } from "ibase-auctions-domain";


export const InnerSellingTypeSelector = (props:any) => {
    const placeHolder = [<option key={`sellingtype:name`} value="0">{props.t(`domain:sales.events.type.name`)}</option>]; 
    const options = Object.entries(SellingType).map((x:any) => {
        return <option key={`sellingtype:${x[1]}`} value={x[1]}>{props.t(`domain:sales.events.type.value.${(SellingType as any)[x[0]]}`)}</option>
    });
    return <select data-placeholder="Any Type" value={props.selected} className="chosen-select-no-single" onChange={(ev:any)=> {props.onChange(ev.target.value)}}>
        {[...placeHolder,...options]}	
    </select>;;
} 


export const SellingTypeSelector = (withTranslation(["domain"])(InnerSellingTypeSelector) as any) as (props:any) => JSX.Element;