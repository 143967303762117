import React, { Fragment } from "react";
import { MapView, MapProps } from "../../../../components/maps/MapView";
import { GeographicLocation } from "ibase-auctions-domain";
import { TranslationProps } from "modbin-domain"

export const GeographicLocationView = (props:TranslationProps & {location:GeographicLocation}) => {
    const newProps:MapProps = {
        type:"single",
        center:props.location,
        height:400,
        width:"100%",
        zoom:10,
        markers:[{
          location:props.location  
        }]
    }
    const body = newProps.center !== undefined
        ? <MapView {...newProps}/>
        : <div>{props.t("screens:business.lots.details.noLocation")}</div>
    return <Fragment>
        <h3 className="desc-headline no-border" id="location">{props.t("screens:business.lots.details.locationTitle")}</h3>
        {body}
    </Fragment>;
}