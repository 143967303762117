"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleSubCategory = void 0;
var VehicleSubCategory;

(function (VehicleSubCategory) {
  VehicleSubCategory["PASSENGER"] = "passenger";
  VehicleSubCategory["DUTY"] = "duty";
  VehicleSubCategory["HEAVYDUTY"] = "heavyduty";
  VehicleSubCategory["FARMIMGTRACTOR"] = "farmingtractor";
  VehicleSubCategory["MOTORCYCLE"] = "motorcycle";
  VehicleSubCategory["BOAT"] = "boat";
  VehicleSubCategory["AIRCRAFT"] = "aircraft";
  VehicleSubCategory["CARAVAN"] = "caravan";
  VehicleSubCategory["OTHER"] = "othervehicle"; //109
})(VehicleSubCategory = exports.VehicleSubCategory || (exports.VehicleSubCategory = {}));