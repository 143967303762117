import {DefaultApiErrorHandler, ErrorMessage, IErrorMessageMapper} from "modbin-api";
import { IBaseErrorMessageMapper } from "ibase-auctions-api";
import { IUserNotificationService } from "modbin-web";
import { ILogger } from "modbin-domain";

const showErrorMessageDetails = (error:ErrorMessage, showDevelopmentMessages:boolean, userNotificationService:IUserNotificationService) => {
    if(showDevelopmentMessages){
        const notification = {
            message:error.message,
            error:error
        };
        userNotificationService.error(notification)
    }
}

export class IBaseApiErrorHandler extends DefaultApiErrorHandler {
    logger:ILogger;
    userNotificationService:IUserNotificationService;
    showDetails:boolean;
    showDevelopmentMessages:boolean;

    constructor(errorMessageMapper:IErrorMessageMapper, logger:ILogger, userNotificationService:IUserNotificationService, showDevelopmentMessages:boolean,showDetails:boolean){
        const errorMapper = errorMessageMapper !== undefined ? errorMessageMapper : new IBaseErrorMessageMapper();
        super(errorMapper);
        this.logger = logger;
        this.userNotificationService = userNotificationService;
        this.showDetails = showDetails;
        this.showDevelopmentMessages = showDevelopmentMessages;
    }

    handleNotFound(error:ErrorMessage){
        showErrorMessageDetails(error,this.showDevelopmentMessages,this.userNotificationService);
        this.logger.info("Not found (iBaseApiErrorHandler).", error);
        return Promise.reject(error);
    }

    handleUnauthorized(error:ErrorMessage){
        showErrorMessageDetails(error,this.showDevelopmentMessages,this.userNotificationService);
        this.logger.info("Unauthorized (iBaseApiErrorHandler).", error);
        return Promise.reject(error);
    }

    handleUnauthenticated(error:ErrorMessage){
        showErrorMessageDetails(error,this.showDevelopmentMessages,this.userNotificationService);
        this.logger.info("Unauthenticated (iBaseApiErrorHandler).", error);
        return Promise.reject(error);
    }

    handleApplicationError(error:ErrorMessage){
        showErrorMessageDetails(error,this.showDevelopmentMessages,this.userNotificationService);
        this.logger.error("Application error (iBaseApiErrorHandler).", error);
        return Promise.reject(error);
    }

    handleServerError(error:ErrorMessage){
        showErrorMessageDetails(error,this.showDevelopmentMessages,this.userNotificationService);
        this.logger.error("Error in api call (iBaseApiErrorHandler): internal server error", error);
        return Promise.reject(error);
    }
}