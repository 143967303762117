import {ConfigProperties} from "./Config.types";
import { companyInfo } from "./info/CompanyInfo";
import { loadApiConfiguration } from "./api/ApiConfig.types";

const environment = process.env.REACT_APP_ENV || 'development';
//console.log("REACT_APP_ENV",process.env.REACT_APP_ENV);
var configFile = require(`../../config.${environment}.json`);

//console.log(`App initialization -> loading conffiguration file ${environment} with properties: ${configFile}`)
export const configProperties:ConfigProperties = {
    appConfig:{...configFile.app, environment:process.env.REACT_APP_ENV},
    webConfig:configFile.web,
    companyInfo: companyInfo,
    apiConfig: loadApiConfiguration(configFile.api),
    services:configFile.services
};