import { IIocContainer, IKeyedObject } from "modbin-domain";
import { IViewNavigator, IViewDefinitionProvider, IStore } from "modbin-web";
import { IMainAppViewStore } from "./IMainAppViewStore";

export interface IStoreFactory {
    createStores(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider, mainAppViewStore:IMainAppViewStore):IKeyedObject<IStore>;
    createMainViewStore(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider):IStore;
    create(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider):IKeyedObject<IStore>;
}

export abstract class  DefaultStoreFactory implements IStoreFactory {

    abstract createStores(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider, mainAppViewStore:IMainAppViewStore):IKeyedObject<IStore>;
    
    abstract createMainViewStore(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider):IMainAppViewStore;

    create(container:IIocContainer, viewNavigator:IViewNavigator, viewProvider:IViewDefinitionProvider){
        const mainAppViewStore = this.createMainViewStore(container,viewNavigator,viewProvider);
        const stores = this.createStores(container,viewNavigator,viewProvider, mainAppViewStore);
        mainAppViewStore.configure(stores);
        const finalStores = {
            ...stores,
            mainAppViewStore
        }
        return finalStores;
    }
}

