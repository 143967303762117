import React from "react";
import { NavigationBackLink } from "../navigation/menus/NavigationLink";

export const TitleBar = (props:{title:string}) => {
    return <div id="titlebar">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2>{props.title}</h2>
                </div>
            </div>
        </div>
    </div>;
}

export const TitleBarBack = (props:{title:string})=> {
    return <div id="titlebar" className="property-titlebar margin-bottom-0">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <NavigationBackLink className="back-to-listings"></NavigationBackLink>
                    <div className="property-title">
                        <h2>{props.title}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}