"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insertSeparator = void 0;

var IsNullOrEmpty_1 = require("./IsNullOrEmpty");

exports.insertSeparator = function (array, separator) {
  if (IsNullOrEmpty_1.arrayIsNullOrEmpty(array)) {
    return [];
  }

  if (array.length === 1) {
    return array;
  }

  var result = [];
  array.forEach(function (x, index) {
    result.push(x);

    if (index < array.length - 1) {
      result.push(separator);
    }
  });
  return result;
};