"use strict";

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Entity = void 0;

var cloneDeep = require('lodash.clonedeep');

var Entity =
/** @class */
function () {
  function Entity(options) {
    this._id = undefined;
    var currentDate = new Date(new Date().toISOString());
    this.setCreatedDate(currentDate);
    this.setModifiedDate(currentDate);

    if (options !== undefined) {
      var id = options.id,
          createdDate = options.createdDate,
          modifiedDate = options.modifiedDate,
          rest = __rest(options, ["id", "createdDate", "modifiedDate"]);

      this._id = id !== undefined ? id : undefined;
      this._createdDate = createdDate !== undefined ? createdDate : this._createdDate;
      this._modifiedDate = modifiedDate !== undefined ? modifiedDate : this._modifiedDate;

      if (rest !== undefined) {
        this.setProperties(rest);
      }
    }
  }

  Entity.prototype.getId = function () {
    return this._id;
  };

  ;

  Entity.prototype.getCreatedDate = function () {
    return this._createdDate;
  };

  Entity.prototype.setCreatedDate = function (date) {
    this._createdDate = date;
  };

  ;

  Entity.prototype.getModifiedDate = function () {
    return this._modifiedDate;
  };

  Entity.prototype.setModifiedDate = function (date) {
    this._modifiedDate = date;
  };

  ;

  Entity.prototype.updateModifiedDate = function (date) {
    var fDate = date !== undefined ? date : new Date(new Date().toISOString());
    this.setModifiedDate(fDate);
  };

  Entity.prototype.updateAfterCreation = function (id, createdDate, modifiedDate) {
    this._id = id;
    this._createdDate = createdDate;
    this._modifiedDate = modifiedDate;
  };

  Entity.prototype.clone = function (options) {
    if (options === undefined) {
      return cloneDeep(this);
    }

    if (this._id === undefined) {
      var id = options.id,
          createdDate = options.createdDate,
          modifiedDate = options.modifiedDate;
      this.updateAfterCreation(id, createdDate, modifiedDate);
      return cloneDeep(this);
    }

    throw new Error("Cannot change id after creation!");
  };

  Entity.prototype.setProperties = function (options) {
    var _this = this;

    Object.getOwnPropertyNames(options).forEach(function (x) {
      _this[x] = options[x];
    });
  };

  return Entity;
}();

exports.Entity = Entity;