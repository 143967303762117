import React, { Component, Fragment } from 'react';
import { TranslationProps} from 'modbin-domain';
import { inject, observer } from 'mobx-react';
import { TitleBar } from '../../../../../components/layout/header/TitleBar';
import { LoadingView } from '../../../../../components/loading/LoadingView';
import { RegistrationSuccessfulMessage } from './RegistrationMessages';
import { RegistrationState } from '../../operations/States';
import { LoginViewStore } from '../LoginViewStore';

export class ScreensSessionsRegisterCompletedView extends Component<TranslationProps & {registrationState:RegistrationState},{}> {
    
    render() {
        const getTranslationSessions = (key:string) => this.props.t(`screens:public.sessions.${key}`); 
        const isLoading = this.props.registrationState === "saving";
        return <LoadingView
            isLoading={isLoading}
        >
            <Fragment>            
                <TitleBar title={getTranslationSessions("registerCompletedTitle")}></TitleBar>
                <div className="container">
                    <RegistrationSuccessfulMessage />
                </div>
            </Fragment>
        </LoadingView>;
    }
}

@inject("loginViewStore") @observer
export class ScreensSessionsRegisterCompleted extends Component<TranslationProps & {loginViewStore:LoginViewStore},{}> {
    
    render() {
        return <ScreensSessionsRegisterCompletedView t={this.props.t} registrationState={this.props.loginViewStore!.registrationState}/>;
    }
}