import React, { ComponentType } from "react";
import { observer } from "mobx-react";
import { MenuLayout } from "../../components/layout/MenuLayout";
import { withTranslation } from "react-i18next";
import { ViewDefinition } from "modbin-web";
import { LayoutType } from "../../components/layout/Layouts.types";
import { BaseLayout } from "../../components/layout/BaseLayout";
import { SupportLayout } from "../../screens/support/components/SupportLayout";
import { UiTestLayout } from "../../screens/support/tests/screens/components/UiTestLayout";
import { GlobalErrorBoundary } from "../../screens/common/errors/GlobalErrorBoundary";

export const layoutComponents = new Map<LayoutType,any>();
layoutComponents.set(LayoutType.MENU,MenuLayout);
layoutComponents.set(LayoutType.BASE,BaseLayout);
layoutComponents.set(LayoutType.SUPPORT,SupportLayout);
layoutComponents.set(LayoutType.TESTING,UiTestLayout);

const renderWithLayout = (Component:any,layout?:any) => {
    if(layout !== undefined){
        const Layout = layout.component !== undefined 
            ? layoutComponents.get(layout.component)
            : MenuLayout;
        const props = layout.props !== undefined 
            ? layout.props
            : {};
        return <Layout children={Component} {...props}/>
    }
    return <MenuLayout children={Component} />;
}

const renderPage = (Component:any,Layout?:any) => {
    // return (props:any) => <GlobalErrorBoundary key={"error-" + Component.name}>{renderWithLayout(<Component key={Component.name} {...props}></Component>)}</GlobalErrorBoundary>;
    return (props:any) => {
        const componentToRender = <GlobalErrorBoundary key={"error-" + Component.name}>{renderWithLayout(<Component key={Component.name} {...props}></Component>, Layout)}</GlobalErrorBoundary>;
        //renderWithLayout(<Component key={Component.name} {...props}></Component>, Layout);
        return componentToRender;
    };
}

@observer
export class CurrentView extends React.Component<{ current:ViewDefinition},{}> {
    render(){
        const Current = this.props.current.component;
        const layout = this.props.current.layout;
        const props = this.props.current.store !== undefined && this.props.current.store.props !== undefined
            ? this.props.current.store.props
            : {};
        return renderPage(withTranslation()(Current), layout)({...props});
    }
}