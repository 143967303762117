"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.directoryOperations = exports.readDirectory = exports.deleteAllFilesinDirectory = exports.deleteDirectory = exports.createDirectory = void 0;

var Directory_1 = require("./Directory");

var FileInfo_1 = require("./FileInfo");

var CommonOperations_1 = require("./CommonOperations");

var FileOperations_1 = require("./FileOperations");

var util = require('util');

var fs = require('fs');

exports.createDirectory = function (path, options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var mkdirAsync;
    return __generator(this, function (_a) {
      mkdirAsync = util.promisify(fs.mkdir);
      return [2
      /*return*/
      , mkdirAsync(path, options).then(function (x) {
        return new Directory_1.Directory(path);
      }, function (err) {
        throw err;
      })];
    });
  });
};

exports.deleteDirectory = function (path) {
  return __awaiter(void 0, void 0, void 0, function () {
    var rmdirAsync;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , exports.deleteAllFilesinDirectory(path)];

        case 1:
          _a.sent();

          rmdirAsync = util.promisify(fs.rmdir);
          return [2
          /*return*/
          , rmdirAsync(path).then(function (x) {
            return x;
          }, function (err) {
            throw err;
          })];
      }
    });
  });
};

exports.deleteAllFilesinDirectory = function (path) {
  return __awaiter(void 0, void 0, void 0, function () {
    var files;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , exports.readDirectory(path)];

        case 1:
          files = _a.sent();
          return [4
          /*yield*/
          , Promise.all(files.map(function (f) {
            return __awaiter(void 0, void 0, void 0, function () {
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    return [4
                    /*yield*/
                    , FileOperations_1.deleteFile(f.getPath())];

                  case 1:
                    return [2
                    /*return*/
                    , _a.sent()];
                }
              });
            });
          }))];

        case 2:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  });
};

exports.readDirectory = function (path, options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var readdirAsync;
    return __generator(this, function (_a) {
      readdirAsync = util.promisify(fs.readdir);
      return [2
      /*return*/
      , readdirAsync(path, options).then(function (x) {
        return x.map(function (x) {
          return new FileInfo_1.FileInfo(CommonOperations_1.getPath([path, x]));
        });
      }, function (err) {
        throw err;
      })];
    });
  });
};

exports.directoryOperations = {
  create: exports.createDirectory,
  read: exports.readDirectory,
  delete: exports.deleteDirectory,
  exists: CommonOperations_1.existsEntry,
  stats: CommonOperations_1.readEntryStats
};