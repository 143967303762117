"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationState = void 0;
var ApplicationState;

(function (ApplicationState) {
  ApplicationState["DOWN"] = "down";
  ApplicationState["UP"] = "up";
  ApplicationState["INITIALIZING"] = "initializing";
  ApplicationState["INITIALIZED"] = "initialized";
  ApplicationState["LOADING"] = "loading";
  ApplicationState["LOADED"] = "loaded";
  ApplicationState["STARTING"] = "starting";
  ApplicationState["STARTED"] = "started";
  ApplicationState["RUNNING"] = "running";
  ApplicationState["READY"] = "ready";
  ApplicationState["STOPPING"] = "stopping";
  ApplicationState["STOPPED"] = "stopped";
  ApplicationState["RESTARTING"] = "restarting";
  ApplicationState["RESTARTED"] = "restarted";
})(ApplicationState = exports.ApplicationState || (exports.ApplicationState = {}));