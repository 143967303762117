import React from "react";

export const CopyrightFooter = () => {
  return (
    <div className="row copyright">
      <div className="col-md-2"></div>
      <div className="col-md-4">
        PARAISO © {new Date().getFullYear()} - All Rights Reserved
      </div>
      <div className="col-md-4" style={{ fontSize: "12px" }}>
        powered by{" "}
        <a
          href="https://www.mitmynid.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          MITMYNID
        </a>{" "}
        | © Copyright All rights reserved
      </div>
      <div className="col-md-2"></div>
    </div>
  );
};
