"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserAuthenticationService = void 0;

var ibase_auctions_domain_1 = require("ibase-auctions-domain");

var modbin_domain_1 = require("modbin-domain");

var UserAuthenticationService =
/** @class */
function () {
  function UserAuthenticationService(apiClient, userAuthenticationDataStorageService, options) {
    this._apiClient = apiClient;
    this._activationUrlTemplate = options.activationUrlTemplate;
    this._reactivationUrlTemplate = options.reactivationUrlTemplate;
    this._loginUrlTemplate = options.loginUrlTemplate;
    this._passwordRecoveryUrlTemplate = options.passwordRecoveryUrlTemplate;
    this._passwordRecoveryRequestUrlTemplate = options.passwordRecoveryRequestUrlTemplate;
    this._userAuthenticationDataStorageService = userAuthenticationDataStorageService;
  }

  UserAuthenticationService.prototype.getAuthenticationData = function () {
    return this._userAuthenticationDataStorageService.getAuthenticationData();
  };

  UserAuthenticationService.prototype.isAuthenticated = function () {
    var authData = this.getAuthenticationData();
    var token = authData.token,
        userId = authData.userId;
    return token !== undefined && token !== null && userId !== undefined && userId !== null;
  };

  UserAuthenticationService.prototype.activate = function (activationData) {
    return __awaiter(this, void 0, void 0, function () {
      var url;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = modbin_domain_1.buildUrlPathFromPathTemplate(this._activationUrlTemplate, activationData);
            return [4
            /*yield*/
            , this._apiClient.get(url)];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  UserAuthenticationService.prototype.login = function (loginData) {
    return __awaiter(this, void 0, void 0, function () {
      var url, x, user, extractedAuthenticationData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = this._loginUrlTemplate;
            return [4
            /*yield*/
            , this._apiClient.post(url, loginData)];

          case 1:
            x = _a.sent();
            user = new ibase_auctions_domain_1.User(ibase_auctions_domain_1.convertToUser(x));

            if (user.accountState === ibase_auctions_domain_1.UserAccountState.ACTIVATED) {
              extractedAuthenticationData = {
                userId: x.data.id,
                token: x.data.authorization
              };

              this._userAuthenticationDataStorageService.saveAuthenticationData(extractedAuthenticationData);

              return [2
              /*return*/
              , user];
            } else {
              throw new Error("Account not activated");
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  UserAuthenticationService.prototype.logout = function () {
    this._userAuthenticationDataStorageService.deleteAuthenticationData();

    return Promise.resolve();
  };

  UserAuthenticationService.prototype.recoverPassword = function (passwordRecoveryData) {
    return __awaiter(this, void 0, void 0, function () {
      var url, resultPasswordData, x;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = this._passwordRecoveryUrlTemplate;
            resultPasswordData = {
              password: passwordRecoveryData.password,
              recover_token: passwordRecoveryData.recoveryToken
            };
            return [4
            /*yield*/
            , this._apiClient.put(url, resultPasswordData)];

          case 1:
            x = _a.sent();
            return [2
            /*return*/
            , x];
        }
      });
    });
  };

  UserAuthenticationService.prototype.requestUserPasswordRecovery = function (email) {
    return __awaiter(this, void 0, void 0, function () {
      var url, postData, x;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = this._passwordRecoveryRequestUrlTemplate;
            postData = {
              email: email
            };
            return [4
            /*yield*/
            , this._apiClient.post(url, postData)];

          case 1:
            x = _a.sent();
            return [2
            /*return*/
            , x];
        }
      });
    });
  };

  UserAuthenticationService.prototype.reactivate = function (email) {
    return __awaiter(this, void 0, void 0, function () {
      var url, postData, x;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = this._reactivationUrlTemplate;
            postData = {
              email: email
            };
            return [4
            /*yield*/
            , this._apiClient.post(url, postData)];

          case 1:
            x = _a.sent();
            return [2
            /*return*/
            , x];
        }
      });
    });
  };

  return UserAuthenticationService;
}();

exports.UserAuthenticationService = UserAuthenticationService;