import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { Currency } from "ibase-auctions-domain";
import { BidForm } from "./BidForm";
import { ButtonType } from "../../../../../components/buttons/ActionButton";
import { UiTestDescription, sleepableConsoleLogger } from "modbin-web";

export const description:UiTestDescription = {
    id:"BidFormUiTest",
    groupId:"components",
    description:"lalal",
    title:"BidForm component",
    parentId:"components"
};

export class BidFormUiTest extends Component<TranslationProps,{}>{
        
    render(){
        return <BidForm minimumBid={{value:1000.00,currency:Currency.EURO}} buttonType={ButtonType.BID} onBid={(value:any)=> {return sleepableConsoleLogger(value)}}/>;
    }
}