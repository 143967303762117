import { IIocContainer, BaseModule, ServiceDescriptor, IServiceDescriptor } from "modbin-domain";
import { LotService, ApiRepositoryFactory, BidService } from "ibase-auctions-api";
import { Lot, Booking, LotUpdateData, Bid, DocumentFile } from "ibase-auctions-domain";
import { ApiConfiguration } from "../api/ApiConfig.types";
import { InMemoryRepository, IResourceMapper } from "modbin-domain-extensions";
import { ITimerService } from "modbin-domain";
import { subCategories } from "../../screens/support/tests/data/SubCategories";
import { lots } from "../../screens/support/tests/data/Lots";
import { InMemorySubCategoryRepository } from "../temp/InMemorySubCategoryRepository";
import { IBaseModule } from "./IBaseModule";

export class LotsModule extends IBaseModule {

    constructor(){
        super("lots");
    }
    
    createBindingsForLocal(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const lotRepository = new InMemoryRepository<Lot>({initialElements:lots});
        const lotBookingRepository = new InMemoryRepository({initialElements:[]});
        const lotUpdateDataRepository = new InMemoryRepository({initialElements:[]});
        return [
            ServiceDescriptor.create("lotRepository",lotRepository),
            ServiceDescriptor.create("lotBookingRepository",lotBookingRepository),
            ServiceDescriptor.create("lotUpdateDataRepository",lotUpdateDataRepository),
        ];
    }

    createBindingsForApi(kernel:IIocContainer, options?: any):IServiceDescriptor[]{
        const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
        const apiRepositoryFactory:ApiRepositoryFactory = kernel.resolve<ApiRepositoryFactory>("apiRepositoryFactory");
        
        const lotRepository = apiRepositoryFactory!.createRepository<Lot>(
            undefined as any,
            {source:"lote",target:"lot",targetConstructor:Lot},
            () => {
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {
                    collection:apiConfig.endpoints.assets.lots.collection,
                    get:apiConfig.endpoints.assets.lots.single
                }
            });
        //const lotRepository = new InMemoryRepository<Lot>({initialElements:lots});
        const lotBookingRepository = apiRepositoryFactory!.createRepository<Booking>(
            undefined as any,
            {source:"visita",target:"booking",targetConstructor:Lot},
            () => {
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {collection:apiConfig.endpoints.assets.lots.bookings};
            });
        const lotUpdateDataRepository = apiRepositoryFactory!.createRepository<LotUpdateData>(
            undefined as any,
            {source:"loteUpdateData",target:"lotUpdateData",targetConstructor:LotUpdateData},
            () => {
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {
                    collection:apiConfig.endpoints.assets.lots.updates,
                    single:apiConfig.endpoints.assets.lots.updates
                }
            });
        const lotFileRepository =  apiRepositoryFactory!.createRepository<DocumentFile>(
            undefined as any,
            {source:"documento",target:"documentFile",targetConstructor:DocumentFile},
            () => {
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {collection:apiConfig.endpoints.assets.lots.documents};
            });
        const bidRepository = apiRepositoryFactory!.createRepository<Bid>(
            undefined as any,
            {source:"licitacao",target:"bid",targetConstructor:Bid},
            () => {
                const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
                return {collection:apiConfig.endpoints.sales.events.bid};
            });
        return [
            ServiceDescriptor.create("lotRepository",lotRepository),
            ServiceDescriptor.create("lotBookingRepository",lotBookingRepository),
            ServiceDescriptor.create("lotFileRepository",lotFileRepository),
            ServiceDescriptor.create("lotUpdateDataRepository",lotUpdateDataRepository),
            ServiceDescriptor.create("bidRepository",bidRepository)
        ];
    }

    createCommonBindings(kernel:IIocContainer, options?: any):IServiceDescriptor[] {
        const apiConfig:ApiConfiguration = kernel.resolve<ApiConfiguration>("apiConfig");
        const bidService = new BidService(
            this.getDescriptor("bidRepository"), 
            kernel.resolve<ITimerService>("timeService"),
            `${(apiConfig as any).baseUrl}${apiConfig.endpoints.sales.events.proposalGenerator}`,
            kernel.resolve<IResourceMapper>("resourceMapper"),
            {threshold:5,minutesToEnd:2});
        const lotService = new LotService(
            this.getDescriptor("lotRepository"),
            this.getDescriptor("lotBookingRepository"),
            this.getDescriptor("lotFileRepository"),
            this.getDescriptor("lotUpdateDataRepository"),
            bidService,
            apiConfig.endpoints.assets.lots.single,
            apiConfig.endpoints.assets.lots.updates,
            apiConfig.endpoints.assets.lots.userFavorites
        );

        const subCategoryRepository = new InMemorySubCategoryRepository({ elements: subCategories, resourceMapper:kernel.resolve<IResourceMapper>("resourceMapper")});

        return [
            ServiceDescriptor.create("lotService",lotService),
            ServiceDescriptor.create("subCategoryRepository",subCategoryRepository),
            ServiceDescriptor.create("bidService",bidService)
        ];
    }
}