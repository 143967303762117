import React, { Component } from 'react';
import { TranslationProps} from 'modbin-domain';

export class IBaseComponentsScreenIndex extends Component<TranslationProps,{}> {

    render(){
        return (<div>
                <h1>Ui tests ibase components index</h1>
            </div>
        );
    }
}