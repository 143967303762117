import React, { Component, Fragment } from 'react';
import { TitleBar } from '../../../components/layout/header/TitleBar';
import { NavigationLink } from '../../../components/layout/navigation/menus/NavigationLink';
import { TranslationProps } from "modbin-domain";

export class ScreensPrivacyPolicyIndex extends Component<TranslationProps,{}> {
    render() {
        const getTranslation = (key:string) => this.props.t(`screens:public.privacyPolicy.${key}`); 
        return (
            <Fragment>
                <TitleBar title={getTranslation("title")}></TitleBar>
                <div className="container">
                    <div className="row">
                        <div style={{textAlign: "justify"}}>
                            <h3>Política de privacidade e tratamento de dados pessoais</h3>
                            <p>A Agência de Leilões C. Paraíso Lda., no âmbito da prestação dos seus serviços, necessita de recolher regularmente dados pessoais dos seus clientes sendo o nome e morada dois exemplos claros (mas não os únicos) dessa necessidade.</p>
                            <p>Por outro lado, a Agência de Leilões C. Paraíso Lda., está empenhada em proteger a privacidade de todos os utilizadores do seu Website, interação cada vez mais frequente dos nossos clientes, que requer, nalguns casos, a recolha de informações pessoais do utilizador com vista à utilização de serviços prestados pela Agência de Leilões C. Paraíso Lda., ou noutros casos, para melhorar a performance das aplicações, a recolha de dados do seu computador por intermédio de pequenos ficheiros conhecidos do público em geral como cookies.</p>
                            <p>A Declaração de Privacidade e Tratamento de Dados Pessoais ajuda-o a compreender como recolhemos, usamos e protegemos os seus dados pessoais quando usa os nossos serviços ou visita os nossos Website.</p>
                            <p>Poderá obter informação adicional ou esclarecer qualquer dúvida sobre esta Declaração de Privacidade e Tratamento de Dados Pessoais, bastando para o efeito remeter as suas questões para:</p>
                            
                            <strong>Agência de Leilões C. Paraíso</strong>
                            <br />
                            Rua Andrade, n.º 2, R/C Dto.
                            <br />
                            <p>1170-015 Lisboa</p>

                            <strong>A/C Responsável pela Privacidade</strong>
                            <br />
                            Exma. Sra. Raquel Florindo
                            <br />
                            ou para o endereço de correio eletrónico: raquel.florindo@cparaiso.pt

                            <h3>Declaração de privacidade e tratamento de dados pessoais</h3>
                            <p>A Agência de Leilões C. Paraíso Lda., está empenhada em proteger a privacidade dos seus Clientes na utilização dos seus produtos e serviços, bem como na utilização da Website e em desenvolver tecnologia que ofereça as melhores e mais seguras experiências online.</p>
                            <p>Aos utilizadores é dada a possibilidade de navegar peo site da Agência de Leilões C. Paraíso Lda.,sem que seja necessário fornecer informações pessoais. No entanto, por vezes, são necessárias informações para o fornecimento de serviços solicitados pelos utilizadores e Clientes.</p>
                            <p>Esta Declaração de Privacidade e Tratamento de Dados Pessoais ajuda-o a compreender como recolhemos, usamos e protegemos os seus dados pessoais quando visita o nosso Website e quando, genericamente, usa ou nossos produtos e serviços.</p>
                            <p>Nesse sentido, e por forma a garantir que todos os dados pessoais são tratados e protegidos de acordo com o novo Regulamento Geral de Protecção de Dados, pedimos que leia, a nova política de privacidade e de protecção de dados com a devida atenção.</p> 
                            <p>Ao disponibilizar os seus dados pessoais à Agência de Leilões C. Paraíso Lda., o Cliente reconhece e consente que os mesmos sejam processados de acordo com esta Declaração de Privacidade e Tratamento de Dados Pessoais e com as regras e princípios constantes dos respetivos termos e condições dos serviços utilizados. Nesta medida e no que se refere ao processamento dos dados pessoais, deve o Cliente ler a presente Declaração de Privacidade conjuntamente com os Termos e Condições que regulam a oferta dos diversos produtos e serviços da Agência de Leilões C. Paraíso Lda</p> 
                            <p>Se for nossa intenção efetuar alguma alteração a esta Declaração, colocaremos as referidas alterações nesta página para que possa consultar o tipo de informação que recolhemos e a forma como a utilizamos.</p>


                            <h4>Definições</h4>
                            <p><b>O que são dados pessoais?</b></p> 
                            <p>Entende-se por Dados Pessoais qualquer informação, de qualquer natureza e independentemente do respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou identificável (titular dos dados). É considerada identificável a pessoa que possa ser identificada direta ou indiretamente, designadamente por referência a um número de identificação ou a mais elementos específicos da sua identidade física, fisiológica, psíquica, económica, cultural ou social.</p>
                            <p><b>Recolha de Dados</b></p>
                            <p><b>Que dados são recolhidos e gerados pela rede ou sistemas da Agência de Leilões C. Paraíso Lda?</b></p>
                            <p>Normalmente, os dados pessoais recolhidos pela Agência de Leilões C. Paraíso Lda limitam-se ao nome, endereço, número de telefone ou correio eletrónico, mas poderão ser recolhidos outros dados necessários ao fornecimento de produtos ou serviços.</p>
                            <p>Estes dados são processados automaticamente, em cumprimento da legislação aplicável e das condições definidas pela Comissão Nacional de Proteção de Dados (“CNPD”).</p>
                            <p><b>Quando são recolhidos e gerados dados pessoais?</b></p>
                            <p>Em geral, os dados pessoais são solicitados quando o Cliente subscreve um dos nossos produtos ou serviços.</p>
                            <p>A Agência de Leilões C. Paraíso Lda respeita o seu direito à privacidade e não recolhe qualquer informação pessoal sobre si sem o seu prévio consentimento.</p>
                            <p>Alguns dos dados pessoais são de fornecimento obrigatório para a prestação do serviço. A Agência de Leilões C. Paraíso Lda informará dessa obrigatoriedade e na eventualidade de falta ou de fornecimento incorreto dos dados exigidos, a Agência de Leilões C. Paraíso Lda não poderá prestar o serviço contratado. A disponibilização de dados incorretos ou inexatos é da sua exclusiva responsabilidade.</p>
                            <p><b>Qual a finalidade da recolha dos dados pessoais?</b></p>
                            <p>Em regra, os dados pessoais recolhidos junto dos Clientes destinam-se à prestação e faturação dos Serviços, à gestão e avaliação da relação contratual com o Cliente, à adaptação dos serviços às necessidades e interesses do Cliente, ao pagamento dos serviços, a ações de informação, marketing ou telemarketing da Agência de Leilões C. Paraíso Lda., de empresas associadas ou em relação de domínio ou de grupo com a Agência de Leilões C. Paraíso Lda e de terceiros, bem como à inclusão do Cliente nas listas de assinantes.</p>
                            <p>Os seus dados pessoais também serão tratados para responder às suas questões, sugestões ou reclamações.</p>
                            <p><b>Quem é responsável pelo tratamento dos dados pessoais?</b></p>
                            <p>O responsável pelo tratamento dos dados pessoais é a Exma. Sra. Raquel Florindo, com domicilio profissional na Rua Andrade, n.º 2, R/C Dto., 1170-015 Lisboa e com o endereço de correio eletrónico: Raquel.florindo@cparaiso.pt</p>
                            <p><b>Subcontratantes, Comunicação e Transferência de dados pessoais</b></p>
                            <p>Os dados pessoais, as bases de dados, as plataformas de serviços e outras operações, poderão, nos termos e condições legalmente definidos, ser alojados, partilhados e comunicados a outras empresas que existam ou venham a existir em relação de grupo ou parceria com a Agência de Leilões C. Paraíso Lda.,</p>
                            <p>Os dados pessoais poderão, ainda, ser disponibilizados aos tribunais e às demais autoridades competentes, no estrito cumprimento do disposto na lei, designadamente quando se revelem necessários para a resolução de litígios relativos à faturação e para atividades relativas à proteção da segurança pública, da defesa e segurança do Estado e à prevenção, investigação ou detecção de ilícitos criminais.</p>
                            <p>A Agência de Leilões C. Paraíso Lda., pode também recorrer a subcontratantes para a totalidade ou parte das finalidades acima identificadas, bem como para a manutenção, alojamento e gestão dos seus sistemas e equipamentos informáticos, nos termos permitidos pela legislação que regula o tratamento de dados pessoais, ficando estas entidades obrigadas a guardar sigilo e a garantir a segurança relativamente aos dados dos Clientes da Agência de Leilões C. Paraíso Lda., a que, para o efeito, tenham acesso, não devendo utilizar esses dados para quaisquer outros fins, ou em benefício próprio, nem relacioná-los com outros dados que possuam.</p>
                            <p>Nas situações em que os dados dos Clientes sejam transferidos ou alojados noutras eventuais empresas que existam ou venham a existir em relação de grupo ou parceria com a Agência de Leilões C. Paraíso Lda os níveis de segurança e proteção não serão inferiores aos prestados pela Agência de Leilões C. Paraíso Lda.</p>
                            <p><b>Qual o período de conservação dos dados pessoais?</b></p>
                            <p>Os seus dados pessoais são conservados apenas durante o período de tempo necessário para a prestação do serviço ou produto, ou para o cumprimento de obrigações legais da Agência de Leilões C. Paraíso Lda.</p>

                            <h4>Cookies</h4>
                            <p><b>O que são Cookies?</b></p> 
                            <p>“Cookies” são pequenos ficheiros de texto que são armazenados no equipamento (computador, tablet, telemóvel) através do navegador de internet (“browser”) e que são utilizados habitualmente para reter informação da visita ao site entre diversas sessões (ex: preferências, páginas visitadas, etc.).</p>
                            <p>Os cookies são utilizados pela maioria dos sites na internet com o objetivo final de melhorar a experiência do utilizador.</p>
                            <p><b>Que tipo de Cookies são utilizados nos websites da Agência de Leilões C. Paraíso Lda?</b></p><br/>
                            <ul>
                                <li><b>Cookies estritamente necessários:</b> permitem que navegue no site e utilize as aplicações e serviços que a Agência de Leilões C. Paraíso Lda disponibiliza, como por exemplo aceder a áreas seguras do site, entre outros. Sem estes cookies os serviços não podem ser prestados.</li> 
                                <li><b>Cookies Analíticos:</b> permitem obter dados agregados para análise estatística e melhoria do site.</li>
                                <li><b>Cookies Funcionais:</b> permitem uma navegação fácil e individualizada, pois guardam as preferências do utilizador relativamente à utilização do site, para que não seja necessário voltar a configurar em cada visita.</li>
                                <li><b>Cookies de Publicidade:</b> Permitem direcionar a publicidade em função dos interesses de cada utilizador, mediante a recolha de hábitos de navegação na internet. Desta forma, a publicidade disponibilizada corresponderá às suas necessidades.<br/>Alguns Cookies utilizados no site serão eliminados do equipamento automaticamente ao encerrar a sessão do navegador (chamados “cookies de sessão”). Outros tipos de Cookies permanecerão no seu computador e possibilitam a identificação do mesmo na próxima visita ao site (chamados “cookies permanentes”).</li>
                            </ul>
                            <p></p>
                            <p><b>Como pode bloquear a utilização dos Cookies?</b></p>
                            <p>O seu navegador permite que faça a gestão dos cookies nas opções de configuração, podendo inclusive bloquear todos os cookies da Agência de Leilões C. Paraíso Lda. No entanto, ao bloquear os Cookies, irá afetar a sua experiência enquanto utilizador.</p>
                            <p><b>Quais as entidades que podem ter acesso à informação recolhida pelos Cookies?</b></p>
                            <p>A informação recolhida pelos cookies pode ser utilizada pela Agência de Leilões C. Paraíso Lda seus subcontratantes ou parceiros.</p>


                            <h4>Casos particulares</h4>
                            <p><b>Marketing e Telemarketing</b></p> 
                            <p>Para informar o Cliente sobre novos produtos e serviços, a Agência de Leilões C. Paraíso Lda pode utilizar os seguintes métodos: Correio ou fax, inserção da informação na respetiva fatura de serviço, telefone, e-mail, SMS, MMS ou qualquer outro serviço de comunicações eletrónicas, salvo se o Cliente se tiver oposto.</p>
                            <p>Na Proposta de Contrato de Prestação de Serviços de Comunicações Eletrónicas poderá não autorizar o tratamento dos seus dados pessoais para efeitos de Marketing ou Telemarketing.</p>
                            <p>O Cliente pode recusar o seu consentimento em qualquer altura, bastando para o efeito remeter o seu pedido para:</p>
                            <p><b>Agência de Leilões C. Paraíso</b><br/>
                            Rua Andrade, n.º 2, R/C Dto. <br/>
                            1170-015 Lisboa<br/>
                            </p>
                            <p><b>A/C Responsável pela Privacidade</b><br/>
                                Exma. Sra. Raquel Florindo<br/>							 
                                ou para o endereço de correio eletrónico: <a href="mailto:raquel.florindo@cparaiso.pt">raquel.florindo@cparaiso.pt</a>
                            </p>

                            <h4>Direitos do cliente</h4>
                            <p><b>Direito de acesso, correção, oposição e eliminação dos dados pessoais</b></p> 
                            <p>Nos termos da legislação aplicável, é garantido ao Cliente o direito de acesso aos dados que lhe digam diretamente respeito, podendo solicitar a sua correção ou aditamento, contactando para o efeito a:</p>
                            <p><b>Agência de Leilões C. Paraíso</b><br/>
                            Rua Andrade, n.º 2, R/C Dto. <br/>
                            1170-015 Lisboa<br/>
                            </p>
                            <p><b>A/C Responsável pela Privacidade</b><br/>
                                Exma. Sra. Raquel Florindo<br/>							 
                                ou para o endereço de correio eletrónico: <a href="mailto:raquel.florindo@cparaiso.pt">raquel.florindo@cparaiso.pt</a>
                            </p>
                            <p>O Cliente pode, ainda, a qualquer momento, opor-se ao tratamento dos dados, com excepção dos estritamente necessários à prestação do serviço, e/ou requerer eliminação dos seus dados para fins de marketing ou telemarketing ou inclusão em listas de assinantes e serviços informativos, devendo para o efeito contactar a Responsável pelo tratamento de dados identificada no parágrafo anterior, por escrito ou através do endereço de correio eletrónico.</p>
                            <p><b>Segurança dos dados pessoais</b></p>
                            <p>Assegurar a confidencialidade e segurança dos dados dos Clientes é uma permanente preocupação da Agência de Leilões C. Paraíso Lda.</p> 
                            <p>Porque reconhece a sensibilidade desta informação, elaborou e divulgou a todos os seus colaboradores Políticas de Privacidade e Segurança dos dados.</p>
                            <p>A Agência de Leilões C. Paraíso Lda. designou também um Encarregado pelo Tratamento dos Dados, para acompanhar o cumprimento destas políticas e definiu regras claras de contratualização do tratamento de dados pessoais com os subcontratantes.</p> 
                            <p>Para garantir a permanente sensibilização dos seus colaboradores e prestadores de serviços para as regras aplicáveis à proteção de dados, a Agência de Leilões C. Paraíso Lda. desenvolve periodicamente ações de formação junto destas pessoas, tendo, de igual modo, elaborado e divulgado um manual sobre regras aplicáveis ao segredo profissional.</p>
                            <p>Também os colaboradores da Agência de Leilões C. Paraíso Lda., bem como aqueles que lhe prestam serviços, assumem o compromisso de não revelar a terceiros ou utilizar para fins contrários à lei, qualquer informação pessoal dos Clientes da Agência de Leilões C. Paraíso Lda. cujo conhecimento lhes advenha do exercício das suas funções.</p>
                            <p>A Agência de Leilões C. Paraíso Lda. dispõe, ainda, de sistemas e de equipas destinadas a garantir a segurança das informações, criando e atualizando procedimentos que previnam acessos não autorizados, perdas acidentais e/ ou destruição dos dados pessoais.</p>
                            <p>A Agência de Leilões C. Paraíso Lda. compromete-se a respeitar a legislação relativa à proteção da privacidade dos dados dos Clientes e a tratar estes dados apenas para os fins para que foram recolhidos, assim como a garantir que estes dados são tratados com adequados níveis de segurança e confidencialidade, nos termos da legislação aplicável e das condições fixadas pela CNPD.</p> 
                            <p>De forma a garantir a segurança dos seus serviços e da rede, a Agência de Leilões C. Paraíso Lda. reserva-se o direito de monitorizar a performance e a qualidade do serviço e rede, de modo a detetar eventuais falhas técnicas, erros de transmissão, ameaças, correio eletrónico não solicitado, vírus, pirataria informática e outras vulnerabilidades de segurança, não exercendo, porém, através de meios humanos, qualquer controlo sobre o conteúdo da informação transmitida através da sua rede.</p> 
                            <p>Não obstante a Agência de Leilões C. Paraíso Lda ter implementadas medidas de segurança para a receção de dados pela Internet, alertamos para o facto de os dados que circulam na Internet não estarem totalmente protegidos contra desvios eventuais, sendo a comunicação de senhas de acesso, passwords, códigos confidenciais e de qualquer informação sensível efetuada sob inteira responsabilidade do Cliente.</p>
                            <p>Durante a sessão de utilização dos nossos sites são usados cookies que expiram quando o browser é fechado. A utilização de cookies permite facilitar a transmissão da comunicação e prestação do serviço que nos tenha solicitado. A maioria dos browsers permitem que o Cliente escolha aceitar ou não os cookies, pelo que, caso assim o entenda, poderá recusar esses mesmos cookies.</p>
                            <p>Para sua segurança, no acesso ao site Agência de Leilões C. Paraíso Lda são registados em Logs as opções e operações efetuadas.</p>
                            <p>Para sua proteção, o acesso a alguns serviços da Agência de Leilões C. Paraíso Lda, estão protegidos por uma password ou código de acesso. Estes códigos serão enviados pela Agência de Leilões C. Paraíso Lda para o seu email, não devendo o Cliente transmitir a terceiros. Por questões de segurança, recomendamos que memorize a sua password e que proceda à sua alteração com regularidade.</p>
                            <p>Recomendamos, ainda, que verifique a data do último login no Agência de Leilões C. Paraíso Lda e a memorize ou anote em local seguro para confirmar que inexistem acessos por terceiros.</p>
                            <p>Também como medida de proteção dos seus dados pessoais, tem ao seu dispor a possibilidade de inibir o detalhe das comunicações, o que poderá fazer através de pedido dirigido, por escrito, à Agência de Leilões C. Paraíso Lda.</p>
                            <p>Pode também, a qualquer momento, solicitar a confidencialidade dos seus dados e retirar o seu consentimento para que estes dados sejam divulgados nas listas telefónicas ou serviços informativos da Agência de Leilões C. Paraíso Lda ou de terceiros, devendo para o efeito contactar a Responsável pela Privacidade, Exma. Sra. Raquel Florindo ou para o endereço de correio eletrónico: <a href="mailto:raquel.florindo@cparaiso.pt">raquel.florindo@cparaiso.pt</a>.</p> 

                            <h4>Direito de apresentar reclamação</h4>
                            <p>O cliente tem o direito de apresentar reclamação à <NavigationLink to="public:ral">entidade de controlo</NavigationLink></p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}