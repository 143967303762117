import React from "react";
import { observer, inject } from "mobx-react";
import { observable, computed } from "mobx";
import { getNumberValueOrDefault, isNotNullOrEmptyString } from "modbin-domain";
import { TextSwitcherView } from "../../../components/text/TextSwitcherView";
import { CompactedSearchForm } from "../../business/components/search/CompactedSearchForm";
import { TranslationProps } from "modbin-domain";
import { LayoutStore } from "./LayoutStore";
import { Banner, BannerType } from "ibase-auctions-domain";

@inject("layoutStore") @observer
export class HomeImageSwitcherView extends React.Component<TranslationProps&{layoutStore?:LayoutStore},{}> {

    @observable selectedImageIndex:number = 0;
    @observable 
    _currentInterval:NodeJS.Timeout | undefined = undefined;
    @observable className = "";

    changeIndex(){
        const currentIndex = this.selectedImageIndex;
        this.selectedImageIndex = (currentIndex + 1 === this.getBanners().length)
            ? 0
            : this.selectedImageIndex + 1;
    }

    getBanners(){
        const layout = this.props.layoutStore!.layout;
        if(layout === undefined){
            return [];
        }
        const {defaultHomeBanner, banners=[]} = layout;
        const topBanners = banners.filter(x => x.type === BannerType.TOP)
        if(topBanners.length > 0)
        {
            return topBanners;
        }        
        const dhomeBanner = isNotNullOrEmptyString(defaultHomeBanner)
            ? [{ url: defaultHomeBanner}]
            : ( isNotNullOrEmptyString(((defaultHomeBanner as unknown)as Banner).url)
                ? [(defaultHomeBanner as any)]
                : [""]);
        return dhomeBanner;
    }

    toggleFadeClass(){
        if(this.className === ""){
            this.className = "fade"
        }
        else {
            this.className = "";
        }
    }

    componentWillMount(){
        this.runTimer();
    }

    componentWillUnmount(){
        if(this._currentInterval !== undefined){
            clearInterval(this._currentInterval!);
        }
    }

    @computed
    get timeout(){
        return getNumberValueOrDefault(15000,5000);   
    }

    @computed
    get selectedImageUrl(){
        if(!this.props.layoutStore!.isLoaded){
            return "";
        }
        return this.getBanners()[this.selectedImageIndex].url;   
    }

    runTimer(){
        this._currentInterval = setInterval(() => {
            this.toggleFadeClass();
            this.changeIndex();
            setTimeout(() =>{
                this.toggleFadeClass()
            }, 1000);
        }, this.timeout);
    }

    render(){
        const getTranslation = (key:string) => this.props.t(`screens:public.home.${key}`);
        return  <div className="parallax"
            style={{
                backgroundImage: `url(${this.selectedImageUrl})`,
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}>
        <div className="parallax-overlay"></div>
        <div className="parallax-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-search-container">
                            <TextSwitcherView texts={[getTranslation("slogan1"),getTranslation("slogan2")]}/>
                            <CompactedSearchForm></CompactedSearchForm>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    }
}