import { Formik, Form } from "formik";
import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { Price } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/forms/elements/FormInput";
import { ActionButton, ButtonType } from "../../../../../components/buttons/ActionButton";

const PriceElement = observer((props:TranslationProps & {name:string, formikState:any}) => {
    const getTranslationPrice = (key:string) => props.t(`domain:sales.price.${key}`);
    return <div className="col-md-2">
        <FormInput type="number" name={props.name} label={getTranslationPrice(props.name)} formikState={props.formikState} />
    </div>
})

const PriceFormView = observer((props:TranslationProps & {price:Price, onSave:(ev:any)=>Promise<void>}) => {
    if(props.price === undefined){
        return <Fragment></Fragment>;
    }
    const {price,t} = props;
    return (
        <Formik
                initialValues={{...price}}
                onSubmit={(values, actions) => {
                  return props.onSave(values).then((x)=>{
                        actions.setSubmitting(false);
                  });
                }}
              >
                {(formProps) => (
                  <Form>
                      <PriceElement t={t} name="base" formikState={formProps}/>
                      <PriceElement t={t} name="reserve" formikState={formProps}/>
                      <PriceElement t={t} name="starting" formikState={formProps}/>
                      <PriceElement t={t} name="current" formikState={formProps}/>
                      <PriceElement t={t} name="nextBid" formikState={formProps}/>
                      <div className="col-md-12 my-profile">
                        <ActionButton type={ButtonType.CONTINUE} onClick={()=>{return formProps.submitForm()}} style={{float:"right"}} className="border margin-top-10"/>
                      </div>
                  </Form>
                )}
              </Formik>
      );
  });
  
  export const PriceForm = (withTranslation(["common"])(PriceFormView as any) as any) as (props:any) => JSX.Element;