import { IEvent, DomainEvent } from "modbin-domain"
import { User, IUserProperties, Address } from "ibase-auctions-domain";
import { toJS } from "mobx";

export class UserUpdateEvent extends DomainEvent implements IEvent {
    constructor(previousUser:User, changedUser:User){
        super({type:"UpdateUser", name:"UpdateUser"});
        const fields = this.getChanges(previousUser,changedUser);
        this.metadata = fields;
    }

    compareProperties(pUser:any,cUser:any,properties?:string[]):string[]{
        let result:string[] = [];
        Object.keys(pUser).forEach((x)=> {
            if(properties !== undefined && properties.length > 0){
                if(properties.includes(x)){
                    if(pUser[x] !== cUser[x]){
                        result.push(x);
                    }
                }
            }
            else {
                if(pUser[x] !== cUser[x]){
                    result.push(x);
                }
            }
        })
        return result;
    }

    getChanges(previousUser:User, changedUser:User):string[]{
        const result:string[] = [];
        const pUser:any = toJS(previousUser);
        const cUser:any = toJS(changedUser);
        const address = this.compareProperties(pUser.address, cUser.address);
        const contact = this.compareProperties(pUser.contact, cUser.contact);
        const identificationCard = this.compareProperties(pUser.identificationCard, cUser.identificationCard);
        const validProperties = ["firstName","lastName","title","maritalStatus","birthDate", "bornAt", "accountState", "provider", "officeId"];
        const user = this.compareProperties(pUser,cUser,validProperties);
        const r = result.concat(
            address.map(x => `address.${x}`),
            contact.map(x => `contact.${x}`),
            identificationCard.map(x => `identficationCard.${x}`),
            user);
        return r;
    }
}