import React, { ReactNode, Component } from 'react';
import { EmptyLayout } from './EmptyLayout';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { inject, observer } from 'mobx-react';
import { MainAppViewStore } from '../../configuration/stores/MainAppViewStore';
import { LoadingView } from '../loading/LoadingView';
// import { Breadcrumb } from 'react-bootstrap';
// import { BreadcrumbView } from './navigation/breadcrums/BreadcrumbView';
import { TranslationProps, isNullOrEmptyString } from 'modbin-domain';
import { withTranslation } from 'react-i18next';

// export const BaseLayout = (props:{
//     header?:ReactNode|undefined,
//     type?:"fluid"|"fixed",
//     children:any
// }) => {
//     const body = props.type !== undefined && props.type === "fluid" 
//             ? props.children
//             : <div className="container">{props.children}</div>
            
// 		return <EmptyLayout
//                 header={<Header>{props.header}</Header>}
//                 footer={<Footer />} 
//             >     
//                 {body}
//             </EmptyLayout>;
// }

@inject("mainAppViewStore", "lotIndexViewStore") @observer
export class InnerBaseLayout extends Component<TranslationProps&{
    mainAppViewStore?:MainAppViewStore,
    header?:ReactNode|undefined,
    type?:"fluid"|"fixed",
    children:any,
    
},{}>{

    createBody(){
        const { type, children} = this.props;
        const body = type !== undefined && type === "fluid" 
            ? children
            : <div className="container">{children}</div>;
        return body;
    }

    render(){
        const isLoading = !this.props.mainAppViewStore!.isLoaded;
        const nodes = this.props.mainAppViewStore!.currentPath !== undefined
            ? this.props.mainAppViewStore!.currentPath!.map(x => { const text = this.props.t(x.nameTranslationKey); return {...x, text:isNullOrEmptyString(text, true) ? x.nameTranslationKey : text}})
            : [];
		return <EmptyLayout
                header={<Header>{this.props.header}</Header>}
                footer={<Footer />} 
            >     
            {/* <div className="container margin-top-20 margin-bottom-20">
                <div className="row">
                <div className="col-md-12">
                <BreadcrumbView nodes={nodes} onClick={(ev:any,viewId:string) => { this.props.mainAppViewStore!.navigateTo(viewId, {event:ev})}}/>
                </div>
                </div>
            
            </div> */}
                <LoadingView
                                    isLoading={isLoading} 
                                >
                                {this.createBody()}
                            </LoadingView>
            </EmptyLayout>;
    }
}

export const BaseLayout = (withTranslation(["common"])(InnerBaseLayout as any) as any) as (props:any) => JSX.Element;
