import {observable, computed} from "mobx";
import { LotStore } from "../../Store";
import { LotUpdateData, CreateBidCmd, User, IBidService, getClockDatesFromSellingDates, SellingEventDateState, Bid, Lot, SellingDates, ClockDate } from "ibase-auctions-domain";
import { ILotService } from "ibase-auctions-api";
import { downloadFile } from "../../../../../components/files/Download";
import { IUserNotificationService, ITimerService, ILogger, isNotNullOrEmptyString, isNullOrEmptyString } from "modbin-domain";
import { calculateRefreshTimeout } from "../CalculateRefreshTimeout";
import { application } from "../../../../../Startup";
import { getEventDateState } from "../../../components/helpers/Dates";
import { IContextProvider } from "../../../../../configuration/temp/web/application/ContextProvider";
import { BaseStore, IViewStore, IMainAppViewStore } from "modbin-web";
import { SessionStore } from "../../../../public/sessions/SessionStore";
import { isLotOlderThan } from "./IsLotOlderThan";
const moment = require("moment");

export enum BidderState {
    NOBIDS = "nobids",
    OUTBID = "outbid",
    HIGHESTBIDDER = "highestbidder"
}


export class LotDetailsViewStore extends BaseStore implements IViewStore {

    @observable
    _selected:string|undefined = undefined; 
    @observable _selected_eventId: string|undefined = undefined;
    @observable isLoading:boolean = false;
    @observable isLoadingPrices:boolean = false;
    _currentTimeout:NodeJS.Timeout | undefined = undefined;
    @observable _currentBidderState: undefined | BidderState = undefined;

    constructor(
        private lotStore:LotStore, 
        private lotService:ILotService,
        private userNotificationService:IUserNotificationService, 
        private contextProvider:IContextProvider, 
        private sessionsStore:SessionStore,
        private mainAppViewStore:IMainAppViewStore,
        private numberOfDaysToShowPastEvents:number,
        private logger:ILogger) {
        super();
    }

    
    getSelected(){
        return this.lotStore.getLotById(this._selected!);
    } 

    @computed
    get props(){
        return {
            selected:this.getSelected(),
            isLoading:this.isLoading,
            isLoadingPrices:this.isLoadingPrices
        }
    }

    setState(params:{eventId:string, lotId:string}){
        this._selected = params.lotId;
        this._selected_eventId = params.eventId;
        this.isLoading = true;
        this.lotStore.fetchLotById(this._selected, params.eventId).then(x => {
            if(x === undefined || (x !== undefined && isLotOlderThan(x.event.dates.endDate,new Date(Date.now()),this.numberOfDaysToShowPastEvents))){
                this.mainAppViewStore.navigateTo("common:errors:notfound");
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.isLoadingPrices = true;
            this._currentBidderState = undefined;
            this.updateData().then(x=> { 
                this.startUpdateData()
            },(e) => {
                try {
                    this.logger.error(`Error in setState (update data) method on LotDetailsViewStore (EventId: ${params.eventId}), (LotId: ${params.lotId}) : `,e);
                }
                catch(err){
                    console.log(err);
                }    
                this.isLoading = false;
            });
        },
        (e) => {
            if(e.status !== undefined && e.status === 404){
                this.mainAppViewStore.navigateTo("common:errors:notfound");
            }
            else {
                try {
                    this.logger.error(`Error in setState method on LotDetailsViewStore (EventId: ${params.eventId}), (LotId: ${params.lotId}) : `,e);
                }
                catch(err){
                    console.log(err);
                }    
            }
            this.isLoading = false;
        });
    }

    getState(){
        const lot = this.lotStore.getLotById(this._selected!);
        const eventId = lot !== undefined
            ? lot.eventId
            : this._selected_eventId;
        return { 
            lotId: this._selected,
            eventId: eventId
        };
    }

    setBidderState(bids:Bid[]){
        const userIsDefined = this.contextProvider.hasContext(c => (c.user !== undefined && c.user.id !== undefined));
        if(!userIsDefined){
            this._currentBidderState = undefined;
            return;
        }
        const userId = this.contextProvider.getContext().user.id!;
        const userBiddingState = this.calculateBidderState(bids,userId);
        if(userBiddingState !== this._currentBidderState){
            this._currentBidderState = userBiddingState;
        }
    }

    handleBidsUpdate(bids:Bid[]){
        const userIsDefined = this.contextProvider.hasContext(c => (c.user !== undefined && c.user.id !== undefined));
        if(!userIsDefined){
            this._currentBidderState = undefined;
            return;
        }
        const userId = this.contextProvider.getContext().user.id!;
        const bidderState = this.calculateBidderState(bids,userId);
        if(this._currentBidderState === bidderState){
            return;
        }
        if(this._currentBidderState === undefined){
            this._currentBidderState = bidderState;
            return;
        }
        this._currentBidderState = bidderState;
        // if(this._currentBidderState === BidderState.HIGHESTBIDDER){
        //     this.userNotificationService.success(application.translations.translate("screens:business.lots.details.bidSuccessfulMessage"));
        // }
        if(this._currentBidderState === BidderState.OUTBID){
            this.userNotificationService.warning(application.translations.translate("screens:business.lots.details.bidOutbidMessage"));
        }
    }

    updateData():Promise<LotUpdateData|undefined>{
        return this.lotStore.fetchLotUpdateData(this._selected!,this._selected_eventId!).then((x:any)=>{
            this.handleBidsUpdate(x.bids);
            this.isLoadingPrices = false;
            return x;
        });
    }

    logDates(eventDates:SellingDates,timeout:number|undefined,clockDate:ClockDate, nowDate:Date){
        console.log("-----EVENT DATES------")
        console.log("dates:",eventDates);
        console.log("TIMEOUT:",timeout)
        console.log("Date-start:",clockDate.start)
        console.log("Date-end:",clockDate.end)
        console.log("Date-now:",clockDate.now)
        console.log("NOWDATE:",nowDate)
        console.log("Client-date-now:",new Date(Date.now()))
    }

    getTimeout(){
        if(this._selected === undefined){
            return undefined;
        }
        const lot = this.lotStore.getLotById(this._selected!)!; 
        const timerService = application.getContainer().resolve<ITimerService>("timeService");
        const nowDate = timerService.getCurrentDate();
        const dates = getClockDatesFromSellingDates(lot.event.dates,nowDate);
        const timeout = calculateRefreshTimeout(dates.start,dates.end,dates.now, 40000);
        //this.logDates(lot.event.dates,timeout,dates,nowDate);
        return timeout;
    }

    startUpdateData(){        
        if(this._selected === undefined){
            return;
        }
        const lot = this.lotStore.getLotById(this._selected!)!; 
        const state = getEventDateState(lot);
        if((state === SellingEventDateState.ENDED || state === SellingEventDateState.NOTSTARTED) && !this.isLoadingPrices){
            return;
        }
        else {
            if(this._currentTimeout !== undefined){
                clearTimeout(this._currentTimeout);
            }
            this.updateData();
            const timeout = this.getTimeout();
            if(timeout !== undefined){
                this._currentTimeout = setTimeout(() => {
                this.startUpdateData();
                }, timeout);
            }
        }
    }

    onBid(value:number, user?:User):Promise<void>{
        if(this._selected === undefined){
            throw new Error("A bid must be made on a lot.")
        }
        const lot = this.getSelected()!;
        const cmd:CreateBidCmd = {
            value:value,
            userId:user!.getId(),
            eventId:lot.eventId,
            dates: lot.event.dates,
            lotId:lot.getId(),
            officeId:lot.officeId,
            provider:"C.Paraiso"
        } as any;
        return this.lotService.makeBidOnLot(cmd).then((x)=>{
            this.handleSuccessfulBid(this._selected!,x);
        },(err) => {
            if(isNullOrEmptyString(err.message)){
                this.userNotificationService.error("Internal server error");
                return;
            }
            if(err.message.startsWith("A licitação não é de valor suficiente para garantir a próxima licitação mínima")){
                this.userNotificationService.error(err.message);  
                this.isLoadingPrices = true;
                return this.updateData().then((x) => {
                    return Promise.resolve();     
                });
            }
            else {
                this.logger.error("Error in onBid method in LotDetailsViewStore:", err)
                this.userNotificationService.error(err.message); 
                if(err.message.startsWith("A sua sessão expirou")){
                    this.logger.error("Error Session expired LotDetailsViewStore:", err)
                    this.sessionsStore.logoutUser();
                }
            }
        });
    }

    onGenerateProposal(value:number, user?:User):Promise<void>{
        const bidService = application.getContainer().resolve<IBidService>("bidService");
        const lot = this.getSelected()!;
        const userId = user !== undefined ? user!.getId() : undefined;
        const createBidCmd = {
            value:value,
            userId: userId,
            eventId:lot.eventId,
            lotId:lot.getId(),
            officeId:lot.officeId,
            provider:"C.Paraiso"
        }
        return bidService.makeProposalGeneratorUrl(createBidCmd).then((x:string) => {
       //         window.open(x,"_blank");
                return downloadFile(x,"Proposta.pdf","application/pdf");
            },
            (e:any) => {
                this.logger.error(`Error in onGenerateProposal method on LotDetailsViewStore: `,e);
                throw e;
            });
    }

    calculateBidderState(bids:Bid[], userId:string){
        if(bids === undefined || bids.length === 0){
            return BidderState.NOBIDS;
        }
        if(bids.filter(x => x.userId === userId).length === 0){
            return BidderState.NOBIDS;
        }
        const orderedBids = bids.sort(function(a, b){return b.value-a.value})
        if(orderedBids[0].userId === userId){
            return BidderState.HIGHESTBIDDER;
        } 
        else {
            return BidderState.OUTBID;
        }
    }

    handleSuccessfulBid(lotId:string,bid:Bid){
        this.updateData().then(x => {
            this.userNotificationService.success(application.translations.translate("screens:business.lots.details.bidSuccessfulMessage"));
            this.lotStore.updateSuccessfulBidValue(lotId,bid);
            this._currentBidderState = BidderState.HIGHESTBIDDER;
        })
    }

    
    reset(){
        this._selected = undefined; 
        this.isLoading = false;
        this.isLoadingPrices = false;
        this._currentTimeout = undefined;
        this._currentBidderState = undefined;
        return Promise.resolve();

    }
}