import { commonTranslations } from "./Common.translations";
import { TranslationDefinition } from "./Translations.types";
import { publicTranslations } from "../screens/public/Public.translations";
import { domainTranslations } from "ibase-auctions-domain";
import { privateTranslations } from "../screens/private/Private.translations";
import { businessTranslations } from "../screens/business/Business.translations";
import { administrationTranslations } from "../screens/administration/Administration.translations";


export const appTranslations:TranslationDefinition = {
    pt: {
        common: commonTranslations.pt,
        domain: domainTranslations.pt,
        screens:{
            public:publicTranslations.pt,
            private:privateTranslations.pt,
            business:businessTranslations.pt,
            administration:administrationTranslations.pt
        }
    },
    en: {
        common: commonTranslations.en,
        domain: domainTranslations.en,
        screens:{
            public:publicTranslations.en,
            private:privateTranslations.en,
            business:businessTranslations.en,
            administration:administrationTranslations.pt
        }
    }
}