import React from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { observer } from 'mobx-react';

export type ImageSource = {
    url:string;
    title?:string;
    metadata?:any;
}

export const ImageBoxView = observer((
    props:{
        images:ImageSource[],
        photoIndex:number, 
        visible:boolean,
        onClose:() => void,
        onIndexChange:(index:number) => void
        //onDelete:() => void
    }) => {
        const { visible, photoIndex } = props;
        const images = props.images.map(x => x.url);
        return (
          <div>         
    
            {visible && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => props.onClose()}
                imageTitle={props.images[photoIndex].title}
                onMovePrevRequest={() => {
                        props.onIndexChange((photoIndex + images.length - 1) % images.length)
                }}
                onMoveNextRequest={() => {
                    props.onIndexChange((photoIndex + 1) % images.length)
                }}
              />
            )}
          </div>
        );
});