export const contactsTranslations = {
  pt: {
    title: "Contactos",
    headquarters: "Sede",
    warehouse_office: "Escritório e Armazém",
    contactUs: "Deixe-nos uma mensagem",
    contactInfo: "CHAMADA PARA A REDE FIXA NACIONAL"
  },
  en: {
    title: "Contacts",
    headquarters: "Headquarters",
    warehouse_office: "Office and Warehouse",
    contactUs: "Leave us a message",
    contactInfo: "LANDLINE CALL"
  },
};
