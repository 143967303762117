"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultQueryableArraySync = void 0;

var Utils_types_1 = require("../../utils/Utils.types");

var DefaultQueryableArraySync =
/** @class */
function () {
  function DefaultQueryableArraySync(options) {
    this.queryableSteps = [];
    this.totalItems = 0;

    if (options) {
      if (options.specifiable) {
        this.filter(options.specifiable);
      } else {
        this.filter(function (x) {
          return true === true;
        });
      } //this.clone(Cloner.withJson);


      if (options.sortable) {
        this.sortBy(options.sortable);
      }

      if (options.pageable) {
        this.paginate(options.pageable);
      }

      if (options.projectable) {
        this.project(options.projectable);
      }
    }
  }

  DefaultQueryableArraySync.prototype.setSteps = function (queryableSteps) {
    this.queryableSteps = queryableSteps;
  };

  DefaultQueryableArraySync.prototype.getTotal = function () {
    return this.totalItems;
  };

  DefaultQueryableArraySync.prototype.sortBy = function (comparer) {
    var sortByFunction = function sortByFunction(items) {
      return items.sort(comparer);
    };

    this.queryableSteps.push(sortByFunction);
    return this;
  };

  DefaultQueryableArraySync.prototype.filter = function (predicates) {
    var _this = this;

    var filterFunction = function filterFunction(items) {
      var result = items.filter(predicates);
      _this.totalItems = result.length;
      return result;
    };

    this.queryableSteps.push(filterFunction);
    return this;
  };

  DefaultQueryableArraySync.prototype.project = function (projector) {
    var projectFunction = function projectFunction(items) {
      var result = items.map(function (x) {
        return projector(x);
      });
      return result;
    };

    this.queryableSteps.push(projectFunction);
    var newQueryable = new DefaultQueryableArraySync();
    newQueryable.setSteps(this.queryableSteps);
    return newQueryable;
  };

  DefaultQueryableArraySync.prototype.paginate = function (parameters) {
    var paginateFunction = function paginateFunction(items) {
      var page = parameters.page,
          size = parameters.size;
      var init = (page - 1) * size;
      var end = page * size;
      return items.slice(init, end);
    };

    this.queryableSteps.push(paginateFunction);
    return this;
  };

  DefaultQueryableArraySync.prototype.execute = function (items) {
    return Utils_types_1.compose.apply(void 0, this.queryableSteps.reverse())(items);
  };

  return DefaultQueryableArraySync;
}();

exports.DefaultQueryableArraySync = DefaultQueryableArraySync;