"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.builderOptionsMerger = exports.mergeMap = void 0;

var Properties_1 = require("../../utils/objects/Properties");

var mergePageable = function mergePageable(source, destination) {
  var result = {
    page: Properties_1.setIfUndefined("page", source, destination),
    size: Properties_1.setIfUndefined("size", source, destination)
  };
  return result;
};

exports.mergeMap = function (source, destination) {
  if (source === undefined && destination === undefined) {
    return new Map();
  }

  if (source === undefined) {
    return destination;
  }

  if (destination === undefined) {
    return source;
  }

  source.forEach(function (v, k) {
    return destination.set(k, v);
  });
  return destination;
};

var mergeSortable = function mergeSortable(source, destination) {
  var result = {
    by: Properties_1.setIfUndefined("by", source, destination),
    direction: Properties_1.setIfUndefined("direction", source, destination),
    mapper: exports.mergeMap(Properties_1.getPropertyOrUndefined("mapper", source), Properties_1.getPropertyOrUndefined("mapper", destination))
  };
  return result;
};

var mergeSpecifiable = function mergeSpecifiable(source, destination) {
  var result = {
    mapper: exports.mergeMap(Properties_1.getPropertyOrUndefined("mapper", source), Properties_1.getPropertyOrUndefined("mapper", destination))
  };
  return result;
};

var mergeProjectabel = function mergeProjectabel(source, destination) {
  var result = {
    mapper: exports.mergeMap(Properties_1.getPropertyOrUndefined("mapper", source), Properties_1.getPropertyOrUndefined("mapper", destination)),
    selector: Properties_1.setIfUndefined("selector", source, destination)
  };
  return result;
};

exports.builderOptionsMerger = function (source, destination) {
  var mergedOptions = {
    pageable: undefined,
    specifiable: undefined,
    sortable: undefined,
    projectable: undefined
  };
  mergedOptions.pageable = mergePageable(source.pageable, destination.pageable);
  mergedOptions.sortable = mergeSortable(source.sortable, destination.sortable);
  mergedOptions.specifiable = mergeSpecifiable(source.specifiable, destination.specifiable);
  mergedOptions.projectable = mergeProjectabel(source.projectable, destination.projectable);
  return mergedOptions;
};