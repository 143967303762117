import { TranslationProps } from "modbin-domain";
import React from "react";
import { DocumentFile } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { LoadingView } from "../../../../../components/loading/LoadingView";
import { DownloadButton } from "../../../../../components/files/DownloadButton";

export const DocumentsView = observer((props:TranslationProps & {files:DocumentFile[], isLoading:boolean}) => {
    const body = props.files !== undefined && props.files.length > 0
        ?<ul className="listing-documents">
            {props.files.map(f => {
                return <li key={`document:${(f as any).getId()}`}><DownloadButton url={(f as any).url} name={(f as any).typeDescription} fileName={f.fileName}></DownloadButton></li>;
            })}
        </ul>  
        : props.t("screens:business.lots.details.noDocuments");
    return <div id="booking-widget-anchor" className="boxed-widget booking-widget margin-top-35">
        <h3 style={{textTransform: "uppercase"}}>{props.t("screens:business.lots.details.documentsTitle")}</h3>
        <LoadingView spinner={{size:"medium"}} isLoading={props.isLoading}>
            {body}
        </LoadingView>        
    </div>;
});
