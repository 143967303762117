"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToPerson = void 0;

var IBaseSingleResponseConverter_1 = require("../shared/IBaseSingleResponseConverter");

exports.convertToPerson = function (externalBooking) {
  var extractedExternalBooking = IBaseSingleResponseConverter_1.singleResponseConverter(externalBooking);

  if (extractedExternalBooking.entidade_nome === undefined) {
    return undefined;
  }

  var result = {
    name: extractedExternalBooking.entidade_nome
  };

  if (extractedExternalBooking.entidade_telemovel !== undefined) {
    result.mobilePhoneNumber = extractedExternalBooking.entidade_telemovel;
  }

  if (extractedExternalBooking.entidade_email !== undefined) {
    result.email = extractedExternalBooking.entidade_email;
  }

  if (extractedExternalBooking.entidade_nif !== undefined) {
    result.vatNumber = extractedExternalBooking.entidade_nif;
  }

  return result;
};