import { Currency, Price } from "ibase-auctions-domain";
import { currencyMapper } from "./CurrencyMappers";
import { isNotNullOrEmptyNumber } from "modbin-domain";

function numberWithCommas(x:number) {
    const result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return result;
}

export const getFormatedPrice = (value:number, currency:Currency ) => {
    return `${numberWithCommas(value)}${currencyMapper.get(currency)!.symbol}`;
}

export const getNextBid = (price:Price) => {
    const {nextBid,current,base,reserve, starting, currency} = price;
    const result = {currency:currency,value:0.00}
    if(nextBid !== undefined && nextBid !== null){
        result.value = nextBid;
        return result;
    }
    if(current !== undefined && current !== null){
        result.value = current;
        return result;
    }
    if(starting !== undefined && starting !== null){
        result.value = starting;
        return result;
    }
    if(base !== undefined && base !== null){
        result.value = base;
        return result;
    }
    if(reserve !== undefined && reserve !== null){
        result.value = reserve;
        return result;
    }
    return result;
}

export const getCurrentBid = (price:Price) => {
    const {nextBid,current,base,reserve, starting, currency} = price;
    const result = {currency:currency,value:0.00}
    if(isNotNullOrEmptyNumber(current!,true)){
        result.value = current!;
        return result;
    }
    if(isNotNullOrEmptyNumber(nextBid!,true)){
        result.value = nextBid!;
        return result;
    }
    if(isNotNullOrEmptyNumber(starting!,true)){
        result.value = starting!;
        return result;
    }
    if(isNotNullOrEmptyNumber(base!,true)){
        result.value = base!;
        return result;
    }
    if(isNotNullOrEmptyNumber(reserve!,true)){
        result.value = reserve!;
        return result;
    }
    return result;
}

export const getReserveBid = (price:Price) => {
    const {reserve, currency} = price;
    const result = {currency:currency,value:0.00}
    if(reserve !== undefined && reserve !== null){
        result.value = reserve;
        return result;
    }
    return result;
}