import { Formik, Form } from "formik";
import * as Yup from "yup";
import React from "react";
import { TranslationProps } from "modbin-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/forms/elements/FormInput";
import { ButtonType, ActionButton } from "../../../../../components/buttons/ActionButton";
import { application } from "../../../../../Startup";


const RequestPasswordRecoveryFormView = observer((props:TranslationProps & {onRequest:(email:string)=>Promise<void>}) => {
    const getTranslationSessions = (key:string) => props.t(`screens:public.sessions.${key}`);
    const createRequiredMessage = application.translations.createRequiredErrorMsg;
    const loginSchema = Yup.object().shape({
            email: Yup.string()
            .email(props.t("domain:users.user.emailInvalid"))
            .required(createRequiredMessage("domain:users.user.email"))
          });
      return (
            <Formik
                initialValues={{ email: ""}}
                validationSchema={loginSchema}
                onSubmit={(values, actions) => {
                    return props.onRequest(values.email).then((x)=>{
                        actions.setSubmitting(false);
                    });
                }}
            >
            {(formProps) => (
                <Form>
                    <div className="form-row form-row-wide">
                        <FormInput type="email" name="email" label={getTranslationSessions("email")} placeHolder={props.t("domain:users.user.email")} formikState={formProps} />
                    </div>
                    <div className="form-row">
                        <ActionButton type={ButtonType.CONTINUE} onClick={()=>{return formProps.submitForm()}} className="border margin-top-10"/>
                    </div>
                </Form>
                )}
            </Formik>
      );
  });
  
  export const RequestPasswordRecoveryForm = (withTranslation(["common"])(RequestPasswordRecoveryFormView as any) as any) as (props:any) => JSX.Element;
