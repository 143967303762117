"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultFormattedLogger = void 0;

var DefaultFormattedLogger =
/** @class */
function () {
  function DefaultFormattedLogger(innerLogger) {
    this.innerLogger = innerLogger;
  }

  DefaultFormattedLogger.prototype.fatal = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var fatalMessage = "[FATAL]: " + message;

    (_a = this.innerLogger).fatal.apply(_a, __spreadArrays([fatalMessage], data));
  };

  DefaultFormattedLogger.prototype.error = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var errorMessage = "[ERRROR]: " + message;

    (_a = this.innerLogger).error.apply(_a, __spreadArrays([errorMessage], data));
  };

  DefaultFormattedLogger.prototype.warning = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var warningMessage = "[WARN]: " + message;

    (_a = this.innerLogger).warning.apply(_a, __spreadArrays([warningMessage], data));
  };

  DefaultFormattedLogger.prototype.info = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var infoMessage = "[INFO]: " + message;

    (_a = this.innerLogger).error.apply(_a, __spreadArrays([infoMessage], data));
  };

  DefaultFormattedLogger.prototype.debug = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var debugMessage = "[DEBUG]: " + message;

    (_a = this.innerLogger).error.apply(_a, __spreadArrays([debugMessage], data));
  };

  DefaultFormattedLogger.prototype.trace = function (message) {
    var _a;

    var data = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      data[_i - 1] = arguments[_i];
    }

    var traceMessage = "[TRACE]: " + message;

    (_a = this.innerLogger).error.apply(_a, __spreadArrays([traceMessage], data));
  };

  DefaultFormattedLogger.prototype.log = function (logLevel, message) {
    var data = [];

    for (var _i = 2; _i < arguments.length; _i++) {
      data[_i - 2] = arguments[_i];
    }

    this[logLevel](message, data);
  };

  return DefaultFormattedLogger;
}();

exports.DefaultFormattedLogger = DefaultFormattedLogger;