import React, { Component } from "react";
import { TranslationProps } from "modbin-domain";
import { WebConfiguration } from "../../configuration/Config.types";
import { application } from "../../Startup";
import { UiTestDescription } from "modbin-web";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { ImagePopup, ImageSource } from "./ImagePopup";
import { observer } from "mobx-react";
import { observable } from "mobx";

export const description:UiTestDescription = {
    id:"ImagePopupUiTest",
    groupId:"components",
    description:"lalal",
    title:"Image popup component",
    parentId:"components"
};

const items:ImageSource[] = [
    "/images/single-property-01.jpg",
     "/images/single-property-02.jpg",
    // "/images/single-property-03.jpg",
    // "/images/single-property-04.jpg",
    // "/images/single-property-05.jpg",
].map(x => {return {
    url:`${application.getContainer().resolve<WebConfiguration>("webConfig").baseUrl}${x}`, 
    title:""
}});

@observer
export class ImagePopupUiTest extends Component<TranslationProps,{}>{
    
    @observable visible = false;

    onClose(){
        this.visible = false;
    }

    onOpen(ev:any){
        ev.preventDefault();
        this.visible = true;
    }
    
    render(){
        // return <div><NewCarouselView 
        //     visibleItems={1}
        //     slidesPerStep={1}
        //     items={items.map(x => {return <div><img src={x}></img></div>})}
        //     infinite={true}
        //     autoPlay={true}
        //     showDots={true}
        //     onSlideChange={(index)=>{}}
        //     selectedSlide={1}
        // /></div>;
        return <div>
                <button onClick={(ev) => { this.onOpen(ev)}}> Click</button>
                <ImagePopup images={items} visible={this.visible} onClose={()=> this.onClose()}/>
        </div>;
    }
}