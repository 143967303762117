import React from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

type BadgeProps = {
    text:string;
    cssClass:string;
    group?:string;
    check:(item:any) => boolean;
}

export enum BadgeType {
    NEW = "new",
    SOON = "soon",
    SHORTLY = "shortly",
    ACTIVE = "active",
    FEATURED = "destaque",
    HOT = "hot",
    ENDINGSOON = "endingsoon",
    ENDED = "ended",
    SOLD = "sold"
}

// const soonBadgeChecker = (numberOfDays:number) => (item:Item) => {
//     const checker = function(item:Item){
//         const currentDate = moment().toDate();
//         const startDate = moment(item.auction!.startDate);
//         if()    
//     }
//     return (item:)
    
//     if(item.auction!.startDate)
// }

const getPropertiesForBadge = (type:BadgeType, group:string,check:(item:any)=>boolean):BadgeProps => {
    return {
        text: type.toString(),
        cssClass: `item-badge-${type.toString()} ${group}`,
        group:group,
        check:check
    }
}

const defaultCheck = (item:any) => false;
const featuredCheck = (item:any)=> {return item.featured;}

export const badgeMapper = new Map<BadgeType,BadgeProps>();
badgeMapper.set(BadgeType.NEW, getPropertiesForBadge(BadgeType.NEW, "featured",defaultCheck));
badgeMapper.set(BadgeType.SOON, getPropertiesForBadge(BadgeType.SOON, "featured",defaultCheck));
badgeMapper.set(BadgeType.SHORTLY, getPropertiesForBadge(BadgeType.SHORTLY, "info",defaultCheck));
badgeMapper.set(BadgeType.ACTIVE, getPropertiesForBadge(BadgeType.ACTIVE, "info",defaultCheck));
badgeMapper.set(BadgeType.FEATURED, getPropertiesForBadge(BadgeType.FEATURED, "featured",featuredCheck));
badgeMapper.set(BadgeType.HOT, getPropertiesForBadge(BadgeType.HOT, "featured",defaultCheck));
badgeMapper.set(BadgeType.ENDINGSOON, getPropertiesForBadge(BadgeType.ENDINGSOON, "info", defaultCheck));
badgeMapper.set(BadgeType.ENDED, getPropertiesForBadge(BadgeType.ENDED, "info",defaultCheck));
badgeMapper.set(BadgeType.SOLD, getPropertiesForBadge(BadgeType.SOLD, "featured", defaultCheck));

export const Badge = (props:{ type:BadgeType}) => {
    const badgeProps = badgeMapper.get(props.type);
    return <span className={ `${badgeProps!.cssClass}`}>{badgeProps!.text}</span>;
}

export const BadgeSelector = (props:{group:string, selected:BadgeType,onChange:(b:BadgeType)=>void}) => {
    return <FormGroup controlId="formControlsSelect">
        <ControlLabel>{`Select ${props.group}`}</ControlLabel>
        <FormControl componentClass="select" placeholder="select" value={props.selected} onChange={(e:any) => props.onChange(e.target.value)}>
            {Array.from(badgeMapper.values()).filter(x => x.group === props.group).map(x => {
                return <option value={x.text}>{x.text}</option>;
            })}
        </FormControl>
    </FormGroup>;
}