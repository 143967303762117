"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Severity = void 0;
var Severity;

(function (Severity) {
  Severity["ERROR"] = "error";
  Severity["WARNING"] = "warning";
  Severity["INFO"] = "info";
  Severity["SUCCESS"] = "success";
})(Severity = exports.Severity || (exports.Severity = {}));