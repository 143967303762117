"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToAddressStreet = void 0;

var modbin_domain_1 = require("modbin-domain");

exports.convertToAddressStreet = function (source) {
  var fraction = modbin_domain_1.getStringValueOrDefault(source.morada_fracao, modbin_domain_1.getStringValueOrDefault(source.morada_fraccao, undefined));
  var street = source.morada,
      number = source.morada_numero,
      floor = source.morada_andar,
      locality = source.morada_localidade;
  var result = [street, number, floor, fraction, locality].filter(function (x) {
    return modbin_domain_1.isNotNullOrEmptyString(x, true);
  }).join(" ");
  return modbin_domain_1.isNotNullOrEmptyString(result) ? result : undefined;
};