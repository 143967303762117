"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) {
    if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortableByPropertyGetter = exports.sortable = exports.ComparerType = exports.SortDirection = void 0;

var StringComparer_1 = require("./StringComparer");

var DateComparer_1 = require("./DateComparer");

var PropertyComparer_1 = require("./PropertyComparer"); //import { getLoggerForModule, getLoggerName } from "../../loggers/Debuger";
//var logger = getLoggerForModule(__filename,"sortable");


var SortDirection;

(function (SortDirection) {
  SortDirection["ASC"] = "asc";
  SortDirection["DESC"] = "desc";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));

var ComparerType;

(function (ComparerType) {
  ComparerType["STRING"] = "string";
  ComparerType["DATE"] = "date";
  ComparerType["NUMBER"] = "number";
})(ComparerType = exports.ComparerType || (exports.ComparerType = {}));

var comparerMapper = function () {
  var mapper = new Map();
  mapper.set(ComparerType.DATE.toString().toString(), DateComparer_1.dateComparer);
  mapper.set(ComparerType.STRING.toString(), StringComparer_1.stringComparer);
  return mapper;
}();

exports.sortable = function (propertyName, type) {
  return function (direction) {
    return function (first, second) {
      var comparer = comparerMapper.get(type);
      var valuesToCompare = PropertyComparer_1.getValuesToCompareByPropertyName(first, second, propertyName); //          logger("Sortable by property: first %s, second %s, direction: %s, type:%s", valuesToCompare.first, valuesToCompare.second, direction, type);

      return PropertyComparer_1.propertyComparerWithDirection(valuesToCompare.first, valuesToCompare.second, comparer, direction);
    };
  };
};

exports.sortableByPropertyGetter = function (propertyGetter, type) {
  return function (direction) {
    return function (first, second) {
      var comparer = comparerMapper.get(type);
      var valuesToCompare = PropertyComparer_1.getValuesToCompareByPropertyGetter(first, second, propertyGetter); //        logger("Sortable by property getter: first %s, second %s, direction: %s, type:%s", valuesToCompare.first, valuesToCompare.second, direction, type);

      return PropertyComparer_1.propertyComparerWithDirection(valuesToCompare.first, valuesToCompare.second, comparer, direction);
    };
  };
};

__exportStar(require("./StringComparer"), exports);

__exportStar(require("./DateComparer"), exports);