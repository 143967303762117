import { Lot, Category, SellingType, Currency } from "ibase-auctions-domain";
import moment from "moment";

export const lots:any = [
    new Lot(
    {
        processInsolvent:"Hourojet - Industria e Comércio de Ourivesaria, Lda",
        processId:"4700/19.3T8GMR",
        processReference:"4700/19.3T8GMR",
        // visitas:"Dia 14 de novembro das 10h às 12h00 - Miguel Gracioso : 918730800" //aparentemente são iguais para todos os lotes do leilão
        id:"1",
        officeId:"1",
        eventId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Lote 1",
        description: "Viatura marca Peugeot 206 Comercial de 2 Lugares a Gasóleo, Kms 400.000 matricula 19-64-QX com matricula 19-64-QX da marca Peugeot 206",
        category: Category.VEHICLE,
        reference: "",
        price: {
            reserve: 350,
            market: 0,
            sold: 0,
            starting: 350,
            buyItNow: 0,
            nextBid:400,
            current:0,
            vat: "23",
            currency:Currency.EURO
        },
        address:{
            city: "Guimarães",
            country:"PT",
            street:"",
            zipCode1:"",
            zipCode2:""
        },
        location :undefined,
        published:true,
        featured:true,
        event: {
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: moment("2019-10-30T09:00:00", 'YYYY-MM-DD').toDate(),
                endDate: moment("2020-11-20T11:15:00", 'YYYY-MM-DD').toDate(),
            }
        },
        documents:[],
        subCategory:1,
        thumbnail:"/images/samples/lots/lot1-1.jpg",
        photos: [
        ] 
    }),
    new Lot(
    {
        processInsolvent:"Hourojet - Industria e Comércio de Ourivesaria, Lda",
        processId:"4700/19.3T8GMR",
        // visitas:"Dia 14 de novembro das 10h às 12h00 - Miguel Gracioso : 918730800" //aparentemente são iguais para todos os lotes do leilão
        id:"2",
        officeId:"1",
        eventId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Lote 2",
        description: "Viatura marca Chevrolet Cruze LS a Gasolina de 2010, Kms 117.975 matricula 01-LE-62 com matricula 01-LE-62 da marca Chevrolet Cruze LS",
        category: Category.VEHICLE,
        reference: "",
        price: {
            reserve: 2000,
            market: 0,
            sold: 0,
            starting: 2000,
            buyItNow: 0,
            nextBid:2500,
            current: 2250,
            vat: "23",
            currency:Currency.EURO
        },
        address:{
            city: "Guimarães",
            country:"PT",
            street:"",
            zipCode1:"",
            zipCode2:""
        },
        location : {
            latitude: "41.455276489258",
            longitude: "-8.366389274597"
        },
        published:true,
        featured:true,
        event:{
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: moment("2019-10-30T09:00:00", 'YYYY-MM-DD').toDate(),
                endDate: moment("2019-11-20T11:15:00", 'YYYY-MM-DD').toDate(),
            }
        },
        documents:[],
        subCategory:1,
        thumbnail:"/images/samples/lots/lot2-1.jpg",
        photos: [
        ] 
    }),
    new Lot({
        processInsolvent:"Hourojet - Industria e Comércio de Ourivesaria, Lda",
        processId:"4700/19.3T8GMR",
        // visitas:"Dia 14 de novembro das 10h às 12h00 - Miguel Gracioso : 918730800" //aparentemente são iguais para todos os lotes do leilão
        id:"3",
        officeId:"1",
        eventId:"1",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Lote 3",
        description: "Máquina de Puxar Fio; Aspirador; Cilindros; Lava Mãos; Forja; Máquina Recuperar Ouro; 6 Bancadas de Trabalho; Máquina Polimento; Secador; Máquina Radion; Máquina Esferas; Escritório composto por 2 Secretárias com Alongamento Computador, com Monitor TFT, Impressora H.P e Armário; Existências composto por Pérolas, Zirconicas e Pulseiras, 15 Anéis de Prata Dourada para Criança,vários Artigos em Prata; Máquina Carimbar Peças; Cunhos e Cortantes; Balancé; Máquina de 2 Tambores Abrasivos / Polimento; Máquina Limar Solda; Puxador de Fio e Enrolador Automático; Máquina de Engatar; Máquina de Lapidar Malha; Moldes de Borracha; Computador com Monitor TFT.",
        category: Category.MACHINE,
        reference: "",
        price: {
            reserve: 4650,
            market: 0,
            sold: 0,
            starting: 4650,
            nextBid:4700,
            buyItNow: 0,
            current: 0,
            vat: "23",
            currency: Currency.EURO
        },
        address:{
            city: "Guimarães",
            country:"PT",
            street:"",
            zipCode1:"",
            zipCode2:""
        },
        location : {
            latitude: "41.455276489258",
            longitude: "-8.366389274597"
        },
        published:true,
        featured:true,
        event:{
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: moment("2019-10-30T09:00:00", 'YYYY-MM-DD').toDate(),
                endDate: moment("2019-11-20T11:15:00", 'YYYY-MM-DD').toDate(),
            }
        },
        documents:[],
        subCategory:1,
       // thumbnail:"/images/samples/lots/lot3-1.jpg",
       thumbnail:"/images/single-property-01.jpg",
        photos: [
        ] 
    }),
    new Lot({
        processInsolvent:"Construtorres - Construções, SA",
        processId:"144/19.5T8VFX",
        // visitas: Miguel Gracioso : 91 873 08 00 ( dia 15 de novembro : das 14h30 às 16h30)
        id:"4",
        officeId:"1",
        eventId:"2",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Lote 30",
        description: "RECEPÇÃO – 2 Armários em Madeira com 2 portas de cor castanha, 2 Secretarias, 2 Cadeiras, 2 Computadores com monitores TFT, 2 Cadeiras em acrílico cristal, Central telefónica, Chapeleiro/Bengaleiro e 2 Cestos de Papeis; SALA DE REUNIÕES – Mesa de reuniões com tampo em vidro com 4 cadeiras; ESCRITÓRIO – 4 Estantes, sendo 3 Verticais e uma Horizontal de cor branca do IKEA, 4 Secretarias, 3 Cadeiras, 2 Calculadoras, 2 Computadores com monitor TFT, Cofre, 2 Cestos de Papeis, Armário em madeira castanha com portas e 2 Estantes metálicas com prateleiras; SALA DE SERVIDOR – Servidor, e estantes",
        category: Category.HARDWARE,
        reference: "",
        price: {
            reserve: 1500,
            market: 0,
            sold: 0,
            starting: 1500,
            buyItNow: 0,
            nextBid:1600,
            current: 0,
            vat: "23",
            currency: Currency.EURO
        },
        address:{
            city: "Torres Vedras",
            country:"PT",
            street:"",
            zipCode1:"",
            zipCode2:""
        },
        published:true,
        featured:true,
        event:{
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: moment("2019-10-22T09:00:00").toDate(),
                endDate: moment("2019-11-18T12:00:00").toDate(),
            }
        },
        documents:[],
        subCategory:1,
        thumbnail:"/images/samples/lots/lot4-1.jpg",
        photos: [
            { url:"/images/samples/lots/lot4-1.jpg"} as any,
            { url:"/images/samples/lots/lot4-2.jpg"} as any,
            { url:"/images/samples/lots/lot4-3.jpg"} as any,
            { url:"/images/samples/lots/lot4-4.jpg"} as any
        ] as any
    }),
    new Lot({
        processInsolvent:"Construtorres - Construções, SA",
        processId:"144/19.5T8VFX",
        // visitas: Miguel Gracioso : 91 873 08 00 ( dia 15 de novembro : das 14h30 às 16h30)
        id:"5",
        officeId:"1",
        eventId:"2",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Lote 31",
        description: "RECEGrua Auto-montante da marca PPM Alcance 18 Toneladas; Prumos de Cofragem; Baldes/Funis de Descarga de Entulho; Lote de Andaimes; Lote de Maquinas de pequeno porte, Martelo Elétrico, Berbequim, Vibrador, Rebarbadora Etc; Peneireira de Areia; Betoneira; Dumper ; Pórtico; Mini Retro da marca CEHL Modelo 4635.",
        category: Category.MACHINE,
        reference: "",
        price: {
            reserve: 10000,
            market: 0,
            sold: 0,
            starting: 1500,
            buyItNow: 0,
            nextBid:1600,
            current: 0,
            vat: "23",
            currency: Currency.EURO
        },
        address:{
            city: "Torres Vedras",
            country:"PT",
            street:"",
            zipCode1:"",
            zipCode2:""
        },
        published:true,
        featured:true,
        event:{
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: moment("2019-11-22T09:00:00").toDate(),
                endDate: moment("2019-12-18T12:00:00").toDate(),
            }
        },
        documents:[],
        subCategory:1,
        thumbnail:"/images/samples/lots/lot5-1.jpg",
        photos: [
            { url:"/images/samples/lots/lot5-1.jpg"} as any,
            { url:"/images/samples/lots/lot5-2.jpg"} as any,
            { url:"/images/samples/lots/lot5-3.jpg"} as any,
            { url:"/images/samples/lots/lot5-4.jpg"} as any,
            { url:"/images/samples/lots/lot5-5.jpg"} as any,
            { url:"/images/samples/lots/lot5-6.jpg"} as any,
            { url:"/images/samples/lots/lot5-7.jpg"} as any,
            { url:"/images/samples/lots/lot5-8.jpg"} as any,
            { url:"/images/samples/lots/lot5-9.jpg"} as any,
            { url:"/images/samples/lots/lot5-10.jpg"} as any
        ] as any
    }),
    new Lot({
        processInsolvent:"Selway Apartments",
        processId:"300/190.10T8",
        id:"10",
        officeId:"1",
        eventId:"10",
        createdDate: new Date(),
        modifiedDate: new Date(),
        title: "Selway Apartments",
        description: "lala",
        category: Category.REALESTATE,
        reference: "",
        price: {
            reserve: 1000,
            market: 1200,
            sold: 15000,
            starting:1100,
            buyItNow: 1300,
            current:0,
            vat: "23",
            currency: Currency.EURO
        },
        address:{
            city: "asd",
            country:"PT",
            street:"sdf",
            zipCode1:"",
            zipCode2:"as"
        },
        location : {
            latitude: "40.7427837",
            longitude: "-73.11445617675781"
        },
        published:true,
        featured:false,
        event:{
            type:SellingType.ONLINEAUCTION,        
            dates: {
                startDate: new Date(),
                endDate: new Date()
            }
        },
        documents:[],
        subCategory:1,
        thumbnail:"/images/single-property-01.jpg",
        photos: [
            { url:"/images/single-property-01.jpg"}as any,
            { url:"/images/single-property-02.jpg"}as any,
            { url:"/images/single-property-03.jpg"}as any,
            { url:"/images/single-property-04.jpg"}as any,
            { url:"/images/single-property-05.jpg"}as any,
            { url:"/images/single-property-01.jpg"}as any,
            { url:"/images/single-property-02.jpg"}as any,
            { url:"/images/single-property-03.jpg"}as any,
            { url:"/images/single-property-04.jpg"}as any,
            { url:"/images/single-property-05.jpg"}as any,        
        ] as any
    }),
    new Lot(
        {
            processInsolvent:"Selway Apartments",
            processId:"300/190.10T8",
            id:"11",
            officeId:"1",
            eventId:"10",
            createdDate: new Date(),
            modifiedDate: new Date(),
            title: "Selway Apartments2",
            description: "lala",
            category: Category.REALESTATE,
            reference: "",
            price: {
                reserve: 1000,
                market: 1200,
                sold: 15000,
                starting:1100,
                buyItNow: 1300,
                current:0,
                vat: "23",
                currency: Currency.EURO
            },
            address:{
                city: "asd",
                country:"PT",
                street:"sdf",
                zipCode1:"",
                zipCode2:"as"
            },
            location : {
                latitude: "40.7427837",
                longitude: "-73.11445617675781"
            },
            published:true,
            featured:false,
            event:{
                type:SellingType.ONLINEAUCTION,        
                dates: {
                    startDate: new Date(),
                    endDate: new Date()
                }
            },
            documents:[],
            subCategory:1,
            thumbnail:"/images/single-property-02.jpg",
            photos: [
                { url:"/images/single-property-01.jpg"}as any,
                { url:"/images/single-property-02.jpg"}as any,
                { url:"/images/single-property-03.jpg"}as any,
                { url:"/images/single-property-04.jpg"}as any,
                { url:"/images/single-property-05.jpg"}as any,
                { url:"/images/single-property-01.jpg"}as any,
                { url:"/images/single-property-02.jpg"}as any,
                { url:"/images/single-property-03.jpg"}as any,
                { url:"/images/single-property-04.jpg"}as any,
                { url:"/images/single-property-05.jpg"}as any,
            ] as any
        }),
        new Lot(
            {
                processInsolvent:"Selway Apartments",
                processId:"300/190.10T8",
                id:"12",
                officeId:"1",
                eventId:"10",
                createdDate: new Date(),
                modifiedDate: new Date(),
                title: "Selway Apartments3",
                description: "lala",
                category: Category.REALESTATE,
                reference: "",
                price: {
                    reserve: 1000,
                    market: 1200,
                    sold: 15000,
                    starting:1100,
                    buyItNow: 1300,
                    current:0,
                    vat: "23",
                    currency: Currency.EURO
                },
                address:{
                    city: "asd",
                    country:"PT",
                    street:"sdf",
                    zipCode1:"",
                    zipCode2:"as"
                },
                location : {
                    latitude: "40.7427837",
                    longitude: "-73.11445617675781"
                },
                published:true,
                featured:false,
                event:{
                    type:SellingType.ONLINEAUCTION,        
                    dates: {
                        startDate: new Date(),
                        endDate: new Date()
                    }
                },
                documents:[],
                subCategory:1,
                thumbnail:"/images/single-property-05.jpg",
                photos: [
                    { url:"/images/single-property-01.jpg"} as any,
                    { url:"/images/single-property-02.jpg"} as any,
                    { url:"/images/single-property-03.jpg"} as any,
                    { url:"/images/single-property-04.jpg"} as any,
                    { url:"/images/single-property-05.jpg"} as any,
                    { url:"/images/single-property-01.jpg"} as any,
                    { url:"/images/single-property-02.jpg"} as any,
                    { url:"/images/single-property-03.jpg"} as any,
                    { url:"/images/single-property-04.jpg"} as any,
                    { url:"/images/single-property-05.jpg"} as any,
                ] as any
            })
];