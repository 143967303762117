"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAssetFileUrlMapper = exports.createFileUrlMapper = exports.createAssetFileUrlConverter = exports.createFileUrlConverter = void 0;

exports.createFileUrlConverter = function (fileService) {
  var converter = function converter(file) {
    var parameters = {
      auctionId: file.auction_id.toString(),
      documentoId: file.id
    };
    return fileService.getFileDownloadUrl(parameters);
  };

  return converter;
};

exports.createAssetFileUrlConverter = function (fileService) {
  var converter = function converter(file) {
    var parameters = {
      bemId: file.bem_id.toString(),
      documentoId: file.id
    };
    return fileService.getAssetFileDownloadUrl(parameters);
  };

  return converter;
};

exports.createFileUrlMapper = function (fileService) {
  return {
    sourceType: "file",
    targetType: "fileUrl",
    converter: exports.createFileUrlConverter(fileService)
  };
};

exports.createAssetFileUrlMapper = function (fileService) {
  return {
    sourceType: "assetFile",
    targetType: "assetFileUrl",
    converter: exports.createAssetFileUrlConverter(fileService)
  };
};