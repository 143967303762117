import React, { Fragment, Component } from "react";
import Countdown from 'react-countdown-now';
import { observable } from "mobx";
import { observer } from "mobx-react";
import { getEventDateStateFromDates, SellingEventDateState } from "ibase-auctions-domain";
import { withTranslation } from "react-i18next";
import { TranslationProps } from "modbin-domain";

export type AuctionClockSize = "small"|"medium"|"large";
export type AuctionClockType = "simple"|"extended";

export type AuctionClockProps = {
    startDate:Date,
    endDate:Date,
    nowDate:Date,
    onTick?():void;
    type?:AuctionClockType,
    size:AuctionClockSize
}

const timeMapper = new Map<SellingEventDateState,string>(
    [
        [SellingEventDateState.NOTSTARTED,"notstarted"],
        [SellingEventDateState.DAYS,"days"],
        [SellingEventDateState.DAY,"day"],
        [SellingEventDateState.HOUR,"hour"],
        [SellingEventDateState.MINUTE,"minute"],
        [SellingEventDateState.ENDED,"ended"]
    ]
);

const ExtendedClockView = (props:{children:any}) => {
    return <Fragment>
        <i className="im im-icon-Alarm-Clock"/>
        <span>{props.children}</span>
    </Fragment>;
}

@observer
export class InnerAuctionClockView extends Component<TranslationProps & AuctionClockProps,{}> {
    @observable dateState:SellingEventDateState |undefined = undefined;
    @observable serverTime:number | undefined = undefined;
    @observable clientTime:number = Date.now();

    createState(nowDate:Date){
        return getEventDateStateFromDates(this.props.startDate,this.props.endDate,nowDate);
    }

    getStates(){
        if(this.dateState === undefined){
            return this.createState(new Date(this.getNowDate()));
        }            
        return this.dateState;
    }

    onTick(){
        const newDateState = this.createState(new Date(this.getNowDate()));
        // if((moment(this.props.endDate)).diff(this.getNowDate(),"minute") < 1){
        //     console.log(newDateState)
        // }
        
        if(this.dateState !== newDateState){
            this.dateState = newDateState;
        }
        const now = Date.now();
        this.serverTime = this.getNowDate() + (now - this.clientTime);
        this.clientTime = now;
    }

    getNowDate(){   
        if(this.serverTime === undefined){
            return this.props.nowDate.getTime();
        }
        return this.serverTime!;
    }

    createClock(){
        const newProps:any = {
            date:this.props.endDate.getTime(),
            intervalDelay:0,            
            precision:3,
            onTick:() => this.onTick(),
            onComplete:()=> {this.dateState = this.createState(new Date(this.getNowDate()))},
            now:()=> this.getNowDate(),
            renderer: (props:any) => {
            return <span>{`${props.days}d ${props.hours}h ${props.minutes}m ${props.seconds}s`}</span>;
            }
        };
        const clockView = <Countdown {...newProps}/>;
        return clockView; 
    }

    createInnerBody(date:SellingEventDateState){
        const getTranslation = (key:string) => this.props.t(`common:words.${key}`); 
        if(date === SellingEventDateState.NOTSTARTED){
            return <span>{getTranslation("soon")}</span>;
        }
        if(date === SellingEventDateState.ENDED){
            return <span>{getTranslation("finished")}</span>;
        }
        return this.createClock();
    }

    createBody(dateState:SellingEventDateState){
        const innerBody = this.createInnerBody(dateState);
        if(this.props.type === "extended"){
            return <ExtendedClockView>{innerBody}</ExtendedClockView>
        }
        return innerBody;
    }

    getClass(){
        const {size="small", type="simple"} = this.props;
        return `auction-clock ${type} ${size} ${timeMapper.get(this.getStates())} `
    }

    render(){ 
        return <div className={this.getClass()}>
            {this.createBody(this.getStates())}
        </div>;        
    }
}

export const AuctionClockView = (withTranslation(["domain"])(InnerAuctionClockView as any) as any) as (props:any) => JSX.Element;