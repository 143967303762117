"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Path = exports.getPathSegments = void 0;

var pathNodeModule = require("path");

function getPathSegments(fullPath) {
  var pathComponents = pathNodeModule.parse(fullPath);

  if (pathComponents.ext !== undefined && pathComponents.ext !== null && pathComponents.ext[0] == ".") {
    pathComponents.ext = pathComponents.ext.substring(1);
  }

  var result = {
    fullName: pathComponents.base,
    directory: pathComponents.dir,
    name: pathComponents.name,
    extension: pathComponents.ext,
    mediaType: undefined
  };
  return result;
}

exports.getPathSegments = getPathSegments;

var Path =
/** @class */
function () {
  function Path(path) {
    this._path = path;
    this._pathSegments = undefined;
  }

  Path.prototype.getSegments = function () {
    if (this._pathSegments === undefined) {
      this._pathSegments = getPathSegments(this._path);
    }

    return this._pathSegments;
  };

  Object.defineProperty(Path.prototype, "path", {
    get: function get() {
      return this._path;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Path.prototype, "name", {
    get: function get() {
      return this.getSegments().name;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Path.prototype, "fullName", {
    get: function get() {
      return this.getSegments().fullName;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Path.prototype, "directory", {
    get: function get() {
      return this.getSegments().directory;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Path.prototype, "extension", {
    get: function get() {
      return this.getSegments().extension;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Path.prototype, "mediaType", {
    get: function get() {
      return this.getSegments().mediaType;
    },
    enumerable: false,
    configurable: true
  });
  return Path;
}();

exports.Path = Path;