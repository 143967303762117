import React, { Fragment } from "react";
import { TranslationProps, ITimerService } from "modbin-domain";
import { Lot, getClockDatesFromSellingDates, ClockDate, SellingType } from "ibase-auctions-domain";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { LotSummaryView } from "./LotSummaryView";
import { AuctionClockView } from "../../components/time/ClockCountdownView";
import { getTitle, getDescription } from "../../components/helpers/Properties";
import { application } from "../../../../Startup";
import { NavigationLink } from "../../../../components/layout/navigation/menus/NavigationLink";


export const InnerLotListItemView = observer((props:TranslationProps & {item:Lot, onClick:(id:string, eventId:string)=>void}) => {
    const onClick = (ev:any) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onClick((props.item as any).getId(),props.item.eventId);
    }
    const clockDate:ClockDate = getClockDatesFromSellingDates(props.item.event.dates);
    const getTranslation = (key:string) => props.t(`common:buttons.${key}`); 
    const timerService = application.getContainer().resolve<ITimerService>("timeService");
    const clockView =   props.item.event.type !== SellingType.PRIVATENEGOTIATION 
        ?   <AuctionClockView type="simple" size="small"
                startDate={clockDate.start}
                endDate={clockDate.end} 
                nowDate={timerService.getCurrentDate()}
                t={props.t}
            ></AuctionClockView>
        : <Fragment></Fragment>
    return 	<div className="listing-item" onClick={(ev:any)=> {onClick(ev)}}>

        {/* <SummaryView item={props.item}
        size="small"
        showPictures={true}
        showSellingMode={true}
        badges={[BadgeType.FEATURED]}
         /> */}

        <LotSummaryView item={props.item}></LotSummaryView>
        
        <div className="listing-content">

            <div className="listing-title" style={{paddingBottom:"0px"}}>
                <h4>{getTitle(props.item)}</h4>      
                <NavigationLink className="details button border" to="business:lots:details" state={{eventId:props.item.eventId, lotId:(props.item as any).getId()}}>{getTranslation("details")}</NavigationLink>         
                {/* <a href="# " className="details button border" onClick={(ev:any)=> {onClick(ev)}}>{getTranslation("details")}</a> */}
            </div>
            <div 
                style={{
                    padding: "10px 35px 10px 35px",
                    maxHeight: "70px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    backgroundColor: "#f7f7f7"
                }}
            >
                {getDescription(props.item)}
            </div>
            <ul className="listing-details" style={{padding:"0px", backgroundColor:"white"}}>
            {props.item.processInsolvent != '' && props.item.processInsolvent != null ?
               (/*props.item.processType == 'JUDICIAL' ||*/ props.item.address != null && props.item.address.street == "I" ? ( <li style={{display:"block"}}><strong>{props.t("domain:sales.events.event.insolvent")}: </strong><span>{props.item.processInsolvent}</span></li>):
                <li style={{display:"block"}}><strong>{props.t("domain:sales.events.event.seller")}: </strong><span>{props.item.processInsolvent}</span></li>) :
                null }
                <li style={{display:"block"}}><strong>{props.t("domain:sales.events.event.processId")}: </strong><span>{props.item.processReference}</span></li>
            </ul>

            <div className="listing-footer">
                {/* <ul className="listing-documents">
                    <li><a><i className="fa  fa-file-text-o"></i>Catálogo</a></li>
                </ul> */}
                <span>
                  {clockView}
                </span>
            </div>

        </div>

    </div>;
});

export const LotListItemView = (withTranslation(["domain"])(InnerLotListItemView as any) as any) as (props:any) => JSX.Element;
