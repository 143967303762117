"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoriesProfile = void 0;

var SubCategoryConverter_1 = require("./SubCategoryConverter");

var subCategory = {
  sourceType: "tipo_bem",
  targetType: "subcategory",
  converter: SubCategoryConverter_1.convertToSubCategory
};
exports.categoriesProfile = {
  name: "Categories",
  definitions: [subCategory]
};