import { ILogger, ITimerService } from "modbin-domain";
import { IRestApiClient } from "modbin-api";
import { observable } from "mobx";

export interface IServerTimeService extends ITimerService {
    getServerTime():Promise<number>;
    setTime(time:number):void;
    hasTime():boolean;
}

export class ServerTimeService implements IServerTimeService, ITimerService {
    
    serverTime:number|undefined = undefined;
    @observable clientTimeDifference:number;

    initialized:boolean = false;

    constructor(private apiClient: IRestApiClient,private url:string, private logger:ILogger){
        this.clientTimeDifference = 0;
    }

    initialize():Promise<number>{
        return this.getServerTime().then(x => {
            this.initialized = true;
            return x;
        })
    }
    
    getServerTime():Promise<number>{
        return this.apiClient.get<number>(this.url).then(x => {
            this.serverTime = x;
            this.clientTimeDifference =this.serverTime - Date.now();
            return x;
        })
    }

    getCurrentDateInUtc(): Date {
        if(this.serverTime === undefined){
            //throw new Error("Server time service: not initialized yet. Please call getServerTime first.");
            //this.logger.trace("Supressed error on getCurrentDateInUtc: Server time service: not initialized yet. Please call getServerTime first.");
        }
        return new Date(new Date(Date.now() + this.clientTimeDifference).toISOString());
    }    
    
    getCurrentDate(): Date {
        if(this.serverTime === undefined){
            //throw new Error("Server time service: not initialized yet. Please call getServerTime first.");
            //this.logger.trace("Supressed error on getCurrentDate: Server time service: not initialized yet. Please call getServerTime first.");
        }
        return new Date(Date.now() + this.clientTimeDifference);
    }

    setTime(time:number){
        this.serverTime = time;
        this.clientTimeDifference =this.serverTime - Date.now();
    }

    hasTime(){
        return this.serverTime !== undefined;
    }
}