"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromBannerType = exports.convertToBannerType = void 0;

var Client_types_1 = require("../../client/Client.types");

var modbin_domain_1 = require("modbin-domain");

var DoubleConverter_1 = require("../shared/DoubleConverter");

var bannerTypeMappper = {
  "BANNER_TOP": Client_types_1.BannerType.TOP,
  "BANNER_ADVERTISING_LEFT": Client_types_1.BannerType.EVENTAD,
  "WELCOME_POPUP": Client_types_1.BannerType.POPUP
};
var doubleConverter = DoubleConverter_1.createDoubleConverter(bannerTypeMappper);

exports.convertToBannerType = function (bannerTypeServer) {
  if (modbin_domain_1.isNullOrEmptyString(bannerTypeServer) || bannerTypeMappper[bannerTypeServer] === undefined) {
    return undefined;
  }

  return doubleConverter.toType[bannerTypeServer];
};

exports.convertFromBannerType = function (bannerType) {
  return doubleConverter.fromType[bannerType];
};