import React, { Component } from "react";
import { AddressSelector } from "./AddressSelector";
import { UiTestDescription } from "modbin-web";

export const description:UiTestDescription = {
    id:"AddressSelector",
    groupId:"components",
    description:"lalal",
    title:"Address selector",
    parentId:"components"
};

export class AddressSelectorUiTest extends Component<{},{}>{
    
    render(){
       
        return <div> 
            <div className="row">
                <AddressSelector
                    selectorClass="col-md-4"
                    countyPlaceHolderText="Concelho"
                    parishPlaceHolderText="Freguesia"
                    regionPlaceHolderText="Distrito"
                ></AddressSelector>
            </div>
        </div>;
    }
}