import React from "react";
import { NewsletterSubscriptionForm } from "../../forms/newsletter/NewsletterSubscriptionForm";
import  logo_all_small  from "../../../resources/images/logo_all_small.png";
import { NavigationLink } from "../navigation/menus/NavigationLink";
import { withTranslation } from "react-i18next";
import { TranslationProps } from "modbin-domain";
import  logo_apdel  from "../../../resources/images/logo-apdel.png";

export const InnerNewsletterFooter = (props:TranslationProps) => {
    const getTranslation = (key:string) => props.t(`common:footer.${key}`); 
    return <div className="row">
            <div className="col-md-4 col-sm-6" style={{textAlign:"justify"}}>
                <NavigationLink className="footer-logo" to="public:home"><img src={logo_all_small } alt=""/></NavigationLink>
                <br />      
                <br />                    
                <p>{getTranslation("subscriptionText")}</p>
                <p>{getTranslation("cancelText")}</p>
                <p>{getTranslation("readPolicy")}<NavigationLink to="public:privacy">{getTranslation("privacyLinkText")}</NavigationLink></p>
            </div>
        <div className="col-md-4 col-sm-6 ">
            <h3>{getTranslation("subscriptionTitle")}</h3>
                <NewsletterSubscriptionForm t={props.t}/>
        </div>		
        <div className="col-md-4  col-sm-12">
            <h3>{getTranslation("informationTitle")}</h3>
            <ul className="footer-links" style={{width:"100%"}}>
                <li><NavigationLink to="public:ral">Entidade de Resolução Alternativa de Litigios de Consumo (RAL)</NavigationLink></li>
                <li><NavigationLink to="public:privacy">{getTranslation("privacyLinkText")}</NavigationLink></li>
                <li><a href="https://www.livroreclamacoes.pt/inicio" target="_blank" rel="noopener noreferrer">{getTranslation("complaintsBookLinkText")}</a></li>
            </ul>
            <br />                     
            <p>{getTranslation("authorizationLicenseText")}</p>
            <p>{getTranslation("insuranceText")}</p>
            <div style={{textAlign:"center"}}><img width="60px" src={logo_apdel} alt=""/></div>
        </div>
    </div>;
}

export const NewsletterFooter = withTranslation(["common"])(InnerNewsletterFooter) as any;
