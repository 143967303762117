"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpStatusCode = void 0;
var HttpStatusCode;

(function (HttpStatusCode) {
  HttpStatusCode[HttpStatusCode["OK"] = 200] = "OK";
  HttpStatusCode[HttpStatusCode["CREATED"] = 201] = "CREATED";
  HttpStatusCode[HttpStatusCode["ACCEPTED"] = 202] = "ACCEPTED";
  HttpStatusCode[HttpStatusCode["NON_AUTHORITATIVE_INFO"] = 203] = "NON_AUTHORITATIVE_INFO";
  HttpStatusCode[HttpStatusCode["NO_CONTENT"] = 204] = "NO_CONTENT";
  HttpStatusCode[HttpStatusCode["RESET_CONTENT"] = 205] = "RESET_CONTENT";
  HttpStatusCode[HttpStatusCode["PARTIAL_CONTENT"] = 206] = "PARTIAL_CONTENT";
  HttpStatusCode[HttpStatusCode["MULTIPLE_CHOISES"] = 300] = "MULTIPLE_CHOISES";
  HttpStatusCode[HttpStatusCode["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
  HttpStatusCode[HttpStatusCode["FOUND"] = 302] = "FOUND";
  HttpStatusCode[HttpStatusCode["SEE_OTHER"] = 303] = "SEE_OTHER";
  HttpStatusCode[HttpStatusCode["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
  HttpStatusCode[HttpStatusCode["USE_PROXY"] = 305] = "USE_PROXY";
  HttpStatusCode[HttpStatusCode["SWITCH_PROXY"] = 306] = "SWITCH_PROXY";
  HttpStatusCode[HttpStatusCode["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
  HttpStatusCode[HttpStatusCode["PERMANENT_REDIRECT"] = 308] = "PERMANENT_REDIRECT";
  HttpStatusCode[HttpStatusCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
  HttpStatusCode[HttpStatusCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
  HttpStatusCode[HttpStatusCode["PAYMENT_REQUIRED"] = 402] = "PAYMENT_REQUIRED";
  HttpStatusCode[HttpStatusCode["FORBIDDEN"] = 403] = "FORBIDDEN";
  HttpStatusCode[HttpStatusCode["NOT_FOUND"] = 404] = "NOT_FOUND";
  HttpStatusCode[HttpStatusCode["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
  HttpStatusCode[HttpStatusCode["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
  HttpStatusCode[HttpStatusCode["CONFLICT"] = 409] = "CONFLICT";
  HttpStatusCode[HttpStatusCode["GONE"] = 410] = "GONE";
  HttpStatusCode[HttpStatusCode["UNSUPPORTED_MEDIATYPE"] = 415] = "UNSUPPORTED_MEDIATYPE";
  HttpStatusCode[HttpStatusCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
  HttpStatusCode[HttpStatusCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
  HttpStatusCode[HttpStatusCode["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
  HttpStatusCode[HttpStatusCode["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
  HttpStatusCode[HttpStatusCode["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
  HttpStatusCode[HttpStatusCode["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
  HttpStatusCode[HttpStatusCode["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
})(HttpStatusCode = exports.HttpStatusCode || (exports.HttpStatusCode = {}));