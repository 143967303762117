
export const privateTranslations = {
    pt: {
        profile:{
            title:"Perfil",
            basicInfoTitle:"Informação básica",
            identificationCardTitle:"Identificação",
            contactsTitle:"Contactos",
            addressTitle:"Endereço"
        },
        bids:{
            title:"Lotes em que licitou",
        }
    },
    en:{
        profile:{
            title:"Profile",
            basicInfoTitle:"Basic information",
            identificationCardTitle:"Identification",
            contactsTitle:"Contacts",
            addressTitle:"Address"
        },
        bids:{
            title:"Lots that you bidded",
        }
    }
}