"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserAccountState = void 0;
var UserAccountState;

(function (UserAccountState) {
  UserAccountState["ACTIVATED"] = "activated";
  UserAccountState["UNACTIVATED"] = "unactivated";
  UserAccountState["BLOCKED"] = "blocked";
})(UserAccountState = exports.UserAccountState || (exports.UserAccountState = {}));