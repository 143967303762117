"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseModule = void 0;

var BaseModule =
/** @class */
function () {
  function BaseModule(name) {
    this.dependencies = [];
    this.name = name;
  }

  BaseModule.prototype.configure = function (options) {
    this.container = options.kernel;
  };

  BaseModule.prototype.getDescriptor = function (identifier) {
    var dependency = this.dependencies.find(function (x) {
      return x.name === identifier;
    });

    if (dependency === undefined) {
      throw new Error("Dependency " + dependency + " was not yet added to " + this.name + " module bindings.");
    }

    return dependency.instance;
  };

  BaseModule.prototype.createBindings = function (options) {
    return [];
  };

  return BaseModule;
}();

exports.BaseModule = BaseModule;