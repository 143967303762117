import React, { Fragment } from "react";
import { TranslationProps } from "modbin-domain";
import { Lot } from "ibase-auctions-domain";
import { ProcessDetailsView } from "./ProcessDetailsView";
import { observer } from "mobx-react";
import { getDescription } from "../../components/helpers/Properties";

export const DescriptionView = observer((props:TranslationProps & {item:Lot}) => {
    return <Fragment>
        <h3 className="desc-headline">{props.t("screens:business.lots.details.descriptionTitle")}</h3>
        <div>{getDescription(props.item)}</div>
        <ProcessDetailsView {...props}></ProcessDetailsView>
    </Fragment>;
})





