import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslationProps} from 'modbin-domain';
import { UiTestSpecification } from 'modbin-web';

export const UiTestPanelView = (props:TranslationProps & UiTestSpecification) => {
    const Component = props.Component as any;
    return <div>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        <div>
            <Component t={props.t}></Component>
        </div>
    </div>;
}

export const UiTestPanel = (withTranslation(["domain"])(UiTestPanelView as any) as any) as (props:any) => JSX.Element;