"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertStateToUrlPath = exports.convertParametersToState = exports.convertQueryStringToState = exports.mapFromObject = exports.mapFromStringArray = void 0;

var modbin_domain_1 = require("modbin-domain");

var queryString = require('query-string');

exports.mapFromStringArray = function (urlParameters, mapper, target) {
  urlParameters.forEach(function (x, index) {
    var curr = mapper[index.toString()];

    if (curr !== undefined) {
      target[curr] = x;
    }
  });
  return target;
};

exports.mapFromObject = function (queryStr, mapper, target) {
  Object.keys(queryStr).forEach(function (x) {
    var curr = mapper[x];

    if (curr !== undefined) {
      target[curr] = queryStr[x];
    }
  });
  return target;
};

exports.convertQueryStringToState = function (locationSearchString, mapper, target) {
  var queryStr = queryString.parse(locationSearchString);

  if (queryStr !== undefined) {
    target = exports.mapFromObject(queryStr, mapper, target);
  }

  return target;
};

exports.convertParametersToState = function (options) {
  var stateParam = {};
  var urlParameters = options.urlParameters,
      routeDefinition = options.routeDefinition,
      queryString = options.queryString;

  if (routeDefinition.parametersToStateMapper !== undefined) {
    if (urlParameters !== undefined) {
      stateParam = exports.mapFromStringArray(urlParameters, routeDefinition.parametersToStateMapper, stateParam);
    }

    if (queryString !== undefined) {
      stateParam = exports.convertQueryStringToState(queryString, routeDefinition.parametersToStateMapper, stateParam);
    }
  }

  return stateParam;
};

exports.convertStateToUrlPath = function (routeDefinition, state) {
  var urlParameters = {};

  if (state === undefined) {
    urlParameters = [];
  } else {
    if (routeDefinition.stateToParametersMapper !== undefined) {
      urlParameters = exports.mapFromObject(state, routeDefinition.stateToParametersMapper, urlParameters);
    } else {
      urlParameters = state;
    }
  }

  var urlPath = modbin_domain_1.buildUrlPathFromPathTemplate(routeDefinition.template, urlParameters, {
    begin: ":"
  });
  return urlPath;
};